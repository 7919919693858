import React, { useEffect } from 'react';
import { Router, BrowserRouter, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from './components/Auth';
import ScrollReset from './components/ScrollReset';
import useSettings from './hooks/useSettings';
import { themeCreate } from 'src/theme';
import AppRoutes from './RoutesNew'

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

function App() {
  useStyles();
  const { settings } = useSettings();
  
  return (
    <ThemeProvider theme={themeCreate(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>             
          <BrowserRouter location={history.location} history={history}>
            <Auth>
              <ScrollReset />                                                                
              <AppRoutes />
            </Auth>
          </BrowserRouter>                      
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>       
  );
}

export default App;

    // <>
    //   <BrowserRouter history={history}>
    //     <Auth>
    //       <AppRoutes />
    //     </Auth>
    //     <Test />
    //   </BrowserRouter>
    // </>