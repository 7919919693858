import React from 'react';
import { Card,CardContent,Grid,makeStyles,TextField,
Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Box,
FormControl,InputLabel,Select,MenuItem
} from '@material-ui/core';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
var jwt = require('jsonwebtoken');
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
      paddingTop: "24px",
      paddingBottom: "24px"
    }
  },
  card: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none"
  },
  textField: {
    marginBottom: "15px"
  }
}));

export default function WorkStatus({ companyName,jobTitle,industry,mediaAgency,userId,className, ...rest }) {
  const [editDetailsErrorTitle,setEditDetailsErrorTitle] = React.useState("Success");
  const [editDetailsError,setEditDetailsError] = React.useState("Your work status have been updated");
  const [open, setOpen] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const classes = useStyles();
  return (
      <Grid container spacing={3}
      direction="column"
      alignItems="center"
      justify="center"
      >
      <Card className={classes.card}>
        <CardContent style={{padding : "24px"}}>
        <Grid item  >
           <Formik
              enableReinitialize={true}
      initialValues={{
        company: companyName,
        industry:industry,
        jobTitle: jobTitle,
        mediaAgency: mediaAgency,
      }}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
          setDisableButton(true);
          var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
          var token = jwt.sign({ Id:userId, Company:values.company, Industry:values.industry, JobTitle:values.jobTitle, MediaAgency:values.mediaAgency, token: bearer }, process.env.REACT_APP_ReadToken);
          await fetch('https://abintus-app-api-linux.azurewebsites.net/user/edit/work-status'
          ,{
            method: 'POST',
            headers: {
                'token': token
            }}
            )
          .then(response => response.text()).then(response => {
            if(response === "true")
            {
              setOpen(true);
              token = jwt.sign({ UserId:userId, EventId:10, token: bearer }, process.env.REACT_APP_ReadToken);
              fetch('https://abintus-app-api-linux.azurewebsites.net/user/event'
              ,{
                method: 'POST',
                headers: {
                    'token': token
                }}
                );
            }
            else
            {
              setEditDetailsErrorTitle("Error");
              setEditDetailsError("There was an issue when saving your changes.Please try again.")
              setOpen(true);
            }
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => (
        <form
        onSubmit={handleSubmit}
      >
          <TextField
            className={classes.textField}
            autoComplete="off"
            fullWidth
            label="Your Company"
            margin="normal"
            name="company"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.company}
            variant="outlined"
          />
           <FormControl style={{width:"100%"}} 
                        variant="outlined" className={classes.formControl}>
                        <InputLabel id="IndustryLabel">Industry</InputLabel>
                          <Select
                            labelId="IndustryLabel"
                            label="Industry"
                            id="Industry"
                            name ="industry"
                            value={values.industry}
                            onChange={e => {
                              setFieldValue("industry",e.target.value);
                            }}
                          >
                            <MenuItem value=""></MenuItem>
                            <MenuItem value="Entertainment and Media">Entertainment &amp; Media</MenuItem>
                            <MenuItem value="Travel and Tourism">Travel &amp; Tourism</MenuItem>
                            <MenuItem value="Financial and Insurance">Financial &amp; Insurance</MenuItem>
                            <MenuItem value="Leisure and Gambling">Leisure and Gambling</MenuItem>
                            <MenuItem value="Pharmaceutical">Pharmaceutical</MenuItem>
                            <MenuItem value="Restaurants">Restaurants</MenuItem>
                            <MenuItem value="Services">Services</MenuItem>
                            <MenuItem value="Organizations">Organizations</MenuItem>
                            <MenuItem value="Technology and IT">Technology &amp; IT</MenuItem>
                            <MenuItem value="Healthcare">Healthcare</MenuItem>
                            <MenuItem value="Retail">Retail</MenuItem>
                            <MenuItem value="Clothing">Clothing</MenuItem>
                            <MenuItem value="Public Services">Public Services</MenuItem>
                            <MenuItem value="Real Estate">Real Estate</MenuItem>
                            <MenuItem value="Telecom">Telecom</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
          </FormControl>
           <TextField
           className={classes.textField}
            autoComplete="off"
            fullWidth
            label="Your Job Title"
            margin="normal"
            name="jobTitle"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.jobTitle}
            variant="outlined"
          />
          <FormControl style={{width:"100%"}} 
                        variant="outlined" className={classes.formControl}>
                        <InputLabel id="MediaAgencyLabel">Media Agency You Work With</InputLabel>
                          <Select
                            labelId="MediaAgencyLabel"
                            label="Media Agency You Work With"
                            id="MediaAgency"
                            name="mediaAgency"
                            value={values.mediaAgency}
                            onChange={e => {
                              setFieldValue("mediaAgency",e.target.value);
                            }}
                          >
                            <MenuItem value=""></MenuItem>
                            <MenuItem value="Arena Media">Arena Media</MenuItem>
                            <MenuItem value="Blue 449">Blue 449</MenuItem>
                            <MenuItem value="Carat">Carat</MenuItem>
                            <MenuItem value="Essence">Essence</MenuItem>
                            <MenuItem value="Havas Media">Havas Media</MenuItem>
                            <MenuItem value="Hearts and Science">Hearts &amp; Science</MenuItem>
                            <MenuItem value="Horizon Media">Horizon Media</MenuItem>
                            <MenuItem value="Madison Media">Madison Media</MenuItem>
                            <MenuItem value="Mediacom">Mediacom</MenuItem>
                            <MenuItem value="Mindshare">Mindshare</MenuItem>
                            <MenuItem value="OMD">OMD</MenuItem>
                            <MenuItem value="PHD">PHD</MenuItem>
                            <MenuItem value="PlatformGSK">PlatformGSK</MenuItem>
                            <MenuItem value="Spark Foundry">Spark Foundry</MenuItem>
                            <MenuItem value="Starcom">Starcom</MenuItem>
                            <MenuItem value="UM">UM</MenuItem>
                            <MenuItem value="Vizeum">Vizeum</MenuItem>
                            <MenuItem value="Wavemaker">Wavemaker</MenuItem>
                            <MenuItem value="Zenith">Zenith</MenuItem>
                            <MenuItem value="Dentsu X">Dentsu X</MenuItem>
                            <MenuItem value="m/SIX">m/SIX</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                            <MenuItem value="None">None</MenuItem>
                          </Select>
          </FormControl>
          <Box mt={2}>
            <SaveChangesButton
              color="secondary"
              disabled={disableButton}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Save Changes
            </SaveChangesButton>
          </Box>
          <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="DialogTitle">
          <DialogTitle id="DialogTitle">{editDetailsErrorTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{editDetailsError}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleClose}>Close</Button>
          </DialogActions>
          </Dialog>
        </form>
        
      )}
    </Formik>
                      
        </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

const SaveChangesButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem;
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 30px;
  transition: 0.2s;
  font-family: Poppins;

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
  }
`

WorkStatus.propTypes = {
  companyName: PropTypes.string,
  jobTitle: PropTypes.string,
  userId: PropTypes.any,
  industry: PropTypes.string,
  mediaAgency: PropTypes.string,
  className: PropTypes.string
};




