import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Box,
  Container,
  Card,
  Typography,
  makeStyles,
  TextField,
  FormHelperText
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Page from 'src/components/Page';
import LogoDark from 'src/components/Logo';
import { confirmVerificationCode } from 'src/actions/accountActions';
import { submit } from 'redux-form';
var jwt = require('jsonwebtoken');

const useStyles = makeStyles((theme) => ({
    root: {
      justifyContent: 'center',
      backgroundColor: "#F2F2F2",
      display: 'flex',
      height: '100%',
      minHeight: '100%',
      flexDirection: 'column',
      paddingBottom: 80,
      paddingTop: 80
    },
    backButton: {
      marginLeft: theme.spacing(2)
    },
    card: {
      overflow: 'visible',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      boxShadow: 'none',
      borderRadius: '30px',
      padding: "20px 50px 20px 50px",
      '& > *': {
        flexGrow: 1,
        // flexBasis: '50%',
        // width: '50%'
      }
    },
    errorMessage: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginBottom: "10px"
    }
}));

function ConfirmAuthView({ verify, email }) {
    const classes = useStyles();    
    const [code, setCode] = useState('');
    const [errors, setErrors] = useState(false);
    
    async function submit(code) {    
      try {
        await verify(code);
      }
      catch (err) {
        setErrors(true);
      }      
    }

    return (
        <Page className={classes.root} title="Confirm Code">
            <Container maxWidth="sm">
                <RouterLink to="/">
                  <LogoDark />
                </RouterLink>
                <Card className={classes.card}>
                    <Typography variant="h1">Confirm<br/> Authentication</Typography>
                    <Typography variant="body1">Please enter the code that we sent to <strong>{email}</strong></Typography>                      
                      <TextField                        
                          fullWidth                                                
                          label="Enter code here"
                          margin="normal"                                            
                          variant="outlined"
                          autoComplete='off'
                          style={{marginBottom: "20px"}}
                          onChange={(e) => setCode(e.target.value)}
                      />
                      {
                        errors && (
                          <div className={classes.errorMessage}>
                            <FormHelperText error>
                              Wrong code, please try again.
                            </FormHelperText>
                          </div>
                        )
                      }
                      <ConfirmButton onClick={() => submit(code)}>Confirm</ConfirmButton>
                </Card>
            </Container>
        </Page>
    )
}

const ConfirmButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem;
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 30px;
  transition: 0.2s;

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
  }
`

export default ConfirmAuthView;