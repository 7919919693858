/* eslint-disable */
import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
var jwt = require('jsonwebtoken');

function AuthGuard({ children }) {
  var accessToken = localStorage.getItem('accessToken');
  if(accessToken === null) {
    // if(window.location.href.includes("expert-assessment")) {
    //   var token = window.location.href.split('token=')[1];
    //   localStorage.setItem('accessToken',token);
    // }
    // else
    // {      
      return <Navigate to="/login" replace />
    // }
  }
  var verified = "";
  var email = "";
  var id = "";
  jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {
    if(err)
    { 
      return <Navigate to="/" replace/>
    }
    else
    {
      verified = decoded.verified;
      email = decoded.username;
    }
  });
  var dataLayer = window.dataLayer = window.dataLayer || []; 
  dataLayer.push({ "user_id": id });
  if(verified === false ) {
    return <Redirect to={{pathname:"/verify-email",state:{email:email}}} />;
  }
  else if(verified === "") {    
    return <Navigate to="/" replace/>
  }
  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
