import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from "./Hero.module.css"
import styled, { css } from 'styled-components'
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  Button,
  withStyles,
  Hidden,
  useMediaQuery
} from '@material-ui/core';
import { useHistory } from 'react-router';
import cx from "classnames"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",    
    // backgroundImage: `url(${HomeImage})`,
    backgroundAttachment: window.innerWidth > 500 ? "fixed":"scroll",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    background:"100%",
    width: "100vw",
			//backgroundAttachment: "fixed",
    paddingTop: 50,
    marginBottom: 250,
    [theme.breakpoints.down('md')]: {
      paddingTop: 40,
      marginBottom: 40
    },
  },
  header: {
    fontSize: "65px",
    color: "black",
    fontWeight: "900",
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: "40px", 
    }
  },
  subHeader: {
    [theme.breakpoints.down('md')]: {
      display: "none",
    }
  },
  rightBox: {
    display:"flex",
    flexDirection: "column",
    justifyContent: "center",
    height:"100%",
    paddingTop: "50%", 
    [theme.breakpoints.down('md')]: {      
      alignItems: "left"
    },
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },  
  backgroundRectangleMobile: {
    position: "absolute",      
    opacity: "50%",
    borderRadius: "20px",    
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      display: "none"
    },
  },

  "@keyframes slide": {
    "0%": { transform: "translateX(-100%)" },
    "100%": { transform: "translateX(0)" }
  },
}));

const LaunchButton = withStyles((theme) => ({
  root: {
    // animation: "pulse 2s infinite",
    padding: "9px 25px",
    [theme.breakpoints.down('sm')]: {
      padding: "5px 15px"
    },
    fontSize: 18,
    borderRadius: 0,
    fontFamily: "Poppins",
    fontWeight: "Bold",
    color: "#FFFFFF",
    textTransform: 'none',
    backgroundColor: "#000000",
    border: "2.5px solid #000",
    transition: "0.3s ease-in",
    zIndex: 2,

    '&:hover': {
      backgroundColor: "#75B8BF",
    },
  },
}))(Button);

function Hero({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const [fadeIn, setFadeIn] = useState(false);
  const [delayedFadeIn, setDelayedFadeIn] = useState(false);
  const mobile = useMediaQuery('(max-width:600px');
  var navigate = useNavigate();

  useEffect(() => {    
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true)
    }, 100)
    setTimeout(() => {
      setDelayedFadeIn(true);
    }, 300)
  }, [])

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <div className={styles.container} style={{ position: "relative" }}>
        <div className={cx(styles.backgroundRectangle, fadeIn && styles.fadeIn)} style={{backgroundColor: "#D0E5F2", height: "300px", width: "340px", top: "20%", left: "-3%"}}/>          
        <div className={cx(styles.backgroundRectangle, delayedFadeIn && styles.fadeIn)} style={{backgroundColor: "#75B8BF", height: "250px", width: "250px", top: "60%", left: "11%"}} />
        <div className={cx(styles.backgroundRectangle, fadeIn && styles.fadeInRight)} style={{backgroundColor: "#F2C230", height: "255px", width: "243px", top: "83%", right: "19%"}} />
        <div className={cx(styles.backgroundRectangle, delayedFadeIn && styles.fadeInRight)} style={{backgroundColor: "#025373", height: "134px", width: "344px", top: "93%", right: "-1%", opacity: delayedFadeIn && "40%" }} />
        <div className={cx(styles.backgroundRectangleMobile, fadeIn && styles.fadeIn)} style={{backgroundColor: "#D0E5F2", height: "100px", width: "150px", top: "0%", left: "-10%"}} />
        <div className={cx(styles.backgroundRectangleMobile, delayedFadeIn && styles.fadeInRight)} style={{backgroundColor: "#F2C230", height: "150px", width: "250px", bottom: "10%", right: "0%"}} />
        <div className={cx(styles.backgroundRectangleMobile, fadeIn && styles.fadeInRight)} style={{backgroundColor: "#75B8BF", height: "150px", width: "250px", bottom: "1%", right: "-40%"}} />        
          <div className={styles.leftBox}>
            <h1
              className={cx(styles.header, fadeIn && styles.fadeIn)}
              variant="h1"
              align="left"     
            >              
              Evaluate your media agency contract with our app.
            </h1>              
          </div>           
          <div className={cx(styles.rightBox, fadeIn && styles.fadeIn)}>
            <Typography
            variant="h4"
            className={classes.subHeader}
            style={{textAlign: "right", fontSize: "25px", marginBottom: "25px" }}
            >
              Find out what improvements are required to get the most<br/>out of your media agency.
            </Typography>
            <div mt={3} style={{display: "flex", justifyContent: mobile ? "flex-start" : "flex-end" }} className={classes.button}>
              <LaunchButton onClick={() => navigate('/app/getting-started')}>Start Evaluating</LaunchButton>
            </div>
          </div>           
      </div>
    </div>
  );
}

const leftBox = styled.div`

`
Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
