import React from 'react';
import { useRef, useState, useEffect} from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import Contract from './Media/contract.png'
import Challenge from './Media/challenge.png'
import Presenter from './Media/presenter.png'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    height: "160vh",      
    marginBottom: "50px",
    [theme.breakpoints.down('sm')]: {
      height: "auto"
    },
  },
  contentGrid: {
    display: "flex",
    height: "33%",
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "center",
      height: "auto",
      marginBottom: "50px"
    }
  }, 
  header: {
    color: "#000000",
    fontSize: "2.5rem",
    marginBottom: "15px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.5rem"
    }
  },
  paragraph: {
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.90rem",
      marginBottom: "20px",
    }
  },
  image: {
    height: "355px",
    width: "500px",
    [theme.breakpoints.down('sm')]: {
      height: "210px",
      width: "294px",
    }
  }
}));

function Features({ className, ...rest }) {
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:900px)');

  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0.5 })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.5 })
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 0.5 })

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      {/* <Container maxWidth="lg" style={{padding: 0, width: "100%"}}> */}
        <Grid className={classes.contentGrid}>          
          <SliderFromLeft 
          visible={inView1}
          ref={ref1}                  
          >         
            <Typography 
            variant="h1"
            align="left"
            className={classes.header}            
            >
              Are you getting the most out of your contract?
            </Typography>
            {
              mobile ? (
                <Typography variant="h4" className={classes.paragraph} >
                  Growing media agency complexity fosters opaque financial structures, 
                  permitting arbitrary fee hikes. Advertisers grapple with outdated, non-transparent contracts.
                </Typography>
              ) : (
                <Typography variant="h4" className={classes.paragraph} >
                  Increased complexity has enabled media agencies 
                  to create opaque financial structures 
                  and then inflate their fees and 
                  media prices at will, while many 
                  advertisers are stuck behind contracts 
                  unfit for modern media buying that 
                  provide little scope for transparency and accountability.
                </Typography>
              )
            }
          </SliderFromLeft>
          <NonSlider style={{justifyContent: "center", padding: mobile && 0}}>       
            <img src={Contract} className={classes.image} />   
          </NonSlider>
        </Grid>
        <Grid className={classes.contentGrid}> 
          {/* Not sure why I conditionally rendered this but not the others, too lazy to change */}
          {!mobile ? 
          <>
            <NonSlider style={{justifyContent: "center", padding: mobile && 0}}>        
              <img className={classes.image} src={Challenge} />    
            </NonSlider>
            <SliderFromRight 
            visible={inView2}        
            ref={ref2}
            >
              <Typography
              variant="h1"
              align="right"
              className={classes.header}            
              >
                The Challenge
              </Typography>
              <Typography
              variant="h4" 
              align="right"     
              className={classes.paragraph}      
              >        
              We want you to get the same benefits that the top global advertisers enjoy. 
              The core of a successful relationship between you and your media agency is a well thought-out contract.
              <br/>
              <br/>
              You must ensure that your media agency contract provides clarity and accountability, 
              and contains competitive terms and conditions.
              </Typography>
            </SliderFromRight>
          </> 
          : 
          <>
            <SliderFromRight 
            visible={inView2}        
            ref={ref2}
            >
              <Typography
              variant="h1"
              align="right"
              className={classes.header}            
              >
                The Challenge
              </Typography>                            
              <Typography variant="h4" className={classes.paragraph}>                  
                Elevate your advantages to match those of global advertisers by crafting a robust media agency contract. 
                Prioritize clarity, accountability, and competitive terms for a successful partnership.
              </Typography>
            </SliderFromRight>
            <NonSlider style={{justifyContent: "center", padding: mobile && 0}}>        
              <img className={classes.image} src={Challenge} />    
            </NonSlider>
          </>}
        </Grid>
        <Grid className={classes.contentGrid}>
          <SliderFromLeft 
          visible={inView3}                
          ref={ref3}
          >
            <Typography 
            variant="h1"
            align="left"
            className={classes.header}          
            >
              The Solution
            </Typography>
            {
              mobile ? (
                <Typography variant="h4" className={classes.paragraph}>                  
                  Media agencies often create self-serving contract templates. To regain control, 
                  benchmark your contract against industry standards, 
                  revealing strengths and weaknesses for necessary improvements.
                </Typography>
              ) : (
                <Typography variant="h4" className={classes.paragraph} >
                  It is known that most media agencies design their own contract templates to benefit themselves and not you. 
                  We believe this needs to change and you need to regain control.
                  <br/>
                  <br/>
                  To assess the quality of your contract, you must first benchmark it against the industry average. 
                  Once you know the the strengths and weaknesses of the contract, it is easier to know what needs to be 
                  improved upon.
                </Typography>
              )
            }
          </SliderFromLeft>
          <NonSlider style={{justifyContent: "center", padding: mobile && 0}}>    
            <img src={Presenter} className={classes.image} />        
          </NonSlider>
        </Grid>
      {/* </Container> */}
    </div>
  );
}

const SliderFromLeft = styled.div`
  height: 100%;
  width: 50%;
  // display: flex;
  align-items: center;
  padding: 75px 75px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  opacity: 0;  
  transition: transform 400ms ease-in, opacity 0.5s ease-in;
  @media (max-width: 600px) {
    padding: 0;
    width: 75%;
  }
  ${props => props.visible && `    
    opacity: 100;`
  }
`
const SliderFromRight = styled.div`
  height: 100%;
  width: 50%;
  // display: flex;
  align-items: center;
  padding: 75px 100px 75px 75px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  opacity: 0;
  transition: transform 400ms ease-in, opacity 0.5s ease-in;
  @media (max-width: 600px) {
    padding: 0;
    width: 75%;
  }
  ${props => props.visible && `
    // transform: translateX(0%)
    opacity: 100;`
}
`
const NonSlider = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  padding: 0 75px 0 75px;
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
