import React from 'react';
import {
    Box,
    Container,
    Grid,
    Typography,
    makeStyles,
    Button,
    withStyles,
    Hidden
  } from '@material-ui/core';
  import Macbook from './Media/macbookPro.png';

  function Device() {

    return (
        <img src={Macbook} style={{height:"500px", width:"1000px"}}/>
    )
  }

  export default Device;