import React, { useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    makeStyles,
    useMediaQuery,
} from '@material-ui/core';
import './Benefits.css'
import WhiteChevron from './Media/white-chevron.png'
import GrayChevron from './Media/gray-chevron.png'
import Phone from './Media/iphone-abintus.png'
import Laptop from './Media/laptop-stylized.png'
import Tablet from './Media/tablet-abintus.png'

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            display: "none"
        }
    },
    container: {
        padding: "25px 65px",
        display: "flex",
        justifyContent: "space-between" 
    },
    text: {
        color: "#FFFFFF",
        marginBottom: "20px"
    },
}));


function Benefits() {
    const classes = useStyles(); 
    
    const [active, setActive] = useState({
        activeObject: 0,
        objects: [{
            id: 0,
            title: "Speed",
            message:  "Get our contract assessed, scored and benchmarked from within minutes",
            image: Phone,
            minHeight: "400px",
            minWidth: "400px",
            maxHeight: "400px",
            maxWidth: "400px",
            color: "#025373",
        }, 
        {
            id: 1,
            title: "Convenience",
            message: "Our app is ready when you are, wherever you are. You just need an internet connection!",
            image: Laptop,  
            minHeight: "550px",
            minWidth: "525px",
            color: "#75B8BF",
        },
        {   id: 2,
            title: "Confidentiality",
            message: "Don't want to share your contract? Not a problem. Just use the Self-Assessment option.",
            image: Tablet,
            minHeight: "400px",
            minWidth: "400px",
            color: "#F2D680"
        }]
    });

    function incrementActive() {
        if (active.activeObject == 0){
            setActive({...active, activeObject: 2});
        }
        else {
            setActive({...active, activeObject: active.activeObject - 1});
        }
    }

    function decrementActive() {
        if (active.activeObject == 2){
            setActive({...active, activeObject: 0});
        }
        else {
            setActive({...active, activeObject: active.activeObject + 1});
        }
    }

    return (
        <div className={classes.root}>     
            <Typography variant="h1" style={{fontSize: "2.5rem", marginBottom: "5px", paddingLeft: "75px"}}>Designed for you</Typography>
            <Typography variant="body1" style={{fontSize: "20px", marginBottom: "0px", paddingLeft: "75px"}}>Discover the Abintus App</Typography>
            <div className={classes.container}>
                {active.objects.map((e, index) => {                    
                    return (
                        <div key={index} className="carousel-viewport" style={{backgroundColor: e.color, display: e.id == active.activeObject ? "flex" : "none"}}>
                            <a onClick={() => incrementActive()} className="next-button" id="left-chevron"><img src={WhiteChevron} /></a>
                            <div key={index}  className="slide">
                                <Typography variant="h1" className={classes.text}>{e.title}</Typography>
                                <Typography variant="h4" className={classes.text}>{e.message}</Typography>
                            </div>
                        </div>
                    )
                })}
                <div className="carousel-viewport" style={{backgroundColor: "#F3F3F3", position: "relative"}}>
                    {active.objects.map((e, index) => {
                        return (
                            <div key={index} className="slide-right" style={{display: e.id == active.activeObject ? "flex" : "none"}}>
                                <img src={e.image} className="image" style={{maxHeight: e.maxHeight, maxWidth: e.maxWidth, minHeight: e.minHeight, minWidth: e.minWidth}}/>
                            </div>
                        )
                    })}
                    <a onClick={() => decrementActive()} className="next-button" style={{position: "absolute", right: "0"}}><img src={GrayChevron} /></a>
                </div>
            </div>
        </div>
    )
}


export default Benefits;