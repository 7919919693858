/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, {useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styled from 'styled-components';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import Poppins from "@fontsource/poppins";
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Link,
  Button,
  Typography,
  makeStyles,
  Grid, CardContent,TextField,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Hidden, useMediaQuery
} from '@material-ui/core';
import BlankNDA from './NDAExample.pdf'
import NDADoc from './NDANew.pdf'
import fontkit from '@pdf-lib/fontkit'
var jwt = require('jsonwebtoken');

const useStyles = makeStyles((theme) => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  dialog: {
    backgroundColor: "#FFFFFF",

    "& > &": {
      backgroundColor: "#FFFFFF"
    }
  }
}));

function NDA({
  className,
  onBack,
  onNext,
  onComplete,
  setDbValues,  
  updateId,
  setActiveStep,
  ...rest
}) {
  const classes = useStyles();
  const [showdialog,setshowdialog] = useState(false);
  const [documentFile, setDocumentFile] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const mobile = useMediaQuery('(max-width:900px)')
  useEffect(() => { 
    jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {
      setFirstName(decoded.firstName);
      setLastName(decoded.lastName);
    });
  },[]);
  
  const handleClose = () => {
    setshowdialog(false);
  };


const handleDialog = async (name,country,number,address) => {
  if(name !== "" && country!== "" && number !== "" && address!== "")
  {
    setshowdialog(true);
    const buffer = await fetch(NDADoc).then(res => res.arrayBuffer());
    const existingPdfDocBytes = new Uint8Array(buffer);
    const pdfDoc = await PDFDocument.load(existingPdfDocBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const fontSize = 8;
    //pdfDoc.registerFontkit(Monserrat);
    //const fontUrl = 'https://fonts.googleapis.com/css2?family=Montserrat&display=swap';
   // const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const font = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const boldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);    
    firstPage.drawText(`${name}, a company registered in ${country} with registration number ${number},`, {
    x: 120,
    y: 661,
    size: fontSize,
    font: font,
  });
  firstPage.drawText(`whose registered office is ${address} (“The Discloser")`, {
    x: 112,
    y: 651,
    size: fontSize,
    font: font,
  });
  firstPage.drawText(`${new Date(Date.now()).toLocaleString().substring(0,10)}`, {
    x: 400,
    y: 762,
    size: fontSize,
    font: font,
  });
  const pdf = await pdfDoc.save();
  const url = window.URL.createObjectURL(new Blob([pdf], { type: 'application/pdf' } ));
  setDocumentFile(url);
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', 'NDA.pdf');
    // document.body.appendChild(link);
    // link.click();    
    setDbValues(prev => ({...prev, nda: pdfDoc}))
  return pdfDoc;
  }
};

  return (
    <Formik
    enableReinitialize={true}
    // initialTouched={{ 
    //   companyName: true,
    //   companyRegCountry: true,
    //   companyRegNumber: true,
    //   officeAddress: true,
    // }}
    
    validateOnMount
    initialValues={{
      companyName: "",
      companyRegCountry: "",
      companyRegNumber: "",
      officeAddress: "",
      fullName:firstName + " " + lastName,
    }}
    validationSchema={Yup.object().shape({
      companyName: Yup.string().max(255).required('Company Name is Required'),
      companyRegCountry: Yup.string().max(255).required('Company Registration Country is Required'),
      companyRegNumber: Yup.string().max(255).required('Company Registration Number is Required'),
      officeAddress: Yup.string().max(255).required('Office Address is Required'),
      fullName: Yup.string().max(255).required('Your Full Name is Required'),
    })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
        setCurrentId
      }) => {
        
        try {
          const buffer = await fetch(documentFile).then(res => res.arrayBuffer());
          const existingPdfDocBytes = new Uint8Array(buffer);
          const pdfDoc = await PDFDocument.load(existingPdfDocBytes);
          const pages = pdfDoc.getPages();
          const secondPage = pages[1];
          const fontSize = 10;
          pdfDoc.registerFontkit(fontkit);
          const font = await pdfDoc.embedFont(StandardFonts.TimesRoman);
          secondPage.drawText(`${values.fullName} ${new Date(Date.now()).toLocaleString().substring(0,10)}`, {
            x: 330,
            y: 685,
            size: fontSize,
            font: font,
          });
          const pdf = await pdfDoc.save();          
          const url = window.URL.createObjectURL(new Blob([pdf], { type: 'application/pdf' } ));
          window.open(url, '_blank');
          // FileSaver.saveAs(url,
          //   "SignedNDA.pdf");
          const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'SignedNDA.pdf');
            document.body.appendChild(link);
           link.click();

           // create new entry
           var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
           var accessToken= localStorage.getItem('accessToken');
           var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);
           var token = jwt.sign({ token: bearer,Id:validToken.id,AccessToken:accessToken}, process.env.REACT_APP_ReadToken);
           const response = await fetch('https://abintus-app-api-linux.azurewebsites.net/expert-assessment-journey/add-payment', { method: 'POST', headers: {'token': token}});

           // fetch created entry
           var id = 0;
           jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {
            id = decoded.id;
           });
           var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
           var token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);           
           var mostRecentInstance = await fetch(`https://abintus-app-api-linux.azurewebsites.net/user/expert-assessment/most-recent/${id}` ,{
             method: 'GET',
             headers: {
                 'token': token
             }}
           );
           // test
           var result = await mostRecentInstance.json();          
           var currentInstanceId = result[0];                     
           updateId(result[0]);
           // upload nda
          const session = await response.json();                        
          var formData = new FormData();
          formData.append('file', new Blob([pdf], { type: 'application/pdf' } ));
          bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
          accessToken = localStorage.getItem('accessToken');
          validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);
          var todayDate = new Date().toISOString();
          var name = `NDA-${validToken.id}-${todayDate}.pdf`;

          // get user email
          var clientEmail = ""
          var token = jwt.sign({ UserId: validToken.id, token: bearer }, process.env.REACT_APP_ReadToken);          
          await fetch('https://abintus-app-api-linux.azurewebsites.net/user/get-email', 
          {
            method: 'GET',
            headers: {
              'token': token
            }
          })
          .then(res => res.json())
          .then(res => clientEmail = res[0][1]);
          
          var token = jwt.sign({ Id: validToken.id, InstanceId:currentInstanceId, Email:clientEmail, FirstName:firstName, DocumentName:name, token: bearer }, process.env.REACT_APP_ReadToken);
          await fetch('https://abintus-app-api-linux.azurewebsites.net/nda/upload'
          ,{
            method: 'POST',
            headers: {
              'token': token
          },
            body:formData
          }
            )
            .then(res => res.text());            
          setStatus({ success: true });
          setSubmitting(false);
          if (onComplete) {
            onComplete();
          }            
          if (onNext) {
            onNext();
          }
        }
         catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        validateForm,
        validateField,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <CardContent>
            {/* <Box mt={4} justifyContent="center" align="center">
            <img src={IntroPicture} alt="AgencyContract" style={{height: '80%',
               width: '80%'}}></img>
            </Box> */}
            <Grid container spacing={2} style={{ position: "relative" }}>
            <Grid item md={12} xs={12}><Box mt={2}>
            <Box
              display="flex"
              alignItems="center"
            >
               <Typography
            variant="h5"
            color="textPrimary"
          >
         Thank you for your request. 
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
               <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
          >
           The next step is for you to sign the following NDA for confidentiality purposes.  We guarantee that your contractual information will remain 100% confidential and will be held securely at all times.   
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
            <Typography
            variant="body1"
            color="textPrimary"
          >
          Please review the attached NDA document. Once you are happy to proceed, just add your name and e-sign the document.  
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
            <Typography
            variant="body1"
            color="textPrimary"
          >
          If you have ANY technical issues at any time, please don’t hesitate to let us know by emailing us at <Link style={{color:"#14213D",textDecoration:"underline"}} href="mailto:support@abintus.consulting">support@abintus.consulting</Link>. 
          </Typography>
            </Box>
            </Box></Grid>
            <Grid item md={5} xs={12}>
              <Hidden mdUp><Button fullWidth  size="large" color="secondary" variant="contained" href={BlankNDA} download="NDAExample.pdf">Download Example NDA</Button></Hidden>
            <TextField
            error={Boolean(touched.companyName && errors.companyName)}
            fullWidth
            autoComplete='off'
            helperText={touched.companyName && errors.companyName}
            label="Company Name"
            margin="normal"
            name="companyName"
            onBlur={handleBlur}
            onChange={handleChange}
            type="companyName"
            value={values.companyName}
            variant="outlined"
          />
           <TextField
            error={Boolean(touched.companyRegCountry && errors.companyRegCountry)}
            fullWidth
            autoComplete='off'
            helperText={touched.companyRegCountry && errors.companyRegCountry}
            label="Company Registration Country"
            margin="normal"
            name="companyRegCountry"
            onBlur={handleBlur}
            onChange={handleChange}
            type="companyRegCountry"
            value={values.companyRegCountry}
            variant="outlined"
          />
           <TextField
            error={Boolean(touched.companyRegNumber && errors.companyRegNumber)}
            fullWidth
            autoComplete='off'
            helperText={touched.companyRegNumber && errors.companyRegNumber}
            label="Company Registration Number"
            margin="normal"
            name="companyRegNumber"
            onBlur={handleBlur}
            onChange={handleChange}
            type="companyRegNumber"
            value={values.companyRegNumber}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.officeAddress && errors.officeAddress)}
            fullWidth
            autoComplete='off'
            helperText={touched.officeAddress && errors.officeAddress}
            label="Office Address"
            margin="normal"
            name="officeAddress"
            onBlur={handleBlur}
            onChange={handleChange}
            type="officeAddress"
            value={values.officeAddress}
            variant="outlined"
          />
           <Box mt={2}>
            <EsignButton
              color="secondary"
              onClick={()=>  {setFieldTouched("companyName",true);setFieldTouched("companyRegCountry",true);setFieldTouched("companyRegNumber",true);setFieldTouched("officeAddress",true);handleDialog(values.companyName,values.companyRegCountry,values.companyRegNumber,values.officeAddress)}}
              fullWidth
              size="large"
              variant="contained"
            >
              E-Sign and download NDA
            </EsignButton>
            </Box>
            <BackButton onClick={() => setActiveStep(activeStep => activeStep - 1)}>Back</BackButton>
            </Grid>
            <Hidden mdDown>
              <Grid item md={5} xs={12}> <Box>
              <iframe title="NDAExample" height="600px" width="500px" src={BlankNDA}/>
              </Box></Grid>
            </Hidden>
            </Grid>           
          </CardContent>
          <Dialog
            open={showdialog}
            onClose={handleClose}
            aria-labelledby="DialogTitle"            
          >
            <DialogTitle id="DialogTitle" style={{backgroundColor: "#FFFFFF"}}>E-SIGN AND DOWNLOAD NDA</DialogTitle>
            <DialogContent className={classes.dialog}>
            <DialogContentText>
              Enter your full name and click "E-Sign" to sign and download NDA
            </DialogContentText>
            <Box>
            <TextField
              error={Boolean(touched.fullName && errors.fullName)}
              fullWidth
              autoComplete='off'
              helperText={touched.fullName && errors.fullName}
              label="Full Name"
              margin="normal"
              name="fullName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="fullName"
              value={values.fullName}
              variant="outlined"
            />
            </Box>
            <Box mt={4}>
            <Hidden mdDown>
            <iframe style={{border: "none"}} title="NDA" height="600px" width="550px" src={documentFile} type="application/pdf"/>
            </Hidden>
            </Box>
            </DialogContent>
            <DialogActions style={{backgroundColor: "#FFFFFF", display: "flex", flexDirection: mobile ? 'column' : 'row' }}>
              <EsignButton variant="contained" color="secondary" type="submit" onClick={handleSubmit} disabled={isSubmitting} >E-SIGN AND DOWNLOAD NDA</EsignButton>
              <EsignButton variant="contained" color="secondary" onClick={handleClose}>Close</EsignButton>
            </DialogActions>
          </Dialog>
        </form>
      )}
    </Formik>

  );
}

const EsignButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem; 
  border: none;
  padding: 10px;  
  transition: 0.2s;
  font-family: Poppins;  
  width: 100%;  

  @media (max-width: 900px) {
    margin-bottom: 30px;
  }

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
    color: #F2C230;   
  
    >* {
      color: #F2C230;
    }
  }
`

const BackButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem; 
  border: none;
  padding: 10px;  
  transition: 0.2s;
  font-family: Poppins;
  width: 200px;  
  marginBottom: 10px;  

  @media (min-width: 900px) {
    position: absolute; 
    bottom: 0;    
  }

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
    color: #F2C230;   

    >* {
      color: #F2C230;
    }
  }
`

NDA.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};

export default NDA;
