/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import THEMES from '../../../constants';
const countryList = require('country-list');
var jwt = require('jsonwebtoken');

const useStyles = makeStyles((theme) => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  formControl:{
    borderColor:"#f8d02d",  
    backgroundColor: "white"  
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  stupiddumbmenu: {
    backgroundColor: THEMES.Abintus_Dark.color,
    boxShadow: "none"
  }
}));

function YourAgency({
  className,
  onBack,
  onNext,
  onComplete,
  ...rest
}) {
  const classes = useStyles();
  var defCountry = '';
  var defService ='';
  var defAgency = '';
  if(localStorage.getItem("SelfAssessment") !== null)
  {
    defAgency = JSON.parse(localStorage.getItem("SelfAssessment"))[0];
    defCountry = JSON.parse(localStorage.getItem("SelfAssessment"))[1];
    defService = JSON.parse(localStorage.getItem("SelfAssessment"))[2];
  }
  return (
    <Formik
      initialValues={{
        country:defCountry,
        service:defService,
        agency:defAgency,
      }}
      validationSchema={Yup.object().shape({
        country: Yup.string().required('Required'),
        service: Yup.string().required('Required'),
        agency: Yup.string().required('Required'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          // Do API call to store step data in server session
          // It is important to have it on server to be able to reuse it if user
          // decides to continue later.
          setStatus({ success: true });
          setSubmitting(false);
          var arr = JSON.parse(localStorage.getItem("SelfAssessment"));
          if(arr === null)
          {
            arr = [values.agency,values.country,values.service];
          }
          else
          {
            arr[0] = values.agency;
            arr[1] = values.country;
            arr[2] = values.service;
          }          
          
          localStorage.setItem('SelfAssessment', JSON.stringify(arr));
          var id= 0;
          jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {
            id = parseInt(decoded.id);            
          });
          // set user id so if new users logs in on same computer, they see different contract
          localStorage.setItem('Self Assessment User Id', id)          
          var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
          var token = jwt.sign({ UserId:id, token: bearer }, process.env.REACT_APP_ReadToken);
          await fetch('https://abintus-app-api-linux.azurewebsites.net/user/info'
          ,{
            method: 'GET',
            headers: {
                'token': token
            }}
            )
          .then(response => response.json()).then(response => {

            if(response[0][7] === null || response[0][7] === "")
            {
             
              var companyName = "";
              var jobTitle = "";
              var industry = "";
              if(response[0][2] !== null)
              {
                companyName = response[0][2];
              }

              if(response[0][3] !== null)
              {
                jobTitle = response[0][3];
              }

              if(response[0][6] !== null)
              {
                industry = response[0][6];
              }
              var token = jwt.sign({ Id:id, Company:companyName, Industry:industry, JobTitle:jobTitle, MediaAgency:values.agency, token: bearer }, process.env.REACT_APP_ReadToken);
              fetch('https://abintus-app-api-linux.azurewebsites.net/user/edit/work-status'
              ,{
                method: 'POST',
                headers: {
                    'token': token
                }}
                );
            }
      
          });          
          if (onComplete) {
            onComplete();
          }
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
        <Box mt={4}>
          <FormControl style={{width:"100%", marginBottom: "20px"}} variant="outlined"className={classes.formControl}>
                      <InputLabel style={{width:"80%"}} id="AgencyLabel" >Which Agency Is Your Contract With?</InputLabel>                      
                        <Select
                        style={{paddingTop:window.innerWidth > 500 ?"0px":"20px"}}                        
                        className={classes.stupiddumbmenu}
                          error={Boolean(touched.agency && errors.agency)}
                          labelId="AgencyLabel"
                          label="Which Agency Is Your Contract With?"
                          id="Agency"
                          name ="agency"
                          value={values.agency}
                          onChange={(agency) =>  setFieldValue('agency', agency.target.value)}
                        >
                          <MenuItem value="Arena Media">Arena Media</MenuItem>
                          <MenuItem value="Blue 449">Blue 449</MenuItem>
                          <MenuItem value="Carat">Carat</MenuItem>
                          <MenuItem value="Essence">Essence</MenuItem>
                          <MenuItem value="Havas Media">Havas Media</MenuItem>
                          <MenuItem value="Hearts and Science">Hearts &amp; Science</MenuItem>
                          <MenuItem value="Horizon Media">Horizon Media</MenuItem>
                          <MenuItem value="Madison Media">Madison Media</MenuItem>
                          <MenuItem value="Mediacom">Mediacom</MenuItem>
                          <MenuItem value="Mindshare">Mindshare</MenuItem>
                          <MenuItem value="OMD">OMD</MenuItem>
                          <MenuItem value="PHD">PHD</MenuItem>
                          <MenuItem value="PlatformGSK">PlatformGSK</MenuItem>
                          <MenuItem value="Spark Foundry">Spark Foundry</MenuItem>
                          <MenuItem value="Starcom">Starcom</MenuItem>
                          <MenuItem value="UM">UM</MenuItem>
                          <MenuItem value="Vizeum">Vizeum</MenuItem>
                          <MenuItem value="Wavemaker">Wavemaker</MenuItem>
                          <MenuItem value="Zenith">Zenith</MenuItem>
                          <MenuItem value="Dentsu X">Dentsu X</MenuItem>
                          <MenuItem value="m/SIX">m/SIX</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                        {Boolean(touched.agency && errors.agency) && (
                          <Box mt={1}>
                            <FormHelperText error>
                              {errors.agency}
                            </FormHelperText>
                          </Box>
                        )}
              </FormControl>
          </Box>
          <Box mt={2}>
          <FormControl style={{width:"100%", marginBottom: "20px"}} 
          variant="outlined" className={classes.formControl}>
                        <InputLabel style={{width:"80%"}} id="CountryLabel">Which market does you media agency contract cover?</InputLabel>
                          <Select
                            style={{paddingTop:window.innerWidth > 500 ?"0px":"20px"}}
                            error={Boolean(touched.country && errors.country)}
                            labelId="CountryLabel"
                            label="Which market does you media agency contract cover?"
                            id="Country"
                            name ="country"
                            value={values.country}
                            onChange={(country) =>  setFieldValue('country', country.target.value)}
                          >
                            <MenuItem value="Global">Global</MenuItem>
                            <MenuItem value="North America">North America</MenuItem>
                            <MenuItem value="South America">South America</MenuItem>
                            <MenuItem value="Europe/EMEA">Europe/EMEA</MenuItem>
                            <MenuItem value="Asian Pacific">Asian Pacific</MenuItem>
                            <MenuItem value="Other Region">Other Region</MenuItem>
                            {countryList.getNames().sort().map((value,index) =>
                            {
                            return <MenuItem key={index} value={value}>{value}</MenuItem>
                            })}
                          </Select>
                          {Boolean(touched.country && errors.country) && (
                            <Box mt={1}>
                              <FormHelperText error>
                                {errors.country}
                              </FormHelperText>
                            </Box>
                          )}
            </FormControl>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
          <FormControl style={{width:"100%", backgroundColor: "white"}} variant="outlined" className={classes.formControl}>
                        <InputLabel style={{width:"80%"}} id="ServiceLabel">Which media services does your agency provide?</InputLabel>
                          <Select
                          style={{paddingTop:window.innerWidth > 500 ?"0px":"20px"}}
                            error={Boolean(touched.service && errors.service)}
                            labelId="ServiceLabel"
                            label="Which media services does your agency provide?"
                            id="Service"
                            name ="service"
                            value={values.service}
                            onChange={(service) =>  setFieldValue('service', service.target.value)}
                          >
                            <MenuItem value="Media Planning and Buying (All Media)">Media Planning &amp; Buying (All Media)</MenuItem>
                            <MenuItem value="Media Planning and Buying (Offline Media)">Media Planning &amp; Buying (Offline Media)</MenuItem>
                            <MenuItem value="Media Planning and Buying (Online Media)">Media Planning &amp; Buying (Online Media)</MenuItem>
                            <MenuItem value="Media Planning ONLY (All Media)">Media Planning ONLY (All Media)</MenuItem>
                            <MenuItem value="Media Planning ONLY (Offline Media)">Media Planning ONLY (Offline Media)</MenuItem>
                            <MenuItem value="Media Planning ONLY (Online Media)">Media Planning ONLY (Online Media)</MenuItem>
                            <MenuItem value="Media Buying ONLY (All Media)">Media Buying ONLY (All Media)</MenuItem>
                            <MenuItem value="Media Buying ONLY (Offline Media)">Media Buying ONLY (Offline Media)</MenuItem>
                            <MenuItem value="Media Buying ONLY (Online Media)">Media Buying ONLY (Online Media)</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                          {Boolean(touched.service && errors.service) && (
                            <Box mt={1}>
                              <FormHelperText error>
                                {errors.service}
                              </FormHelperText>
                            </Box>
                          )}
                </FormControl>
            </Box>
          </Box>
          <Box
            mt={6}
            display="flex"
          >
            {onBack && (
              <Button
                onClick={onBack}
                size="large"
                style={{border: "1px solid #14213D", textTransform: "none", fontWeight: "bold"}}
              >
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
              style={{textTransform: "none", fontWeight: "bold"}}
            >
              Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>

  );
}

YourAgency.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};

export default YourAgency;
