import React from 'react'
import { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { 
    makeStyles, 
    Typography,
    useMediaQuery
 } from '@material-ui/core';
import Profits from './Media/profits.jpg'
import Billboards from './Media/billboards.jpg'
import Handshake from './Media/handshake.jpg'
import Team from './Media/team.jpg'
import { render } from 'nprogress';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "95vh",
        padding: "30px 120px 30px 100px",
        marginBottom: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column-reverse",
            justifyContent: "flex-start",
            height: "80",
            padding: 0,
            margin: 0,
        },
    },
    content: {
        // width: "45%",
        height: "100%",
        padding: "100 100 100 100",
        [theme.breakpoints.down('md')]: {
            padding: 0,
            height: "48%",
            
        }
    },
    message: {
        fontSize: "2rem",
        [theme.breakpoints.down('md')]: {
            fontSize: "1.3rem"
            
        }
    },
    [theme.breakpoints.down('sm')]: {
        border: "none"
    },
    source: {
        [theme.breakpoints.up('md')]: {
            fontSize: "1.4rem"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.1rem",
            lineHeight: "1.2"
        },
    },
    rightContent: {
        height: "50%",
        textAlign: "right",
        padding: "50px 0 50px 0",
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            textAlign: "center",
            height: "48%",
            padding: 0
        }
    }
}));

function Results() {
    const classes = useStyles();
    const mobile = useMediaQuery('(max-width: 600px)');
    
    const [active, setActive] = useState({
        activeObject: 0,
        objects: [{
            id: 0,
            title: "Significant Efficiencies",
            message:  "\"The exercise was very fruitful and valuable.  The insights and savings Abintus have generated helped us improve our media performance moving forward.\"",
            source: "Michael Cramer, General Manager DACH, Kao Corporation",
            image: Profits,
            color: '#75B8BF'
        }, 
        {   id: 1,
            title: "Improved Media Mix Optimization",
            message: "\"It was very useful to have a third-party vision on what we are doing and what could be improved.  The process was simple and not time consuming from our end, which was very much appreciated.\"",
            source: "Martin Broderick, Marketing Director, Stage Entertainment Germany",
            image: Billboards,
            color: "#025373"
        }, 
        {
            id: 2,
            title: "Increase Transparency",
            message: "\"I found the Abintus App to be an interesting starting point for client-agency relationships, with a smooth registration process, an intuitive self-assessment tool, and results that exceeded my expectations.\"",
            source: "Carlos Bosch, Former President of the Media Charter of the Spanish Advertiser Association (AEA)",
            image: Handshake,
            color: "#F2C230"
        }, 
        {
            id: 3,
            title: "Enhanced Accountability",
            message: "\"Thanks a lot Abintus. It was a great process and we are definitely happy with the final result. It has been great working together! Really!\"",
            source: "Marta Radi, Media Strategy and Planning Manager, Betway Group",
            image: Team,
            color: "#D0E5F2"
        }]
    });

    function toggleActive(index) {
        setActive({...active, activeObject: active.objects[index].id})        
    }

    return (
        <div className={classes.root}>
            <div className={classes.content} style={{width: mobile ? "100%" : "50%"}}>                                
                {active.objects.map((e, index) => {                    
                    return (
                        <Info 
                        background={e.image}
                        color={e.color}
                        active={index == active.activeObject}
                        key={index} 
                        style={{justifyContent: "center", display: index == active.activeObject && "flex", flexDirection: "column", }}>                    
                            <Typography variant="h1" className={classes.message}>{e.message}</Typography>
                            <br></br>
                            <br></br>
                            <Typography variant="body1" className={classes.source}>-{e.source}</Typography>
                        </Info>                        
                    )
                })}
            </div>
            <div className={classes.content} style={{width: mobile ? "100%" : "40%"}}>
                <div className={classes.rightContent} style={{padding: mobile && "20px"}}>
                    <Typography
                    variant="h1"
                    style={{fontSize: mobile ? "2rem" : "4.5rem", marginBottom: "17px"}}
                    >
                        {mobile ? <>The Results</> : <>The <br/> Results</>}
                    </Typography>
                    <Typography 
                    variant='h6'
                    style={{fontSize: mobile ? "1rem" : "1.2rem"}}
                    >
                    By having a best-in-class contract with your media agency will
                    deliver genuine benefits to your organisation, such as:
                    </Typography>
                </div>
                <div className={classes.rightContent}>
                    {active.objects.map((e, index) => {
                        console.log({ index: index, length: active.objects.length })
                        return (
                            <LongButton 
                                key={index} 
                                onClick={() => toggleActive(e.id)} 
                                active={index == active.activeObject}
                                style={{ 
                                    borderBottom: index === active.objects.length - 1 && "none",
                                    boxShadow: mobile && index === active.objects.length - 1 && index == active.activeObject && "rgba(100, 100, 111, 0.2) 0px -7px 10px 0px"
                                }}
                            >
                                <Typography 
                                variant='h4'
                                style={{fontWeight: !mobile && "bold"}}>
                                    {e.title}
                                </Typography>
                            </LongButton>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const fade = keyframes`
    from { opacity: 0.25; }
    to { opacity: 1; }
`

const Info = styled.div`
    display: none;
    height: 100%;
    width: 100%;
    align-items: center;
    background-image: url(${props => props.background});  
    background-size: cover;    
    color: white;
    padding: 0 70px 0 70px;
    animation: ${fade} 0.5s ease-in;
`

const LongButton = styled.div`
    width: 100%;
    height: 25%;
    border-bottom: solid 0.5px #DEDEDE;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 10px;

    ${props => props.active && `
        color: #75B8BF;
        height: 32%;
        transition: 0.1s ease-in;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-bottom: none;
    `}

    &:hover {
        cursor: pointer;
        color: #75B8BF;
    }
`

export default Results;