import React, { useLayoutEffect, useState, useEffect } from 'react';
import { Container, Grid, makeStyles, Typography, Box, Button, useMediaQuery} from '@material-ui/core';
//import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Page from 'src/components/Page';
import Header from './Header';
import MUIDataTable from "mui-datatables";
import styles from './YourDashboard.module.css'
import ContractGrade from './ContractGrade';
import GlobalBenchmark from './GlobalBenchmark';
import AbintusAverage from './AbintusAverage';
// import { TableContainer } from '@mui/material'
import CompletedContractScore from './CompletedContractScore';
import Benchmarks from './Benchmarks';
var jwt = require('jsonwebtoken');
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),    
    [theme.breakpoints.down('md')]: {
      overflowX: "hidden"
    }
  },
  table:{
    backgroundColor: "#F6F6F6",
    '&.MuiIconButton-root':{
      color: "#14213D"
    }
  },
  container: {    
    // [theme.breakpoints.down('md')]: {
    //   paddingRight: "50px",
    //   paddingLeft: "50px",
    // },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  },
  userName: {
    fontSize: "2rem",
    textAlign: "right",
    [theme.breakpoints.down('md')]: {
      fontSize: "1.2rem"
    }
  },
  recentAssessments: {
    height: "200px",
    width: "1000px",
    backgroundColor: "#F6F6F6"
  }
}));

var recentAssessmentsColumns = [{
  name: "Date",
  options: {
   filter: false,
  }
 },{
  name: "Reference",
  options: {
   filter: false,
  }
 },{
   name: "Product",
   options: {
    filter: true,
   }
  },{
   name: "Status",
   options: {
    filter: false,
   }
  },{
    name: "Score",
    options: {
     filter: false,
    }
   },{
    name: "Report",
    options: {
     filter: false,
     customBodyRender: (value, tableMeta, updateValue) => {      
      if(value !== "" && value !== "N/A")
      {
        return ( <a href={value} target="_blank" rel="noopener noreferrer" ><Button variant="contained" size="small" color="secondary" style={{display:"inline-block"}}>View</Button></a>);
      }
      else{
        return "N/A"
      }
    }
    }
   }
    ];

var recentAssessmentsData = [];
var recentAssessmentsOptions = {
  filterType: "dropdown",
  responsive: "standard",
  rowsPerPage: 3,
  rowsPerPageOptions: [],
  filter: false,
  pagination: true,
  download: false,
  print: false,
  search: false,
  viewColumns: false,
    onTableInit: (action, tableState) => {
      var l = [];
      tableState.displayData.forEach(element => {
        l.push(element.data);
      });
      },
  selectableRows: "none"
};

var recentAssessmentsDataArray = [];
class RecentAssessmentTable extends React.Component {
  _isMounted = false;
    constructor(props){
      super(props);
      this.state = {
        userId: props.userId,
        data: [],
        Loading: true,
        contractLoading: true,        
      };
    }
    
    render() {
      const columns = ["Date", "Reference", "Product", "Status", "Score", "Report"]

      return (
        <React.Fragment>
        <Grid
        item
        md={8}
        lg={8}
        xl={8}
      >             
        <div className={styles.recentAssessments}>
          <div className={styles.topBar}>
            <h3>Completed Assessments</h3>  
            <div className={styles.headerRow}>
              {
                columns.map(column => {
                  if (this.props.mobile) {
                    if (column !== "Product" && column !== "Status") {
                      console.log(column)
                      return (
                        <div className={styles.cell}>
                          <p>{column}</p>
                        </div>
                      )
                    }
                  } 
                  else {
                    return (
                      <div className={styles.cell}>
                        <p>{column}</p>
                      </div>
                    )
                  }              
                })
              }
            </div>
          </div>    
          <div>
            {
              this.Loading ? (
                <>
                  <div className={styles.row} style={{ backgroundColor: "#FCFCFC"}}></div>
                  <div className={styles.row}></div>
                  <div className={styles.row} style={{ backgroundColor: "#FCFCFC"}}></div>
                  <div className={styles.row}></div>
                  <div className={styles.row} style={{ backgroundColor: "#FCFCFC"}}></div>
                  <div className={styles.row}></div>
                </>
              ) : (
                <>                
                  {
                    recentAssessmentsData.length ? (
                      recentAssessmentsData.map((assessment, index) => {                               
                        return (
                          <div className={styles.row} style={{ backgroundColor: index % 2 == 0 && '#FCFCFC' }}>
                            {
                              assessment.map((cell, index) => {  
                                if (this.props.mobile) {
                                  if (index !== 2 && index !== 3) {
                                    return (  
                                      <div className={styles.cell} key={index}>                                
                                        {
                                          index == assessment.length - 1 ? (                                        
                                            cell == "N/A" ? (
                                              "N/A"
                                            ) : (
                                              <a href={cell} target="_#blank"><button>View</button></a>
                                            )                                        
                                          ) : (
                                            <p>{cell}</p>
                                          )
                                        }
                                      </div>                  
                                    )
                                  }
                                } 
                                else {
                                  return (  
                                    <div className={styles.cell} key={index}>                                
                                      {
                                        index == assessment.length - 1 ? (                                        
                                          cell == "N/A" ? (
                                            "N/A"
                                          ) : (
                                            <a href={cell} target="_#blank"><button>View</button></a>
                                          )                                        
                                        ) : (
                                          <p>{cell}</p>
                                        )
                                      }
                                    </div>                  
                                  )
                                }              
                              })
                            }
                          </div>
                        )
                      })
                    ) : (
                      <div style={{ height: "100px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant="body1">You have not completed any assessments</Typography>
                      </div>
                    )
                  }
                </>
              )
            }
          </div>
        </div>      
       {/* </MuiThemeProvider> */}
      </Grid>
      </React.Fragment>);
      }

      componentWillUnmount() {
        this._isMounted = false;
      }
  
      componentDidMount(){
        this._isMounted = true;
          if (this._isMounted && recentAssessmentsData.length < 1) 
          {
            this.getRecentAssessments();
          }
          else
          {
            this.setState({Loading: false});
          }
        }

      AverageRating(score)
      {
        var rating = "Best-in-Class";
        if(score < 39)
        {
          rating = "Poor";
        }
        else if(score < 59)
        {
          rating = "Average";
        }
        else if(score < 80)
        {
          rating = "Strong";
        }
        return rating;
      }

        getRecentAssessments = async _=> {
        var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
        var token = jwt.sign({ token: bearer, Id: this.state.userId }, process.env.REACT_APP_ReadToken);
        
        await fetch("https://abintus-app-api-linux.azurewebsites.net/contracts/self-assessment", {
          method: 'GET',
          headers: {
            'token' : token
          }
        })
        .then(res => res.json())
        .then(data => {               
          data.forEach(element => {
            if (element[33] !== null && element[33] !== "N/A") {
              recentAssessmentsDataArray.push([element[2].substring(0, 10), `#S00${element[0]}`, "Self-Asmnt.", "Completed", `${element[30]}%`, element[33]])
            }
          })
        })      
        // this.contractLoading = false;
        var outputExpertAsessments = await fetch(`https://abintus-app-api-linux.azurewebsites.net/user/expert-assessment/${this.state.userId}`
        ,{
          method: 'GET',
          headers: {
              'token': token
          }}
          );    

        var recentExpertAssessments = await outputExpertAsessments.json();
        recentExpertAssessments.forEach((element => {
          let score = "";          
          let report = "";
          // if(element[6] !== false)
          // {
          // if(element[4] !== false)
          // {
          //   // get data from contracts            
          //   report = "E" + element[0];
          // }
          // else if(element[2] !== "" && element[3] !== "")
          // {
          //   reviewStatus =  "Under Review";
          // }
          // else if(element[2] !== "")
          // {
          //   reviewStatus =  "Pending for Contract";
          // }
          // else
          // {
          //   reviewStatus =  "Pending for NDA";
          // }

          var reviewStatus = element[9] !== null ? "Completed" : "Under Review";
          var analysis = element[9] !== null ? element[9] : "N/A";
          recentAssessmentsDataArray.push([element[8].substring(0,10),"#E00" + element[0], "Expert Asmnt.", reviewStatus, score, analysis]);          
        // }

         }));   
        recentAssessmentsData = recentAssessmentsDataArray.sort((a, b) => new Date(b[0]) - new Date(a[0]))        
        this.setState({Loading: false});
       
      } 
    }

    var underReviewColumns = [{
      name: "Reference",
      options: {
       filter: false,
      }
     },{
       name: "Status",
       options: {
        filter: false,
       }
      },{
        name: "Due Date",
        options: {
         filter: false,
         customBodyRender: (value, tableMeta, updateValue) => {
          return <h4>{value}</h4>
      }
        }
       }
        ];
    
    var underReviewData = [];
    var underReviewOptions = {
      filterType: "dropdown",
      responsive: "standard",
      rowsPerPage: 15,
      filter: false,
      download: false,
      pagination: false,
      print: false,
      search: false,
      viewColumns: false,
        onTableInit: (action, tableState) => {
          var l = [];
          tableState.displayData.forEach(element => {
            l.push(element.data);
          });
          },
      selectableRows: "none"
    };
    
    var underReviewDataArray = [];
class UnderReviewTable extends React.Component {
      _isMounted = false;
        constructor(props){
          super(props);
          this.state = {
            userId: props.userId,
            data: [],
            Loading: true,                        
          };
        }
        render() {
          return (
            <div className={styles.underReviewTable}>
              <div className={styles.underReviewTopBar}>
                <h3>Under Review</h3>
                <div className={styles.underReviewHeaderRow}>
                  <div className={styles.underReviewCell}><p>Reference</p></div>
                  <div className={styles.underReviewCell}><p>Status</p></div>
                  <div className={styles.underReviewCell}><p>Due Date</p></div>
                </div>
              </div>
              <div>
                {
                  this.Loading ? (
                    <p>Loading</p>
                  ) : (
                    <>                  
                      {
                        underReviewData.map((assessment, index) => {
                          return (
                            <div className={styles.underReviewRow} key={index}>
                              {
                                assessment.map(cell => {
                                  return (
                                    <div className={styles.underReviewCell}>
                                      <p style={{ fontSize: '0.8rem' }}>{cell}</p>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          )
                        })
                      }
                    </>
                  )
                }
              </div>
            </div>         
          )}
    
          componentWillUnmount() {
            this._isMounted = false;
          }
      
          componentDidMount(){
            this._isMounted = true;
              if (this._isMounted && underReviewData.length < 1) 
              {
                this.getUnderReviewAssessments();
              }
              else
              {
                this.setState({Loading: false});
              }
            }
    
            getUnderReviewAssessments = async _=> {
            var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
            var token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);
            var outputExpertAsessments = await fetch(`https://abintus-app-api-linux.azurewebsites.net/user/expert-assessment/${this.state.userId}`
            ,{
              method: 'GET',
              headers: {
                  'token': token
              }}
              );
            var underReviewAssessments = await outputExpertAsessments.json();            
            underReviewAssessments.forEach((element => {              
              let reviewStatus = "Pending for NDA";
              if (element[9] == null) {
                if (element[2] !== "" && element[3] !== "") {
                  reviewStatus =  "Under Review";
                }
                else if(element[2] !== "") {
                  reviewStatus =  "Pending for Contract";
                }               
                underReviewDataArray.push(["#E00" + element[0], reviewStatus, "2021-02-25"]);
              }
             }));   
            underReviewData = underReviewDataArray.reverse();            
            this.setState({Loading: false});
           
          } 
        }

        var eventColumns = [{
          name: "Time",
          options: {
            filter: false,
          }
          },
          {
            name: "Event",
            options: {
              filter: false,
            }
            }
          ];
        
        var eventData = [];
        var eventOptions = {
          filterType: "dropdown",
          responsive: "standard",
          rowsPerPageOptions: [],
          rowsPerPage: 5,
          filter: false,
          download: false,
          print: false,
          search: false,
          viewColumns: false,
            onTableInit: (action, tableState) => {
              var l = [];
              tableState.displayData.forEach(element => {
                l.push(element.data);
              });
              },
          selectableRows: "none"
        };
            
  var eventDataArray = [];          

function YourDashboardView() {
  const [name, setName] = useState()
  const classes = useStyles();
  var id = 0;
  var nameString = ""
  jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey, function(err, decoded) {
    id = decoded.id;
    nameString = decoded.firstName + " " + decoded.lastName;
  });
  useEffect(() => {
    setName(nameString)
  }, [nameString])     
  
  const mobile = useMediaQuery('(max-width: 900px)');
  const [abintusScore, setAbintusScore] = React.useState(85);
  const [averageScore, setAverageScore] = React.useState(52);
  const [averageCompletedScore, setAverageCompletedScore] = React.useState(0);
  const [totalCompleted, setTotalCompleted] = React.useState(null);
  const [averageGrade, setAverageGrade] = React.useState("Average");
  const AverageRating  = (score) => {
    var rating = "Best-in-Class";
    if(score < 39)
    {
      rating = "Poor";
    }
    else if(score < 59)
    {
      rating = "Average";
    }
    else if(score < 80)
    {
      rating = "Strong";
    }
    return rating;
  }

  useEffect(() => {
    const token = jwt.sign({ token: `Bearer ${process.env.REACT_APP_SecretKey}` }, process.env.REACT_APP_ReadToken);
    fetch(`https://abintus-app-api-linux.azurewebsites.net/contracts`, {
      method: "GET",
      headers: {
        token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let scoreCal = 0;
        let preparedbyAbintusScore = 0;
        let preparedbyAbintusAmount = 0;

        response.forEach((element) => {
          scoreCal += parseInt(element[9]);
          if (element[6] === true) {
            preparedbyAbintusScore += parseInt(element[9]);
            preparedbyAbintusAmount++;
          }
        });

        const score = parseInt(scoreCal / response.length);
        setAverageGrade(AverageRating(score));
        setAverageScore(parseInt(scoreCal / response.length));
        setAbintusScore(parseInt(preparedbyAbintusScore / preparedbyAbintusAmount));

        let countContracts = 0;
        let contractTotalScores = 0;

        fetch(`https://abintus-app-api-linux.azurewebsites.net/user/self-assessment/${id}`, {
          method: "GET",
          headers: {
            token,
          },
        }
        ).then((res) => res.json())
        .then((res) => {
          res.forEach((element) => {
            countContracts++;
            contractTotalScores += element[30];
          });
        })
        .then(() => {
          if (countContracts !== 0) {
            setAverageCompletedScore(
              Math.round(contractTotalScores / countContracts, 0)
            );
            setTotalCompleted(countContracts);
          }
        });
      fetch(
        `https://abintus-app-api-linux.azurewebsites.net/user/expert-assessment/${id}`,
        {
          method: "GET",
          headers: {
            token,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          data.forEach((item) => {
            if (item[9] !== null) {
              countContracts++;
            }
          });
          setTotalCompleted(countContracts);
        });
    });
  }, []);

  console.log(mobile);
  return (
    <Page
      className={classes.root}
      title="Your Dashboard"
    >
      <Container
        maxWidth={false}
        className={classes.container}
      >
        <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Header />          
          <Typography variant='body1' className={classes.userName}>{name}</Typography>        
        </div>        
        <Box mt={3}></Box>
        <Grid
          container
          spacing={3}
          padding={2}
        >
          <Grid container
          spacing={3}
          direction="row">
            <RecentAssessmentTable userId={id} mobile={mobile}></RecentAssessmentTable>             
            <Grid
              item
              md={4}
              lg={4}
              xl={4}
            >
            <UnderReviewTable userId={id}></UnderReviewTable>
            <Box mt={2}></Box>
            <CompletedContractScore score={averageCompletedScore} contracts={totalCompleted} />
            <Box mt={3}></Box>
          </Grid>
        </Grid>
        <Grid container
          spacing={3}
          direction="row">
            <Grid
            item
            md={8}
            lg={8}
            xl={8}
          > <Box mt={2}></Box>
            <Benchmarks globalscore={averageScore} grade={averageGrade} score={abintusScore} />
          </Grid>
          
          {/* <EventTable id={id}></EventTable> */}
        </Grid>
         
      </Grid>
      </Container>
      <Box mt={3}></Box>
    </Page>
  );
}

export default YourDashboardView;