import React from 'react';
import { Card,CardContent,Container,Grid,makeStyles,TextField,
Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Box,Typography,Link,FormControl,InputLabel,
Select,FormHelperText,MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import * as Yup from 'yup';
import { Formik } from 'formik';
import styled from 'styled-components';
var jwt = require('jsonwebtoken');
const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
      paddingTop: "24px",
      paddingBottom: "24px"
    },

    [theme.breakpoints.down('md')]: {
      paddingTop: "25px",
      paddingRight: 10,
      paddingLeft: 10,
    },
  }
}));

export default function AccountView() {
  const [editDetailsErrorTitle,setEditDetailsErrorTitle] = React.useState("Success");
  const [editDetailsError,setEditDetailsError] = React.useState("Message was sent successfully. Thank you for reaching out! We will come back to you within next 24 hours");
  const [open, setOpen] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const classes = useStyles();
  return (
    <Page  title="Account">
      <Container maxWidth={false} className={classes.container} >
      <Header /> 
      <Box mt={4}></Box>
      <Grid container spacing={3}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <CardContent style={{padding : "24px", color: "#1c2025"}}>
        <Typography  align="left" variant="body1" style={{ marginBottom: "50px" }}>To contact us you can use this email address <strong><Link style={{color:"#14213D",textDecoration:"underline"}} href="mailto:support@abintus.consulting">support@abintus.consulting</Link></strong> or use the form below to send us a message.</Typography>
        <Grid item  >
           <Formik
              enableReinitialize={true}
      initialValues={{
        subject: "I need help with my account",
        message:"", 
       
      }}
      validationSchema={Yup.object().shape({
        subject: Yup.string().max(255).required('Subject is required'),
        message:Yup.string().required('Message is required')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
          setDisableButton(true);
          var userValues = jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey);
          var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
          var token = jwt.sign({ Email:userValues.username, token: bearer,Subject: "Abintus App - " + values.subject,Message:values.message }, process.env.REACT_APP_ReadToken);
          fetch('https://abintus-app-api-linux.azurewebsites.net/contact/message'
          ,{
            method: 'POST',
            headers: {
                'token': token
            }}
            )
          .then(response => response.text()).then(response => {
            if(response === "true")
            {
              setOpen(true);
            }
            else
            {
              setEditDetailsErrorTitle("Error");
              setEditDetailsError("There was an error while sending your message, please try again later.")
              setOpen(true);
            }
          });
          
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values
      }) => (
        <form
        onSubmit={handleSubmit}
      >
        <Box mt={2}></Box>
         <FormControl style={{width:"100%"}} variant="outlined"className={classes.formControl}>
                      <InputLabel style={{width:"80%"}} id="SubjectLabel" >Subject</InputLabel>
                        <Select
                          error={Boolean(touched.subject && errors.subject)}
                          labelId="SubjectLabel"
                          label="Subject"
                          id="Subject"
                          name ="subject"
                          value={values.subject}
                          onChange={(subject) =>  setFieldValue('subject', subject.target.value)}
                        >
                          <MenuItem value="I need help with checkout">I need help with checkout</MenuItem>
                          <MenuItem value="I need help with NDA">I need help with NDA</MenuItem>
                          <MenuItem value="I need help with uploading my contract">I need help with uploading my contract</MenuItem>
                          <MenuItem value="I need help with accessing my results">I need help with accessing my results</MenuItem>
                          <MenuItem value="I need help with downloading my results">I need help with downloading my results</MenuItem>
                          <MenuItem value="I have a general question/query">I have a general question/query</MenuItem>
                          <MenuItem value="I need help with my account">I need help with my account</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                        {Boolean(touched.subject && errors.subject) && (
                          <Box mt={1}>
                            <FormHelperText error>
                              {errors.subject}
                            </FormHelperText>
                          </Box>
                        )}
              </FormControl>
          <TextField
            autoComplete="off"
            error={Boolean(touched.message && errors.message)}
            fullWidth
            multiline
            rows={8}
            helperText={touched.message && errors.message}
            label="Message"
            margin="normal"
            name="message"
            onBlur={handleBlur}
            onChange={handleChange}
            type="message"
            value={values.message}
            variant="outlined"
          />
          <Box mt={2}>
            {/* <Button
              color="secondary"
              disabled={disableButton}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Send Message
            </Button> */}
            <SendButton type="submit">Send Message</SendButton>
          </Box>
          <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="DialogTitle">
          <DialogTitle id="DialogTitle">{editDetailsErrorTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{editDetailsError}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleClose}>Close</Button>
          </DialogActions>
          </Dialog>
        </form>
        
      )}
    </Formik>
                      
        </Grid>
        </CardContent>
    </Grid>
    </Container>
    </Page>
  );
}

const SendButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem;
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 30px;
  transition: 0.2s;
  font-family: Poppins;

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
  }
`