import React, {
} from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import {
 Grid,
 CircularProgress,
 makeStyles
} from '@material-ui/core';
var jwt = require('jsonwebtoken');
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
      paddingTop: "24px",
      paddingBottom: "24px"
    }
  }
}));

var billingColumns = [{
  name: "Date",
  options: {
    filter: false,
  }
  },
  {
    name: "Product",
    options: {
      filter: true,
    }
    },
    {
      name: "Amount",
      options: {
        filter: false,
      }
      }
  ];

var billingData = [];
var billingOptions = {
  filterType: "dropdown",
  responsive: "standard",
  textLabels: {
    body: {
      noMatch: "No Billing Data Found",
    }},
  rowsPerPageOptions: [5,10,50],
  rowsPerPage: 5,
    onTableInit: (action, tableState) => {
      var l = [];
      tableState.displayData.forEach(element => {
        l.push(element.data);
      });
      },
  selectableRows: "none"
};
    
var billingDataArray = [];  

class BillingTable extends React.Component {
  _isMounted = false;
    constructor(props){
      super(props);
      this.state = {
        id: props.id,
        billingdata: [],
        Loading: true,
      };
    }
    render() {
      return (
        <React.Fragment>
        <Grid
        item
        md={6}
        lg={6}
        xl={6}
      >
        {/* <MuiThemeProvider 
        theme={theme} 
      >  */}
        <MUIDataTable
         className={useStyles.table}
          title={<h1 variant="title">
          {this.state.Loading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4, color:"#f8d02d"}} />}
          </h1>
          }
          data={billingData}
          columns={billingColumns}
          options={billingOptions}
      > </MUIDataTable>
         {/* </MuiThemeProvider> */}
      </Grid>
      </React.Fragment>);
      }

      componentWillUnmount() {
        this._isMounted = false;
      }
  
      componentDidMount(){
        this._isMounted = true;
        if (this._isMounted && billingData.length < 1) 
        {
          this.getBillings();
        }
        else
        {
          this.setState({Loading: false});
        }
      }

        getBillings = async _=> {
        var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
        var token = jwt.sign({ token: bearer,UserId:this.state.id }, process.env.REACT_APP_ReadToken);
        await fetch(`https://abintus-app-api-linux.azurewebsites.net/billing-history`
        ,{
          method: 'GET',
          headers: {
              'token': token
          }}
          )
        .then(response => response.json())
        .then(response => {
          response.forEach((element => {
            billingDataArray.push([element[2].substring(0, 10), element[0], "€" + element[1].toString()]);
          }));

          billingData = billingDataArray.reverse();
          this.setState({Loading: false});
        });
      } 
    }

function Billing({ className,userId, ...rest }) {
  return (
    <Grid container spacing={3}
    alignItems="center"
    justify="center"
    >
      <BillingTable id={userId}></BillingTable>
 
    </Grid>
  );
}

Billing.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.any
};

export default Billing;
