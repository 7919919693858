/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import styled from 'styled-components';
import {DropzoneArea} from 'material-ui-dropzone'
import {
  Box,
  Button,
  Link,
  Typography,
  makeStyles,
  CardContent,withStyles,  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,  
  useMediaQuery
} from '@material-ui/core';
var jwt = require('jsonwebtoken');

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const CustomDropBox = withStyles((theme) => ({
  root:{
    backgroundColor: "#FFFFFF",
    border:"1px solid #14213D",
    paddingTop: "50px",
    marginBottom: "20px",

    "& .MuiDropzonePreviewList-image":
    {
      // boxShadow:"0 0 0",
      backgroundColor:"#FFFFF",
      height:"50px",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}))(DropzoneArea);

function UploadContract({
  className,
  onBack,
  onNext,
  onComplete,
  setDbValues,
  dbValues,
  currentId,
  setActiveStep,
  ...rest
}) {
  const classes = useStyles();
  const [filesSelected,setFileSelected] = useState([]);
  const [fileUploadButton,setFileUploadButton] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [agency, setAgency] = useState("")
  const mobile = useMediaQuery('(max-width: 900px)')
  var dialogTitle = "Success";
  var dialogText = "Your contract has been uploaded successfully";    

  const selectFile = (files) =>
  {
      if(files.length !== 0)
      {
        setFileUploadButton(false);
        setFileSelected(files);
      }
      else
      {
        setFileUploadButton(true);
      }
  }

  const fileUpload = async() =>
  {
    var formData = new FormData();
   
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var accessToken = localStorage.getItem('accessToken');
    var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);
    var clientName = validToken.firstName + " " + validToken.lastName;
    var name = [];
    var type = [];
    var i=1;        
    setFileUploadButton(true);
        
    if (filesSelected.length == 0) {
      setErrorDialog(true);      
    }
    else if (filesSelected.length > 0) {
      if (onNext) {
        onNext();
      }
      filesSelected.forEach(element => {
        type.push(element.type);
        formData.append('file', element);
        name.push(`ExpertAssessmentContract-${currentId}-${validToken.id}-DocNr${i}`);
        ++i;
      })

      var token = jwt.sign({ UserId:validToken.id, EventId:7, token: bearer }, process.env.REACT_APP_ReadToken);
      fetch('https://abintus-app-api-linux.azurewebsites.net/user/event'
      ,{
        method: 'POST',
        headers: {
            'token': token
        }}
        );
      
      token = jwt.sign({ Id: validToken.id, InstanceId:currentId, Agency:agency, DocumentName:name, Type:type, token: bearer,Email:validToken.username, ClientName: clientName }, process.env.REACT_APP_ReadToken);
      await fetch('https://abintus-app-api-linux.azurewebsites.net/expert-assessment/contract/upload'
      ,{
        method: 'POST',
        headers: {
          'token': token
      },
        body:formData
      }
        )
        .then(res => res.text()).then(res => {
          setFileUploadButton(false);
          setShowDialog(true);
        });
    }
  }


  return (
    <Formik
      initialValues={{
      }}
      validationSchema={Yup.object().shape({
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          setStatus({ success: true });
          setSubmitting(false);
          if (onComplete) {
            onComplete();
          }
          // if (onNext) {
          //   onNext();
          // }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <CardContent align="left">
            {/* <Box mt={4} justifyContent="center" align="center">
            <img src={IntroPicture} alt="AgencyContract" style={{height: '80%',
               width: '80%'}}></img>
            </Box> */}
             <Box
             mt={2}
              display="flex"
              alignItems="center"
            >
               <Typography
            variant="h5"
            color="textPrimary"
          >
         Thank you for signing the NDA document.  
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
            >
               <Typography
            variant="body1"
            color="textPrimary"
          >
           The next step is for you to upload your media agency contract for us to assess.     
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
            >
               <Typography
            variant="body1"
            color="textPrimary"
          >
          If you have any technical issues at any time, please don’t hesitate to let us know by emailing us at <Link style={{color:"#14213D",textDecoration:"underline"}} href="mailto:support@abintus.consulting">support@abintus.consulting</Link>     
          </Typography>
            </Box>
            <Box mt={1}>
              <TextField fullWidth variant="outlined" label="Please enter agency name" onChange={(e) => setAgency(e.target.value)}/>
            </Box>
            <Box mt={3}>
            <CustomDropBox
              style={{backgroundColor: "#FFFFFF"}}
              //dropzoneClass={classes.dropzone}
              acceptedFiles={['application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/docx','application/pdf','application/msword']}
              filesLimit={5}
              maxFileSize={60000000}
              showFileNames={true}
              dropzoneText={'Drag and drop Contract files here or click here'}
              onChange={(files) => {selectFile(files)}}
        />
            </Box>
            <Typography variant="body1" style={{color: "red", display: errorDialog ? "block" : "none"}}>Please add a file to submit to us.</Typography>
            <Box mt={2} align="center" style={{display: "flex", justifyContent: "space-between"}}>
            <BackButton style={{ width: mobile && "150px" }} onClick={() => setActiveStep(activeStep => activeStep - 1)}>Back</BackButton>
            <UploadButton            
              style={{ width: mobile && "150px" }}
              onClick={fileUpload}
            >
              Upload File
            </UploadButton>
            </Box>
          </CardContent>
          <Dialog          
          open={showDialog}
          aria-labelledby="DialogTitle"
          style={{border: "none"}}
          >
          <DialogTitle id="DialogTitle" style={{backgroundColor: "#FFFFFF"}}>{dialogTitle}</DialogTitle>
          <DialogContent style={{backgroundColor: "#FFFFFF"}}>
            <DialogContentText>
              {dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{justifyContent:"center", backgroundColor: "#FFFFFF"}}>
            <UploadButton align="center" variant="contained" color="secondary" onClick={handleSubmit}>Go To Results page</UploadButton>
          </DialogActions>
        </Dialog>
        </form>
      )}
    </Formik>

  );
}

const UploadButton = styled.button`
  background-color: #F2C12E;
  color: #000000;
  font-size: 1rem; 
  border: none;
  padding: 10px;  
  transition: 0.2s;
  font-family: Poppins;
  width: 200px;  
  marginBottom: 10px;

  &:hover {
    opacity: 0.8;
    transition: 0.2s;
    cursor: pointer;     
  
    >* {
      color: #F2C230;
    }
  }
`

const BackButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem; 
  border: none;
  padding: 10px;  
  transition: 0.2s;
  font-family: Poppins;
  width: 200px;    

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
    color: #F2C230;   

    >* {
      color: #F2C230;
    }
  }
`

UploadContract.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};

export default UploadContract;
