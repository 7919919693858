
const THEMES = {
  // LIGHT: 'LIGHT',
  // ONE_DARK: 'ONE_DARK',
  // UNICORN: 'UNICORN',
  // Abintus_Light: "Abintus_Light",
  Abintus_Dark: "Abintus_Dark",
  // Abintus_Dark2: "Abintus_Dark2"
};

export default THEMES;