import React from 'react';
import { Container,Grid,makeStyles,Button,CircularProgress } from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import ContractGrade from './ContractGrade';
import NumberOfContracts from './NumberOfContracts';
import MUIDataTable from "mui-datatables";
import GlobalBenchmark from './GlobalBenchmark';
import KPIChart from './KPIChart';

var jwt = require('jsonwebtoken');
// function setCharAt(str,index,chr) {
//   if(index > str.length-1) return str;
//   return str.substring(0,index) + chr + str.substring(index+1);
// }
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  table:{
    '&.MuiIconButton-root':{
      color: "#14213D"
    }
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  }
}));

var columns = [{
  name: "Contract ID",
  options: {
   filter: false,
  }
 },
 {
  name: "Client ID",
  options: {
   filter: false,
   customBodyRender: (value, tableMeta, updateValue) => {
    var link = `https://www.abintus.app/app/admin/users/user/${value}`
    if(value !== "")
    {
    return ( <a href={link}>{value}</a>);
    }
  }
  }},
 {
  name: "Admin ID",
  options: {
   filter: false,
   customBodyRender: (value, tableMeta, updateValue) => {
    var link = `https://www.abintus.app/app/admin/users/user/${value}`
    if(value !== "")
    {
    return ( <a href={link}>{value}</a>);
    }
  }
  }},
 {
  name: "Advertiser",
  options: {
   filter: true,
  }
 },{
   name: "Agency",
   options: {
    filter: true,
   }
  },{
   name: "Agency Group",
   options: {
    filter: true,
   }
  },{
   name: "Services",
   options: {
    filter: true,
   }
  },{
   name: "Date of Review",
   options: {
    filter: true,
   }
  },{
   name: "Prepared by Abintus?",
   options: {
    filter: true,
   //  customBodyRender: (value, tableMeta, updateValue) => {
   //   test.push(value);
   //   const best = value;
   //  // this.setState({ best});
   //   if (value === 1)
   //     return (
   //       <Tooltip title="Yes">
   //         <Done color="primary" />
   //       </Tooltip>
   //     );
   //   else
   //     return (
   //       <Tooltip title="No">
   //         <Error color="error" />
   //       </Tooltip>
   //     );
   // }
 }
  },{
   name: "Country",
   options: {
    filter: true,
   }
  },{
   name: "Annual Media Budget",
   options: {
    filter: true,
    filterType: 'checkbox',
   }
  },{
   name: "Contract Score",
   options: {
    filter: false,
   }
  },{
   name: "Contract Rating",
   options: {
    filter: true,
    filterType: 'checkbox',
   }
  },{
     name: "Annual Net Spend (EUR)",
     options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if(value !== null)
        {
          return (value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        }
      },
       filter: true,
       filterType: 'checkbox',
       filterOptions: {
         names: ['Less than €1M', '€1M - €5M', '€5M - €10M', '€10M - €25M','€25M - €50M','€50M - €100M'],
         logic(spend, filterVal) {
           //spend = spend.replace(/[^\d]/g, '');
           const show =
             (filterVal.indexOf('Less than €1M') >= 0 && spend < 1000000) ||
             (filterVal.indexOf('€1M - €5M') >= 0 && spend >= 1000000 && spend < 5000000) ||
             (filterVal.indexOf('€5M - €10M') >= 0 && spend >= 5000000 && spend < 10000000)||
             (filterVal.indexOf('€10M - €25M') >= 0 && spend >= 10000000 && spend < 25000000)||
             (filterVal.indexOf('€25M - €50M') >= 0 && spend >= 25000000 && spend < 50000000)||
             (filterVal.indexOf('€50M - €100M') >= 0 && spend >= 50000000 && spend < 100000000);
           return !show;
         },
       },
       sort: true,
     },
   },{
     name: "Max Fees (EUR)",
     options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        if(value !== null)
        {
          return (value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        }
      },
     }
    },{
     name: "Remuneration Model",
     options: {
      filter: false,
     }
    },{
     name: "Estimated AVBs",
     options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        if(value !== null)
        {
          return (value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        }
      },
     }
    },{
     name: "AVB calculation",
     options: {
      filter: false,
     }
    },{
     name: "Start Date",
     options: {
      filter: false,
     }
    },{
     name: "Length of Contract",
     options: {
      filter: false,
     }
    },{
     name: "Auto Renew",
     options: {
      filter: false,
     }
    },{
     name: "Payment Terms",
     options: {
      filter: false,
     }
    },{
     name: "Signed by Both Parties",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "Definitions",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "Services Described",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "Reporting Clause",
     options: {
      filter: false,
      display: false
     }
    },{
    name: "Agent Only",
    options: {
     filter: false,
     display: false
    }
   },{
     name: "Authority Clause",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "Exclusivity Reciprocal",
     options: {
      filter: false,
      display: false
     }
    },{
    name: "Key Individuals",
    options: {
     filter: false,
     display: false
    }
   },{
     name: "Transparent Remuneration",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "PRF Included",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "PRF with Malus",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "PRF Methodology",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "Agency Commitments",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "Audit Rights",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "Rebates Clauses",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "EPD Clause",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "Unbilled Media Clause",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "Competitive Payment Terms",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "Standard Termination Clause",
     options: {
      filter: false,
      display: false
     }
    },{
     name: "Standard Subcontracting Clause",
     options: {
      filter: false,
      display: false
     }},
     {
      name: "Contract Documents",
      options: {
       filter: false,
       customBodyRender: (value, tableMeta, updateValue) => {
        if(value[0] !== "")
        {
          if(value[1] > 0)
          {
            const output = [];
            const contractNames = value[2].split(",");
            var x = 1;
            contractNames.forEach(element => {
              var link = value[0];
              link = link.replace(/[^/]+(?=\?)/g,element);
              output.push(<a href={link} key={x} target="_blank" rel="noopener noreferrer" ><Button variant="contained" color="secondary" style={{display:"inline-block",fontSize:"8px"}}>Cont. {x}</Button></a>);
              x += 1;
            });
          
          return (<div>{output}</div>);
        }
        }
      }
      }
     },
     {
      name: "Expert Assess. ID",
      options: {
       filter: false,
      }},
        {
          name: "Check Evaluation Table",
          options: {
           filter: false,
           customBodyRender: (value, tableMeta, updateValue) => {
            if(value !== "")
            {
               return (<a href={`/app/admin/evaluation-table/${value}`}><Button variant="contained" color="secondary" style={{display:"inline-block"}}>View</Button></a>);
            }
          }
          }},
    ];

var data = [];
var options = {
  filterType: "dropdown",
  responsive: "standard",
  rowsPerPage: 15,
  rowsPerPageOptions: [1,5,15,30,50],
    onTableChange: (action, tableState) => {
      var l = [];
        tableState.displayData.forEach(element => {
          l.push(element.data);
        });
         window.ContractTable.RefreshTopData(l);
        // this.setState({
        //     data: Calculations(l)
        // });
    },
    onTableInit: (action, tableState) => {
      var l = [];
      tableState.displayData.forEach(element => {
        l.push(element.data);
      });
       window.ContractTable.RefreshTopData(l);
      // this.setState({
      //   data: Calculations(l)
      // });
      },
      customSort:(data, colIndex, order)=> {
        if(colIndex === 11 || colIndex === 10 || colIndex === 8)
        {
          if(order === "desc")
          {
            return data.sort(function(a,b){ 
              if(a.data[colIndex] == null)
              {
                return 1;
              }
              else if(b.data[colIndex] == null)
              {
                return -1;
              }

              return parseInt(a.data[colIndex]) > parseInt(b.data[colIndex]) ? -1 : 1; });
          }
          else
          {
            return data.sort(function(a,b){
              if(a.data[colIndex] == null)
              {
                return -1;
              }
              else if(b.data[colIndex] == null)
              {
                return 1;
              }

              return parseInt(a.data[colIndex]) > parseInt(b.data[colIndex]) ? 1 : -1; });
          }
         
        }
        else if(colIndex === 4 || colIndex === 15)
        {
          if(order === "desc")
          {
            return data.sort(function(a,b){ 
              var partsA = a.data[colIndex].split('/');
              var dateA = new Date(partsA[2], partsA[1] - 1, partsA[0]); 
              var partsB = b.data[colIndex].split('/');
              var dateB = new Date(partsB[2], partsB[1] - 1, partsB[0]); 
              return dateA > dateB ? -1 : 1; });
          }
          else
          {
            return data.sort(function(a,b){ 
              var partsA = a.data[colIndex].split('/');
              var dateA = new Date(partsA[2], partsA[1] - 1, partsA[0]); 
              var partsB = b.data[colIndex].split('/');
              var dateB = new Date(partsB[2], partsB[1] - 1, partsB[0]); 
              return dateB > dateA ? -1 : 1; });
          }
        }
        else
        {
          if(order === "desc")
          {
            return data.sort(function (a, b) {return ('' + a.data[colIndex]).localeCompare(b.data[colIndex]); })
          }
          else
          {
            return data.sort(function (a, b) {return ('' + b.data[colIndex]).localeCompare(a.data[colIndex]); })
          }
          
        }
      },
  selectableRows: "none"
};

var dataArray = [];


// var theme = createMuiTheme({
//   palette: {primary: theme.palette.background.dark },
//   typography: {useNextVariants: true},
//   overrides: {
//     MUIDataTableToolbar: {
//       root: {
//         //position:"relative",
//         //right: "80px"
//         //backgroundColor: "#7F7372"
//       }
//     }
//   }
// });

class ContractTable extends React.Component {
  _isMounted = false;
    constructor(props){
      super(props);
      window.ContractTable = this;
      this.state = {
        data: [],
        isLoading: true,
        numberOfContracts: 0,
        benchmark:0,
        averageGrade: "Poor",
        calculation:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
      };
    }
    render() {
      return (
        <React.Fragment>
        <Grid
        item
        md={4}
        lg={4}
        xl={4}
      >
        <NumberOfContracts contracts={this.state.numberOfContracts} />
      </Grid>
      <Grid
        item
        md={4}
        lg={4}
        xl={4}
      >
      <GlobalBenchmark globalscore={this.state.benchmark}/>
      </Grid>
      <Grid
        item
        md={4}
        lg={4}
        xl={4}
      >
        <ContractGrade grade={this.state.averageGrade} />
      </Grid> 
      <Grid
        item
        lg={12}
        xs={12}
      >
       {/* <MuiThemeProvider 
       theme={theme} 
      >  */}
      
        <MUIDataTable
        className={useStyles.table}
         title={<h1 variant="title">
         {this.state.isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4, color:"#f8d02d"}} />}
         </h1>
         }
          data={data}
          columns={columns}
          options={options}
      > </MUIDataTable>
       {/* </MuiThemeProvider> */}
      </Grid>
      {Array.apply(null, { length: 20 }).map((e,i)=> <Grid
          item
          md={3}
          lg={3}
          xl={3}
          key={i}
        >
          <KPIChart keyvalue={i} percentage={this.state.calculation[i]}/>
        </Grid>)}
      </React.Fragment>);
      }

      YesandNoCalculation(index,data)
      {
        var yesCalculation = 0;
        data.forEach(element => {
          if(String(element[index]).toLowerCase() === "yes")
          {
            ++yesCalculation;
          }
        });
        yesCalculation = (yesCalculation / data.length)*100;
        return parseInt(Math.round(yesCalculation));
      }

      AverageRating(score)
      {
        var rating = "Best-in-Class";
        if(score < 39)
        {
          rating = "Poor";
        }
        else if(score < 59)
        {
          rating = "Average";
        }
        else if(score < 80)
        {
          rating = "Strong";
        }
        return rating;
      }

      RefreshTopData(data) {
        if (this._isMounted) 
        {
          var score = 0
          data.forEach((element => {
           score += parseInt(element[11]);
          }));
          var nOfContracts = data.length;
          var scoreVal = score/nOfContracts;
          var rating = this.AverageRating(scoreVal);
          this.setState({ benchmark:scoreVal,numberOfContracts:nOfContracts,averageGrade:rating,});
          var x = 22;
          this.setState({calculation:[this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),
            this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data)]});
        }
      }

      componentWillUnmount() {
        this._isMounted = false;
      }
  
      componentDidMount(){
          this._isMounted = true;
          dataArray = [];
          this.getContracts();
        }

        tableValueOutput(val){
          if(val === true)
          {
            return "Yes";
          }
          else if(val === false)
          {
            return "No";
          }
          else
          {
            return "Not Applicable";
          }
        }
       getContracts = async _=> {
        var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
        var token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);
        var output = await fetch('https://abintus-app-api-linux.azurewebsites.net/contracts/expert-assessment'
            ,{
              method: 'GET',
              headers: {
                  'token': token
              }}
            );
        var expertAssessmentList = await output.json();

        var evaluationKpis = await fetch('https://abintus-app-api-linux.azurewebsites.net/contract/evaluation/Ids'
        ,{
          method: 'GET',
          headers: {
              'token': token
          }}
        );
        var evaluationKpisList = await evaluationKpis.json();
        var contractUrls = [];
        fetch('https://abintus-app-api-linux.azurewebsites.net/contracts/url/list'
        ,{
          method: 'GET',
          headers: {
              'token': token
          }}
          )
        .then(response => response.json())
        .then(response => {
          contractUrls = response;
          fetch('https://abintus-app-api-linux.azurewebsites.net/contracts'
        ,{
          method: 'GET',
          headers: {
              'token': token
          }}
          )
        .then(response => response.json())
        .then(response => {
          var score = 0;
        response.forEach((element => {
          var url = "";
          var numberOfContracts = 0;
          var contractNames = "";
          for(var i = 0; i < contractUrls.length; i++)
          {
            if(contractUrls[i][0] === element[0])
            {
              url = contractUrls[i][1];
              numberOfContracts = contractUrls[i][2];
              contractNames = contractUrls[i][3];
              break;
            }
          }
          score+=parseInt(element[9]);
          var dateOfReview = element[5].substring(0,10);
          var dateOrder = dateOfReview.split('-');
          dateOfReview = dateOrder[2]+"/"+ dateOrder[1]+"/"+ dateOrder[0];
          var startDateOrder = element[16].substring(0,10).split('-');
          var userId = "";
          if(element[40] !== null)
          {
            for(let index = 0; index < expertAssessmentList.length; index++) {
              const item = expertAssessmentList[index];
              if(parseInt(item[0]) == parseInt(element[40]))
              {
                userId = item[1];
                break;
              }
            }
          }

          var evaluationTable = "";
          for (let index = 0; index < evaluationKpisList.length; index++) {
            const tableId = evaluationKpisList[index];
             if(element[0] === tableId)
             {
               evaluationTable = element[0];
               break;
             }
          }
          
          var startDate = startDateOrder[2]+"/"+ startDateOrder[1]+"/"+ startDateOrder[0];
          dataArray.push([element[0],userId,element[41],element[1],element[2],element[3],element[4],dateOfReview,(element[6])?"Yes":"No",element[7],element[8],element[9],element[10],element[11],element[12],element[13],element[14],element[15],startDate,element[17],(element[18])?"Yes":"No",element[19],
          this.tableValueOutput(element[20]),this.tableValueOutput(element[21]),this.tableValueOutput(element[22]),this.tableValueOutput(element[23]),this.tableValueOutput(element[24]),this.tableValueOutput(element[25]),this.tableValueOutput(element[26]),this.tableValueOutput(element[27]),this.tableValueOutput(element[28]),this.tableValueOutput(element[29]),this.tableValueOutput(element[30]),this.tableValueOutput(element[31]),this.tableValueOutput(element[32]),this.tableValueOutput(element[33]),this.tableValueOutput(element[34]),
          this.tableValueOutput(element[35]),this.tableValueOutput(element[36]),this.tableValueOutput(element[37]),this.tableValueOutput(element[38]),this.tableValueOutput(element[39]),[url,numberOfContracts,contractNames],element[40],evaluationTable]);
          }));

          data = dataArray.reverse();
          var nOfContracts = response.length;
          var scoreVal = score/nOfContracts;
          var rating = this.AverageRating(scoreVal);
          this.setState({ isLoading: false,benchmark:scoreVal,numberOfContracts:nOfContracts,averageGrade:rating });
          this.setState({data:dataArray.reverse()});
          var x = 22;
          this.setState({calculation:[this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),
            this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data),this.YesandNoCalculation(x++,data)]});
        })
        .catch(err => console.error(err))
        });

      } 
    }

function ContractDashboard() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container
        maxWidth={false}
        className={classes.container}
      >
        <Header />
        <Grid
          container
          spacing={3}
        >
          <ContractTable></ContractTable>
      </Grid>
      </Container>
    </Page>
  );
}

export default ContractDashboard;
