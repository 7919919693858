/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
var jwt = require('jsonwebtoken');
const useStyles = makeStyles((theme) => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
}));

function AboutYou({
  className,
  onBack,
  onNext,
  onComplete,
  setId,
  ...rest
}) {
  const [errors, setErrors] = useState(false);
  const classes = useStyles();
  var defDealWithMedia = '';
  var defCompanyRole ='';
  var defBudget ='';
  var defSatisfactionScore = 'Not Applicable';
  var arr = JSON.parse(localStorage.getItem("SelfAssessment"));
  if(typeof arr[23] !== 'undefined')
  {
    defCompanyRole = arr[23];
    defBudget = arr[24];
    defDealWithMedia = arr[25];
    defSatisfactionScore = arr[26];
  }

  return (
    <Formik
      initialValues={{
        dealWithMedia: defDealWithMedia,
        companyRole: defCompanyRole,
        satisfactionScore: defSatisfactionScore,
        budget: defBudget,
      }}
      validationSchema={Yup.object().shape({
        dealWithMedia: Yup.string().required('Required'),
        budget:Yup.string().required('Required'),
        companyRole:Yup.string().required('Required'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {

    var arr = JSON.parse(localStorage.getItem("SelfAssessment"));

    if(typeof arr[23] === 'undefined')
    {
      arr.push(values.companyRole);
      arr.push(values.budget);
      arr.push(values.dealWithMedia);
      arr.push(values.satisfactionScore);
    }
    else
    {
      arr[23] = values.companyRole;
      arr[24] = values.budget;
      arr[25] = values.dealWithMedia;
      arr[26] = values.satisfactionScore;
    }
    
    var yesValues = 0;
    var totalQuestions = 20;
    for (var i = 3; i < 23 ; i++)
    {
       if(arr[i] === "Yes")
       {
          ++yesValues;
       }
       else if(i == 3 && arr[i] === "Not Applicable")
       {
          totalQuestions -=1;
       }
       else if(i === 12 && arr[i] === "No")
       {
          totalQuestions -= 2;
       }
       else if(i === 18 && arr[i] === "Not Applicable")
       {
          totalQuestions -=1;
       }
    }

    var scoreVal = Math.round((yesValues/totalQuestions)*100,0);
    arr.push(scoreVal);
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var scoreCal = 0;
    var preparedbyAbintusScore = 0;
    var preparedbyAbintusAmount = 0;
    var tag = "";
    var removeTags = [];
    if(scoreVal < 39)
    {
      tag = "Contract_Self_Assessment_Result_Poor";
      removeTags.push("Contract_Self_Assessment_Result_Average");
      removeTags.push("Contract_Self_Assessment_Result_Strong");
      removeTags.push("Contract_Self_Assessment_Result_BestInClass");
    }
    else if(scoreVal < 59)
    {
      tag = "Contract_Self_Assessment_Result_Average";
      removeTags.push("Contract_Self_Assessment_Result_Poor");
      removeTags.push("Contract_Self_Assessment_Result_Strong");
      removeTags.push("Contract_Self_Assessment_Result_BestInClass");
    }
    else if(scoreVal < 80)
    {
      tag = "Contract_Self_Assessment_Result_Strong";
      removeTags.push("Contract_Self_Assessment_Result_Average");
      removeTags.push("Contract_Self_Assessment_Result_Poor");
      removeTags.push("Contract_Self_Assessment_Result_BestInClass");
    }
    else
    {
      tag = "Contract_Self_Assessment_Result_BestInClass";
      removeTags.push("Contract_Self_Assessment_Result_Average");
      removeTags.push("Contract_Self_Assessment_Result_Strong");
      removeTags.push("Contract_Self_Assessment_Result_Poor");
    }
    
    var accessToken = localStorage.getItem('accessToken');
    var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);
    // Test
    var token = jwt.sign({ token: bearer, Id: validToken.activeCampaignId, Tag: tag }, process.env.REACT_APP_ReadToken);
    token = jwt.sign({ token: bearer,Id:validToken.id,Email: validToken.username }, process.env.REACT_APP_ReadToken);
    fetch('https://abintus-app-api-linux.azurewebsites.net/notification/self-assessment'
      ,{
        method: 'POST',
        headers: {
            'token': token
        }
      }
    );
    
    token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);
    await fetch('https://abintus-app-api-linux.azurewebsites.net/contracts'
    ,{
      method: 'GET',
      headers: {
          'token': token
      }}
      )
    .then(response => response.json())
    .then(response => {      
      response.forEach((element => {
        scoreCal += parseInt(element[9]);
        if(element[6] === true)
        {
          preparedbyAbintusScore += parseInt(element[9]);
          ++preparedbyAbintusAmount;
        }
      }));
      arr.push(parseInt(scoreCal / response.length));
      arr.push(parseInt(preparedbyAbintusScore / preparedbyAbintusAmount));
    });

    localStorage.setItem('SelfAssessment', JSON.stringify(arr));
    for(var x=0; x< arr.length - 4; x++)
    {
        if(arr[x] === "Not Applicable")
        {
          arr[x] = "";
        }

        if(arr[x] === "Yes")
        {
          arr[x]=1;
        }

        if(arr[x] === "No")
        {
          arr[x]=0;
        }
    }
    arr.push("N/A");
    var dataLayer = window.dataLayer = window.dataLayer || []; 
    dataLayer.push({"event": "selfAssessment"});

    token = jwt.sign({ UserId:validToken.id, EventId:6, token: bearer }, process.env.REACT_APP_ReadToken);
    fetch('https://abintus-app-api-linux.azurewebsites.net/user/event' ,{
      method: 'POST',
      headers: {
          'token': token
      }}
    );

    token = jwt.sign({ token: bearer, Data: arr, Id:validToken.id}, process.env.REACT_APP_ReadToken);    
    fetch('https://abintus-app-api-linux.azurewebsites.net/self-assessment-score'
    ,{
      method: 'POST',
      headers: {
          'token': token
      }}
      )
    .then(response => response.text()).then(response => {         
      setId(response)
      localStorage.setItem('activeSelfAssessment', response)
    });
        try {
          setStatus({ success: true });
          setSubmitting(false);

          if (onComplete) {
            onComplete();
          }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Box
              mt={3}
              display="flex"
              alignItems="center"
            >
              <FormControl style={{width:"100%"}} 
                       variant="outlined" className={classes.formControl}>
                        <InputLabel id="CompanyRoleLabel">What position do you hold within your company?</InputLabel>
                          <Select
                          style={{paddingTop:window.innerWidth > 500 ?"0px":"20px"}}
                            error={Boolean(touched.companyRole && errors.companyRole)}
                            labelId="CompanyRoleLabel"
                            label="What position do you hold within your company?"
                            id="CompanyRole"
                            name ="companyRole"
                            value={values.companyRole}
                            onChange={(companyRole) =>  setFieldValue('companyRole', companyRole.target.value)}
                          >
                            <MenuItem value="Marketing">Marketing</MenuItem>
                            <MenuItem value="Brand">Brand</MenuItem>
                            <MenuItem value="Procurement">Procurement</MenuItem>
                            <MenuItem value="Legal">Legal</MenuItem>
                            <MenuItem value="C-Suite">C-Suite</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                          {Boolean(touched.companyRole && errors.companyRole) && (
                            <Box mt={1}>
                              <FormHelperText error>
                                {errors.companyRole}
                              </FormHelperText>
                            </Box>
                          )}
            </FormControl>
            </Box>
            <Box
              mt={3}
              display="flex"
              alignItems="center"
            >
            <FormControl style={{width:"100%"}} 
                       variant="outlined" className={classes.formControl}>
                        <InputLabel id="BudgetLabel">What is your average annual media budget?</InputLabel>
                          <Select
                          style={{paddingTop:window.innerWidth > 500 ?"0px":"20px"}}
                            error={Boolean(touched.budget && errors.budget)}
                            labelId="BudgetLabel"
                            label="What is your average annual media budget?"
                            id="Budget"
                            name ="budget"
                            value={values.budget}
                            onChange={(budget) =>  setFieldValue('budget', budget.target.value)}
                          >
                            <MenuItem value="Below $1M">Below $1M</MenuItem>
                            <MenuItem value="$1M - $5M">$1M - $5M</MenuItem>
                            <MenuItem value="$5M - $10M">$5M - $10M</MenuItem>
                            <MenuItem value="$10M - $25M">$10M - $25M</MenuItem>
                            <MenuItem value="$25M - $50M">$25M - $50M</MenuItem>
                            <MenuItem value="$50M - $100M">$50M - $100M</MenuItem>
                            <MenuItem value="Above $100M">Above $100M</MenuItem>

                          </Select>
                          {Boolean(touched.budget && errors.budget) && (
                            <Box mt={1}>
                              <FormHelperText error>
                                {errors.budget}
                              </FormHelperText>
                            </Box>
                          )}
            </FormControl>
            </Box>
            <Box
              mt={3}
              display="flex"
              alignItems="center"
            >
            <FormControl style={{width:"100%"}} 
                       variant="outlined" className={classes.formControl}>
                        <InputLabel id="DealWithMediaLabel">Do you directly deal with your media agency?</InputLabel>
                          <Select
                          style={{paddingTop:window.innerWidth > 500 ?"0px":"20px"}}
                            error={Boolean(touched.dealWithMedia && errors.dealWithMedia)}
                            labelId="DealWithMediaLabel"
                            label="Do you directly deal with your media agency?"
                            id="DealWithMedia"
                            name ="dealWithMedia"
                            value={values.dealWithMedia}
                            onChange={(dealWithMedia) =>  setFieldValue('dealWithMedia', dealWithMedia.target.value)}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                          {Boolean(touched.dealWithMedia && errors.dealWithMedia) && (
                            <Box mt={1}>
                              <FormHelperText error>
                                {errors.dealWithMedia}
                              </FormHelperText>
                            </Box>
                          )}
            </FormControl>
            </Box>
            <Box mt={3}>
            <FormControl style={{width:"100%"}} 
          variant="outlined" className={classes.formControl}>
                        <InputLabel 
                        // style={{width:"80%"}} 
                        id="SatisfactionScoreLabel">How satisfied are you with your media agency? (10 - Very satisfied; 1 - Very disappointed)</InputLabel>
                          <Select
                          style={{paddingTop:window.innerWidth > 500 ?"0px":"20px"}}
                            error={Boolean(touched.satisfactionScore && errors.satisfactionScore)}
                            labelId="SatisfactionScoreLabel"
                            label="How satisfied are you with your media agency? (10- Very satisfied; 1 - Very disappointed)"
                            id="SatisfactionScore"
                            name ="satisfactionScore"
                            value={values.satisfactionScore}
                            onChange={(satisfactionScore) =>  setFieldValue('satisfactionScore', satisfactionScore.target.value)}
                          >
                             <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                            <MenuItem value="0">0</MenuItem>
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="3">3</MenuItem>
                            <MenuItem value="4">4</MenuItem>
                            <MenuItem value="5">5</MenuItem>
                            <MenuItem value="6">6</MenuItem>
                            <MenuItem value="7">7</MenuItem>
                            <MenuItem value="8">8</MenuItem>
                            <MenuItem value="9">9</MenuItem>
                            <MenuItem value="10">10</MenuItem>

                          </Select>
                          {Boolean(touched.satisfactionScore && errors.satisfactionScore) && (
                            <Box mt={1}>
                              <FormHelperText error>
                                {errors.satisfactionScore}
                              </FormHelperText>
                            </Box>
                          )}
            </FormControl>
          </Box>
          <Box
            mt={6}
            display="flex"
          >
            {onBack && (
              <Button
                onClick={onBack}
                size="large"
                style={{border: "1px solid #14213D", textTransform: "none", fontWeight: "bold"}}
              >
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
              style={{textTransform: "none", fontWeight: "bold"}}
            >
              Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>

  );
}

AboutYou.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default AboutYou;
