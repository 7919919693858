import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  // notifications: notificationsReducer,
  // chat: chatReducer,
  // mail: mailReducer,
  // kanban: kanbanReducer,
  // form: formReducer
});

export default rootReducer;
