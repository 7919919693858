import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Paper,
  FormHelperText,
  Typography,
  Radio,
  Button,
  makeStyles,
  withStyles,
  MobileStepper,
  Grid
} from '@material-ui/core';


const questions = [[1,"Is your contract signed by both parties?","Is your contract been signed by both The Agency (Vendor) and The Client (Advertiser)? If you are still in the process of designing or finalizing your media agency contract, please choose ‘Not Applicable’ for this question."],
[2,"Does your contract include 'Defined Terms and Definitions'?","Defined terms and definitions could be a separate section in your contract (usually in the beginning or at the end of your contract) where the meaning of all legal and media terms is explained in a transparent way, leaving no place for ambiguity or misinterpretation. In some occasions, it is also possible to have the definitions presented throughout the contract, but it has to be clearly explained."],
[3,"Are the agreed 'Services' and 'Deliverables' clearly described?","Look for a detailed description of all the services and deliverables which are part of the agreement.  It is usually in a form of a table within one of the appendices of your contract."],
[4,"Does your contract cover 'Detailed and Transparent Reporting'?","Look for the ‘Reporting’ section or clauses within the body of the contract.  It could also be listed within one of the appendices of your contract. It should explain in detail the level of reporting you will receive and what KPIs will be reported."],
[5,"Is your agency acting purely as an 'Agent'?","Whether your agency acting as an ‘Agent’ or ‘Principal’ should be stated clearly at the beginning or in the body of your contract. If not, look at for terms like ‘Inventory Media’ or ‘Proprietary Media’ which relate to your agency acting as ‘Principal’.  If you can’t find these terms, and your agency is stating that they will act in a transparent way, then you can assume your agency is acting as ‘Agent’."],
[6,"Do you have strong 'Authority' clauses?","Look for a clause in your contract which indicates that any changes to your contract requires the authorisation of the signatories to the agreement to avoid situations where a person without proper authority could amend the key terms of the agreement."],
[7,"Is the 'Exclusive' / 'Non-Exclusive' basis reciprocal?","Look for ‘Exclusivity’ clauses or section in your contract. If you work with your agency on a non-exclusive basis and your agency also works with you on a non-exclusive basis, then your answer should be ‘Yes’. If you work with your agency on an exclusive basis, and your agency also works with you on an exclusive basis, then answer is also ‘Yes’. Otherwise, please select ‘No’ as your answer."],
[8,"Does the contract covers the key individuals working on your account?","Does your contract specifically mention the name and position of the key individuals who are working on your account from the agency side?"],
[9,"Is your agency remuneration calculated in a transparent manner?","Look for a table indicating how the agency remuneration was calculated.  It usually includes all the people or positions working on your account, their FTE, their average annual salary, and the overheads and profit margin applied."],
[10,"Does your agency remuneration model include a 'PRF' or ‘PRIP’ scheme?","A Performance Related Fee (PRF) is a scheme designed to incentivise and penalise your agency financially based on performance.  It also referred as a PRIP (Performance Related Incentive Program) or Bonus scheme."],
[11,"Does your 'PRF' scheme include both a bonus and a malus mechanism?","Does your PRF scheme has a mechanism to reward your media agency for ‘Above Expectations’ deliverables (Bonus) AND a mechanism to penalize your media agency for ‘Below Expectations’ deliverables or ‘Missed Commitments’ (Malus)? If your contract does not have a PRF or PRIP scheme, then you only have the ‘Not Applicable’ answer available to you."],
[12,"Does your contract include a methodology for calculating the agency bonus/malus?","Look for a methodology document (or detailed steps with the PRF/PRIP scheme) in the appendices explaining in detail how the bonus or malus will be calculated. If your contract does not have a PRF or PRIP scheme, then you only have the ‘Not Applicable’ answer available to you."],
[13,"Does your contract include any 'Agency Commitments' for all key media channels?","Following a media agency pitch (or contract re-negotiation process), a media agency would usually offer commitments for media prices, media quality KPIs, AVBs/Cash Rebates, etc… to be received during the length of the contract.  These would be included either in the body of the contract or in the appendices."],
[14,"Does your contract include robust Audit Rights?","Look for Audit clauses or section. It is considered robust audit rights if you have the flexibility to appoint any auditor of your choice, for any audit services (performance auditing & financial auditing) without any restrictions from the agency?"],
[15,"Does your contract cover the treatment of Agency Volume Benefits (AVBs)?","Agency Volume Benefits deals (commonly referred to as AVBs, agency volume deals, rebates or media kickbacks) typically take the form of cash incentives (or free media inventory) offered to media agencies by media owners to incentivize them to spend more on their properties. Look for these terms in your contract. Best practice is to receive your fair-share of AVBs based on your annual media expenditure on a calendar year."],
[16,"Does your contract cover the treatment of 'Early Payment Discounts' (EPD)?","In most markets, advertisers are entitled to receive additional discounts (EPD) for paying media upfront (before the campaign starts). Your agency must disclose the existence of all EPD opportunities, and the terms and conditions should be included in your contract."],
[17,"Does your contract cover the treatment of 'Unbilled Media'?","Look for the term ‘Unbilled Media’ in the body of your contract. Unbilled Media arise because some media owner/sales houses are poor at billing and sometimes forgot to invoice the Agency for activity that has been booked and has taken place. The amounts involved can be very significant, so it is important that you have the treatment of unbilled media covered in your contract."],
[18,"Are your agreed payment terms competitive?","Usually 30 days. However, based on the local market, it could be as long as 90 days. Anything below 30 days is not considered competitive."],
[19,"Do you have standard termination clauses?","The notice period will generally depend on the size of the account and scope of the agreement.  However, it is common practice in the industry to have a 3-month notice period without cause."],
[20,"Does the contract have strong sub-contracting clauses?","Look for ‘Sub-contracting’ clauses in the body of your contract. The Agency should be entitled to sub-contract its performance of the Services and/or Deliverables, but ONLY with your prior written approval."]]

var typeOptions = [
  {
    value: 'Yes',
    title: 'Yes',
    output: true,
  },
  {
    value: 'No',
    title: 'No',
    output: true,
  },
  {
    value: 'Not Applicable',
    title: 'Not Applicable',
    output: false,
    outputApproved: [1,16]
  }
];

const CustomStepper = withStyles({
  progress:{
    backgroundColor:"#7c6816",
    "& .MuiLinearProgress-barColorPrimary":{
      backgroundColor:"rgba(248, 208, 45, 1)",
    },
  },
  root: {
    padding: "10px 0 0 0",
    //fontSize: "14px",
    //color: "#222431",
    backgroundColor: "#DBE7F2",
    //"#f8d02d"

  }
})(MobileStepper);


const useStyles = makeStyles((theme) => ({
  root: {},
  radioButton:{
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
    border: "solid 1px #025373",
    alignItems: "center"
  },
  stepButton: {
    '& + &': {
      // marginLeft: theme.spacing(2)
    }
  }
}));

function ContractInformation({
  className,
  onBack,
  onNext,
  ...rest
}) {

  // const handleNextt = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };
  
  // const handleBackk = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };
  //const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  var arr = JSON.parse(localStorage.getItem("SelfAssessment"));
  var disableButton = false;
  var explanationText = " Please select one option";
  var defaultType = null;
  if(rest.question === 12 || rest.question === 13)
  {
      if(arr[12] === "No")
      {
        typeOptions = [
          {
            value: 'Yes',
            title: 'Yes',
            output: true,
          },
          {
            value: 'No',
            title: 'No',
            output: true,
          },
          {
            value: 'Not Applicable',
            title: 'Not Applicable',
            output: false,
            outputApproved: [1,11,12,16]
          }
        ];
        disableButton = true;
        explanationText = "Due to your previous answers we have disabled some of the options";
        defaultType = typeOptions[2].value;
      }
      else
      {
        typeOptions = [
          {
            value: 'Yes',
            title: 'Yes',
            output: true,
          },
          {
            value: 'No',
            title: 'No',
            output: true,
          },
          {
            value: 'Not Applicable',
            title: 'Not Applicable',
            output: false,
            outputApproved: [1,16]
          }
        ];
        defaultType = typeOptions[0].value;
      }

  }
  else
  {
    if(typeof arr[2 + rest.question - 1] !== 'undefined')
    {
      if(arr[2 + rest.question - 1] === "Yes")
      {
        defaultType = typeOptions[0].value;
      }
      else if (arr[2 + rest.question - 1] === "Not Applicable")
      {
        defaultType = typeOptions[2].value;
      }
      else
      {
        defaultType = typeOptions[1].value;
      }
    }
  }

  const [type, setType] = useState(defaultType);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (newType) => {
    setType(newType);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (type == null) {
      setError("Please submit an answer before continuing.")
    }
    else {
      var arr = JSON.parse(localStorage.getItem("SelfAssessment"));
      if(typeof arr[2 + rest.question - 1] === 'undefined')
      {
        arr.push(type);
      }
      else
      {
        arr[2 + rest.question - 1] = type;
      }
      
      localStorage.setItem('SelfAssessment', JSON.stringify(arr));
      try {
        // Do api call
        setSubmitting(false);
  
        if (onNext) {
          onNext();
        }
      } catch (err) {
        setError(err.message);
        setSubmitting(false);
      }
    }

  };

  return (
    <form
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Typography
        variant="h4"
        color="textPrimary"
        style={{fontWeight:"bold"}}
      >
        {questions[rest.question - 2][1]}
      </Typography>
      <Box mt={2}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
        >
           {explanationText}
        </Typography>
      </Box>
      <Box mt={2}>
        {typeOptions.map((typeOption) => (
          typeOption.output === true ?
          <Paper                     
            display="flex"
            alignItems="flex-start"
            className={classes.radioButton}
            p={2}
            mb={2}
            component={Box}
            elevation={type === typeOption.value ? 10 : 1}
            key={typeOption.value}
            onClick={() => handleChange(typeOption.value)}
          >
            <Radio
              checked={type === typeOption.value}
              onClick={() => handleChange(typeOption.value)}
              disabled ={disableButton}
              color="primary"
            />
            <Box ml={2} >
              <Typography
                gutterBottom
                variant="h5"
                color="textPrimary"
              >
                {typeOption.title}
              </Typography>
            </Box>
          </Paper>
        : typeOption.outputApproved.includes(rest.question - 1)?
        <Paper        
        display="flex"
        alignItems="flex-start"
        className={classes.radioButton}
        p={2}
        mb={2}
        component={Box}
        elevation={type === typeOption.value ? 10 : 1}
        key={typeOption.value}
        onClick={() => handleChange(typeOption.value)}
      >
        <Radio
          checked={type === typeOption.value}
          onClick={() => handleChange(typeOption.value)}
          color="primary"
        />
        <Box ml={2}>
          <Typography
            gutterBottom
            variant="h5"
            color="textPrimary"
          >
            {typeOption.title}
          </Typography>
        </Box>
      </Paper>:<Paper key={typeOption.value}></Paper>))}
      </Box>
      {error && (
        <Box mt={2}>
          <FormHelperText error>
            {error}
          </FormHelperText>
        </Box>
      )}
       <Box mt={2}>
       <Typography
                gutterBottom
                variant="subtitle1"
                align="justify"
              >
                <Typography style={{color:"#14213D",fontWeight:"bold"}}>Hint: </Typography>{questions[rest.question - 2][2]}
              </Typography>
        </Box>
        <Grid item md={12} xs={12} lg={12} align="center" >
         <CustomStepper
         style={{backgroundColor: "#FFFFFF",}}
          variant="progress"
          steps={21}
          position="static"
          activeStep={rest.question - 2}
          className={classes.root}
          backButton = { <Button
          style={{marginRight:"20px", fontSize:"13px", fontWeight: "bold", border: "1px solid #14213D", textTransform: "none"}}
          onClick={onBack}
          size="large"
          >
            Previous
          </Button>}
          nextButton ={
            <Button
          color="secondary"
          style={{marginLeft:"20px", textTransform: "none", fontWeight: "bold"}}
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          size="large"          
        >
          Next
        </Button>
          }
        />
      </Grid>
    </form>
  );
}

ContractInformation.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default ContractInformation;
