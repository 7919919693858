import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { login } from 'src/actions/accountActions';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  Link,
  makeStyles,
  Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,CircularProgress
} from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
var jwt = require('jsonwebtoken');
var bcrypt = require('bcryptjs');

const useStyles = makeStyles(() => ({
  root: {},
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #A2BEE3 inset"
    }
  }}));

function RegisterForm({ className, onSubmitSuccess, ...rest }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [loginError,setLoginError] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [disableButton,setDisableButton] = React.useState(false);
  const [displayLoading,setDisplayLoading] = React.useState("none");

  const handleClose = async() => {
    await new Promise(r => setTimeout(r, 1000));
    console.log("getting started");
	if(localStorage.getItem('accessToken') === null)
	{
		console.log("empty");
	}
    
    history.push('/login');
  };
  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        Password: '',
        confirmPassword: '',
        policy: false
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required('First name is required'),
        lastName: Yup.string().max(255).required('Last name is required'),
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        Password: Yup.string()
        .min(7)
        .max(255).required('Password is required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('Password'), null],'Passwords must match').required('Confirm Password is required'),
        policy: Yup.boolean().oneOf([true], 'This field must be checked')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
          setDisableButton(true);
          setDisplayLoading("inline");
          var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
          var token = jwt.sign({ Username: values.email, token: bearer }, process.env.REACT_APP_ReadToken);
          var result = "false";          
          setLoginError("");
          await fetch('https://abintus-app-api-linux.azurewebsites.net/user'
          ,{
            method: 'GET',
            headers: {
                'token': token
            }}
            )
          .then(response => response.text()).then(response => {
            result = response;
          });
          if(result === "true")
          {
            var salt = bcrypt.genSaltSync(10);
            var hash = bcrypt.hashSync(values.Password, salt);
            var id = 0;
            token = jwt.sign({ Username:values.email, Password:hash, MobileNumber:values.mobileNumber, token: bearer }, process.env.REACT_APP_ReadToken);
            await fetch('https://abintus-app-api-linux.azurewebsites.net/user/register'
            ,{
              method: 'POST',
              headers: {
                  'token': token
              }}
              )
            .then(response => response.text()).then(response => {
              id = response;
              console.log(response);
              token = jwt.sign({ Id:id, FirstName:values.firstName, LastName: values.lastName,Email:values.email, MobileNummber:values.mobileNumber, token: bearer }, process.env.REACT_APP_ReadToken);
              fetch('https://abintus-app-api-linux.azurewebsites.net/user/register/info'
              ,{
                method: 'POST',
                headers: {
                    'token': token
                }}
                )
                .then(res => res.text()).then(res => { 
              if(res !=="true")
              {                  
                  setDisplayLoading("none");
                  setStatus({ success: false });
                  setErrors({ submit: result });
                  setLoginError("We're having service issues. Please try again later");
                  setDisableButton(false);
                  setOpen(false);
                }
              else
              {
                token = jwt.sign({token: bearer,FirstName:values.firstName, LastName: values.lastName,Email:values.email }, process.env.REACT_APP_ReadToken);                
                 token = jwt.sign({ UserId:id, EventId:1, token: bearer }, process.env.REACT_APP_ReadToken);                
                  token = jwt.sign({ Email: values.email, token: bearer }, process.env.REACT_APP_ReadToken);
                setDisplayLoading("none");
                setOpen(true);
                setDisableButton(false);
               
              }});
            });

            // await dispatch(login(values.email, values.Password));

          }
          else
          {
            setDisplayLoading("none");
            setStatus({ success: false });
            setErrors({ submit: result });
            setDisableButton(false);
            setLoginError("Account with this email address already exists");
            setSubmitting(false);
          }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Box display={displayLoading} > <CircularProgress color="secondary" /></Box>
          <TextField
            inputProps={{ className: classes.input }}
            autoComplete="off"
            error={Boolean(touched.firstName && errors.firstName)}
            fullWidth
            helperText={touched.firstName && errors.firstName}
            label="First Name"
            margin="normal"
            name="firstName"
            onBlur={handleBlur}
            onChange={handleChange}
            type="firstName"
            value={values.firstName}
            // variant="outlined"
          />
          <TextField
            inputProps={{ className: classes.input }}
            autoComplete="off"
            error={Boolean(touched.lastName && errors.lastName)}
            fullWidth
            helperText={touched.lastName && errors.lastName}
            label="Last Name"
            margin="normal"
            name="lastName"
            onBlur={handleBlur}
            onChange={handleChange}
            type="lastName"
            value={values.lastName}            
          />
          <TextField
            inputProps={{ className: classes.input }}
            autoComplete="off"
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            style={{marginBottom: "5px"}}
          />
          <TextField
            inputProps={{ className: classes.input }}
            autoComplete="off"
            error={Boolean(touched.Password && errors.Password)}
            fullWidth
            helperText={touched.Password && errors.Password}
            label="Password"
            margin="normal"
            name="Password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.Password}                        
          />
          <Typography
              variant="body2"
              color="textSecondary"
              style={{marginBottom: "-10px"}}
            >
              Password must be at least 7 characters long
            </Typography>
          <TextField
            inputProps={{ className: classes.input }}
            autoComplete="off"
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            label="Confirm Password"
            margin="normal"
            name="confirmPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.confirmPassword}
            style={{marginBottom: "20px"}}
          />
          <MuiPhoneNumber style={{width:"100%", paddingBottom: "10px"}} defaultCountry={'gb'} label="Phone Number" value={values.mobileNumber}  onChange={value => {
            setFieldValue("mobileNumber",value);
          }} />     
          <Box
            alignItems="center"
            display="flex"
            mt={2}
            ml={-1}
          >
            <Checkbox
              checked={values.policy}
              name="policy"
              color="primary"
              onChange={handleChange}
            />
            <Typography
              variant="body2"
              color="textSecondary"
            >
              I have read the
              {' '}
              <Link
                component="a"
                href="/terms"
                style={{textDecoration: "underline"}}
                target="_blank" rel="noopener noreferrer"
                color="primary"
              >
                Terms and Conditions
              </Link>
            </Typography>
          </Box>
          {Boolean(touched.policy && errors.policy) && (
            <FormHelperText error>
              {errors.policy}
            </FormHelperText>
          )}
          <Box mt={2}>
            <RegisterButton
              color="secondary"
              disabled={disableButton}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Create account
            </RegisterButton>
            <FormHelperText error>
              {loginError}
            </FormHelperText>
          </Box>
          <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="DialogTitle">
        <DialogTitle id="DialogTitle">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your account has been successfully created
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>Continue</Button>
        </DialogActions>
      </Dialog>
        </form>
        
      )}
    </Formik>
  );
}

const RegisterButton = styled.button`
background-color: #000000;
color: #FFFFFF;
font-size: 1rem;
width: 100%;
border: none;
padding: 10px;
border-radius: 30px;
transition: 0.2s;

&:hover {
  background-color: #3D3D3D;
  transition: 0.2s;
  cursor: pointer;
}`

RegisterForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};

RegisterForm.default = {
  onSubmitSuccess: () => {}
};

export default RegisterForm;
