/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Home as HomeIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  FilePlus as PlusIcon,
  BarChart2 as Bar2ChartIcon,
  Star as StarIcon,
  HelpCircle as HelpIcon,
  File as FileIcon,
  Settings as SettingsIcon,
} from 'react-feather';

import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import { logout } from 'src/actions/accountActions';
import { useDispatch} from 'react-redux';
import { useSnackbar } from 'notistack';
var jwt = require('jsonwebtoken');


const navConfig = [
  {
    subheader: 'MEDIA AGENCY CONTRACT',
    items: [
      {
        title: 'Your Dashboard',
        icon: HomeIcon,
        href: '/app/your-dashboard'
      },
      {
        title: 'Getting Started',
        icon: HelpIcon,
        href: '/app/getting-started'
      },
      {
        title: 'Self Assessment',
        icon: Bar2ChartIcon,
        href: '/app/self-assessment'
      },
      {
        title: 'Expert Assessment',
        icon: StarIcon,
        href: '/app/expert-assessment'
      },
      {
        title: 'Settings',
        icon: SettingsIcon,
        href: '/app/settings'
      },
      {
        title: 'Contact Us',
        icon: MailIcon,
        href: '/app/contact-us'
      },
    ]
  },
  {
    subheader: `Admin  Version: ${process.env.React_APP_VersionNumber}`,
    items:[
      {
        title: 'Contracts',
        icon: PieChartIcon,
        items: [
          {
            title: 'Contract Dashboard',
            icon: PieChartIcon,
            href: '/app/admin/contract-dashboard'
          },
          {
            title: 'Add Contract Evaluation',
            icon: PlusIcon,
            href: '/app/admin/addcontract'
          },
          {
            title: 'Edit Contract Evaluation',
            icon: EditIcon,
            href: '/app/admin/editcontract'
          }
        ]
      },
      {
        title: 'Self Assess. Dashboard',
        icon: PieChartIcon,
        href: '/app/admin/self-assessment-dashboard'
      },
      {
        title: 'Expert Dashboard',
        icon: PieChartIcon,
        href: '/app/admin/expert-dashboard'
      },
      // {
      //   title: 'Users',
      //   icon: UsersIcon,
      //   href: '/app/admin/users'
      // },
      // {
      //   title: 'DocGenerator',
      //   icon: FileIcon,
      //   href: '/app/admin/doc-generator'
      // },
    ]
  },
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    // const open = matchPath(pathname, {
    //   path: item.href,
    //   exact: false
    // });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        style={{color:"#172354"}}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        style={{color:"#172354"}}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%"
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logOut: {
    height: "50px",
    textAlign: "center",
  }, 
  logOutLink: {
    color: "#172354",

    "&:hover": {
      cursor: "pointer"
    }
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();   
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    var id = 0;
    jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {      
      id = parseInt(decoded.id);
    });
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ UserId:id, EventId:4, token: bearer }, process.env.REACT_APP_ReadToken);
    fetch('https://abintus-app-api-linux.azurewebsites.net/user/event'
    ,{
      method: 'POST',
      headers: {
          'token': token
      }}
      );
    try {
      // handleClose();
      await dispatch(logout());
      navigate("/")
      window.location.reload();
    } catch (error) {     
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      style={{backgroundColor:"#EEEEEE"}}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>        
          <Box
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
            <Typography variant="h3" style={{ fontSize: "15px"}}>Admin</Typography>
          </Box>            
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                  style={{color:"#172354"}}
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
        <Divider />
        {/* <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              style={{color:"#172354"}}
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              style={{color:"#172354"}}
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
      <Box className={classes.logOut}>
          <Divider style={{marginBottom: "10px"}}/>
          <Link variant="body1" className={classes.logOutLink} onClick={handleLogout}>Log Out</Link>
        </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
