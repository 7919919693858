import React from 'react';
import { Card,CardContent,Grid,TextField,
Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Box,Typography,makeStyles
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from "styled-components"
var jwt = require('jsonwebtoken');
var bcrypt = require('bcryptjs');


const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none"
  }
}));
export default function WorkStatus({ className,userId,email, ...rest }) {
  const [editDetailsErrorTitle,setEditDetailsErrorTitle] = React.useState("Success");
  const [editDetailsError,setEditDetailsError] = React.useState("Your password has been changed");
  const [open, setOpen] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
      <Grid container spacing={3}
      direction="column"
      alignItems="center"
      justify="center"
      >
      <Card className={classes.card}>
        <CardContent style={{padding : "24px"}}>
        <Grid item  >
           <Formik
              enableReinitialize={true}
      initialValues={{
        Password: "",
        confirmPassword:"",
      }}
      validationSchema={Yup.object().shape({
        Password: Yup.string()
        .min(7)
        .max(255),
        confirmPassword: Yup.string().oneOf([Yup.ref('Password'), null],'Passwords must match').min(7).when('Password', {
          is: (Password) => Password != null,
          then: Yup.string().required('Confirm Password is required')}),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
          setDisableButton(true);
          var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
          if(values.Password.length > 0)
          {
            var salt = bcrypt.genSaltSync(10);
            var hash = bcrypt.hashSync(values.Password, salt);
           
            var token = token = jwt.sign({ Id:userId, Password:hash, Salt:salt, token: bearer,Email:email }, process.env.REACT_APP_ReadToken);
            fetch('https://abintus-app-api-linux.azurewebsites.net/user/edit/password'
            ,{
              method: 'POST',
              headers: {
                  'token': token
              }}
              )
            .then(response => response.text()).then(response => {
              if(response === "true")
              {
                setOpen(true);
                token = jwt.sign({ UserId:userId, EventId:11, token: bearer }, process.env.REACT_APP_ReadToken);
                fetch('https://abintus-app-api-linux.azurewebsites.net/user/event'
                ,{
                  method: 'POST',
                  headers: {
                      'token': token
                  }}
                  );
              }
              else
              {
                setEditDetailsErrorTitle("Error");
                setEditDetailsError("There was an issue when saving your changes.Please try again.")
                setOpen(true);
              }
            });
          };
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => (
        <form
        onSubmit={handleSubmit}
      >
        <Typography
              variant="body2"
              color="textSecondary"
            >
              New Password must be at least 7 characters long
            </Typography>
          <TextField
            autoComplete = "new-password"
            error={Boolean(touched.Password && errors.Password)}
            fullWidth
            helperText={touched.Password && errors.Password}
            label="New Password"
            margin="normal"
            name="Password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.Password}
            variant="outlined"
          />
          <TextField
            autoComplete="off"
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            label="Confirm Password"
            margin="normal"
            name="confirmPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.confirmPassword}
            variant="outlined"
          />
          <Box mt={2}>
            <SaveChangesButton
              color="secondary"
              disabled={disableButton}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Save Changes
            </SaveChangesButton>
          </Box>
          <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="DialogTitle">
          <DialogTitle id="DialogTitle">{editDetailsErrorTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{editDetailsError}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleClose}>Close</Button>
          </DialogActions>
          </Dialog>
        </form>
        
      )}
    </Formik>
                      
        </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

const SaveChangesButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem;
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 30px;
  transition: 0.2s;
  font-family: Poppins;

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
  }
`

WorkStatus.propTypes = {
  userId: PropTypes.any,
  email:PropTypes.string,
  className: PropTypes.string
};




