import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'space-between',
    backgroundColor: "rgba(208, 229, 242, 0.6)",
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    [theme.breakpoints.down('md')]: {
      width: "97%",
      margin: "0 auto",
    }
  },
  headerContainer: {
    fontFamily: 'Poppins',
    fontSize: '1.05rem',
    [theme.breakpoints.down('md')]: {
      width: "100%",      
    },    
  },
  statistics: {
    [theme.breakpoints.down('md')]: {
      width: "100%",
      justifyContent: "space-between"
    }
  },
  subContainer: {
    [theme.breakpoints.down('md')]: {
      justifyContent: "space-between",
      paddingRight: "50px"
    }
  }
}));

function CompletedContractScore({ className, ...rest }) {
  const classes = useStyles();
  const data = {
    score: rest.score,
    numberOfContracts: rest.contracts,
  };

    return (
      <div 
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Grid
          container
          spacing={3}
          className={classes.subContainer}
        >
          <Box flexGrow={1} className={classes.headerContainer}>
          <h3 className={classes.header}>
            Assessments Overview
          </h3>          
          </Box>
          <Grid
            item
            md={6}
            lg={6}
            xl={6}            
          >
            <Box
          >
            <Typography
              variant="h2"
              color="textPrimary"
              style={{textAlign:"center", fontWeight:"bold", marginBottom: "8px" }}
            >
              {data.numberOfContracts}
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              style={{textAlign:"center", fontSize:"13px"}}
            >
               No. Of Assessments
            </Typography>
          </Box>
          </Grid>
          <Grid
            item
            md={6}
            lg={6}
            xl={6}
          >
             <Box>
            <Typography
              variant="h2"
              color="textPrimary"
              style={{textAlign:"center",fontWeight:"bold", marginBottom: "8px" }}
            >
              {data.score}%  
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              style={{textAlign:"center", fontSize:"13px"}}
            >
              Score
            </Typography>
          </Box>
            </Grid>
        </Grid>

      </div>
    );
}

CompletedContractScore.propTypes = {
  className: PropTypes.string
};

export default CompletedContractScore;
