import React from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles,Typography
} from '@material-ui/core';
import { changePassword } from 'src/actions/accountActions';
import styled, { keyframes } from 'styled-components';
var jwt = require('jsonwebtoken');
const useStyles = makeStyles(() => ({
  root: {},  
}));

function ResetPassswordForm({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        Password: '',
        confirmPassword: '',
      }}
      validationSchema={Yup.object().shape({
        Password: Yup.string()
        .min(7)
        .max(255).required('Password is required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('Password'), null],'Passwords must match').required('Confirm Password is required'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
            
          //await dispatch(resetPassword(values.email));
          var arr = JSON.parse(localStorage.getItem("Reset"));
          if(typeof arr[0] === 'undefined')
          {
            const message = "There was an issue while updating your account.Please try again";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
          else
          {
            await dispatch(changePassword(values.Password,arr[0], arr[1]));
          }
          var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
          var token = jwt.sign({ Email: arr[0], token: bearer }, process.env.REACT_APP_ReadToken);
          var output = await fetch('https://abintus-app-api-linux.azurewebsites.net/user/user-id'
          ,{
            method: 'GET',
            headers: {
                'token': token
            }}
          );
          var id = await output.text();
          token = jwt.sign({ UserId:id, EventId:5, token: bearer }, process.env.REACT_APP_ReadToken);
          fetch('https://abintus-app-api-linux.azurewebsites.net/user/event'
          ,{
            method: 'POST',
            headers: {
                'token': token
            }}
            );
          onSubmitSuccess();
        } catch (error) {
          console.log(error);
          const message = (error.response && error.response.data.message) || "There was an issue while updating your account.Please try again";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
         <TextField
            error={Boolean(touched.Password && errors.Password)}
            fullWidth
            helperText={touched.Password && errors.Password}
            label="Password"
            margin="normal"
            name="Password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.Password}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            label="Confirm Password"
            margin="normal"
            name="confirmPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.confirmPassword}
            variant="outlined"
          />
           <Typography
              variant="body2"
              color="textSecondary"
            >
              Password must be at least 7 characters
            </Typography>
          <Box mt={2}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}



ResetPassswordForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};

ResetPassswordForm.defaultProps = {
  onSubmitSuccess: () => {}
};

export default ResetPassswordForm;
