import React from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import { confirmResetPassword } from 'src/actions/accountActions';
import "@fontsource/poppins"
import styled, { keyframes } from 'styled-components';
const useStyles = makeStyles(() => ({
  root: {},  
}));

function ConfirmCodeForm({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        code: '',
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().required('Code is required'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          await dispatch(confirmResetPassword(values.code));
          var arr = JSON.parse(localStorage.getItem("Reset"));
          arr.push(values.code);
          localStorage.setItem("Reset", JSON.stringify(arr));
          onSubmitSuccess();
        } catch (error) {
          console.log(error);
          const message = (error.response && error.response.data.message) || "You have entered an incorrect code or the code has expired";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.code && errors.code)}
            fullWidth
            helperText={touched.code && errors.code}
            label="Confirmation Code"
            margin="normal"
            name="code"
            onBlur={handleBlur}
            onChange={handleChange}
            type="code"
            value={values.code}            
          />
          <Box mt={2}>
            <ResetNowButton
              style={{fontFamily: "Poppins"}}
            >
              Reset Now
            </ResetNowButton>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}

const ResetNowButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem;
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 30px;
  transition: 0.2s;

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
  }
`
ConfirmCodeForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};

ConfirmCodeForm.defaultProps = {
  onSubmitSuccess: () => {}
};

export default ConfirmCodeForm;
