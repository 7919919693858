import React from 'react';
import { Container,makeStyles,
Box,Typography,Link
} from '@material-ui/core';
import Page from 'src/components/Page';
import Footer from '../../pages/HomeView/Footer'

require('typeface-open-sans');


const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
      paddingTop: "48px",
      paddingBottom: "48px"
    }
  }

}));


export default function TermsAndConditions() {
  const classes = useStyles();
  return (
    <Page  title="Terms And Conditions">
      <Box mt={4}></Box>
      <Container className={classes.container}>
      <Typography  align="center" variant="h1" style={{fontWeight:"bold"}}>Terms And Conditions</Typography>
      <Box mt={4}></Box>
      <Typography  align="left" variant="body1">Abintus Consulting Ltd provides all content on its websites subject to the following terms and conditions. By using our site, you indicate that you accept these terms of use and that you agree to abide by them. If you do not agree to these terms of use, please refrain from using our site. For an explanation on how Abintus Consulting Ltd collects and uses the personal information you provide on our websites, please read our Privacy Policy.</Typography>
      <Box mt={2}>
        <Typography  align="left" variant="h5" style={{fontWeight:"bold"}}>Copyrights &amp; Trademarks</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1">All content on all Abintus Consulting Ltd owned websites, including text, graphics, logos, icons and images is the exclusive property of Abintus Consulting Ltd and protected by international copyright laws. The trademarks, service marks, designs and logos displayed on all Abintus Consulting Ltd owned websites are the registered and unregistered Trademarks of Abintus Consulting Ltd and subsidiaries. By browsing our websites, you agree that you will not refer to or feature any information about Abintus Consulting Ltd in any public medium (blogs, press releases, web content) for promotional purposes or advertising means. You will also not use or copy any trademark nor imply any endorsement by Abintus Consulting Ltd for the purpose of influencing a third party.</Typography>
      </Box>
      <Box mt={2}>
        <Typography  align="left" variant="h5" style={{fontWeight:"bold"}}>Use of Site Content and Intellectual Property Rights</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1">Abintus Consulting Ltd is the owner and licensee of all intellectual property rights on all our sites, and the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved. You may print off a copy, and download extracts of any page(s) from our site for your personal reference and you may draw the attention of others within your organisation to material posted on our site.</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1">You must not, however, modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not alter or edit any illustrations, photographs and graphics, video or audio sequences from their original look and content. You must not use any part of the materials on our site for commercial purposes without obtaining a licence to do so from us beforehand.</Typography>
      </Box>
      <Box mt={2}>
        <Typography  align="left" variant="h5" style={{fontWeight:"bold"}}>User Postings</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1">You acknowledge and agree that Abintus Consulting Ltd shall own and have the unrestricted right to use and publish any and all information that you post or publish on any of its sites, in postings, survey responses and otherwise. You hereby waive any claims against Abintus Consulting Ltd for alleged or actual infringements towards any rights of privacy or publicity or rights of acknowledgement in connection with Abintus Consulting Ltd’s use and publication of such submissions. Abintus Consulting Ltd reserve the right to refuse to post and the right to remove any information, in whole or in part, for any reason or for no reason.</Typography>
      </Box>
      <Box mt={2}>
        <Typography  align="left" variant="h5" style={{fontWeight:"bold"}}>Free Access to the Abintus Academy and the Abintus App </Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="h5">The following terms and conditions will apply if you have registered as a free member of the Abintus Academy or the Abintus App: </Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1"> • All customers signing up for a free access will be required to use a valid email address.</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1"> • Abintus Consulting Ltd reserves the right to remove or cancel the free access at any time for any reason.</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1"> • Abintus Consulting Ltd reserves the right, in its absolute discretion, to withdraw or to modify the free access to the Abintus Academy and/or the Abintus App at any time without prior notice and with no liability.</Typography>
      </Box>
      <Box mt={2}>
        <Typography  align="left" variant="h5" style={{fontWeight:"bold"}}>Consistent Site Changes</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1">We aim to update elements of our site regularly and may change the content at any time. If the need arises, we may suspend access to our site. Any of the material on our site may be out of date at any given time, and we are under no obligation to update such material.</Typography>
      </Box>
      <Box mt={2}>
        <Typography  align="left" variant="h5" style={{fontWeight:"bold"}}>Our Liability</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1">The material displayed on our websites is provided without any guarantees, conditions or warranties as to its accuracy. To the extent permitted by law, we and other members of our group of companies hereby expressly exclude:</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1">Any liability for any direct, indirect or consequential loss or damage incurred by any user in connection with our websites or in connection with the use, inability to use, or results of the use of our websites, any other websites linked to it and any materials posted on it.</Typography>
      </Box>
      <Box mt={2}>
        <Typography  align="left" variant="h5" style={{fontWeight:"bold"}}>Viruses, Hacking &amp; Other Offences</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1">You must not misuse our websites by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our websites, the server on which our websites are stored, or any server, computer or database connected to our websites. You must not attack our websites via a denial-of-service attack or a distributed denial-of-service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities, and we will cooperate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</Typography>
      </Box>
      <Box mt={2}>
        <Typography  align="left" variant="h5" style={{fontWeight:"bold"}}>Linking to our Websites</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1">You may link to our websites, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</Typography>
      </Box>
      <Box mt={2}>
        <Typography  align="left" variant="h5" style={{fontWeight:"bold"}}>Variations</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1">We may revise these terms of use at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we made, as they are binding on you. Some of the provisions contained in these terms of use may also be superseded by provisions or notices published elsewhere on our websites.</Typography>
      </Box>
      <Box mt={2}>
        <Typography  align="left" variant="h5" style={{fontWeight:"bold"}}>Your Concerns</Typography>
        <Box mt={2}></Box>
        <Typography  align="left" variant="body1">If you have any concerns about material which appears on our websites, please contact Abintus Consulting Ltd, 75 Shelton Street, Covent Garden, London, WC2H 9JQ, United Kingdom or send us a message <Link style={{color:"#14213D",textDecoration:"underline"}} href="mailto:info@abintus.consulting">info@abintus.consulting</Link></Typography>
      </Box>
      <Box mt={4}></Box>
      </Container>
      <Footer></Footer>
    </Page>
  );
}
