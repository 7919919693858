/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ReactPlayer from 'react-player'
import {
  Box,
  Button,
  Typography,
  makeStyles, 
  CardContent,
  Hidden,
  useMediaQuery
} from '@material-ui/core';
import IntroPicture from './Media/VideoThumbnail.PNG'
import './SelfAssessment.css'
import styled from 'styled-components'
import AssessmentVideo from './Media/Self-Assessment.mp4'
import {
  PlayCircle as PlayCircleIcon,
} from 'react-feather';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  startButton:{
    boxShadow: "none",

    "&:hover": {
      boxShadow: "none"
    }
  },
  // pulse2:{
  //   content: '""',
  //   width: "100px",
  //   marginLeft:"0px",
  //   height: "75px",
  //   boxSizing: "border-box",
  //   marginLeft: "50px",
  //   marginTop: "0%",
  //   borderRadius: "4px",
  //   backgroundColor: "#f8d02d",
  //   animation: "pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite",
  // },
  introImage:{
    backgroundRepeat: "no-repeat",
			backgroundPosition: "center center",
      backgroundSize: "cover",
      background:"100%",
    paddingBottom: 400,
    backgroundImage: `linear-gradient(
      rgba(162, 190, 227, 0.2), 
      rgba(162, 190, 227, 0.2)
    ), url(${IntroPicture})`,
  },
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  videoContainer: {
    height: "450px",
    borderRadius: "15px",
    overflow: "hidden",

    [theme.breakpoints.down('md')]: {
      height: "220px"
    }
  },
  videoPlayer: {
    width: "100%",
    height: "100%",
    border: "none"
  },
}));

function VideoPlayer() {
  const classes = useStyles();

  return (
    <div className={classes.videoContainer}>
      <iframe 
      className={classes.videoPlayer} 
      sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
      allowFullScreen="true"
      src={`https://youtube.com/embed/Q6BFkAJ6Re4?autoplay=0`}>    
      </iframe>
    </div>
  )
}

function Introduction({
  className,
  onBack,
  onNext,
  onComplete,
  ...rest
}) {
  const classes = useStyles();
 
  return (
    <Formik
      initialValues={{
      }}
      validationSchema={Yup.object().shape({
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          // Do API call to store step data in server session
          // It is important to have it on server to be able to reuse it if user
          // decides to continue later.
          setStatus({ success: true });
          setSubmitting(false);
          if (onComplete) {
            onComplete();
          }
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <CardContent>
          {/* <div className="pulsating-circle"></div>
            <Box mt={2} justifyContent="center" align="center" className={classes.introImage}>
            </Box> */}
            <Box mt={2}>
              <Hidden mdDown>
                <VideoPlayer />
              </Hidden>
              <Hidden mdUp>
                <VideoPlayer />
              </Hidden>
            </Box>
          <Box mt={2}>
            <Box
              mt={3}
              display="flex"
              alignItems="center"
            >
               <Typography
            variant="body1"
            color="textPrimary"
            align="justify"     
            style={{fontSize: "18px"}}       
          >
           {/* Using our free self-assessment service, you will be able to assess the quality of your media agency contract within minutes, and without the need to share your media agency contract. */}
           Assess your media agency contract within minutes, using our free self-assessment service. 
           You only need to get a copy of the media agency contract you would like to assess.
          </Typography>
            </Box>           
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
            <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
            style={{fontSize: "18px"}}
          >
           Just answer a few questions about you, your agency, and the characteristics of your media agency contract and you will get an overall score and rating of your contract.
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
              style={{marginBottom: "-22px"}}
            >
            <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
            style={{fontSize: "18px"}}
          >           
           We will also highlight the strengths and weaknesses of your contract, as well as benchmark your results against our media agency contract database.
          </Typography>
            </Box>
          </Box>
          <Box
            mt={6}
            display="flex"
          >
            <Box flexGrow={1} />
            <StartButton
              // className={classes.startButton}
              // color="secondary"
              disabled={isSubmitting}
              type="submit"
              // variant="contained"
              // size="large"
              // style={{textTransform: "none", fontWeight: "bold"}}
            >
              Start
            </StartButton>
          </Box>
          </CardContent>
        </form>
      )}
    </Formik>

  );
}

const StartButton = styled.button`
  background-color: #F2C12E;
  color: #000000;
  font-size: 1rem; 
  border: none;
  padding: 10px;  
  transition: 0.2s;
  font-family: Poppins;
  width: 200px;  
  marginBottom: 10px;

  &:hover {
    opacity: 0.8;
    transition: 0.2s;
    cursor: pointer;     
  
    >* {
      color: #F2C230;
    }
  }
`

Introduction.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};

export default Introduction;
