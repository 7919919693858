import React from "react";
import {
  Container,
  Grid,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import Page from "src/components/Page";
import Header from "./Header";
import ContractGrade from "./ContractGrade";
import NumberOfContracts from "./NumberOfContracts";
import MUIDataTable from "mui-datatables";
import GlobalBenchmark from "./GlobalBenchmark";
import KPIChart from "./KPIChart";

var jwt = require("jsonwebtoken");
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  table: {
    "&.MuiIconButton-root": {
      color: "#14213D",
    },
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}));

var columns = [
  {
    name: "Results PDF",
    options: {
      filter: false,
    },
  },
  {
    name: "Self Assess. ID",
    options: {
      filter: false,
    },
  },
  {
    name: "Client ID",
    options: {
      filter: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        var link = `https://www.abintus.app/app/admin/users/user/${value}`;
        if (value !== "") {
          return <a href={link}>{value}</a>;
        }
      },
    },
  },
  {
    name: "Agency",
    options: {
      filter: true,
    },
  },
  {
    name: "Services",
    options: {
      filter: true,
    },
  },
  {
    name: "Date of Review",
    options: {
      filter: true,
    },
  },
  {
    name: "Country",
    options: {
      filter: true,
    },
  },
  {
    name: "Annual Media Budget",
    options: {
      filter: true,
      filterType: "checkbox",
    },
  },
  {
    name: "Contract Score",
    options: {
      filter: false,
    },
  },
  {
    name: "Contract Rating",
    options: {
      filter: true,
      filterType: "checkbox",
    },
  },
  // {
  //    name: "Annual Net Spend (EUR)",
  //    options: {
  //      filter: true,
  //      filterType: 'checkbox',
  //      filterOptions: {
  //        names: ['Less than €1M', '€1M - €5M', '€5M - €10M', '€10M - €25M','€25M - €50M','€50M - €100M'],
  //        logic(spend, filterVal) {
  //          //spend = spend.replace(/[^\d]/g, '');
  //          const show =
  //            (filterVal.indexOf('Less than €1M') >= 0 && spend < 1000000) ||
  //            (filterVal.indexOf('€1M - €5M') >= 0 && spend >= 1000000 && spend < 5000000) ||
  //            (filterVal.indexOf('€5M - €10M') >= 0 && spend >= 5000000 && spend < 10000000)||
  //            (filterVal.indexOf('€10M - €25M') >= 0 && spend >= 10000000 && spend < 25000000)||
  //            (filterVal.indexOf('€25M - €50M') >= 0 && spend >= 25000000 && spend < 50000000)||
  //            (filterVal.indexOf('€50M - €100M') >= 0 && spend >= 50000000 && spend < 100000000);
  //          return !show;
  //        },
  //      },
  //      sort: true,
  //    },
  //  },
  {
    name: "Company Role",
    options: {
      filter: false,
    },
  },
  {
    name: "Deal With Media",
    options: {
      filter: false,
    },
  },
  {
    name: "Satisfaction Score",
    options: {
      filter: false,
    },
  },
  {
    name: "Signed by Both Parties",
    options: {
      filter: false,
    },
  },
  {
    name: "Definitions",
    options: {
      filter: false,
    },
  },
  {
    name: "Services Described",
    options: {
      filter: false,
    },
  },
  {
    name: "Reporting Clause",
    options: {
      filter: false,
    },
  },
  {
    name: "Agent Only",
    options: {
      filter: false,
    },
  },
  {
    name: "Authority Clause",
    options: {
      filter: false,
    },
  },
  {
    name: "Exclusivity Reciprocal",
    options: {
      filter: false,
    },
  },
  {
    name: "Key Individuals",
    options: {
      filter: false,
    },
  },
  {
    name: "Transparent Remuneration",
    options: {
      filter: false,
    },
  },
  {
    name: "PRF Included",
    options: {
      filter: false,
    },
  },
  {
    name: "PRF with Malus",
    options: {
      filter: false,
    },
  },
  {
    name: "PRF Methodology",
    options: {
      filter: false,
    },
  },
  {
    name: "Agency Commitments",
    options: {
      filter: false,
    },
  },
  {
    name: "Audit Rights",
    options: {
      filter: false,
    },
  },
  {
    name: "Rebates Clauses",
    options: {
      filter: false,
    },
  },
  {
    name: "EPD Clause",
    options: {
      filter: false,
    },
  },
  {
    name: "Unbilled Media Clause",
    options: {
      filter: false,
    },
  },
  {
    name: "Competitive Payment Terms",
    options: {
      filter: false,
    },
  },
  {
    name: "Standard Termination Clause",
    options: {
      filter: false,
    },
  },
  {
    name: "Standard Subcontracting Clause",
    options: {
      filter: false,
    },
  },
];

var data = [];
var options = {
  filterType: "dropdown",
  responsive: "standard",
  rowsPerPage: 15,
  onTableChange: (action, tableState) => {
    var l = [];
    tableState.displayData.forEach((element) => {
      l.push(element.data);
    });
    window.ContractTable.RefreshTopData(l);
    // this.setState({
    //     data: Calculations(l)
    // });
  },
  onTableInit: (action, tableState) => {
    var l = [];
    tableState.displayData.forEach((element) => {
      l.push(element.data);
    });
    window.ContractTable.RefreshTopData(l);
    // this.setState({
    //   data: Calculations(l)
    // });
  },
  selectableRows: "none",
};

var dataArray = [];

class SelfAssessmentTable extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    window.ContractTable = this;
    this.state = {
      data: [],
      Loading: true,
      numberOfContracts: 0,
      benchmark: 0,
      averageGrade: "Poor",
      calculation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };
  }
  dismiss() {
    this.props.unmountMe();
  }
  render() {
    return (
      <React.Fragment>
        <Grid item md={4} lg={4} xl={4}>
          <NumberOfContracts contracts={this.state.numberOfContracts} />
        </Grid>
        <Grid item md={4} lg={4} xl={4}>
          <GlobalBenchmark globalscore={this.state.benchmark} />
        </Grid>
        <Grid item md={4} lg={4} xl={4}>
          <ContractGrade grade={this.state.averageGrade} />
        </Grid>
        <Grid item lg={12} xs={12}>
          {/* <MuiThemeProvider 
       theme={theme} 
      >  */}

          <MUIDataTable
            className={useStyles.table}
            title={
              <h1 variant="title">
                {this.state.Loading && (
                  <CircularProgress
                    size={24}
                    style={{
                      marginLeft: 15,
                      position: "relative",
                      top: 4,
                      color: "#f8d02d",
                    }}
                  />
                )}
              </h1>
            }
            data={data}
            columns={columns}
            options={options}
          >
            {" "}
          </MUIDataTable>
          {/* </MuiThemeProvider> */}
        </Grid>
        {Array.apply(null, { length: 20 }).map((e, i) => (
          <Grid item md={3} lg={3} xl={3} key={i}>
            <KPIChart keyvalue={i} percentage={this.state.calculation[i]} />
          </Grid>
        ))}
      </React.Fragment>
    );
  }

  YesandNoCalculation(index, data) {
    var yesCalculation = 0;
    data.forEach((element) => {
      if (String(element[index]).toLowerCase() === "yes") {
        ++yesCalculation;
      }
    });
    yesCalculation = (yesCalculation / data.length) * 100;
    return parseInt(Math.round(yesCalculation));
  }
  AverageRating(score) {
    var rating = "Best-in-Class";
    if (score < 39) {
      rating = "Poor";
    } else if (score < 59) {
      rating = "Average";
    } else if (score < 80) {
      rating = "Strong";
    }
    return rating;
  }

  RefreshTopData(data) {    
    if (this._isMounted) {
      var score = 0;
      data.forEach((element) => {
        score += parseInt(element[8]);
      });
      console.log(score);
      var nOfContracts = data.length;
      var scoreVal = score / nOfContracts;
      var rating = this.AverageRating(scoreVal);
      this.setState({
        benchmark: scoreVal,
        numberOfContracts: nOfContracts,
        averageGrade: rating,
      });
      var x = 12;
      this.setState({
        calculation: [
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
          this.YesandNoCalculation(x++, data),
        ],
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    dataArray = [];
    this.getContracts();
  }

  tableValueOutput(val) {
    if (val === true) {
      return "Yes";
    } else if (val === false) {
      return "No";
    } else {
      return "Not Applicable";
    }
  }

  getContracts = (_) => {
    var bearer = "Bearer " + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);
    fetch(
      "https://abintus-app-api-linux.azurewebsites.net/contracts/self-assessment",
      {
        method: "GET",
        headers: {
          token: token,
        },
      },
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        var score = 0;
        response.forEach((element) => {
          score += parseInt(element[30]);
          var dateOfReview = element[2].substring(0, 10);
          var dateOrder = dateOfReview.split("-");
          var ratingVal = this.AverageRating(element[30]);
          dateOfReview = dateOrder[2] + "/" + dateOrder[1] + "/" + dateOrder[0];
          var l = 5;
          dataArray.push([
            element[33] == null ? "N/A" : <a href={element[33]} target="_blank">View</a>,
            element[0],
            element[1],
            element[3],
            element[5],
            dateOfReview,
            element[4],
            element[27],
            element[30],
            ratingVal,
            element[26],
            element[28] ? "Yes" : "No",
            element[29],
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
            this.tableValueOutput(element[++l]),
          ]);
        });
        data = dataArray;
        var nOfContracts = response.length;
        var scoreVal = score / nOfContracts;
        var rating = this.AverageRating(scoreVal);
        this.setState({
          Loading: false,
          benchmark: scoreVal,
          numberOfContracts: nOfContracts,
          averageGrade: rating,
        });
        this.setState({ data: dataArray });
        var x = 12;
        this.setState({
          calculation: [
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
            this.YesandNoCalculation(x++, data),
          ],
        });
      });
  };
}

function SelfAssessmentDashboardView() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Self-Assessment Dashbaord">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <Grid container spacing={3}>
          <SelfAssessmentTable></SelfAssessmentTable>
        </Grid>
      </Container>
    </Page>
  );
}

export default SelfAssessmentDashboardView;
