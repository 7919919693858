import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'space-between',
    // textAlign: 'center'
  }
}));

function AbintusScore({ className, ...rest }) {
  const classes = useStyles();
  const data = {
    value: rest.score,

  };

    return (
      <Card 
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box flexGrow={1}>
          <Typography
            component="h3"
            gutterBottom
            variant="overline"
            color="textSecondary"
          >
             Abintus Clients Average Score
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
           
          >
            <Typography
              variant="h3"
              color="textPrimary"
              style={{fontWeight: "Bold",textAlign:"center"}}
            >
              {data.value}%
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  }

  AbintusScore.propTypes = {
  className: PropTypes.string
};

export default AbintusScore;
