import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card,CardContent,Container,Grid,makeStyles,
Button,Box,Typography,withStyles,Divider,Link,Breadcrumbs,Hidden
} from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Page from 'src/components/Page';
import { useHistory } from 'react-router';
import HowToMainImage from './Media/HowToMainImage.jpg';
import ScrollDown from './Media/ScrollDown.png';
import SelfAssessmentImage from './Media/SelfAssessment.jpg';
import ExpertAssessmentImage from './Media/ExpertAssessment.jpg';
import styled from 'styled-components'
import './GettingStarted.css';
import { scale } from 'pdf-lib';
require('typeface-open-sans');
var jwt = require('jsonwebtoken');

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',    
  },
  frontImage:{   
    backgroundImage: `linear-gradient(
      rgba(20, 33, 61, 0.2), 
      rgba(20, 33, 61, 0.2)
    ), url(${HowToMainImage})`,
    backgroundAttachment:  window.innerWidth > 500 ? "fixed":"scroll",
    backgroundRepeat: "no-repeat",
			backgroundPosition: "center center",
      backgroundSize: "cover",
      background:"100%",
      //height:"100%",
			//backgroundAttachment: "fixed",
    paddingTop:100,
    paddingBottom: 100,
  },
  selfAssessmentFront:{    
    paddingBottom: 50,
    height: 600,
    width:450,
    border: "solid 2px #14213D",
    borderRadius: 20,
    color:"#14213D",           
    padding: 20,
    position: 'relative',
    transition: "0.3s",
    // padding: theme.spacing(5, 3),  
    '&:hover': {
      transform: "scale(1.01)"
    },     
    [theme.breakpoints.down('md')]: {
      width: "90vw",
      overflow: "auto",
    }
  },
  bottomContent: {
    width: "92%",
    position: "absolute",
    bottom: 10,
  },
  expertAssessmentFront:{
    position: 'relative',
    paddingBottom: 50,
    height:600,
    width:450,
    padding: 20,
    color:"#14213D",
    border: "solid 2px #F8D02D",
    borderRadius: 20,

    transition: "0.3s",
    // padding: theme.spacing(5, 3),  
    '&:hover': {
      transform: "scale(1.01)"
    },
    [theme.breakpoints.down('md')]: {
      width: "90vw",
      overflow: "auto",
    }
  },
  assessmentText:{
    display:"none"
  },
  selfAssessment:{       
    width:350,
    color:"#14213D",
    backgroundColor: "#D0E5F2",
    position: 'relative',
    padding: theme.spacing(5, 3),
  
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      transform: 'scale(1.05)'
    }
  },  
  scrolldown:{
    "-webkit-animation": "action 1s infinite  alternate",
    animation: "action 1s infinite  alternate",
    // animationName: "stretch",
    // animationDuration: "2.5s", 
    // animationTimingFunction: "ease-out", 
    // animationDelay: "0s",
    // animationDirection: "alternate",
    // animationIterationCount: "infinite",
    // animationFillMode: "none",
    // animationPlayState: "running", 
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
      paddingTop: "30px",
      paddingBottom: "24px"
    }
  },
  card: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none",    
  },
  option: {
    border: "solid 2px black",
    height: "500px",
  }
}));

const StyledButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem;
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 30px;
  transition: 0.2s;

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
  }
`

const BuyButton = withStyles((theme) => ({
  root: {
    borderRadius: 30,
    fontSize: 16,
    width:200,
    cursor: 'pointer',
    fontFamily: "Montserrat",
    fontWeight: "Bold",
    color: "#14213D",
    textTransform: 'none',
    backgroundColor: "#f8d02d",
    '&:hover': {
      backgroundColor: "#f8d02d",
      filter: "brightness(80%)",
      // color: "#14213D"

    },
  },
}))(Button);

export default function GettingStartedView() {
  const [step, setStep] = useState();
  const history = useHistory();
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width: 800px)');  

  useEffect(() => {                  
    var accessToken = localStorage.getItem('accessToken');
    var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);              
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);
    fetch('https://abintus-app-api-linux.azurewebsites.net/contracts/expert-assessment'
    ,{
      method: 'GET',
      headers: {
          'token': token
      }}
      )
    .then(res => res.json())
    .then(res => {        
        var arr = [];    
        res.forEach(contract => {                              
            if (contract[1] == validToken.id) {
              arr.push(contract)
            }
        })
        setStep(arr.length ? null : 0);
    });
    
}, []);

  return (
    <Page  title="Account">       
      <Container maxWidth={false} className={classes.container} 
      // style={{ paddingLeft: 64,paddingRight: 64,
      // paddingTop: "24px",
      // paddingBottom: "24px"}}
      >
      {/* <Header /> */}
      <Hidden only={['xs', 'sm']}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          style={{marginBottom: "20px"}}
        >

          <Link
            variant="body1"
            color="inherit"
            to="/app/getting-started"
            component={RouterLink}
            >
            Home
          </Link>
          <Typography
            variant="body1"
            color="textPrimary"
            >
            Getting Started
          </Typography>
        </Breadcrumbs>    
      </Hidden>
      <Card className={classes.card}>
        <CardContent> 
          <a name="Options" ></a>
            <Box mt={4}>
           
               <Typography
                align="center"
                variant="h1"
                style={{ fontSize: mobile ? "30px" : "40px", kfontWeight:"999", marginBottom: "15px"}}
                >
                Need help getting started?
              </Typography>
              <Typography
              variant="h2"
              align="center"
              style={{ fontSize: mobile ? "20px" : "30px"}}>
                2 options available to you
              </Typography>
              <Box mt={4}></Box>
              
              <Grid  container
                  spacing={4}
                  justifyContent="center">
                  <Grid item>                    
                      <Box className={classes.selfAssessmentFront}>                    
                        <Typography variant="h1" align="center" style={{fontSize: "40px", marginBottom: "15px"}}>SELF<br/>ASSESSMENT</Typography>
                        <svg height="5" width="100%" style={{marginBottom: "15px"}}>
                          <line x1="0" y1="0" x2="98%" style={{stroke: "black", strokeWidth: 2}} />
                        </svg>
                        <Typography variant="body1" style={{fontSize: "17px", marginBottom: "30px"}}>
                        Assess the quality of your contract in minutes! 
                        Just answer a few questions about the characteristics of your Media Agency contract, 
                        and then get an overall score for your contract, along with a detailed analysis.
                        </Typography>
                        <Typography>
                        • Contract Score<br />
                        • Industry Average Score<br />
                        • Abintus Clients Score<br />
                        • Key Strenghs and Weaknesses<br />
                        </Typography>
                        <div className={classes.bottomContent}>
                          <Link href="/app/self-assessment">
                            <StyledButton style={{marginBottom: "10px"}}>
                              Start Now
                            </StyledButton>
                          </Link>
                          <Typography variant="body1" align="center">Results in minutes</Typography>
                        </div>
                      </Box>                    
                  </Grid>
                  <Grid item>                  
                  <Box className={classes.expertAssessmentFront}>
                  <Typography variant="h1" align="center" style={{fontSize: "40px", marginBottom: "15px"}}>EXPERT<br/>ASSESSMENT</Typography>
                        <svg height="5" width="100%" style={{marginBottom: "15px"}}>
                          <line x1="0" y1="0" x2="98%" style={{stroke: "black", strokeWidth: 2}} />
                        </svg>
                        <Typography variant="body1" style={{fontSize: "17px", marginBottom: "30px"}}>
                        Have our experts assess the quality of your Media Agency contract! 
                        Receive an in-depth review of your agreement and recommendations for improvement. 
                        Sign an NDA, upload your contract, and let our experts do the rest!
                        </Typography>
                        <Typography>
                        • Detailed Contract Scores<br />
                        • Detailed Benchmarks<br />
                        • Findings and Comments<br />
                        • Best Practices<br />
                        • Recommendations for improvement<br/>
                        • 60 Minute Call With Our Experts
                        </Typography>
                        <div className={classes.bottomContent}>
                          <RouterLink to={{
                            pathname: "/app/expert-assessment",
                            state: {
                              step: step,
                            }}}>
                            <StyledButton style={{marginBottom: "10px"}}>
                              Start Now
                            </StyledButton>
                          </RouterLink>
                          <Typography variant="body1" align="center">Results in 48 hours</Typography>
                        </div>
                  </Box>                  
                  </Grid>
              
              </Grid>
            </Box>

            <Box mt={6}>
            {/* <Typography
                align="center"
                variant="h1"
                style={{ fontSize:"36px",fontWeight:"999",fontFamily: 'Open Sans'}}
                >
                CHOOSE YOUR ASSESSMENT
              </Typography>
               <Box mt={6}></Box>
              <Grid  container
                spacing={4}
                justify="center">
                <Grid item>
                 <Box className={classes.selfAssessment}>
                 <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"24px",fontWeight:"bold",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  SELF-ASSESSMENT
                </Typography>
                <Box mt={2}></Box>
                <Typography align="center"
                  variant="h1" style={{ fontSize:"50px",fontWeight:"bold",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,margin:"-10px"}}>FREE</Typography>
                  <Box mt={3}></Box>
                  <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"14px",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  YOUR RESULTS WITHIN MINUTES
                </Typography>
                <Box mt={4} align="center"><BuyButton  onClick={() => {history.push('/app/self-assessment')}}>Select</BuyButton></Box>
                <Box mt={4} align="center"> <Divider style={{backgroundColor:"gray",border:"none",height:"1px",width:"350px",marginLeft:"-24px"}}></Divider></Box>
                <Box mt={4}> <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"14px",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  Your Contract Score
                </Typography>
                </Box>
                <Box mt={2} align="center"> <Divider style={{border:"none",borderTop: "thin solid grey",width:"80%"}}></Divider></Box>
                <Box mt={2}> <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"14px",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  Industry Average Score
                </Typography>
                </Box>
                <Box mt={2} align="center"> <Divider style={{border:"none",borderTop: "thin solid grey",width:"80%"}}></Divider></Box>
                <Box mt={2}> <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"14px",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  Abintus Clients Score
                </Typography>
                </Box>
                <Box mt={2} align="center"> <Divider style={{border:"none",borderTop: "thin solid grey",width:"80%"}}></Divider></Box>
                <Box mt={2}> <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"14px",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  Key Strenghts
                </Typography>
                </Box>
                <Box mt={2} align="center"> <Divider style={{border:"none",borderTop: "thin solid grey",width:"80%"}}></Divider></Box>
                <Box mt={2}> <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"14px",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  Key Weaknesses
                </Typography>
                </Box>
              
                <Box mt={2} align="center"> <Divider style={{border:"none",borderTop: "thin solid grey",width:"80%"}}></Divider></Box>
                 </Box>
                </Grid>
                <Grid item>
                 <Box className={classes.selfAssessment}>
                 <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"24px",fontWeight:"bold",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  EXPERT ASSESSMENT
                </Typography>
                <Box mt={2}></Box>
                
                <Typography align="center"
                  variant="h1" style={{ fontSize:"50px",fontWeight:"bold",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,margin:"-10px"}}>FREE</Typography>
                  <Box mt={3}></Box>
                  <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"14px",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  YOUR RESULTS WITHIN 48 HOURS
                </Typography>
                <Box mt={4} align="center"><BuyButton  onClick={() => {history.push('/app/expert-assessment')}}>Select</BuyButton></Box>
                <Box mt={4} align="center"> <Divider style={{backgroundColor:"gray",border:"none",height:"1px",width:"350px",marginLeft:"-24px"}}></Divider></Box>
                <Box mt={4}> <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"14px",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  Your Detailed Scorecard
                </Typography>
                </Box>
                <Box mt={2} align="center"> <Divider style={{border:"none",borderTop: "thin solid grey",width:"80%"}}></Divider></Box>
                <Box mt={2}> <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"14px",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  Detailed Benchmarks
                </Typography>
                </Box>
                <Box mt={2} align="center"> <Divider style={{border:"none",borderTop: "thin solid grey",width:"80%"}}></Divider></Box>
                <Box mt={2}> <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"14px",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  Detailed Findings &amp; Comments
                </Typography>
                </Box>
                <Box mt={2} align="center"> <Divider style={{border:"none",borderTop: "thin solid grey",width:"80%"}}></Divider></Box>
                <Box mt={2}> <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"14px",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  Our Best Practices
                </Typography>
                </Box>
                <Box mt={2} align="center"> <Divider style={{border:"none",borderTop: "thin solid grey",width:"80%"}}></Divider></Box>
                <Box mt={2}> <Typography
                  align="center"
                  variant="h1"
                  style={{ fontSize:"14px",fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`}}
                  >
                  60-minute Call with Our Experts
                </Typography>
                </Box>
              
                <Box mt={2} align="center"> <Divider style={{border:"none",borderTop: "thin solid grey",width:"80%"}}></Divider></Box>
                 </Box>
                </Grid>
              
              </Grid> */}
            </Box>
            <Box mt={6}></Box>
        </CardContent>
      </Card>
    </Container>
    </Page>
  );
}
