import React,{useEffect} from 'react';
import { Card,CardContent,Container,Grid,makeStyles,TextField,Select,MenuItem,InputLabel,FormControl,
FormHelperText,Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,InputAdornment,Typography,withStyles,Box
} from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import { Autocomplete } from '@material-ui/lab';
import { useHistory } from 'react-router';
import {DropzoneArea} from 'material-ui-dropzone'
// import NumberFormat from 'react-number-format';
var jwt = require('jsonwebtoken');
const countryList = require('country-list');
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
      paddingTop: "24px",
      paddingBottom: "24px"
    }
  }
}));

const CustomDropBox = withStyles((theme) => ({
  root:{
    border:"1px solid #14213D",
    "& .MuiDropzonePreviewList-image":
    {
      boxShadow:"0 0 0",
      color:"#f8d02d",
      height:"50px",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}))(DropzoneArea);

function AverageRating(score)
{
  var rating = "Best-in-Class";
  if(score < 39)
  {
    rating = "Poor";
  }
  else if(score < 59)
  {
    rating = "Average";
  }
  else if(score < 80)
  {
    rating = "Strong";
  }
  return rating;
}

export default function AddContractView() {
  const history = useHistory();
  const classes = useStyles();
  const [filesSelected,setFileSelected] = React.useState([]);
  const [contractId, SetContractId] = React.useState(0);
  const [isSubmitting,SetIsSubmitting] = React.useState(false);
  const [preparedByAbintus, setPreparedByAbintus] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState('Various');
  const [annualMediaBudget, setAnnuaMediaBudget] = React.useState('');
  const [remunerationModel, setRemunerationModel] = React.useState('');
  const [lenghtOfContract, setLenghtOfContract] = React.useState('');
  const [autoRenew, setAutoRenew] = React.useState('');
  const [paymentTerms, setPaymentTerms] = React.useState('');
  const [signedByBothParties, setSignedByBothParties] = React.useState('');
  const [signedByBothPartiesImpr, setSignedByBothPartiesImpr] = React.useState('');
  const [definitions, setDefinitions] = React.useState('');
  const [definitionsImpr, setDefinitionsImpr] = React.useState('');
  const [servicesDescribed, setServicesDescribed] = React.useState('');
  const [servicesDescribedImpr, setServicesDescribedImpr] = React.useState('');
  const [reportingClause, setReportingClause] = React.useState('');
  const [reportingClauseImpr, setReportingClauseImpr] = React.useState('');
  const [agentOnly, setAgentOnly] = React.useState('');
  const [agentOnlyImpr, setAgentOnlyImpr] = React.useState('');
  const [authorityClause, setAuthorityClause] = React.useState('');
  const [authorityClauseImpr, setAuthorityClauseImpr] = React.useState('');
  const [exclusivityReciprocal, setExclusivityReciprocal] = React.useState('');
  const [exclusivityReciprocalImpr, setExclusivityReciprocalImpr] = React.useState('');
  const [keyIndividuals, setKeyIndividuals] = React.useState('');
  const [keyIndividualsImpr, setKeyIndividualsImpr] = React.useState('');
  const [transparentRemuneration, setTransparentRemuneration] = React.useState('');
  const [transparentRemunerationImpr, setTransparentRemunerationImpr] = React.useState('');
  const [prfIncluded, setPrfIncluded] = React.useState('');
  const [prfIncludedImpr, setPrfIncludedImpr] = React.useState('');
  const [prfWithMalus, setPrfWithMalus] = React.useState('');
  const [prfWithMalusImpr, setPrfWithMalusImpr] = React.useState('');
  const [prfMethodology, setPrfMethodology] = React.useState('');
  const [prfMethodologyImpr, setPrfMethodologyImpr] = React.useState('');
  const [agencyCommitments, setAgencyCommitments] = React.useState('');
  const [agencyCommitmentsImpr, setAgencyCommitmentsImpr] = React.useState('');
  const [auditRights, setAuditRights] = React.useState('');
  const [auditRightsImpr, setAuditRightsImpr] = React.useState('');
  const [rebatesClauses, setRebatesClauses] = React.useState('');
  const [rebatesClausesImpr, setRebatesClausesImpr] = React.useState('');
  const [epdClause, setEpdClause] = React.useState('');
  const [epdClauseImpr, setEpdClauseImpr] = React.useState('');
  const [unbilledMediaClause, setUnbilledMediaClause] = React.useState('');
  const [unbilledMediaClauseImpr, setUnbilledMediaClauseImpr] = React.useState('');
  const [competitivePaymentTerms, setCompetitivePaymentTerms] = React.useState('');
  const [competitivePaymentTermsImpr, setCompetitivePaymentTermsImpr] = React.useState('');
  const [standardTerminationClause, setStandardTerminationClause] = React.useState('');
  const [standardTerminationClauseImpr, setStandardTerminationClauseImpr] = React.useState('');
  const [standardSubcontractingClause, setStandardSubcontractingClause] = React.useState('');
  const [standardSubcontractingClauseImpr, setStandardSubcontractingClauseImpr] = React.useState('');
  const [dateReviewFlag, setDateReviewFlag] = React.useState(false);
  const [preparedByAbintusFlag, setPreparedByAbintusFlag] = React.useState(false);
  const [annualMediaBudgetFlag, setAnnualMediaBudgetFlag] = React.useState(false);
  const [remunerationModelFlag, setRemunerationModelFlag] = React.useState(false);
  const [lenghtOfContractFlag, setLenghtOfContractFlag] = React.useState(false);
  const [autoRenewFlag, setAutoRenewFlag] = React.useState(false);
  const [paymentTermsFlag, setPaymentTermsFlag] = React.useState(false);
  const [startDateFlag, setStartDateFlag] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("Success");
  const [dialogText, setDialogText] = React.useState("Contract has been added to the database");
  const [agencyList, setAgencyList] = React.useState(["Arena Media","Blue 449","Carat","Essence","Havas Media","Hearts and Science","Horizon Media","Madison Media","Mediacom","Mindshare","OMD","PHD","PlatformGSK","Spark Foundry","Starcom","UM","Vizeum","Wavemaker","Dentsu X","m/SIX"]);
  const [advertiserList, setAdvertiserList] = React.useState(["Pernord Ricard Italia","Stage Entertainment Germany","Kao Germany"]);
  const [agencyGroupList, setAgencyGroupList] = React.useState(["Independent","GroupM","Havas Media Group"]);
  const [servicesList, setServicesList] = React.useState(['All Media','Social Media & Creative','Full Media Management','Media & Creative','Offline','Online']);
  const [avbCalculations, setAvbCalculationsList] = React.useState(['Commitment','Fair Share','Full transparency','None','Not Applicable','Not defined']);
  const [selectClient, setSelectClient] = React.useState('None');
  const [currencyList, setCurrencyList] = React.useState(["EUR (€)","GBP (£)","BRL (R$)","MXN ($)","ZAR ($)"]);
  const [expertIds,setExpertIds] = React.useState([]);
  var columnNumber = 0;

  const selectFile = (files) =>
  {
      if(files.length !== 0)
      {
        setFileSelected(files);
      }
  }

  var getListValues = (columnName,func) =>
  {
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ column:columnName, token: bearer }, process.env.REACT_APP_ReadToken);
    fetch('https://abintus-app-api-linux.azurewebsites.net/contracts/list'
    ,{
      method: 'GET',
      headers: {
          'token': token
      }}
      )
    .then(response => response.json()).then(response => {
      func(response);
    });
  }

  var clearSessions = () => {
    // var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    // var token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);
    // fetch('https://abintus-app-api-linux.azurewebsites.net/session-clear'
    // ,{
    //   method: 'GET',
    //   headers: {
    //       'token': token
    //   }}
    //   );
  }

  useEffect(() => { 
    var agencyArray = ["Arena Media","Blue 449","Carat","Essence","Havas Media","Hearts and Science","Horizon Media","Madison Media","Mediacom","Mindshare","OMD","PHD","PlatformGSK","Spark Foundry","Starcom","UM","Vizeum","Wavemaker","Dentsu X","m/SIX"];
    getListValues("Advertiser",(result) => {setAdvertiserList(result);});
    getListValues("Agency",(result) => {agencyArray.forEach(element => {
      result.push(element);
    }); const uniqueNames = Array.from(new Set(result));setAgencyList(uniqueNames);});
    getListValues("AgencyGroup",(result) => {setAgencyGroupList(result);});
    getListValues("Services",(result) => {setServicesList(result);});
    getListValues("AVBCalculation",(result) => {setAvbCalculationsList(result);});
    //getListValues("Currency",(result) => {setCurrencyList(result);});
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);
    var val = [];
    fetch('https://abintus-app-api-linux.azurewebsites.net/contracts/expert-assessment'
    ,{
      method: 'GET',
      headers: {
          'token': token
      }}
      )
    .then(response => response.json())
    .then(response => {
      response.forEach((element => {
      val.push([element[0],"Client ID: "+ element[1] + " Expert Asses. ID: " + element[0]]);
      }))

      setExpertIds(val);
    });
    
  },[]);
const inputValidation = ()=>
{
  var noError = true;
  if(document.getElementById('DateOfReview').value === "")
  {
    setDateReviewFlag(true);
    setDialogText("Please modify fields marked with an error");
    noError = false;
  }
  
  if(preparedByAbintus === "")
  {
    setPreparedByAbintusFlag(true);
    noError = false;
  }

  if(annualMediaBudget === "")
  {
    setAnnualMediaBudgetFlag(true);
    noError = false;
  }

  if(remunerationModel === "")
  {
    setRemunerationModelFlag(true);
    noError = false;
  }

  if(document.getElementById('StartDate').value === "")
  {
    setStartDateFlag(true);
    noError = false;
  }

  if(lenghtOfContract === "")
  {
    setLenghtOfContractFlag(true);
    noError = false;
  }

  if(autoRenew === "")
  {
    setAutoRenewFlag(true);
    noError = false;
  }

  if(paymentTerms === "")
  {
    setPaymentTermsFlag(true);
    noError = false;
  }

  if(noError === false)
  {
    setDialogText("Please modify fields marked with an error");
  }

  if(signedByBothParties === "")
  {
    noError = false;
    setDialogText("Signed By Both Parties must be set");
  }

  if(definitions === "")
  {
    noError = false;
    setDialogText("Definitions must be set");
  }

  if(servicesDescribed === "")
  {
    noError = false;
    setDialogText("Services Described must be set");
  }

  if(reportingClause === "")
  {
    noError = false;
    setDialogText("Reporting Clause must be set");
  }

  if(agentOnly === "")
  {
    noError = false;
    setDialogText("Agent Only must be set");
  }

  if(authorityClause === "")
  {
    noError = false;
    setDialogText("Authority Clause must be set");
  }

  if(exclusivityReciprocal === "")
  {
    noError = false;
    setDialogText("Exclusivity Reciprocal must be set");
  }

  if(keyIndividuals === "")
  {
    noError = false;
    setDialogText("Key Individuals must be set");
  }

  if(transparentRemuneration === "")
  {
    noError = false;
    setDialogText("Transparent Remuneration must be set");
  }

  if(prfIncluded === "")
  {
    noError = false;
    setDialogText("PRF Included must be set");
  }

  if(prfWithMalus === "")
  {
    noError = false;
    setDialogText("PRF with Malus must be set");
  }

  if(prfMethodology === "")
  {
    noError = false;
    setDialogText("PRF Methodology must be set");
  }

  if(agencyCommitments === "")
  {
    noError = false;
    setDialogText("Agency Commitments must be set");
  }

  if(auditRights === "")
  {
    noError = false;
    setDialogText("Audit Rights must be set");
  }

  if(rebatesClauses === "")
  {
    noError = false;
    setDialogText("Rebates Clauses must be set");
  }

  if(epdClause === "")
  {
    noError = false;
    setDialogText("EPD Clause must be set");
  }

  if(unbilledMediaClause === "")
  {
    noError = false;
    setDialogText("Unbilled Media Clause must be set");
  }

  if(competitivePaymentTerms === "")
  {
    noError = false;
    setDialogText("Competitive Payment Terms must be set");
  }

  if(standardTerminationClause === "")
  {
    noError = false;
    setDialogText("Standard Termination Clause Terms must be set");
  }

  if(setStandardSubcontractingClause === "")
  {
    noError = false;
    setDialogText("Standard Subcontracting Clause Terms must be set");
  }

  return noError;
}

const handleClose = () => {
  SetIsSubmitting(false);
  if(dialogTitle === "Success")
  {
    window.location.reload(false);
    setOpen(false);
  }
  else
  {
    setOpen(false);
  }
};

const contractEvaluation = () => {
  history.push('/app/admin/evaluation-table',{id: contractId});
};

const trueOrFalse = (val) =>
{
    var result = null;
     if(val !== "Not Applicable")
     {
       if (val === "Yes")
       {
         result = true;
       }
       else
       {
         result = false;
       }
     }
   return result;
}

const submitContract = async(event) =>  
{
  event.preventDefault();
  var result = [];
  SetIsSubmitting(true);
  if(inputValidation())
  {
    var ratingYesValues = 0;
    var totalQuestions = 20;
    var calculationAnswers = [trueOrFalse(signedByBothParties),trueOrFalse(definitions),trueOrFalse(servicesDescribed),trueOrFalse(reportingClause),trueOrFalse(agentOnly),trueOrFalse(authorityClause),trueOrFalse(exclusivityReciprocal),trueOrFalse(keyIndividuals),trueOrFalse(transparentRemuneration),trueOrFalse(prfIncluded),trueOrFalse(prfWithMalus),trueOrFalse(prfMethodology),trueOrFalse(agencyCommitments),trueOrFalse(auditRights),trueOrFalse(rebatesClauses),trueOrFalse(epdClause),trueOrFalse(unbilledMediaClause),trueOrFalse(competitivePaymentTerms),trueOrFalse(standardTerminationClause),trueOrFalse(standardSubcontractingClause)];
    for (let index = 0; index < calculationAnswers.length; index++) {
      const element = calculationAnswers[index];
      if(element === true)
      {
        ++ratingYesValues;
      }

      if(index === 0 && element === null)
      {
        totalQuestions = totalQuestions - 1;
      }
      else if(index === 9 && element == false)
      {
        totalQuestions = totalQuestions - 2;
      }
      else if(index === 15 && element === null)
      {
        totalQuestions = totalQuestions - 1;
      }
    }
    var ratingValue = Math.round((ratingYesValues/totalQuestions* 100),0);
    var rating = AverageRating(parseInt(ratingValue));
    var annualNetSpend = document.getElementById('AnnualNetSpend').value === ""? null: document.getElementById('AnnualNetSpend').value;
    var maxFees = document.getElementById('MaxFees').value === ""? null: document.getElementById('MaxFees').value;
    result.push(document.getElementById('Advertiser').value,document.getElementById('Agency').value,document.getElementById('AgencyGroup').value,document.getElementById('Services').value,document.getElementById('DateOfReview').value);
    result.push(trueOrFalse(preparedByAbintus),selectedCountry,annualMediaBudget,ratingValue,rating,annualNetSpend,maxFees,remunerationModel);
    result.push(document.getElementById('EstimatedAvbs').value,document.getElementById('AvbCalculation').value,document.getElementById('StartDate').value);
    result.push(lenghtOfContract,trueOrFalse(autoRenew),paymentTerms,trueOrFalse(signedByBothParties),trueOrFalse(definitions),trueOrFalse(servicesDescribed),trueOrFalse(reportingClause),trueOrFalse(agentOnly),trueOrFalse(authorityClause),trueOrFalse(exclusivityReciprocal),trueOrFalse(keyIndividuals),trueOrFalse(transparentRemuneration),trueOrFalse(prfIncluded),trueOrFalse(prfWithMalus),trueOrFalse(prfMethodology),trueOrFalse(agencyCommitments),trueOrFalse(auditRights),trueOrFalse(rebatesClauses),trueOrFalse(epdClause),trueOrFalse(unbilledMediaClause),trueOrFalse(competitivePaymentTerms),trueOrFalse(standardTerminationClause),trueOrFalse(standardSubcontractingClause));
    var expertIdValue = selectClient === "None"?null:selectClient;
    result.push(expertIdValue);
    var accessToken = localStorage.getItem('accessToken');
    var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);
    result.push(validToken.id);
    result.push(document.getElementById('Currency').value);
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ contract: result, token: bearer }, process.env.REACT_APP_ReadToken);
    var output = await fetch('https://abintus-app-api-linux.azurewebsites.net/contract/add'
    ,{
      method: 'GET',
      headers: {
          'token': token
      }}
      );

    var addContract = await output.text();    
    if(Object.is(parseInt(addContract), NaN))
    {
      setDialogTitle("Error");
      setDialogText("There has been an issue adding contract to a database. Please try again or contract IT support");
      setOpen(true);
    }
    else
    {
      var evaluationValues = [];
      setDialogTitle("Success");
      setDialogText("Contract has been added to the database with Id: " + parseInt(addContract));
      SetContractId(parseInt(addContract));
      evaluationValues.push(trueOrFalse(signedByBothPartiesImpr),document.getElementById('SignedByBothPartiesComment').value.toString().replace(/'/g,"''"),trueOrFalse(definitionsImpr),document.getElementById('DefinitionsComment').value.toString().replace(/'/g,"''"),trueOrFalse(servicesDescribedImpr),document.getElementById('ServicesDescribedComment').value.toString().replace(/'/g,"''"),trueOrFalse(reportingClauseImpr),document.getElementById('ReportingClauseComment').value.toString().replace(/'/g,"''"),trueOrFalse(agentOnlyImpr),document.getElementById('AgentOnlyComment').value.toString().replace(/'/g,"''"),trueOrFalse(authorityClauseImpr),document.getElementById('AuthorityClauseComment').value.toString().replace(/'/g,"''"),trueOrFalse(exclusivityReciprocalImpr),document.getElementById('ExclusivityReciprocalComment').value.toString().replace(/'/g,"''"),trueOrFalse(keyIndividualsImpr),document.getElementById('KeyIndividualsComment').value.toString().replace(/'/g,"''"),trueOrFalse(transparentRemunerationImpr),document.getElementById('TransparentRemunerationComment').value.toString().replace(/'/g,"''"),trueOrFalse(prfIncludedImpr),document.getElementById('PRFIncludedComment').value.toString().replace(/'/g,"''"));
      evaluationValues.push(trueOrFalse(prfWithMalusImpr),document.getElementById('PRFWithMalusComment').value.toString().replace(/'/g,"''"),trueOrFalse(prfMethodologyImpr),document.getElementById('PRFMethodologyComment').value.toString().replace(/'/g,"''"),trueOrFalse(agencyCommitmentsImpr),document.getElementById('AgencyCommitmentsComment').value.toString().replace(/'/g,"''"),trueOrFalse(auditRightsImpr),document.getElementById('AuditRightsComment').value.toString().replace(/'/g,"''"),trueOrFalse(rebatesClausesImpr),document.getElementById('RebatesClausesComment').value.toString().replace(/'/g,"''"),trueOrFalse(epdClauseImpr),document.getElementById('EPDClauseComment').value.toString().replace(/'/g,"''"),trueOrFalse(unbilledMediaClauseImpr),document.getElementById('UnbilledMediaClauseComment').value.toString().replace(/'/g,"''"),trueOrFalse(competitivePaymentTermsImpr),document.getElementById('CompetitivePaymentTermsComment').value.toString().replace(/'/g,"''"),trueOrFalse(standardTerminationClauseImpr),document.getElementById('StandardTerminationClauseComment').value.toString().replace(/'/g,"''"),trueOrFalse(standardSubcontractingClauseImpr),document.getElementById('StandardSubcontractingClauseComment').value.toString().replace(/'/g,"''"));
      token = jwt.sign({ Id: parseInt(addContract),contractEvaluation: evaluationValues,token: bearer }, process.env.REACT_APP_ReadToken);
      await fetch('https://abintus-app-api-linux.azurewebsites.net/contract/add/evaluation'
      ,{
        method: 'GET',
        headers: {
            'token': token
        }}
        ).then(res => res.text()).then(res => { console.log(res);});

      if(filesSelected.length > 0)
      {
        var formData = new FormData();
        var name = [];
        var type = [];
        var i=1;
        //var patt1=/\.[0-9a-z]+$/i;
        filesSelected.forEach(element => {
          type.push(element.type);
          formData.append('file', element);
          //${element.name.match(patt1)[0]}
          name.push(`ContractID-${addContract}-DocNr${i}`);
          ++i;
        })

        token = jwt.sign({DocumentName:name, Type:type, token: bearer, Id: addContract}, process.env.REACT_APP_ReadToken);
        await fetch('https://abintus-app-api-linux.azurewebsites.net/contract/add-contract/upload'
        ,{
          method: 'POST',
          headers: {
            'token': token
        },
          body:formData
        }
          )
          .then(response => response.text()).then(response => {            
            if(response === "false")
            {
              setDialogTitle("Error");
              setDialogText("Your Contract has been added but couldn't upload your file");
              setOpen(true);
            }
            else
            {
              setOpen(true);
            }
          });
      }
      else
      {
        setOpen(true);
      }
    }

  }
  else
  {
    setDialogTitle("Validation Error");
    setOpen(true);
  }
}

// function NumberFormatCustom(props) {
//   const { inputRef, onChange, ...other } = props;

//   return (
//     <NumberFormat
//       {...other}
//       getInputRef={inputRef}
//       onValueChange={(values) => {
//         onChange({
//           target: {
//             name: props.name,
//             value: values.value,
//           },
//         });
//       }}
//       thousandSeparator
//       isNumericString
//     />
//   );
// }
  return (
    <Page  title="Add Contract">
      <form id="contractForm">
      <Container maxWidth={false} className={classes.container}>
        <Header style={{paddingBottom: "16px"}}/>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={4} xs={12}>
                <Autocomplete
                    id="Advertiser"
                    freeSolo
                    options={advertiserList.sort().map((option) => option)}
                    renderInput={(params) => (
                      < TextField {...params} label="Advertiser" margin="normal" variant="outlined"
                    />
                    )}
                  />
                </Grid>
              <Grid item md={4} xs={12}>
                <Autocomplete
                    id="Agency"
                    freeSolo
                    options={agencyList.sort().map((option) => option)}
                    renderInput={(params) => (
                      <TextField {...params} label="Agency" margin="normal" variant="outlined" />
                    )}
                  />
                </Grid>
              <Grid item md={4} xs={12}>
                <Autocomplete
                    id="AgencyGroup"
                    freeSolo
                    options={agencyGroupList.sort().map((option) => option)}
                    renderInput={(params) => (
                      <TextField {...params} label="Agency Group" margin="normal" variant="outlined" />
                    )}
                  />
              </Grid>
              <Grid item md={4} xs={12}>
                <Autocomplete
                        id="Services"
                        freeSolo
                        options={servicesList.sort().map((option) => option)}
                        renderInput={(params) => (
                          <TextField {...params} label="Services" margin="normal" variant="outlined" />
                        )}
                      />
              </Grid>
              <Grid item md={4} xs={12}>
              <TextField
                      style={{width:"100%",marginTop: "16px"}}
                      error = {dateReviewFlag}
                      helperText={dateReviewFlag === true?"Date Of Review can't be blank":""}
                      variant="outlined"
                        id="DateOfReview"
                        label="Date Of Review"
                        type="date"
                        onChange={(event)=> { setDateReviewFlag(false)}}
                        defaultValue=""
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl style={{width:"100%", marginTop: "16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="PreparedByAbintusLabel">Prepared by Abintus</InputLabel>
                          <Select
                            error = {preparedByAbintusFlag}
                            id="PreparedByAbintus"
                            labelId="PreparedByAbintusLabel"
                            label="Prepared by Abintus"
                            value={preparedByAbintus}
                            onChange={(event) => {
                              setPreparedByAbintus(event.target.value);
                              setPreparedByAbintusFlag(false);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                          <FormHelperText  error = {preparedByAbintusFlag} >{preparedByAbintusFlag === true?"Prepared by Abintus must be set":""}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
              <FormControl style={{width:"100%", marginTop: "16px"}} 
          variant="outlined" className={classes.formControl}>
                        <InputLabel id="CountryLabel">Country</InputLabel>
                          <Select
                            labelId="CountryLabel"
                            label="Country"
                            id="Country"
                            name ="country"
                            value={selectedCountry}
                            onChange={(event) => {
                              setSelectedCountry(event.target.value);
                            }}
                          >
                            <MenuItem value="Global">Global</MenuItem>
                            <MenuItem value="Various">Various</MenuItem>
                            {countryList.getNames().sort().map((value,index) =>
                            {
                            return <MenuItem key={index} value={value}>{value}</MenuItem>
                            })}
                          </Select>
            </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl style={{width:"100%", marginTop: "16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="AnnualMediaBudgetLabel">Annual Media Budget</InputLabel>
                          <Select
                            error={annualMediaBudgetFlag}
                            labelId="AnnualMediaBudgetLabel"
                            label="Annual Media Budget"
                            id="AnnualMediaBudget"
                            value={annualMediaBudget}
                            onChange={(event) => {
                              setAnnuaMediaBudget(event.target.value);
                              setAnnualMediaBudgetFlag(false);
                            }}
                          >
                            <MenuItem value="Less than €1M">Less than €1M</MenuItem>
                            <MenuItem value="€1M - €5M">€1M - €5M</MenuItem>
                            <MenuItem value="€5M - €10M">€5M - €10M</MenuItem>
                            <MenuItem value="€10M - €25M">€10M - €25M</MenuItem>
                            <MenuItem value="€25M - €50M">€25M - €50M</MenuItem>
                            <MenuItem value="€50M - €100M">€50M - €100M</MenuItem>
                          </Select>
                          <FormHelperText  error = {annualMediaBudgetFlag} >{annualMediaBudgetFlag === true?"Annual Media Budget must be set":""}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
              <TextField
                style={{width:"100%",marginTop: "16px"}}
                variant="outlined"
                id="AnnualNetSpend"
                label="Annual Net Spend"
                type="tel"
                InputProps={{
                  // inputComponent: NumberFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">
                      €
                    </InputAdornment>
                  ),
                }}
                defaultValue=""
                            />
              </Grid>
              <Grid item md={4} xs={12}>
              <TextField
                style={{width:"100%",marginTop: "16px"}}
                variant="outlined"
                id="MaxFees"
                label="Max Fees"
                type="tel"
                InputProps={{
                  // inputComponent: NumberFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">
                      €
                    </InputAdornment>
                  ),
                }}
                defaultValue=""
                            />
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="RemunerationModelLabel">Remuneration Model</InputLabel>
                          <Select
                            error={remunerationModelFlag}
                            labelId="RemunerationModelLabel"
                            label="Remuneration Model"
                            id="RemunerationModel"
                            value={remunerationModel}
                            onChange={(event) => {
                              setRemunerationModel(event.target.value);
                              setRemunerationModelFlag(false);
                            }}
                          >
                            <MenuItem value="Commission">Commission</MenuItem>
                            <MenuItem value="Commission + PRF">Commission + PRF</MenuItem>
                            <MenuItem value="Commission + Fixed Fees">Commission + Fixed Fees</MenuItem>
                            <MenuItem value="Commission + Fixed Fee + PRF">Commission + Fixed Fee + PRF</MenuItem>
                            <MenuItem value="Daily rate">Daily rate</MenuItem>
                            <MenuItem value="Fixed Fees">Fixed Fees</MenuItem>
                            <MenuItem value="Fixed Fees + PRF">Fixed Fees + PRF</MenuItem>
                          </Select>
                          <FormHelperText  error = {remunerationModelFlag} >{remunerationModelFlag === true?"Remuneration Model must be set":""}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                          style={{width:"100%",marginTop: "16px"}}
                          variant="outlined"
                          id="EstimatedAvbs"
                          label="Estimated AVBs"
                          type="text"
                          defaultValue=""
                          InputProps={{
                            // inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <InputAdornment position="start">
                                €
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                      />
              </Grid>
              <Grid item md={4} xs={12}>
              <Autocomplete
                    id="AvbCalculation"
                    freeSolo
                    options={avbCalculations.sort().map((option) => option)}
                    renderInput={(params) => (
                      < TextField {...params} label="AVB Calculation" margin="normal" variant="outlined"
                    />
                    )}
                  />
              </Grid>
              <Grid item md={4} xs={12}>
              <TextField
                        style={{width:"100%", marginTop:"16px"}}
                        error = {startDateFlag}
                        helperText={startDateFlag === true?"Start Date can't be blank":""}
                        variant="outlined"
                        id="StartDate"
                        label="Start Date"
                        type="date"
                        defaultValue=""
                        onChange={(event)=>{setStartDateFlag(false);}}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
              {/* <KeyboardDatePicker
                      className={classes.datePicker}
                        style={{width:"100%", marginTop:"16px", fill:"#f8d02d",color:"primary",buttonColor:"#f8d02d"}}
                        error = {startDateFlag}
                        helperText={startDateFlag === true?"Start Date can't be blank":""}
                        inputVariant="outlined"
                        format="DD/MM/YYYY"
                        id="StartDate"
                        label="Start Date"
                        defaultValue=""
                        onChange={(event)=>{setStartDateFlag(false);}}
                        InputLabelProps={{
                          fill:"#f8d02d",
                          color:"primary",
                          shrink: true,
                        }}
                      /> */}
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="LengthOfContractLabel">Length Of Contract</InputLabel>
                        <Select
                            labelId="LengthOfContractLabel"
                            label = "Length Of Contract"
                            id="LengthOfContract"
                            error={lenghtOfContractFlag}
                            value={lenghtOfContract}
                            onChange={(event) => {
                              setLenghtOfContract(event.target.value);
                              setLenghtOfContractFlag(false);
                            }}
                          >
                            <MenuItem value="1 Year">1 Year</MenuItem>
                            <MenuItem value="2 Years">2 Years</MenuItem>
                            <MenuItem value="3 Years">3 Years</MenuItem>
                            <MenuItem value="4 Years">4 Years</MenuItem>
                            <MenuItem value="5 Years">5 Years</MenuItem>
                            <MenuItem value="Indefinite">Indefinite</MenuItem>
                          </Select>
                          <FormHelperText  error = {lenghtOfContractFlag} >{lenghtOfContractFlag === true?"Length Of Contract must be set":""}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="AutoRenewLabel">Auto Renew</InputLabel>
                          <Select 
                            error={autoRenewFlag}
                            labelId="AutoRenewLabel"
                            label = "Auto Renew"
                            id="AutoRenew"
                            value={autoRenew}
                            onChange={(event) => {
                              setAutoRenew(event.target.value);
                              setAutoRenewFlag(false);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                          <FormHelperText  error = {autoRenewFlag} >{autoRenewFlag === true?"Auto Renew must be set":""}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
              <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                    <InputLabel id="PaymentTermsLabel">Payment Terms</InputLabel>
                      <Select
                        error={paymentTermsFlag}
                        label="Payment Terms"
                        labelId="PaymentTermsLabel"
                        id="PaymentTerms"
                        value={paymentTerms}
                        onChange={(event) => {
                          setPaymentTerms(event.target.value);
                          setPaymentTermsFlag(false);
                        }}
                      >
                        <MenuItem value="< 30 Days">&lt; 30 Days</MenuItem>
                        <MenuItem value="30 Days">30 Days</MenuItem>
                        <MenuItem value="60 Days">60 Days</MenuItem>
                        <MenuItem value="90 Days">90 Days</MenuItem>
                        <MenuItem value="120 Days">120 Days</MenuItem>
                      </Select>
                      <FormHelperText  error = {paymentTermsFlag} >{paymentTermsFlag === true?"Payment Terms must be set":""}</FormHelperText>
                      </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <Autocomplete
                    id="Currency"
                    freeSolo
                    options={currencyList.sort().map((option) => option)}
                    renderInput={(params) => (
                      <TextField {...params} label="Currency" margin="normal" variant="outlined"
                    />
                    )}
                  />
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl style={{width:"100%", marginTop: "16px"}} 
                 variant="outlined" className={classes.formControl}>
                        <InputLabel id="UserLabel">Select Client</InputLabel>
                          <Select
                            labelId="UserLabel"
                            label="Select Client"
                            id="Expert"
                            name ="expert"
                            value={selectClient}
                            onChange={(event) => {
                              setSelectClient(event.target.value);
                            }}
                          >
                            <MenuItem value="None">None</MenuItem>
                            {expertIds.map((value,index) =>
                            {
                            return <MenuItem key={index} value={value[0]}>{value[1]}</MenuItem>
                            })}
                          </Select>
            </FormControl>
                </Grid>
            </Grid>
          </CardContent>
        </Card>
        <div style={{paddingBottom: "24px"}}></div>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="SignedbyBothPartiesLabel">Signed By Both Parties</InputLabel>
                        <Select
                          label= "Signed By Both Parties"
                          labelId="SignedbyBothPartiesLabel"
                          id="SignedByBothParties"
                          value={signedByBothParties}
                          onChange={(event) => {
                            setSignedByBothParties(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="SignedbyBothPartiesImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label= "Improvements Required?"
                          labelId="SignedbyBothPartiesImprLabel"
                          id="SignedByBothPartiesImpr"
                          value={signedByBothPartiesImpr}
                          onChange={(event) => {
                            setSignedByBothPartiesImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                  <TextField
                        style={{width:"100%",marginTop: "16px"}}
                        variant="outlined"
                          id="SignedByBothPartiesComment"
                          label="Comment"
                          type="text"
                          defaultValue=""
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="DefinitionsLabel">Definitions</InputLabel>
                        <Select
                          label="Definitions"
                          id="Definitions"
                          labelId="DefinitionsLabel"
                          value={definitions}
                          onChange={(event) => {
                            setDefinitions(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="DefinitionsImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="DefinitionsImpr"
                          labelId="DefinitionsImprLabel"
                          value={definitionsImpr}
                          onChange={(event) => {
                            setDefinitionsImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                  <TextField
                          style={{width:"100%",marginTop: "16px"}}
                          variant="outlined"
                            id="DefinitionsComment"
                            label="Comment"
                            type="text"
                            defaultValue=""
                            InputLabelProps={{
                              shrink: true,
                            }}
                  />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="ServicesDescribedLabel">Services Described</InputLabel>
                      <Select
                        label="Services Described"
                        id="ServicesDescribed"
                        labelId="ServicesDescribedLabel"
                        value={servicesDescribed}
                        onChange={(event) => {
                          setServicesDescribed(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="ServicesDescribedImprLabel">Improvements Required?</InputLabel>
                      <Select
                        label="Improvements Required?"
                        id="ServicesDescribedImpr"
                        labelId="ServicesDescribedImprLabel"
                        value={servicesDescribedImpr}
                        onChange={(event) => {
                          setServicesDescribedImpr(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                          style={{width:"100%",marginTop: "16px"}}
                          variant="outlined"
                            id="ServicesDescribedComment"
                            label="Comment"
                            type="text"
                            defaultValue=""
                            InputLabelProps={{
                              shrink: true,
                            }}
                  />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="ReportingClauseLabel">Reporting Clause</InputLabel>
                        <Select
                          id="ReportingClause"
                          label="Reporting Clause"
                          labelId="ReportingClauseLabel"
                          value={reportingClause}
                          onChange={(event) => {
                            setReportingClause(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>

                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="ReportingClauseImprLabel">Improvements Required?</InputLabel>
                        <Select
                          id="ReportingClauseImpr"
                          label="Improvements Required?"
                          labelId="ReportingClauseImprLabel"
                          value={reportingClauseImpr}
                          onChange={(event) => {
                            setReportingClauseImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                  <TextField
                            style={{width:"100%",marginTop: "16px"}}
                            variant="outlined"
                              id="ReportingClauseComment"
                              label="Comment"
                              type="text"
                              defaultValue=""
                              InputLabelProps={{
                                shrink: true,
                              }}
                    />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="AgentOnlyLabel">Agent Only</InputLabel>
                      <Select
                        label="Agent Only"
                        id="AgentOnly"
                        labelId="AgentOnlyLabel"
                        value={agentOnly}
                        onChange={(event) => {
                          setAgentOnly(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="AgentOnlyImprLabel">Improvements Required?</InputLabel>
                      <Select
                        label="Improvements Required?"
                        id="AgentOnlyImpr"
                        labelId="AgentOnlyImprLabel"
                        value={agentOnlyImpr}
                        onChange={(event) => {
                          setAgentOnlyImpr(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                  <TextField
                              style={{width:"100%",marginTop: "16px"}}
                              variant="outlined"
                                id="AgentOnlyComment"
                                label="Comment"
                                type="text"
                                defaultValue=""
                                InputLabelProps={{
                                  shrink: true,
                                }}
                      />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="AuthorityClauseLabel">Authority Clause</InputLabel>
                        <Select
                          label="Authority Clause"
                          id="AuthorityClause"
                          labelId="AuthorityClauseLabel"
                          value={authorityClause}
                          onChange={(event) => {
                            setAuthorityClause(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="AuthorityClauseImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="AuthorityClauseImpr"
                          labelId="AuthorityClauseImprLabel"
                          value={authorityClauseImpr}
                          onChange={(event) => {
                            setAuthorityClauseImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                              style={{width:"100%",marginTop: "16px"}}
                              variant="outlined"
                                id="AuthorityClauseComment"
                                label="Comment"
                                type="text"
                                defaultValue=""
                                InputLabelProps={{
                                  shrink: true,
                                }}
                      />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="ExclusivityReciprocalLabel">Exclusivity Reciprocal</InputLabel>
                      <Select
                        label="Exclusivity Reciprocal"
                        id="ExclusivityReciprocal"
                        labelId="ExclusivityReciprocalLabel"
                        value={exclusivityReciprocal}
                        onChange={(event) => {
                          setExclusivityReciprocal(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="ExclusivityReciprocalImprLabel">Improvements Required?</InputLabel>
                      <Select
                        label="Improvements Required?"
                        id="ExclusivityReciprocalImpr"
                        labelId="ExclusivityReciprocalLabel"
                        value={exclusivityReciprocalImpr}
                        onChange={(event) => {
                          setExclusivityReciprocalImpr(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                              style={{width:"100%",marginTop: "16px"}}
                              variant="outlined"
                                id="ExclusivityReciprocalComment"
                                label="Comment"
                                type="text"
                                defaultValue=""
                                InputLabelProps={{
                                  shrink: true,
                                }}
                      />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="KeyIndividualsLabel">Key Individuals</InputLabel>
                      <Select
                        label="Key Individuals"
                        id="KeyIndividuals"
                        labelId="KeyIndividualsLabel"
                        value={keyIndividuals}
                        onChange={(event) => {
                          setKeyIndividuals(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="KeyIndividualsImprLabel">Improvements Required?</InputLabel>
                      <Select
                        label="Improvements Required?"
                        id="KeyIndividualsImpr"
                        labelId="KeyIndividualsImprLabel"
                        value={keyIndividualsImpr}
                        onChange={(event) => {
                          setKeyIndividualsImpr(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                              style={{width:"100%",marginTop: "16px"}}
                              variant="outlined"
                                id="KeyIndividualsComment"
                                label="Comment"
                                type="text"
                                defaultValue=""
                                InputLabelProps={{
                                  shrink: true,
                                }}
                      />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="TransparentRemunerationLabel">Transparent Remuneration</InputLabel>
                        <Select
                          label="Transparent Remuneration"
                          id="TransparentRemuneration"
                          labelId="TransparentRemunerationLabel"
                          value={transparentRemuneration}
                          onChange={(event) => {
                            setTransparentRemuneration(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="TransparentRemunerationImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="TransparentRemunerationImpr"
                          labelId="TransparentRemunerationImprLabel"
                          value={transparentRemunerationImpr}
                          onChange={(event) => {
                            setTransparentRemunerationImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                              style={{width:"100%",marginTop: "16px"}}
                              variant="outlined"
                                id="TransparentRemunerationComment"
                                label="Comment"
                                type="text"
                                defaultValue=""
                                InputLabelProps={{
                                  shrink: true,
                                }}
                      />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="PRFIncludedLabel">PRF Included</InputLabel>
                        <Select
                          label="PRF Included"
                          id="PRFIncluded"
                          labelId="PRFIncludedLabel"
                          value={prfIncluded}
                          onChange={(event) => {
                            setPrfIncluded(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="PRFIncludedImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="PRFIncludedImpr"
                          labelId="PRFIncludedImprLabel"
                          value={prfIncludedImpr}
                          onChange={(event) => {
                            setPrfIncludedImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                              style={{width:"100%",marginTop: "16px"}}
                              variant="outlined"
                                id="PRFIncludedComment"
                                label="Comment"
                                type="text"
                                defaultValue=""
                                InputLabelProps={{
                                  shrink: true,
                                }}
                      />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="PRFWithMalusLabel">PRF With Malus</InputLabel>
                        <Select
                          label="PRF With Malus"
                          id="PRFWithMalus"
                          labelId="PRFWithMalusLabel"
                          value={prfWithMalus}
                          onChange={(event) => {
                            setPrfWithMalus(event.target.value);
                          }}
                        >
                        {prfIncluded ===  "Yes" && <MenuItem value="Yes">Yes</MenuItem>}
                        {prfIncluded ===  "Yes" && <MenuItem value="No">No</MenuItem>}
                        {prfIncluded ===  "No" && <MenuItem value="Not Applicable">Not Applicable</MenuItem>}
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="PRFWithMalusImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="PRFWithMalusImpr"
                          labelId="PRFWithMalusImprLabel"
                          value={prfWithMalusImpr}
                          onChange={(event) => {
                            setPrfWithMalusImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                              style={{width:"100%",marginTop: "16px"}}
                              variant="outlined"
                                id="PRFWithMalusComment"
                                label="Comment"
                                type="text"
                                defaultValue=""
                                InputLabelProps={{
                                  shrink: true,
                                }}
                      />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="PRFMethodologyLabel">PRF Methodology</InputLabel>
                        <Select 
                          label="PRF Methodology"
                          id="PRFMethodology"
                          labelId="PRFMethodologyLabel"
                          value={prfMethodology}
                          onChange={(event) => {
                            setPrfMethodology(event.target.value);
                          }}
                        >
                        {prfIncluded ===  "Yes" && <MenuItem value="Yes">Yes</MenuItem>}
                        {prfIncluded ===  "Yes" && <MenuItem value="No">No</MenuItem>}
                        {prfIncluded ===  "No" && <MenuItem value="Not Applicable">Not Applicable</MenuItem>}
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="PRFMethodologyImprLabel">Improvements Required?</InputLabel>
                        <Select 
                          label="Improvements Required?"
                          id="PRFMethodologyImpr"
                          labelId="PRFMethodologyImprLabel"
                          value={prfMethodologyImpr}
                          onChange={(event) => {
                            setPrfMethodologyImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                              style={{width:"100%",marginTop: "16px"}}
                              variant="outlined"
                                id="PRFMethodologyComment"
                                label="Comment"
                                type="text"
                                defaultValue=""
                                InputLabelProps={{
                                  shrink: true,
                                }}
                      />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="AgencyCommitmentsLabel">Agency Commitments</InputLabel>
                        <Select
                          label="Agency Commitments"
                          id="AgencyCommitments"
                          labelId="AgencyCommitmentsLabel"
                          value={agencyCommitments}
                          onChange={(event) => {
                            setAgencyCommitments(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="AgencyCommitmentsImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="AgencyCommitmentsImpr"
                          labelId="AgencyCommitmentsImprLabel"
                          value={agencyCommitmentsImpr}
                          onChange={(event) => {
                            setAgencyCommitmentsImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                  <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="AgencyCommitmentsComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue=""
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="AuditRightsLabel">Audit Rights</InputLabel>
                        <Select
                          label="Audit Rights"
                          id="AuditRights"
                          labelId="AuditRightsLabel"
                          value={auditRights}
                          onChange={(event) => {
                            setAuditRights(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="AuditRightsImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="AuditRightsImpr"
                          labelId="AuditRightsImprLabel"
                          value={auditRightsImpr}
                          onChange={(event) => {
                            setAuditRightsImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="AuditRightsComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue=""
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="RebatesClausesLabel">Rebates Clauses</InputLabel>
                      <Select
                        label="Rebates Clauses"
                        id="RebatesClauses"
                        labelId="RebatesClausesLabel"
                        value={rebatesClauses}
                        onChange={(event) => {
                          setRebatesClauses(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="RebatesClausesImprLabel">Improvements Required?</InputLabel>
                      <Select
                        label="Improvements Required?"
                        id="RebatesClausesImpr"
                        labelId="RebatesClausesImprLabel"
                        value={rebatesClausesImpr}
                        onChange={(event) => {
                          setRebatesClausesImpr(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="RebatesClausesComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue=""
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="EPDClauseLabel">EPD Clauses</InputLabel>
                        <Select
                          label="EPD Clauses"
                          id="EPDClause"
                          labelId="EPDClauseLabel"
                          value={epdClause}
                          onChange={(event) => {
                            setEpdClause(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="EPDClauseImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="EPDClauseImpr"
                          labelId="EPDClauseImprLabel"
                          value={epdClauseImpr}
                          onChange={(event) => {
                            setEpdClauseImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="EPDClauseComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue=""
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="UnbilledMediaClauseLabel">Unbilled Media Clause</InputLabel>
                      <Select
                        label="Unbilled Media Clause"
                        id="UnbilledMediaClause"
                        labelId="UnbilledMediaClauseLabel"
                        value={unbilledMediaClause}
                        onChange={(event) => {
                          setUnbilledMediaClause(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="UnbilledMediaClauseImprLabel">Improvements Required?</InputLabel>
                      <Select
                        label="Improvements Required?"
                        id="UnbilledMediaClauseImpr"
                        labelId="UnbilledMediaClauseImprLabel"
                        value={unbilledMediaClauseImpr}
                        onChange={(event) => {
                          setUnbilledMediaClauseImpr(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="UnbilledMediaClauseComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue=""
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="CompetitivePaymentTermsLabel">Competitive Payment Terms</InputLabel>
                      <Select
                        label="Competitive Payment Terms"
                        id="CompetitivePaymentTerms"
                        labelId="CompetitivePaymentTermsLabel"
                        value={competitivePaymentTerms}
                        onChange={(event) => {
                          setCompetitivePaymentTerms(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="CompetitivePaymentTermsImprLabel">Improvements Required?</InputLabel>
                      <Select
                        label="Improvements Required?"
                        id="CompetitivePaymentTermsImpr"
                        labelId="CompetitivePaymentTermsImprLabel"
                        value={competitivePaymentTermsImpr}
                        onChange={(event) => {
                          setCompetitivePaymentTermsImpr(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="CompetitivePaymentTermsComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue=""
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="StandardTerminationClauseLabel">Standard Termination Clause</InputLabel>
                        <Select
                          label="Standard Termination Clause"
                          id="StandardTerminationClause"
                          labelId="StandardTerminationClauseLabel"
                          value={standardTerminationClause}
                          onChange={(event) => {
                            setStandardTerminationClause(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="StandardTerminationClauseImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="StandardTerminationClauseImpr"
                          labelId="StandardTerminationClauseImprLabel"
                          value={standardTerminationClauseImpr}
                          onChange={(event) => {
                            setStandardTerminationClauseImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="StandardTerminationClauseComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue=""
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                </Grid>
                <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="StandardSubcontractingClauseLabel">Standard Subcontracting Clause</InputLabel>
                      <Select
                        label="Standard Subcontracting Clause"
                        id="StandardSubcontractingClause"
                        labelId="StandardSubcontractingClauseLabel"
                        value={standardSubcontractingClause}
                        onChange={(event) => {
                          setStandardSubcontractingClause(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="StandardSubcontractingClauseImprLabel">Improvements Required?</InputLabel>
                      <Select
                        label="Improvements Required?"
                        id="StandardSubcontractingClauseImpr"
                        labelId="StandardSubcontractingClauseImprLabel"
                        value={standardSubcontractingClauseImpr}
                        onChange={(event) => {
                          setStandardSubcontractingClauseImpr(event.target.value);
                        }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                      </Select>
                      </FormControl>
                </Grid>
                <Grid item md={5} xs={12}>
                <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="StandardSubcontractingClauseComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue=""
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                </Grid>
                <Grid item md={12} xs={12} align="center">
                <Box mt={4}>
                <CustomDropBox
                    acceptedFiles={['application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/docx','application/pdf','application/msword','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint','application/vnd.openxmlformats-officedocument.presentationml.slideshow','application/vnd.openxmlformats-officedocument.presentationml.presentation']}
                    filesLimit={10}
                    maxFileSize={60000000}
                    showFileNames={true}
                    dropzoneText={'Drag and drop files here or click here'}
                    onChange={(files) => {selectFile(files)}}
              />
                </Box>

                  <Button disabled={isSubmitting} variant="contained" color="secondary" onClick={submitContract}>Add Contract</Button>
                </Grid>
            </Grid>
          </CardContent>
        </Card>
        
      </Container>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="DialogTitle">
        <DialogTitle id="DialogTitle">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         {dialogTitle === "Success" && 
          <Button variant="contained" color="secondary" onClick={contractEvaluation}>Contract Evaluation</Button>
         } 
          <Button variant="contained" color="secondary" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      </form>
    </Page>
  );
}
