import React,{useLayoutEffect} from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Paper,Stepper,Step,
  StepConnector,
  StepLabel,
  withStyles,
  Avatar,
  useMediaQuery

} from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import { useState } from 'react';
import LightBulbIcon from '@material-ui/icons/EmojiObjects';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Document from '@material-ui/icons/InsertDriveFile';
import Options from './Options';
import Introduction from './Introduction';
import NDA from './NDA';
import UploadContract from './UploadContract';
import YourResults from './YourResults';
import {
  Award as AwardIcon
} from 'react-feather';
import './ExpertAssessment.css';
var jwt = require('jsonwebtoken');


const steps = [
  {
    label: 'Introduction',
    icon: LightBulbIcon,
  },
  {
    label: 'NDA',
    icon: VerifiedUser,
  },
  {
    label: 'Upload Contract',
    icon: Document,
  },
  {
    label: 'Your Results',
    icon: AwardIcon,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),    
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  },
  card: {
    backgroundColor: "white",
    boxShadow: "none",
    paddingTop: "10px"
  },
}));

function ExpertAssessment(props) {  
  var location = useLocation()
  var test = location.state == undefined ? null : location.state.step
  
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width: 900px)');
  const [currentId, setCurrentId] = useState(0);
  const [activeStep, setActiveStep] = useState(test);
  const [dbValues, setDbValues] = useState({
    nda: null,
    contract: [],
  });    

  function updateId(id) {
    setCurrentId(id);
  }

  useLayoutEffect(() => { 
     async function fetchAPI()
     {
      var accessToken = localStorage.getItem('accessToken');
      var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);      
      var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
      var token = jwt.sign({ token: bearer,Id:validToken.id }, process.env.REACT_APP_ReadToken);
      var output = await fetch('https://abintus-app-api-linux.azurewebsites.net/expert-assessment-journey'      
      ,{
        method: 'GET',
        headers: {
            'token': token
        }}
        );                

       var journey = await output.json();          
        if(journey.length === 0)
        {
          // setActiveStep(0);
        }
        else
        {          
          if(journey[0][3] === true)
          {
            if(journey[0][1] === "")
            {
              setActiveStep(1);
            }
            else if(journey[0][2] === "")
            {
              setActiveStep(2);
            }
            else
            {
              setActiveStep(3);
            }
          }
          else
          {
            // token = jwt.sign({ token: bearer,Session:journey[0][0] }, process.env.REACT_APP_ReadToken);
            // var paymentCheck =  await fetch('https://abintus-app-api-linux.azurewebsites.net/expert-assessment-journey/payment-check'
            // ,{
            //   method: 'GET',
            //   headers: {
            //       'token': token
            //   }}
            //   );
            // var paymentCheckOutcome = await paymentCheck.text();

            var paymentCheckOutcome = null;
            if(paymentCheckOutcome === "paid")
            {
                if(journey[0][1] === "")
                {
                  setActiveStep(2);
                }
                else if(journey[0][2] === "")
                {
                  setActiveStep(3);
                }
                else
                {
                  setActiveStep(4);
                }
               
                token = jwt.sign({ token: bearer,Session:journey[0][0], Email:validToken.username }, process.env.REACT_APP_ReadToken);
                var updatePayment =  await fetch('https://abintus-app-api-linux.azurewebsites.net/expert-assessment-journey/update-payment-status'
              ,{
                method: 'GET',
                headers: {
                    'token': token
                }}
                );
                await updatePayment.text();
                token = jwt.sign({ token: bearer,Product:"Expert Assessment", Amount:1, UserId:validToken.id }, process.env.REACT_APP_ReadToken);
                var billings = await fetch('https://abintus-app-api-linux.azurewebsites.net/billing-history/add'
                ,{
                  method: 'POST',
                  headers: {
                      'token': token
                  }}
                );
                await billings.text();
                token = jwt.sign({ UserId:validToken.id, EventId:8, token: bearer }, process.env.REACT_APP_ReadToken);
                fetch('https://abintus-app-api-linux.azurewebsites.net/user/event'
                ,{
                  method: 'POST',
                  headers: {
                      'token': token
                  }}
                  );                
            }
          }
        }
     }
     fetchAPI();
    },[]);

  const CustomStepConnector = withStyles((theme) => ({
    vertical: {
      marginLeft: 19,
      padding: 0,
    },
    alternativeLabel:{
      line: {
        display: "none"
      }
    },
    line: {
      borderLeft: "solid 2px #025373",
    } 
  }))(StepConnector);
  
  const useCustomStepIconStyles = makeStyles((theme) => ({
    root: {
      backgroundColor:"#FFFFFF",
      color:"#000000",
      border: "solid 2px #000000",
    },
    active: {
      backgroundColor: theme.palette.secondary.main,
      color: "#000000",
      // boxShadow: theme.shadows[10]
    },
    completed: {
      backgroundColor: "#000000",
      color: theme.palette.secondary.main,
    }
  }));

  function CustomStepIcon({ active, completed, icon }) {
    const classes = useCustomStepIconStyles();    
    const Icon = steps[icon - 1].icon;
    return (
      <Avatar
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        <Icon size="20" />
      </Avatar>
    );
  }
  const restart = () => {
    setActiveStep(null);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Page
      className={classes.root}
      title="Expert Assessment"
    >
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <Header />
        <Box mt={2}></Box>
        <Paper className={classes.card}>
            <Grid container>
              {activeStep !== null && !mobile && (
                <Grid
                  item
                  xs={12}
                  md={3}
                >
                    <Stepper
                      activeStep={activeStep}
                      connector={<CustomStepConnector />}
                      orientation="vertical"
                      component={Box}
                      style={{
                        backgroundColor: "#FFFFFF"
                      }}
                    >
                      {steps.map((step) => 
                        
                      <Step  key={step.label}>
                        <StepLabel StepIconComponent={CustomStepIcon}>
                          {step.label}
                        </StepLabel>
                      </Step>
                      )}
                    </Stepper>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={9}
                lg={activeStep == null && 12}
              >
                <Box >
                {activeStep == null && (
                  <Options onNext={handleNext} activeStep={activeStep} setActiveStep={setActiveStep}/>
                )}
                {activeStep === 0 && (
                    <Introduction  onNext={handleNext} setActiveStep={setActiveStep} mobile={mobile}/>
                  )}
                   {/* {activeStep === 1 && (
                    <Checkout  onNext={handleNext} onBack={handleBack}/>
                  )} */}
                   {activeStep === 1 && (
                    <NDA  onNext={handleNext} setActiveStep={setActiveStep} setDbValues={setDbValues} updateId={updateId} mobile={mobile}/>
                  )}
                   {activeStep === 2 && (
                    <UploadContract  onNext={handleNext} setActiveStep={setActiveStep} setDbValues={setDbValues} dbValues={dbValues} currentId={currentId} />
                  )}
                    {activeStep === 3 && (
                    <YourResults  onNext={handleNext} setActiveStep={setActiveStep} restart={restart}/>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
      </Container>
    </Page>
  );
}

export default ExpertAssessment;
