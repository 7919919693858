
import React, { useState, useReducer } from 'react';
import { Container,Grid,makeStyles,Button,CircularProgress,Dialog,DialogContent } from '@material-ui/core';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import Page from 'src/components/Page';
import Header from './Header';
import MUIDataTable from "mui-datatables";
import styled from 'styled-components';

var jwt = require('jsonwebtoken');

function setCharAt(str,index,chr) {
  if(index > str.length-1) return str;
  return str.substring(0,index) + chr + str.substring(index+1);
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  table:{
    '&.MuiIconButton-root':{
      color: "#14213D"
    }
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  }
}));

var columns = [{
  name: "Expert Assess. Id",
  options: {
   filter: false,
  }
 },{
  name: "Client Id",
  options: {
   filter: false,
   customBodyRender: (value, tableMeta, updateValue) => {
    var link = `https://www.abintus.app/app/admin/users/user/${value}`
    if(value !== "")
    {
    return ( <a href={link}>{value}</a>);
    }
  }
  }
 },
 {
  name: "First Name",
  options: {
   filter: false,
  }
 },
 {
  name: "Last Name",
  options: {
   filter: false,
  }
 },
 {
  name: "Date",
  options: {
   filter: false,
  }
 },{
   name: "NDA",
   options: {
    filter: false,
    customBodyRender: (value, tableMeta, updateValue) => {
      if(value !== "")
      {
        return ( <a href={value} target="_blank" rel="noopener noreferrer" ><Button variant="contained" color="secondary" style={{display:"inline-block"}}>View Uploaded NDA</Button></a>);
      }
      else{
        return <h4>Pending</h4>
      }
    }
   }
  },{
   name: "Contract",
   options: {
    filter: false,
    customBodyRender: (value, tableMeta, updateValue) => {        
      if(value === "")
      {
        return (<h4>Pending</h4>);
      }
      else
      {
        const output = []                
        for (let index = 1; index < tableMeta.rowData[8] + 1; index++) {
          var link = value;                    
          var location = link.indexOf("DocNr") + 5;
          link = setCharAt(link,location,index.toString());          
          output.push(<a href={link} key={index} target="_blank" rel="noopener noreferrer" ><Button variant="contained" style={{display:"inline-block", backgroundColor: "light gray"}}>Cont. {index}</Button></a>);          
        }        
      return (<div>{output}</div>);
      }
    }
   }
  },{
   name: "Paid",
   options: {
    filter: true,
   }
  },{
   name: "Number Of Documents",
   options: {
    filter: false,
   }
  },
  {
    name: "Analysis"
  },
  {
    name: "Contract Review",
    options: {
     filter: true,
     customBodyRender: (value, tableMeta, updateValue) => {
        return <h4>{value}</h4>
    }
    }
   }
    ];

var data = [];
var options = {
  filterType: "dropdown",
  responsive: "standard",
  rowsPerPage: 15,
    onTableInit: (action, tableState) => {
      var l = [];
      tableState.displayData.forEach(element => {
        l.push(element.data);
      });
      // window.ContractTable.RefreshTopData(l);
      },
  selectableRows: "none"
};

var dataArray = [];

const StyledButton = styled.button`
  padding: 8px 25px;
  background-color: #F3F3F3;
  color: #000000;
  border: none;
  transition: 0.3s;

  &:hover {
    background-color: #E2E2E2;
    cursor: pointer;
  }
`

function UploadAnalysis({ contractId, userId, analysis }) {  
  const [open, setOpen] = useState(false);    

  async function UploadFile(file) {
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var accessToken = localStorage.getItem('accessToken');
    var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);
    var formData = new FormData();    
    formData.append('file', file[0]);
    var type = file[0].type;
    var documentName = `Expert-Assessment-${validToken.id}-Analysis-${contractId}.pdf`;
    var clientEmail = "";
    
    // get user email
    var token = jwt.sign({ Id: validToken.id, UserId: userId, token: bearer }, process.env.REACT_APP_ReadToken);
    await fetch('https://abintus-app-api-linux.azurewebsites.net/user/get-email', 
    {
      method: 'GET',
      headers: {
        'token': token
      }
    })
    .then(res => res.json())
    .then(res => clientEmail = res[0][1]);

    // get user info
    var userFirstName = "";
    await fetch('https://abintus-app-api-linux.azurewebsites.net/user/info', 
    {
      method: 'GET',
      headers: {
        'token': token
      }
    })
    .then(res => res.json())
    .then(res => userFirstName = res[0][0]);

    // upload analysis and notify user
    token = jwt.sign({ Id: validToken.id, Email: clientEmail, ContractId: contractId, FirstName:userFirstName, DocumentName: documentName, Type: type, token: bearer }, process.env.REACT_APP_ReadToken);    
    await fetch('https://abintus-app-api-linux.azurewebsites.net/expert-assessment/upload-analysis', 
    {
      method: 'POST',
      headers: {
        'token': token
      },
      body: formData,
    })
    window.location.reload(true);
    handleOpen();    
  }

  function handleOpen() {
    setOpen(prev => !prev);
  }

  if (analysis !== null) {
    return (
      <a href={analysis} target="_blank"><StyledButton>View Analysis</StyledButton></a>
    )
  } 
  else 
  {
    return (
      <>
        <StyledButton onClick={handleOpen}>
          Upload Analysis      
        </StyledButton> 
        <DropzoneDialog open={open} onClose={handleOpen} dropzoneText="Drag and drop a file or click here to choose a file to upload" clearOnUnmount={true} onSave={(files) => { UploadFile(files)}} submitButtonText="submit">
          <DropzoneArea />     
        </DropzoneDialog>
      </>
    )
  }  
}

class ExpertTable extends React.Component {
  _isMounted = false;
    constructor(props){
      super(props);
      window.ContractTable = this;
      this.state = {
        data: [],
        Loading: true,
      };      
    }
    
    render() {
      return (
        <React.Fragment>
        <Grid
        item
        md={12}
        lg={12}
        xl={12}
      >
       {/* <MuiThemeProvider 
       theme={theme} 
      >  */}
      
        <MUIDataTable
        className={useStyles.table}
         title={<h1 variant="title">
         {this.state.Loading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4, color:"#f8d02d"}} />}
         </h1>
         }
          data={data}
          columns={columns}
          options={options}
      > </MUIDataTable>
       {/* </MuiThemeProvider> */}
      </Grid>
      </React.Fragment>);
      }

      componentWillUnmount() {
        this._isMounted = false;
      }
  
      componentDidMount(){
          this._isMounted = true;
          dataArray = [];
          this.getContracts();
        }

       getContracts = async _=> {
        var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
        var token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);
        var output = await fetch('https://abintus-app-api-linux.azurewebsites.net/users-info-list'
        ,{
          method: 'GET',
          headers: {
              'token': token
          }}
        );
        var userInfo = await output.json();
        fetch('https://abintus-app-api-linux.azurewebsites.net/contracts/expert-assessment'
        ,{
          method: 'GET',
          headers: {
              'token': token
          }}
          )
        .then(response => response.json())
        .then(response => {          
          response.forEach((element => {
            var input = [];
            userInfo.forEach(info => {
                if(info[0] === element[1])
                {
                  input = info;
                }
            });
            var reviewStatus = "Completed";
            if(element[4] === true)
            {
              reviewStatus = "Completed";
            }
            else if(element[6] === false)
            {
              reviewStatus =  "Pending For Payment";
            }
            else if(element[2] !== "" && element[3] !== "")
            {
              reviewStatus =  "Under Review";
            }
            else if(element[2] !== "")
            {
              reviewStatus =  "Pending for Contract";
            }
            else
            {
              reviewStatus =  "Pending for NDA";
            }            
          dataArray.push([element[0],element[1],input[1],input[2],element[8].substring(0,10),element[2],element[3],(element[6])?"Yes":"No",element[7], element[9] ? <a href={element[9]} target="_blank"><StyledButton>View Analysis</StyledButton></a> : <UploadAnalysis analysis={element[9]} contractId={element[0]} userId={element[1]} />,reviewStatus]);
         }));
          // dataArray.push([element[0],element[1],element[2],element[3],element[4],dateOfReview,(element[6])?"Yes":"No",element[7],element[8],element[9],element[10],element[11],element[12],element[13],element[14],element[15],startDate,element[17],(element[18])?"Yes":"No",element[19],
          // (element[20])?"Yes":"No",(element[21])?"Yes":"No",(element[22])?"Yes":"No",(element[23])?"Yes":"No",(element[24])?"Yes":"No",(element[25])?"Yes":"No",(element[26])?"Yes":"No",(element[27])?"Yes":"No",(element[28])?"Yes":"No",(element[29])?"Yes":"No",(element[30])?"Yes":"No",(element[31])?"Yes":"No",(element[32])?"Yes":"No",(element[33])?"Yes":"No",(element[34])?"Yes":"No",
          // (element[35])?"Yes":"No",(element[36])?"Yes":"No",(element[37])?"Yes":"No",(element[38])?"Yes":"No",(element[39])?"Yes":"No"]);
          // }));
          
          dataArray.sort(function(a, b) {
            const date1 = new Date(a[4]);
            const date2 = new Date(b[4]);

            return date2 - date1;
          })
          
          data = dataArray;
          this.setState({Loading: false});
        });

      } 
    }



function ExpertDashboardView() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Expert Dashboard"
    >
      <Container
        maxWidth={false}
        className={classes.container}
      >
        <Header />
        <Grid
          container
          spacing={3}
        >
          <ExpertTable>           
          </ExpertTable>
      </Grid>
      </Container>
    </Page>
  );
}

export default ExpertDashboardView;