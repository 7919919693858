import React from 'react';

function LogoDark(props) {
  return (
    <img
      alt="Logo"
      src="/static/AbintusGroupLogo.png"
      width="165px"
      
      {...props}
    />
  );
}

export default LogoDark;
