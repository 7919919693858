import React from 'react'
import {
    Box,
    Container,
    Grid,
    Typography,
    makeStyles,
    Button,
    withStyles,
    Hidden,
    useMediaQuery
  } from '@material-ui/core';
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom';
import "@fontsource/poppins";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100vw",
        backgroundColor: "#013D55",
        color: "white",
        padding: "100px 250px",
        marginTop: "50px",
        [theme.breakpoints.down('sm')]: {
            padding: "50px 20px",
            height: "35vh",
            marginTop: 0,
        }
    },
}));

function CTA() {
    const classes = useStyles();
    const mobile = useMediaQuery('(max-width: 600px)');

    return (
        <div className={classes.root}>            
            <Typography
            variant="h1"
            align="center"
            style={{fontSize: mobile ? "25px" : "40px", marginBottom: "50px"}}
            >
                Find out how your contract compares to industry standards
            </Typography>
            <StartButton
            href='/register'
            >
                Get Started
            </StartButton>
        </div>
    );
}

const StartButton = styled.a`
    padding: 15px 35px;
    font-size: 20px;
    background-color: #013D55;
    color: white;
    border: solid 3px white;
    transition: 0.2s ease-in;
    text-decoration: none;
    font-family: Poppins;

    &:hover {
        background-color: white;
        color: #013D55;
        cursor: pointer;
    }

`

export default CTA;