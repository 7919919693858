import React,{useEffect, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import "@fontsource/poppins";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Link,
  makeStyles,
  Hidden,
  IconButton,
  SvgIcon,
  Drawer,
  Box,
  useMediaQuery
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import { Menu as MenuIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
  },
  toolbar: {
    height: 80,
    width: "100vw",
    justifyContent: "space-between",
    padding: "0 100px 0 100px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    }
    
  },
  paper:{
    backgroundColor:"#14213D",
  },
  logo: {
    marginRight: theme.spacing(80),
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
    paddingTop: "15px",
  },
  icon:{
    width: 30,
    height: 30,
  },
  link: {
    fontWeight: 0,
    color:"#000000",
    fontFamily: "Poppins",
    fontWeight: "900",
    fontSize: "1rem",
    paddingTop: "15px",
    width: "auto",
    '& + &': {
      marginLeft: theme.spacing(10),
    },
    '&:hover':{
      color:"#75B8BF",
      borderTop: "4px solid #75B8BF",
      paddingTop: "15px",
    },
  },
  drawerLink: {    
    padding: "15px",
    fontWeight: 0,
    color:"#FFFFFF",
    fontFamily:"Poppins",
  },
}));

function TopBar({ className, ...rest }) {
  const classes = useStyles();
  const [loggedIn,SetLoggedIn] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  useEffect(() => { 
    var accessToken = localStorage.getItem('accessToken');
    if(accessToken !== null)
    {
      SetLoggedIn(true);
    }
    },[]);

   const OpenNavBar = () =>{
    setOpenDrawer(true);

   }

   const ClosenNavBar = () =>{
    setOpenDrawer(false);
    
   }
 
  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
        <Hidden mdUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={OpenNavBar}
          >
            <SvgIcon  className={classes.icon} fontSize="small">
              <MenuIcon   style={{color:"#025373"}} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        {/* <Box flexGrow={10} /> */}
        <Hidden smDown>        
        {loggedIn === false && <Link
          className={classes.link}
          color="textSecondary"          
          component={RouterLink}
          to="/login"
          underline="none"
          variant="body2"
        >
          Login
        </Link>}
        {loggedIn === false &&<Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/register"
          underline="none"
          variant="body2"
        >
          Register
        </Link>}
        <Link
          className={classes.link}
          color="textSecondary"
          target="_blank"
          href="https://www.abintus.consulting/contact?hsLang=en"
          underline="none"
          variant="body2"
        >
          Get In Touch
        </Link>
        {loggedIn  && <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/app/getting-started"
          underline="none"
          variant="body2"
        >
          Go To Portal
        </Link>}
        </Hidden>
        <Drawer anchor="left" open={openDrawer} onClose={ClosenNavBar} classes={{ paper: classes.paper }}>
          <Box mt={4}></Box>        
        {loggedIn === false && <Link
          className={classes.drawerLink}
          color="textSecondary"
          component={RouterLink}
          to="/login"
          underline="none"
          variant="body2"
          style={{fontFamily: "Poppins"}}
        >
          Login
        </Link>}
        {loggedIn === false &&<Link
          className={classes.drawerLink}
          color="textSecondary"
          component={RouterLink}
          to="/register"
          underline="none"
          variant="body2"
        >
          Register
        </Link>}
        <Link
          className={classes.drawerLink}
          color="textSecondary"
          target="_blank"
          href="https://www.abintus.consulting/contact?hsLang=en"
          underline="none"
          variant="body2"
        >
          Get In Touch
        </Link>
        {loggedIn  && <Link
          className={classes.drawerLink}
          color="textSecondary"
          component={RouterLink}
          to="/app/getting-started"
          underline="none"
          variant="body2"
        >
          Go To Portal
        </Link>}
          </Drawer>
        
      </Toolbar>
    </AppBar>
  );
}

const burgerMenu = styled.button`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 1rem;
  width: 1rem;
  background: transparent;
  padding: 0;
  z-index: 10;

  div {
    width: 1rem;
    height: 0.25rem;
    background: #025373;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
