import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
import Footer from './Footer';
import Carousel from './Carousel';
import Results from './Results';
import Benefits from './Benefits';
import Device from './Device';
import CTA from './CTA';
import './Home.css';
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#ffffff",
    overflowX: "hidden"
  }
}));

function HomeView() {
  const classes = useStyles();
  return (
    <Page
      className={classes.root}
      title="Abintus App"
    >
      <Hero />       
      <Carousel />     
      <Features />      
      <Results /> 
      <Benefits />
      <Carousel />     
      <CTA />
      <Footer />
    </Page>
  );
}

export default HomeView;
