import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles,
  CircularProgress,
  Typography
} from '@material-ui/core';
import "@fontsource/poppins"
var jwt = require('jsonwebtoken');

const useStyles = makeStyles(() => ({
  root: {},
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #A2BEE3 inset"
    }
  },
}));

function LoginForm({ className, onSubmitSuccess, emailRef, ...rest }) {
  const classes = useStyles();  
  const [displayLoading,setDisplayLoading] = React.useState("none");
  const [test, setTest] = useState('');


  return (
    <Formik
      initialValues={{
        email: '',
        Password: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
        .email('Must be a valid email').required('Email Address is required'),
        Password: Yup.string().min(7).max(255).required('Password is required')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {               
        try {
          setDisplayLoading("inline");                  
          var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
          var token = jwt.sign({ username: values.email, password: values.Password, token: bearer }, process.env.REACT_APP_ReadToken);
          fetch('https://abintus-app-api-linux.azurewebsites.net/login'
          ,{
            method: 'GET',            
            headers: {
            'token': token
          }}
          )
          .then(response => response.json())
          .then(response => {
            if (response.length !== 0) {              
              onSubmitSuccess(values.email, values.Password, response[response.length - 1]);                                                                           
            }
            else 
            {
              setDisplayLoading("none")
              setErrors({ submit: "Wrong Email or Password" })
              setSubmitting(false);
              setStatus({ success: false });              
            }
          });
        } catch (error) {    
          console.log(error);      
          setDisplayLoading("none");
          const message = (error.response && error.response.data.message) || "Wrong Email or Password. Please Try Again";          
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Box display={displayLoading} > <CircularProgress color="secondary" /></Box>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            inputProps={{ className: classes.input }}
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            // onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            ref={emailRef}
            // variant="outlined"
          />
          <TextField
            error={Boolean(touched.Password && errors.Password)}
            fullWidth
            inputProps={{ className: classes.input }}
            helperText={touched.Password && errors.Password}
            label="Password"
            margin="normal"
            name="Password"
            // onBlur={handleBlur}
            onChange={handleChange}
            type="Password"
            value={values.Password}
            // variant="outlined"
          />
          <Typography variant="body1">{test}</Typography>
          <Box mt={2}>
            <LogInButton
              style={{fontFamily: "Poppins"}}
            >
              Log In
            </LogInButton>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}

const LogInButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem;
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 30px;
  transition: 0.2s;

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
  }
`

LoginForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};

LoginForm.defaultProps = {
  onSubmitSuccess: () => {}
};

export default LoginForm;
