import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Hidden } from '@material-ui/core';
import NavBar from './NavBar';
import NavBarAdmin from './NavBarAdmin';
import TopBar from './TopBar';
var jwt = require('jsonwebtoken');

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    display: 'flex',    
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: "50px",
    },
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    // paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  var accessToken = localStorage.getItem('accessToken');
  var admin = false;
  if(accessToken !== null)
  {
    var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);

    if(validToken.admin === true)
    {
      admin = true;
    }
  }

  return (
    <div className={classes.root}>
      <Hidden only={['lg', 'xl']}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      </Hidden>
      { admin === false ? <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />: <NavBarAdmin
      onMobileClose={() => setMobileNavOpen(false)}
      openMobile={isMobileNavOpen}
    />
      }
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
