import React from 'react';
import { Card,CardContent,Grid,makeStyles,TextField,
Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Box,
InputLabel,Select,MenuItem,FormControl,Link
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';
import { logout } from 'src/actions/accountActions';
import { useHistory } from 'react-router';
import styled from 'styled-components';

var jwt = require('jsonwebtoken');
const countryList = require('country-list');
const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
      paddingTop: "24px",
      paddingBottom: "24px"
    }
  },
  card: {
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    boxShadow: "none"
  },
  select: {
    backgroundColor: "white"
  }
}));

export default function PersonalDetails({ firstName,lastName,location,userId,mobileNumber,email,className, ...rest }) {
  const [editDetailsErrorTitle,setEditDetailsErrorTitle] = React.useState("Success");
  const [editDetailsError,setEditDetailsError] = React.useState("Your personal details have been updated");
  const [open, setOpen] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(false);
  const [deleteAccount, setDeleteAccount] = React.useState(false);
  // const dispatch = useDispatch();
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const handleDeleteClose = () => {
    setDeleteAccount(false);
  };

  const activateDeleteAccountDialog = () => {
    setDeleteAccount(true);
  };

  const handleDelete = async () => {
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ UserId:userId, Email:email, token: bearer }, process.env.REACT_APP_ReadToken);
    await fetch('https://abintus-app-api-linux.azurewebsites.net/user/delete/'
    ,{
      method: 'POST',
      headers: {
          'token': token
      }}
      ).then(response => response.text().then(response => {       
       if(response === "true")
       {
        dispatch(logout());
        localStorage.clear();
        history.push('/');
       }
      }))
  };

  const classes = useStyles();
  return (
      <Grid container spacing={3}
      direction="column"
      alignItems="center"
      justify="center"
      >
      <Card className={classes.card}>
        <CardContent style={{padding : "24px"}}>
        <Grid item  >
           <Formik           
              enableReinitialize={true}
      initialValues={{
        firstName: firstName,
        lastName: lastName,
        location:location,
        mobileNumber:mobileNumber,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required('First name is required'),
        lastName: Yup.string().max(255).required('Last name is required'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
          setDisableButton(true);
          var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
          var token = jwt.sign({ Id:userId, FirstName:values.firstName, LastName: values.lastName, Location: values.location, MobileNumber:values.mobileNumber, token: bearer }, process.env.REACT_APP_ReadToken);
          await fetch('https://abintus-app-api-linux.azurewebsites.net/user/edit/personal-details'
          ,{
            method: 'POST',
            headers: {
                'token': token
            }}
            )
          .then(response => response.text()).then(response => {
            // Make sure to delete in express too
            if(response === "true")
            {
              var verifiedValue = true;
              var adminValue = false;
              var activeCampaignIdValue = "";

              jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) 
              {
                verifiedValue = decoded.verified;
                adminValue = decoded.admin;
                activeCampaignIdValue = decoded.activeCampaignId;
              });
              token = jwt.sign({ username: email, id: userId, verified: verifiedValue, admin:adminValue, activeCampaignId:activeCampaignIdValue, firstName:values.firstName, lastName: values.lastName}, process.env.REACT_APP_PrivateKey,{ expiresIn: '12h' });
              localStorage.setItem('accessToken', token);
              setOpen(true);
              token = jwt.sign({ UserId:userId, EventId:9, token: bearer }, process.env.REACT_APP_ReadToken);
              fetch('https://abintus-app-api-linux.azurewebsites.net/user/event'
              ,{
                method: 'POST',
                headers: {
                    'token': token
                }}
                );
            }
            else
            {
              setEditDetailsErrorTitle("Error");
              setEditDetailsError("There was an issue when saving your changes. Please try again.")
              setOpen(true);
            }
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => (
        <form
        onSubmit={handleSubmit}        
      >
          <TextField
            autoComplete="off"
            error={Boolean(touched.firstName && errors.firstName)}
            fullWidth
            helperText={touched.firstName && errors.firstName}
            label="First Name"
            margin="normal"
            name="firstName"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.firstName}   
            variant="outlined"         
          />
          <TextField
            autoComplete="off"
            error={Boolean(touched.lastName && errors.lastName)}
            fullWidth
            helperText={touched.lastName && errors.lastName}
            label="Last Name"
            margin="normal"
            name="lastName"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.lastName}    
            variant="outlined"        
          />
           <TextField
            fullWidth
            disabled
            label="Email"
            margin="normal"
            name="email"
            type="text"
            value={email}     
            variant="outlined"
            InputProps={{
              style: {
                color: '#6A6A6A'
              }
            }}                   
          />
           <Box mt={1}></Box>
           <FormControl style={{width:"100%"}} 
            variant="outlined" className={classes.formControl}>
            <InputLabel id="LocationLabel">Location</InputLabel>
              <Select
                className={classes.select}
                labelId="LocationLabel"
                label="Location"
                id="Location"
                name ="Location"
                value={values.location}
                onChange={e => {
                  setFieldValue("location",e.target.value);
                }}
              >
                <MenuItem value=""></MenuItem>
                {countryList.getNames().sort().map((value, index) =>
                {
                  return <MenuItem key={index} value={value}>{value}</MenuItem>
                })}
              </Select>
          </FormControl>           
          <Box mt={2}></Box>
          {/* <MuiPhoneNumber 
            style={{width:"100%", paddingBottom: "10px"}} 
            variant="outlined" 
            defaultCountry={'gb'} 
            name="mobileNumber" 
            value={values.mobileNumber}  
            onChange={value => {
              setFieldValue("mobileNumber",value);
            }} 
          /> */}
           <Link
              variant="body2"
              color="textSecondary"
              onClick={activateDeleteAccountDialog}              
            >
              Remove Account
            </Link>
          <Box mt={2}>
            <SaveChangesButton
              color="secondary"
              disabled={disableButton}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Save Changes
            </SaveChangesButton>
          </Box>
          <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="DialogTitle">
          <DialogTitle id="DialogTitle">{editDetailsErrorTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{editDetailsError}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleClose}>Close</Button>
          </DialogActions>
          </Dialog>
          <Dialog
          open={deleteAccount}
          onClose={handleClose}
          aria-labelledby="DialogTitle">
          <DialogTitle id="Delete">Delete Account</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you would like to delete your account? 
             (Your account is going to be deactivated after 30 day)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleDelete}>Yes</Button>
            <Button variant="contained" color="secondary" onClick={handleDeleteClose}>No</Button>
          </DialogActions>
          </Dialog>
        </form>
        
      )}
    </Formik>
                      
        </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

const SaveChangesButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem;
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 30px;
  transition: 0.2s;
  font-family: Poppins;

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
  }
`

PersonalDetails.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  location: PropTypes.string,
  userId: PropTypes.any,
  mobileNumber: PropTypes.string,
  email: PropTypes.string,
  className: PropTypes.string
};




