import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'space-between',
    backgroundColor: "rgba(255, 230, 127, 0.4)",
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    [theme.breakpoints.down('md')]: {      
      alignItems: "center",
      width: "97%",
      margin: "0 auto",
    }
  },
  headerContainer: {
    [theme.breakpoints.down('md')]: {
      width: "100%",
    }
  },
  dataContainer: {
    [theme.breakpoints.down('md')]: {
      margin: "0 auto",
    }
  }
}));

function CompletedContractScore({ className, ...rest }) {
  const classes = useStyles();
  const data = {
    global: parseInt(rest.globalscore) + "%",
    grade: rest.grade,
    abintusScore: rest.score,
  };

    return (
      <div 
        className={clsx(classes.root, className)}        
        {...rest}
      >
        <Grid
          container
          spacing={3}
        >
          <Box flexGrow={1} mt={2} className={classes.headerContainer}>
          <Typography
            variant="h2"
            gutterBottom            
            color="textSecondary"
            style={{textAlign:"center",fontWeight:"bold", fontSize:"20px"}}        
          >
             Benchmarks
          </Typography>
          </Box>
          <Grid
            item
            md={3}
            lg={3}
            xl={3}
            className={classes.dataContainer}
          >
            <Box
          >
            <Typography
              variant="h2"
              color="textPrimary"
              style={{textAlign:"center", fontWeight: "bold", marginBottom: "8px" }}
            >
              {data.global}
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              style={{textAlign:"center", fontSize:"12px"}}
            >
                Total Global Benchmark
            </Typography>
          </Box>
          </Grid>
          <Grid
            item
            md={3}
            lg={3}
            xl={3}
            className={classes.dataContainer}
          >
             <Box
          >
            <Typography
              variant="h2"
              color="textPrimary"
              style={{textAlign:"center", fontWeight:"bold", marginBottom: "8px"}}
            >
              {data.grade}
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              style={{textAlign:"center", fontSize:"12px"}}
            >
            Global Average Contract Grade
            </Typography>
          </Box>
            </Grid>
            <Grid
            item
            md={3}
            lg={3}
            xl={3}
            className={classes.dataContainer}
          >
             <Box
          >
            <Typography
              variant="h2"
              color="textPrimary"
              style={{textAlign:"center",fontWeight:"bold", marginBottom: "5px"}}
            >
              {data.abintusScore}%
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              style={{textAlign:"center", fontSize:"12px"}}
            >
              Abintus Clients Average Score
            </Typography>
          </Box>
            </Grid>
        </Grid>

      </div>
    );
}

CompletedContractScore.propTypes = {
  className: PropTypes.string
};

export default CompletedContractScore;
