/* eslint-disable */
import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
var jwt = require('jsonwebtoken');

function AuthGuardAdmin({ children }) {
    var accessToken = localStorage.getItem('accessToken');
    if(accessToken === null)
    {
      return <Navigate to="/login" replace/>
    }

    var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);
    if(validToken.admin === false || validToken.admin === "")
    {
      return <Navigate to="/app/getting-started" replace/>
    }

  return children;
}

AuthGuardAdmin.propTypes = {
  children: PropTypes.any
};

export default AuthGuardAdmin;
