import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Globe from '@material-ui/icons/Public';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  }
}));

function GlobalBenchmark({ className, ...rest }) {
  const classes = useStyles();
  const data = {
    value: parseInt(rest.globalscore) + "%",
  };
    return (
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box flexGrow={1}>
          <Typography
            component="h3"
            gutterBottom
            variant="overline"
            color="textSecondary"
          >
           TOTAL GLOBAL BENCHMARK
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
          >
            <Typography
              variant="h3"
              color="textPrimary"
            >
              {data.value}
            </Typography>
          </Box>
        </Box>
        <Avatar className={classes.avatar}>
          <Globe style={{height: 36, width: 36}}/>
        </Avatar>
      </Card>
    );
}

GlobalBenchmark.propTypes = {
  className: PropTypes.string
};

export default GlobalBenchmark;
