
import axios from 'axios';

var jwt = require('jsonwebtoken');
var bcrypt = require('bcryptjs');
class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }
    var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey,function(err, decoded) {
      if(err !== null)
      {
        return false;
      }
      else 
      {
        return true;
      }
    });

    if (validToken) {
      this.setSession(accessToken);      
    } else {
      this.setSession(null);
    }
  }

resetPassword = (email) => new Promise((resolve,reject) => {
      var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
      var token = jwt.sign({ Email: email, token: bearer }, process.env.REACT_APP_ReadToken);
      var code = "";
       fetch('https://abintus-app-api-linux.azurewebsites.net/user/check-reset-code'
      ,{
        method: 'GET',
        headers: {
            'token': token
        }}
        )
      .then(respon => respon.text()).then(respon => {
        if(respon.length === 8)
        {
          code = respon;
          token = jwt.sign({ Email: email,Code: code, token: bearer }, process.env.REACT_APP_ReadToken);
          fetch('https://abintus-app-api-linux.azurewebsites.net/user/reset-password'
          ,{
            method: 'POST',
            headers: {
                'token': token
            }}
            )
          .then(response => response.text())
          .then(response => {
             if(response.length === 8 )
             {
                  resolve("works");
             }
             else
             {
              reject("error");
             }
          });
        }
        else if(respon ==="Expired")
        {
            fetch('https://abintus-app-api-linux.azurewebsites.net/user/delete-reset-code'
            ,{
              method: 'POST',
              headers: {
                  'token': token
              }}
              )
            .then(resp => respon.text()).then(resp => {
          token = jwt.sign({ Email: email,Code: code, token: bearer }, process.env.REACT_APP_ReadToken);
          fetch('https://abintus-app-api-linux.azurewebsites.net/user/reset-password'
          ,{
            method: 'POST',
            headers: {
                'token': token
            }}
            )
          .then(response => response.text())
          .then(response => {
             if(response.length === 8 && code === "")
             {
                  token = jwt.sign({ Email: email, Code: response, token: bearer }, process.env.REACT_APP_ReadToken);
                  fetch('https://abintus-app-api-linux.azurewebsites.net/user/store-reset-code'
              ,{
                method: 'POST',
                headers: {
                    'token': token
                }}
                )
              .then(res => res.text())
              .then(res=> {
                if(res === "true")
                {
                  resolve("works");
                }
                else
                {
                  reject(res);
                }
              });
             }
             else if(code !== "")
             {
              resolve("works");
             }
             else
             {
              reject("error");
             }
          });
            });
        }
        else
        {
          token = jwt.sign({ Email: email,Code: code, token: bearer }, process.env.REACT_APP_ReadToken);
          fetch('https://abintus-app-api-linux.azurewebsites.net/user/reset-password'
          ,{
            method: 'POST',
            headers: {
                'token': token
            }}
            )
          .then(response => response.text())
          .then(response => {
             if(response.length === 8 && code === "")
             {
                  token = jwt.sign({ Email: email, Code: response, token: bearer }, process.env.REACT_APP_ReadToken);
                  fetch('https://abintus-app-api-linux.azurewebsites.net/user/store-reset-code'
              ,{
                method: 'POST',
                headers: {
                    'token': token
                }}
                )
              .then(res => res.text())
              .then(res=> {
                if(res === "true")
                {
                  resolve("works");
                }
                else
                {
                  reject(res);
                }
              });
             }
             else if(code !== "")
             {
              resolve("works");
             }
             else
             {
              reject("error");
             }
          });
        }
          
      });
  })

confirmResetPassword = (code) => new Promise((resolve,reject) => {
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ Code: code, token: bearer }, process.env.REACT_APP_ReadToken);
    fetch('https://abintus-app-api-linux.azurewebsites.net/user/confirm-reset-code'
    ,{
      method: 'GET',
      headers: {
          'token': token
      }}
      )
    .then(response => response.text())
    .then(response => {
      if(response === "true")
      {
        resolve("works");
      }
      else if(response === "false")
      {
        reject("Wrong Code");
      }
      else
      {
        reject("Code Expired");  
      }
       
    });
})
changePassword = (password,email,code) => new Promise((resolve,reject) => {
  var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
  var token = jwt.sign({ Code: code,Email:email, token: bearer }, process.env.REACT_APP_ReadToken);
  fetch('https://abintus-app-api-linux.azurewebsites.net/user/confirm-reset-code-and-email'
  ,{
    method: 'GET',
    headers: {
        'token': token
    }}
    )
  .then(response => response.text())
  .then(response => {
    if(response === "true")
    {
      var salt = bcrypt.genSaltSync(10);
      var hash = bcrypt.hashSync(password, salt);
      token = jwt.sign({ Password: hash,Email: email, token: bearer }, process.env.REACT_APP_ReadToken);
      fetch('https://abintus-app-api-linux.azurewebsites.net/user/edit/resetpassword'
      ,{
        method: 'POST',
        headers: {
            'token': token
        }}
        )
      .then(res => res.text())
      .then(res => {
        if(res === "true")
        {
          jwt.sign({ Email: email, token: bearer }, process.env.REACT_APP_ReadToken);
          fetch('https://abintus-app-api-linux.azurewebsites.net/user/delete-reset-code'
            ,{
              method: 'POST',
              headers: {
                  'token': token
              }}
              );
          resolve("works");
        }
        else
        {
          reject("Issue");
        }
      });
    }
    else if(response === "false")
    {
      reject("Wrong Code");
    }
    else
    {
      reject("Code Expired");  
    }
     
  });
})
loginWithEmailAndPassword = (email, password) => new  Promise(async(resolve, reject) => {
      var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
      var token = jwt.sign({ Email: email, token: bearer }, process.env.REACT_APP_ReadToken);
      var userId = 0;
      var actId = 0;
      // var output = await fetch('https://abintus-app-api-linux.azurewebsites.net/user-id'
      // // var output = await fetch('https://abintus-app-api-linux.azurewebsites.net/active-campaign/user-id'
      // ,{
      //   method: 'GET',
      //   headers: {
      //       'token': token
      //   }}
      // );
      // actId = await output.text();
      token = jwt.sign({ username: email, password: password, token: bearer }, process.env.REACT_APP_ReadToken);
      fetch('https://abintus-app-api-linux.azurewebsites.net/login'
      ,{
        method: 'GET',
        headers: {
            'token': token
        }}
        )
      .then(response => 
        response.json()      
      )
      .then(response => {
          //this.isAuthenticated = true;          
            if(response.length !== 0)
            {              
              userId = response[0];
              token = jwt.sign({ UserId: userId, token: bearer }, process.env.REACT_APP_ReadToken);
              fetch('https://abintus-app-api-linux.azurewebsites.net/user/info'
              ,{
                method: 'GET',
                headers: {
                    'token': token
                }}
                )
              .then(r => r.json()).then(r => {
                var token = jwt.sign({ username: email, id: userId, verified: response[2], admin:response[1],activeCampaignId:actId,firstName:r[0][0], lastName:r[0][1]}, process.env.REACT_APP_PrivateKey,{ expiresIn: '12h' });
                this.setSession(token);                
                token = jwt.sign({ UserId:userId, EventId:2, token: bearer }, process.env.REACT_APP_ReadToken);
                var dataLayer = window.dataLayer = window.dataLayer || []; dataLayer.push({ 'event': 'loggedIn' });
                resolve("UserData");
              })
            }
            else
            {              
              reject("Wrong Email or Password.Please Try Again");
            }
          });
  })
sendVerificiationEmail = (email) => new Promise(async(resolve, reject)=>{
  var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
  var token = jwt.sign({ Email: email, token: bearer }, process.env.REACT_APP_ReadToken);
  await fetch('https://abintus-app-api-linux.azurewebsites.net/user/check-verification-code'
  ,{
    method: 'POST',
    headers: {
        'token': token
    }}
    )
  .then(res => res.text()).then(async data => {    
    var code = "";
    if (data.length === 8)
    {
      code = data;
      resolve("works");
    }
    else {
      console.log("hello")
      var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
      token = jwt.sign({ Email: email, Code: code, token: bearer }, process.env.REACT_APP_ReadToken);
      await fetch('https://abintus-app-api-linux.azurewebsites.net/user/send-verification-code'
      ,{
        method: 'POST',
        headers: {
            'token': token
        }}
      )      
      .catch(err => {
        console.log(err)
      });
    }
  })
  .catch(err => {
    console.log(err);
  });
 
})
confirmVerificationCode = (email,pw,code,onSubmitSuccess) => new Promise((resolve,reject) => {
  var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
  var token = jwt.sign({ Email: email, Code: code, token: bearer }, process.env.REACT_APP_ReadToken);  
  fetch('https://abintus-app-api-linux.azurewebsites.net/user/confirm-verification-code'
  ,{
    method: 'GET',
    headers: {
        'token': token
    }}
    )
  .then(response => response.text())
  .then(async (response) => {
    if(response === "true")
    {      
      token = jwt.sign({ Email:email, token: bearer }, process.env.REACT_APP_ReadToken);
      await this.loginWithEmailAndPassword(email, pw);
      fetch('https://abintus-app-api-linux.azurewebsites.net/user/verified'
      ,{
        method: 'POST',
        headers: {
            'token': token
        }}
        ).then(r => r.text()).then(r=>{                    
          var validToken = jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey);
          console.log(validToken);          
          fetch('https://abintus-app-api-linux.azurewebsites.net/user/delete-verification-code'
        ,{
          method: 'POST',
          headers: {
              'token': token
          }}
          ).then(res => res.text()).then(res => {
            resolve("works")
            onSubmitSuccess();
          })
          
        });
    }
    else
    {
      reject("Wrong code")
    } 
  });
})

  loginInWithToken = () => new Promise((resolve, reject) => {
    resolve("UserData");
    // axios.get('/api/account/me')
    //   .then((response) => {
    //     if (response.data.user) {
    //       resolve(response.data.user);
    //     } else {
    //       reject(response.data.error);
    //     }
    //   })
    //   .catch((error) => {
    //     reject(error);
    //   });
  })

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('currentTab')
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
