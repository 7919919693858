import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'space-between',
    // textAlign: 'center'
  }
}));

const StyledCard = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  },
}))(Card);

function ContractGrade({ className, ...rest }) {
  const classes = useStyles();
  const data = {
    value: rest.grade,

  };

  if(rest.print === "true")
  {
    return (
      <StyledCard 
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box flexGrow={1}>
          <Typography
            component="h3"
            gutterBottom
            variant="overline"
          >
            Average Contract Grade
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
           
          >
            <Typography
              variant="h3"
              
            >
              {data.value}
            </Typography>
          </Box>
        </Box>
      </StyledCard>
    );
  }
  else
  {
    return (
      <Card 
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box flexGrow={1}>
          <Typography
            component="h3"
            gutterBottom
            variant="overline"
            color="textSecondary"
          >
             Average Contract Grade
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
           
          >
            <Typography
              variant="h3"
              color="textPrimary"
              
            >
              {data.value}
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  }
}

ContractGrade.propTypes = {
  className: PropTypes.string
};

export default ContractGrade;
