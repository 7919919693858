import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  Box,
  Container,
  Card,
  Typography,
  makeStyles
} from '@material-ui/core';
import EmailIcon from "../Media/email-icon.png"
import PhoneIcon from "../Media/phone-icon.png"
import Page from 'src/components/Page';
import LogoDark from 'src/components/Logo';
import { sendVerificiationEmail } from 'src/actions/accountActions';

const useStyles = makeStyles((theme) => ({
    root: {
      justifyContent: 'center',
      backgroundColor: "#F2F2F2",
      display: 'flex',
      height: '100%',
      minHeight: '100%',
      flexDirection: 'column',
      paddingBottom: 80,
      paddingTop: 80
    },
    backButton: {
      marginLeft: theme.spacing(2)
    },
    card: {
      overflow: 'visible',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      boxShadow: 'none',
      borderRadius: '30px',
      padding: "20px 50px 30px 50px",
      '& > *': {
        flexGrow: 1,
        // flexBasis: '50%',
        // width: '50%'
      }
    },
    buttonContainer:  {
      display: "flex",
      width: "100%",
      justifyContent: "space-around"
    },
    selection: {
      border: "solid 2px #2D3042", 
      borderRadius: "7px",
      height: "auto",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingRight: "20px",
      paddingLeft: "20px", 
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      transition: "0.3s",

      '&:hover': {
        cursor: "pointer",
        transform: "scale(1.02)",        
      }
    },
    otherOptions: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px"
    },
    link: {
      textDecoration: "none"
    }
    // content: {
    //   padding: theme.spacing(8, 4, 3, 4)
    // },
  }));

function ChooseAuthView({ sendCode, sendSMS, changeView, email }) {
  const classes = useStyles(); 

  function test(authMethod) {
    changeView();
    
    if (authMethod == "mail") {      
      sendCode();
    }
    else if (authMethod == "phone") {    
      sendSMS();
    }
  }
  

  return (
    <Page
      className={classes.root}
      title="Email Verification">
            <Container maxWidth="sm">
              <Box
                mb={8}
                display="flex"
                alignItems="center"
                style={{margin: "0"}}
              >
                <RouterLink to="/">
                  <LogoDark />
                </RouterLink>
              </Box>
              <Card className={classes.card}>
                <Typography variant="h1">Two Factor Authentication</Typography>
                <Typography variant="body1" style={{marginBottom: "20px"}}>We need to verify that it's really you before you log in</Typography>
                <Box className={classes.buttonContainer}>                  
                  <div className={classes.selection} onClick={() => test("mail")}>
                    {/* <img src={EmailIcon} style={{height: "75px", width: "75px"}}/> */}
                    <Typography variant="body1">Send email to <strong>{email}</strong></Typography>
                    {/* <div style={{position: "absolute", bottom: 1, left: 5}}>
                      <Typography variant="h3" style={{fontWeight: "bold"}}>Email</Typography>
                      <Typography variant="body1" style={{fontSize: "13px"}}>{email}</Typography>
                    </div> */}
                  </div>                     
                  {/* <div>
                    <div className={classes.selection} onClick={() => test("phone")}>
                      <img src={PhoneIcon} style={{height: "85px", width: "85px"}}/>
                      <div style={{position: "absolute", bottom: 1, left: 5}}>
                        <Typography variant="h3" style={{fontWeight: "bold"}}>Text</Typography>
                        <Typography variant="body1" style={{fontSize: "13px"}}></Typography>
                      </div>
                    </div>
                  </div> */}
                </Box>
              </Card>
            </Container>
        </Page>
    );
}

export default ChooseAuthView;