/* eslint-disable */
const jwt = require('jsonwebtoken');
import React, { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Box,
  Container,
  Card,
  CardContent,
  Divider,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import Page from 'src/components/Page';
import LogoDark from 'src/components/Logo';
import LoginForm from './LoginForm';
import ChooseAuthView from '../ChooseAuthView';
import ConfirmAuthView from '../ConfirmAuthView';
import { sendVerificiationEmail } from 'src/actions/accountActions';
import authService from 'src/services/authService';
import { confirmVerificationCode } from 'src/actions/accountActions';
import { login } from 'src/actions/accountActions';
import { verify } from 'jsonwebtoken';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: '#F2F2F2',
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80
  },
  backButton: {
    marginLeft: theme.spacing(2)
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    boxShadow: 'none',
    borderRadius: '30px',
    padding: "0 50px 0 50px",
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  otherOptions: {
    display: 'flex',
    justifyContent: "space-between",
    padding: "10px"
  }
  // content: {
  //   padding: theme.spacing(8, 4, 3, 4)
  // },
}));



function LoginView() {
  const [display, setDisplay] = useState('login');
  const [authMethod, setAuthMethod] = useState('');
  var navigate = useNavigate();
  const [creds, setCreds] = useState({
    email: '',
    pw: '',
    mobile: '',
  });
  const emailRef = useRef();

  const classes = useStyles(); 
  const dispatch = useDispatch();

  const handleSubmitSuccess = (userEmail, password, number) => {    
    setDisplay('confirmAuth');
    setAuthMethod("mail")    
    authService.sendVerificiationEmail(userEmail);
    setCreds({
      email: userEmail,
      pw: password,
      mobile: number,
    })
  };

  const changeView = () => {
    setDisplay('confirmAuth')    
  }

  const verifyCode = (code) => {   
    if (authMethod == "mail") {      
      return authService.confirmVerificationCode(creds.email, creds.pw, code, onSubmitSuccess)
      .catch(err => {
        if (err == "Wrong code") {
          throw new Error("Wrong code")
        }
      })
    }    
  }

  function onSubmitSuccess() {  
    navigate("/app/getting-started");
  }


  return (
    <>
    {
      display == 'login' && 
      <Page
        className={classes.root}
        title="Login"
        >
          <Container maxWidth="sm">
            <Box
              mb={8}
              display="flex"
              alignItems="center"
              style={{margin: "0"}}
            >
              <RouterLink to="/">
                <LogoDark />
              </RouterLink>
              {/* <Button
                component={RouterLink}
                to="/"
                className={classes.backButton}
              >
                Back to home
              </Button>  */}
            </Box>
            <Card className={classes.card}>
              <InnerCard className={classes.content}>            
                <Box mt={3}>
                  <LoginForm onSubmitSuccess={handleSubmitSuccess} emailRef={emailRef} />
                </Box>            
                <Box my={2}>
                  <Divider />
                </Box>
              </InnerCard>
            </Card>
            <Box className={classes.otherOptions}>
              <Link
                  component={RouterLink}
                  to="/register"
                  variant="body2"
                  color="textSecondary"
                >
                  Register
                </Link>
                <Link
                  component={RouterLink}
                  to="/reset-password"
                  variant="body2"
                  color="textSecondary"
                >
                  Forgot Your Password?
                </Link>
            </Box>
          </Container>
        </Page>
      }    
      {/* { display == 'chooseAuth' && <ChooseAuthView sendCode={sendEmail} changeView={changeView} email={creds.email} /> } */}
      { display == 'confirmAuth' && <ConfirmAuthView verify={verifyCode} email={creds.email} /> }
    </>
  );
}

const InnerCard = styled.div`
  margin: 0 0 0 0;
`

export default LoginView;
