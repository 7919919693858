import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';


const useStyles = makeStyles((theme) => ({
  root: {     
    backgroundColor: "#FFFFFF",
    color: theme.palette.text.primary,     
    boxShadow: 'none',
    position: 'fixed',   
    marginBottom: "100px"    
  },
  toolbar: {    
    minHeight: 64,
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    height: "49.2px",
    width: "120px"
  },
  icon:{
    width: 30,
    height: 30,
  },
}));

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}) {
  const classes = useStyles();

  return (
    <AppBar
      className={classes.root}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>                
          <RouterLink to="/">
            <img src="https://abintus-app-api-linux.azurewebsites.net/images/AbintusLogoLight.png" alt="logo" className={classes.logo}/>
          </RouterLink>                  
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon  className={classes.icon} fontSize="small">
              <MenuIcon style={{color:"#14213D"}} />
            </SvgIcon>
          </IconButton>                
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
