/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Link,
  Typography,
  makeStyles, 
  CardContent,
  useMediaQuery
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

function YourResults({
  className,
  onBack,
  onNext,
  restart,
  onComplete,
  setActiveStep,
  ...rest
}) {
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width: 900px)')
 
  return (
    <Formik
      initialValues={{
      }}
      validationSchema={Yup.object().shape({
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          // Do API call to store step data in server session
          // It is important to have it on server to be able to reuse it if user
          // decides to continue later.
          setStatus({ success: true });
          setSubmitting(false);
          if (onComplete) {
            onComplete();
          }
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <CardContent>
            {/* <Box mt={4} justifyContent="center" align="center">
            <img src={IntroPicture} alt="AgencyContract" style={{height: '80%',
               width: '80%'}}></img>
            </Box> */}
          <Box mt={2}>
            <Box
              display="flex"
              alignItems="center"
            >
               <Typography
            variant="h5"
            color="textPrimary"
            
          >
          Thank you for uploading your media agency contract.  
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
               <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
          >
           Within the next 48 hours, you will receive an email notification that your report is available in the ‘Your Results’ tab for you to view, download and print. You will also be able to schedule your call with one of our experts at a date and time of your choosing using our automated call scheduling platform.  
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
               <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
          >
           Submitted the wrong contract? Just go back one step and submit the right file.
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
            <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
          >
          Thank you,  
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
            <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
          >
          The Abintus Support Team
          </Typography>
            </Box>       
            <Box mt={2} style={{display: "flex", justifyContent: "space-between"}}>
              <BackButton style={{ width: mobile && "120px" }} onClick={() => setActiveStep(activeStep => activeStep - 1)}>Back</BackButton>            
              <RedirectButton style={{ width: mobile && "120px", fontSize: mobile && "0.825rem", padding: mobile && '3px' }} onClick={() => restart()}>Back to Dashboard</RedirectButton>
            </Box>     
          </Box>
          </CardContent>
        </form>
      )}
    </Formik>

  );
}

YourResults.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};

const RedirectButton = styled.button`
  background-color: #F2C12E;
  color: #000000;
  font-size: 1rem; 
  border: none;
  transition: 0.2s;
  font-family: Poppins;
  width: 200px;  
  padding: 10px;
  height: 45px;
  margin-top: 20px;

  &:hover {
    opacity: 0.8;
    transition: 0.2s;
    cursor: pointer;     

    >* {
      color: #F2C230;
    }
  }
`

const BackButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem; 
  border: none;
  padding: 10px;
  transition: 0.2s;
  font-family: Poppins;
  width: 200px;  
  height: 45px; 
  margin-top: 20px; 

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
    color: #F2C230;   

    >* {
      color: #F2C230;
    }
  }
`

export default YourResults;
