import React from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import { resetPassword } from 'src/actions/accountActions';
import styled from 'styled-components';
import "@fontsource/poppins"
const useStyles = makeStyles(() => ({
  root: {},
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #A2BEE3 inset"
    }
  }, 
}));

function ConfirmAccountForm({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
        .email('Must be a valid email'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
            
          await dispatch(resetPassword(values.email));
          var arr = [values.email];
          localStorage.setItem("Reset", JSON.stringify(arr));
          onSubmitSuccess();
        } catch (error) {
          console.log(error);
          const message = (error.response && error.response.data.message) || "This email account doesn't exist.";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            inputProps={{ className: classes.input }}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            autoFocus
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            // variant="outlined"
          />
          <Box mt={2}>
            <SendEmailButton
              style={{fontFamily: "Poppins"}}
            >
              Send Email
            </SendEmailButton>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}

const SendEmailButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem;
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 30px;
  transition: 0.2s;

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
  }
`
ConfirmAccountForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};

ConfirmAccountForm.defaultProps = {
  onSubmitSuccess: () => {}
};

export default ConfirmAccountForm;
