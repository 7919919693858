import React, { useState,useLayoutEffect, useEffect } from 'react';
import clsx from 'clsx';
import styled from 'styled-components'
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Hidden } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Paper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
  withStyles,
  colors,
  Button,
  CircularProgress,
  useMediaQuery
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  User as UserIcon,
  Briefcase as BriefcaseIcon,
  File as FileIcon,
  Award as AwardIcon
} from 'react-feather';
import LightBulbIcon from '@material-ui/icons/EmojiObjects';
import Page from 'src/components/Page';
import AboutYou from './AboutYou';
import Introduction from './Introduction';
import YourAgency from './YourAgency';
import ContractInformation from './ContractInformation';
import GaugeChart from 'react-gauge-chart'
import Academy from './Media/Academy.jpg'
import Expert from './Media/Premium.png'
import Telephone from './Media/telephone.jpg'
import Template from './Media/Template.jpg'
import { useHistory } from 'react-router';
import { PDFDocument, StandardFonts,rgb} from 'pdf-lib';
import ContractSelfAssessment from './ContractSelfAssessment.pdf'
//import FileSaver from 'file-saver';
var jwt = require('jsonwebtoken');
const steps = [
  {
    label: 'Introduction',
    icon: LightBulbIcon,
    show: true
  },
  {
    label: 'About Your Agency',
    icon: BriefcaseIcon,
    show: true
  },
  {
    label: 'About Your Contract',
    icon: FileIcon,
    show: true
  },
  {
    label: '1',
    icon: FileIcon,
    show: false
  },
  {
    label: '2',
    icon: FileIcon,
    show: false
  },
  {
    label: '3',
    icon: FileIcon,
    show: false
  },
  {
    label: '4',
    icon: FileIcon,
    show: false
  },
  {
    label: '5',
    icon: FileIcon,
    show: false
  },
  {
    label: '6',
    icon: FileIcon,
    show: false
  },
  {
    label: '7',
    icon: FileIcon,
    show: false
  },
  {
    label: '8',
    icon: FileIcon,
    show: false
  },
  {
    label: '9',
    icon: FileIcon,
    show: false
  },
  {
    label: '10',
    icon: FileIcon,
    show: false
  },
  {
    label: '11',
    icon: FileIcon,
    show: false
  },
  {
    label: '12',
    icon: FileIcon,
    show: false
  },
  {
    label: '13',
    icon: FileIcon,
    show: false
  },
  {
    label: '14',
    icon: FileIcon,
    show: false
  },
  {
    label: '15',
    icon: FileIcon,
    show: false
  },
  {
    label: '16',
    icon: FileIcon,
    show: false
  },
  {
    label: '17',
    icon: FileIcon,
    show: false
  },
  {
    label: '18',
    icon: FileIcon,
    show: false
  },
  {
    label: '19',
    icon: FileIcon,
    show: false
  },
  {
    label: 'About You',
    icon: UserIcon,
    show: true
  },
  {
    label: 'Your Results',
    icon: AwardIcon,
    show: true
  },
];

const goodSections = ["Your contract is signed by both parties","Your contract has ‘Defined Terms and Definitions’","Your contract has the agreed ‘Services and Deliverables’ clearly described.","Your contract has clauses covering for ‘Detailed and Transparent Reporting’ ","Your agency is acting purely as an ‘Agent’",
"Your contract has strong ‘Authority’ clauses","Your contract has ‘Exclusive/Non-Exclusive’ clauses which are reciprocal ","Your contract covers the key agency people working on your account","Your agency remuneration has been calculated in a transparent manner","Your agency remuneration model includes a ‘PRF’ or ‘PRIP’ scheme ",
"Your ‘PRF’ scheme includes both a bonus and a malus mechanism","Your contract includes a methodology to calculate the agency bonus/malus","Your contract includes ‘Agency Commitments’ for all key media channels","Your contract has robust Audit Rights","Your contract covers the treatment of ‘Agency Volume Benefits’ (AVBs)"
,"Your contract covers the treatment of ‘Early Payment Discounts’ (EPD)","Your contract covers the treatment of ‘Unbilled Media’","Your contract has competitive payment terms","Your contract has standard ‘Termination’ clauses","Your contract has strong ‘Sub-Contracting’ clauses"];

const badSections = ["Your contract is not signed by both parties","Your contract is missing ‘Defined Terms and Definitions’","Your contract is missing ‘Services and Deliverables’ clearly described","Your contract is missing clauses to cover for ‘Detailed and Transparent Reporting’","Your agency is NOT acting purely as an ‘Agent’",
"Your contract is missing strong ‘Authority’ clauses","Your contract has ‘Exclusive/Non-Exclusive’ clauses which are NOT reciprocal","Your contract does NOT cover the key agency people working on your account","Your agency remuneration has NOT been calculated in a transparent manner","Your agency remuneration model is missing a ‘PRF” or ‘PRIP’ scheme",
"Your ‘PRF’ scheme is missing a malus mechanism","Your contract is missing a methodology to calculate the agency bonus/malus","Your contract is missing ‘Agency Commitments’ for all key media channels","Your contract does NOT have robust Audit Rights","Your contract is missing clauses about ‘Agency Volume Benefits’ (AVBs)"
,"Your contract is missing clauses about ‘Early Payment Discounts’ (EPD)","Your contract is missing clauses about ‘Unbilled Media’","Your contract does NOT have competitive payment terms","Your contract does NOT have standard ‘Termination’ clauses","Your contract does NOT have strong ‘Sub-Contracting’ clauses"];

const uglySectionIds = [0,2,3,8,9,12,13,14];

const CustomStepConnector = withStyles((theme) => ({
  vertical: {
    marginLeft: 19,
    padding: 0,
  },
  alternativeLabel:{
    line: {
      display: "none"
    }
  },
  line: {
    borderLeft: "solid 2px #025373",    
  } 
}))(StepConnector);

const useCustomStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:"#FFFFFF",
    color:"#14213D",
    border: "solid 2px #14213D",
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    color: "#14213D",
    // boxShadow: theme.shadows[10]
  },
  completed: {
    backgroundColor: "#14213D",
    color: theme.palette.secondary.main,
  }
}));

function CustomStepIcon({ active, completed, icon }) {
  const classes = useCustomStepIconStyles();
  const Icon = steps[icon - 1].icon;
  return (
    <Avatar
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <Icon size="20" />
    </Avatar>
  );
}

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,      
    }
  },
  card: {
    backgroundColor: "#FFFFFF",    
    boxShadow: "none",    
    overflow: "hidden",  
  },
  section:{
    height: "100%",
  },
  avatar: {
    backgroundColor: colors.red[600]
  },
  expansionPanel: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
    border: "solid 2px #75B8BF"
  }
}));

function ProjectCreateView() {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [score, setScore] = useState(0.0);
  const [benchmark, setBenchmark] = useState(0.52);
  const [abintusAverage, setAbintusAverage] = useState(0.85);
  const [grade, setGrade] = useState("POOR");
  const [gradeColour, setGradeColour] = useState("#ed7f34");
  const [gradeText, setGradeText] = useState("Unfortunately, your media agency contract has been rated as ‘Poor’.Your contract is therefore worse than both our Global Benchmark AND our Abintus Clients Benchmark. Many issues with significant financial implications require your immediate attention, and many immediate actions required.");
  const [goodList, setGoodList] = useState([]);
  const [badList, setBadList] = useState([]);
  const [uglyList, setUglyList] = useState([]);
  const [downloadingDoc,setDownloadingDoc] = useState(false);
  const [displayLoading,setDisplayLoading] = useState("none");
  const [selfAssessmentId, setSelfAssessmentId] = useState(null);
  const [docLink, setDocLink] = useState(null);
  const navigate = useNavigate();
  const mobile = useMediaQuery('(max-width: 900px)')

  useEffect(() => {
    const fetchExistingDoc = async () => {
      var id = 0;
      var doc = null
      jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {
        id = parseInt(decoded.id);
      });
      var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
      var token = jwt.sign({ Id: id, token: bearer }, process.env.REACT_APP_ReadToken);
      await fetch('https://abintus-app-api-linux.azurewebsites.net/contracts/self-assessment', {
        method: 'GET',
        headers: {
          'token': token
        }
      })
      .then(res => res.json())
      .then(data => {        
        var mostRecent = data[0]
        if (mostRecent[33] !== "N/A") doc = mostRecent[33];
      })
      setDocLink(doc)
    }
    
    if (completed) {
      setTimeout(() => {
        if (selfAssessmentId !== null) {      
          setTimeout(() => {            
            createPDF(selfAssessmentId)
          }, 1000)
        }
        else {              
          fetchExistingDoc();
        }
      }, 1000);
    }
  }, [selfAssessmentId, completed])

  useLayoutEffect(() => { 
  var arr = JSON.parse(localStorage.getItem("SelfAssessment"));    
  var oldId = JSON.parse(localStorage.getItem("Self Assessment User Id"))  

  var currentId = null;
  jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {
    currentId = parseInt(decoded.id);            
  });

  if (oldId !== currentId) {
    localStorage.removeItem('SelfAssessment')
  }

  if(arr !== null && oldId == currentId)
  {
    if(typeof arr[26] !== 'undefined')
    {      
      setCompleted(true);
      handleComplete();
    }
  }
  },[]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const resetResults = () =>{
    localStorage.removeItem('SelfAssessment');
    window.location.reload(false);
  }

  const createPDF = async (assessmentId) =>{    
    setDownloadingDoc(true);
    setDisplayLoading("inline");
    var firstName = "";
    var lastName = "";
    var id= 0;
    jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {
      id = parseInt(decoded.id);
    });
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ UserId:id, token: bearer }, process.env.REACT_APP_ReadToken);
    await fetch('https://abintus-app-api-linux.azurewebsites.net/user/info'
    ,{
      method: 'GET',
      headers: {
          'token': token
      }}
      )
    .then(response => response.json()).then(response => {
      firstName = response[0][0];
      lastName =  response[0][1];
    });
    const buffer = await fetch(ContractSelfAssessment).then(res => res.arrayBuffer());
    const existingPdfDocBytes = new Uint8Array(buffer);
    const pdfDoc = await PDFDocument.load(existingPdfDocBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    var fontSize = 12;
    const font = await pdfDoc.embedStandardFont(StandardFonts.Helvetica);
    const fontBold = await pdfDoc.embedStandardFont(StandardFonts.HelveticaBold);
    const scoreText = `${Math.round(score * 100,0)}%`;
    var width = 0;
    if(scoreText.length === 2)
    {
      width = 116;
    }
    else if(scoreText.length === 3)
    {
      width = 98;
    }
    else
    {
      width = 80;
    }
    
    firstPage.drawText(`${firstName.substr(0,1)}. ${lastName}`, {
      x: 450,
      y: 814,
      size: fontSize,
      font: font,
      color: rgb(0.0784313725490196,0.132,0.2392156862745098),
    });

    const fourthPage = pages[3];
    fourthPage.drawText(scoreText, {
      x: width,
      y: 554,
      size: 64,
      font: fontBold,//0.9725490196078431,0.8156862745098039,0.1764705882352941
      color: rgb(0.0784313725490196,0.132,0.2392156862745098),
    });

    fourthPage.drawText(`${Math.round(benchmark* 100, 2)}%`, {
      x: 418,
      y: 484,
      size: 44,
      font: fontBold,
      color: rgb(1,1,1),
    });

    if(grade==="POOR")
    {
      width = 76;
      fontSize = 54;
    }
    else if(grade==="AVERAGE")
    {
      width = 25;
      fontSize = 54;
    }
    else if(grade==="STRONG")
    {
      width = 45;
      fontSize = 54;
    }
    else if(grade ==="BEST-IN-CLASS")
    {
      width = 12;
      fontSize = 38;
    }

    fourthPage.drawText(`${grade}`, {
      x: width,
      y: 134,
      size: fontSize,
      font: fontBold,
      color: rgb(1,1,1),
    });

    fourthPage.drawText(`${Math.round(abintusAverage * 100, 2) }%`, {
      x: 418,
      y: 85,
      size: 44,
      font: fontBold,
      color: rgb(0.0784313725490196,0.132,0.2392156862745098),
    });

    const fifthPage = pages[4];
    const goodListtext = goodList.length > 0 ?`Based on your Self-Assessment, it is good to see that you have ${goodList.length} essential best`:`Based on your Self-Assessment, there is unfortunately no best practices included`;
    const goodListtext2 = goodList.length > 0 ?`practices in your contract, which are:`:`in your media agency contract at present.`;
  
  fifthPage.drawText(goodListtext, {
    x: 80,
    y: 305,
    size: 12,
    font: font,
    color: rgb(0.0784313725490196,0.132,0.2392156862745098),
  });
  fifthPage.drawText(goodListtext2, {
    x: 80,
    y: 290,
    size: 12,
    font: font,
    color: rgb(0.0784313725490196,0.132,0.2392156862745098),
  });

    var amount = goodList.length > 10? 10 :goodList.length
    for (let index = 0; index < amount; index++) 
    {
      const element = goodList[index];
      fifthPage.drawText(`•  ${element}`, {
        x: 80,
        y: 260 - (20 * index),
        size: 12,
        font: font,
        color: rgb(0.0784313725490196,0.132,0.2392156862745098),
      });
    }

    const sixthPage = pages[5];
    const badListtext = badList.length > 0 ?`Our analysis would like to highlight the following ${badList.length} sections/clauses/conditions`:`Our analysis has found no sections/clauses/conditions which are missing or`;
    const badListtext2 = badList.length > 0 ?`which require your attention and should be addressed while renegotiating`:`require improvements.`;
    const badListtext3 = "your contract or while conducting a media agency pitch:";
    sixthPage.drawText(badListtext, {
      x: 80,
      y: 305,
      size: 12,
      font: font,
      color: rgb(0.0784313725490196,0.132,0.2392156862745098),
    });
    sixthPage.drawText(badListtext2, {
      x: 80,
      y: 290,
      size: 12,
      font: font,
      color: rgb(0.0784313725490196,0.132,0.2392156862745098),
    });

    if(badList.length > 0)
    {
      sixthPage.drawText(badListtext3, {
        x: 80,
        y: 275,
        size: 12,
        font: font,
        color: rgb(0.0784313725490196,0.132,0.2392156862745098),
      });
    }

      amount = badList.length > 10? 10 :badList.length
      for (let index = 0; index < amount; index++) 
      {
        const element = badList[index];
        sixthPage.drawText(`•  ${element}`, {
          x: 80,
          y: 245 - (20 * index),
          size: 12,
          font: font,
          color: rgb(0.0784313725490196,0.132,0.2392156862745098),
        });
      }

    const seventhPage = pages[6];
    const uglyListText = uglyList.length > 0 ?`In addition, the following ${uglyList.length} elements are missing in your contract and are`:`We found no missing elements in your contract which are essential to make it in line`;
    const uglyListText2 = uglyList.length > 0 ?`essential to make sure your contract is in line with industry norms.`:`with industry norms.`;
    const uglyListText3 = "These elements require your consideration and should be addressed as a";
    const uglyListText4 = "matter of priority while renegotiating your contract or while conducting a";
    const uglyListText5 = "media agency pitch:"
    seventhPage.drawText(uglyListText, {
      x: 80,
      y: 305,
      size: 12,
      font: font,
      color: rgb(0.0784313725490196,0.132,0.2392156862745098),
    });
    seventhPage.drawText(uglyListText2, {
      x: 80,
      y: 290,
      size: 12,
      font: font,
      color: rgb(0.0784313725490196,0.132,0.2392156862745098),
    });

    if(uglyList.length > 0)
    {
      seventhPage.drawText(uglyListText3, {
        x: 80,
        y: 265,
        size: 12,
        font: font,
        color: rgb(0.0784313725490196,0.132,0.2392156862745098),
      });

      seventhPage.drawText(uglyListText4, {
        x: 80,
        y: 250,
        size: 12,
        font: font,
        color: rgb(0.0784313725490196,0.132,0.2392156862745098),
      });

      seventhPage.drawText(uglyListText5, {
        x: 80,
        y: 235,
        size: 12,
        font: font,
        color: rgb(0.0784313725490196,0.132,0.2392156862745098),
      });

    }

      amount = uglyList.length > 10? 10 :uglyList.length
      for (let index = 0; index < amount; index++) 
      {
        const element = uglyList[index];
        seventhPage.drawText(`•  ${element}`, {
          x: 80,
          y: 205 - (20 * index),
          size: 12,
          font: font,
          color: rgb(0.0784313725490196,0.132,0.2392156862745098),
        });
      }
   
  const pdf = await pdfDoc.save();  
  var accessToken = localStorage.getItem('accessToken');
  var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);  
  setDisplayLoading("none");
  setDownloadingDoc(false);
  
  
  var documentName = `Self-Assessment-${selfAssessmentId}-User-${validToken.id}.pdf`
  var file = new File([pdf], documentName, {type: 'mimetype'})
  var formData = new FormData()
  formData.append('file', file)

  var blobUrl = "";
  
  if (assessmentId !== null) {
    token = jwt.sign({ token: bearer, Id: assessmentId, DocumentName: documentName }, process.env.REACT_APP_ReadToken);
    await fetch("https://abintus-app-api-linux.azurewebsites.net/upload/self-assessment", {
      method: 'POST',
      headers: {
        'token' : token
      },
      body: formData
    })
    .then(res => res.json())
    .then(res => {
      blobUrl = res.url;
    })
    setDocLink(blobUrl);
  }

  var clientName = validToken.firstName + " " + validToken.lastName;   
    token = jwt.sign({ token: bearer, ClientEmail: validToken.username, ClientName: clientName, DocLink: blobUrl, DocType: "Self Assessment", Id: id }, process.env.REACT_APP_ReadToken)
    await fetch("https://abintus-app-api-linux.azurewebsites.net/non-db/user-notif", {
        method: 'POST',
        headers: {
          'token' : token
        }
    });

  }

  const handleComplete = () => {    
    var values = JSON.parse(localStorage.getItem("SelfAssessment"));
    var yesValueIndex = [];
    var noValueIndex = [];
    for (var i = 3; i < 23 ; i++)
    {
      if(values[i]=== "Yes")
      {
        yesValueIndex.push(i);
      }
      else if(values[i] === "No")
      {
        noValueIndex.push(i); 
      }
    }

    var goodSectionList = [];
    var uglySectionList = [];
    var badSectionList = [];

    yesValueIndex.forEach((val =>{
    goodSectionList.push(goodSections[val - 3]);
    }));
 
    noValueIndex.forEach((val =>{
      if(uglySectionIds.includes(val - 3))
      {
        uglySectionList.push(badSections[val - 3]);
      }
      else
      {
        badSectionList.push(badSections[val - 3]);
      }
      }));


    setGoodList(goodSectionList);
    setBadList(badSectionList);
    setUglyList(uglySectionList);
    const scoreVal = values[27];
    setBenchmark(values[28] / 100);
    setAbintusAverage(values[29] / 100);

    var rating = "BEST-IN-CLASS";
    var colour = "#00b050";
    var text = "Congratulations! Your media agency contract has been rated as ‘Best-in-Class’.Your contract is therefore better than our Global Benchmark AND on par with our Abintus Clients Benchmark. Only one or two very minor actions required, but nothing major.";
    if(scoreVal < 39)
    {
      rating = "POOR";
      colour = "#ed7f34";
      text = "Unfortunately, your media agency contract has been rated as ‘Poor’.Your contract is therefore worse than both our Global Benchmark AND our Abintus Clients Benchmark. Many issues with significant financial implications require your immediate attention, and many immediate actions required.";
    }
    else if(scoreVal < 59)
    {
      rating = "AVERAGE";
      colour = "#ffd65a";
      text= "Unfortunately, your media agency contract has been rated as ‘Average’. Your contract is therefore on par with our Global Benchmark BUT much worse than our Abintus Clients Benchmark. A few issues with significant financial implications require your attention, and some immediate actions required.";
    }
    else if(scoreVal === 100)
    {
      text ="Congratulations! Your media agency contract has been rated as ‘Best-in-Class’.Your contract is therefore better than our Global Benchmark AND on par with our Abintus Clients Benchmark.";
    }
    else if(scoreVal < 80)
    {
      rating = "STRONG";
      colour = "#92d050";
      text ="Congratulations!  Your media agency contract has been rated as ‘Strong’. Your contract is therefore better than our Global Benchmark BUT worse than our Abintus Clients Benchmark. A few actions required which should be raised and discussed internally, but nothing major.";
    }
    
    setGradeText(text);
    setGradeColour(colour);
    setGrade(rating);
    setScore(scoreVal / 100);
    setCompleted(true);
  };

  const useNextStepStyles = makeStyles((theme) => ({
    card: {
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",        
      height: "425px",
      width: "250px",
      padding: 0,
      borderRadius: 0
    },    
    subtext: {

    },    
    image: {
      height: "45%",
      width: "100%",
      marginBottom: "10px",      
      objectFit: "fill",
      objectPosition: "left"
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      height: "95%",

    },
    link: {
      textDecoration: "none",
      color: "#FFFFFF",
      height: "100%",
      width: "100%"
      // "&:hover": {
      //   color: "yellow"
      // }
    }
  }));
  const NextStep = ({ grade }) => {
    const classes = useNextStepStyles();

  if(grade === "STRONG")
  {
    return ( 
    <Grid container spacing={2} justifyContent="center">
    <Grid item md={4} xs={12} align="center">
      <Card className={classes.card}>
      <div className={classes.subContainer}>
        <img className={classes.image} src={Telephone} alt="Call"></img>      
        <Typography
          variant="h3"
          color="textPrimary"
          align="left"
          style={{marginBottom: "12px"}}
        >
        Schedule Free Call
        </Typography>
        <Typography
        variant="body1"
        align="left"
        className={classes.subtext}
        >
          Talk to one of our media experts if you have questions or queries about your results
        </Typography>
      </div>
      {/* <Box mt={4}></Box> */}
      <Box alignItems="center"> 
      <a href="https://meetings-eu1.hubspot.com/philippe-dominois">
        <NextOptionsButton>                          
            Schedule
        </NextOptionsButton>
        </a>
      </Box>
      <Box mt={4}></Box>
      </Card>
    </Grid>
    <Grid item md={4} xs={12} align="center">
    <Card className={classes.card}>
      <div className={classes.subContainer}>
        <img className={classes.image} src={Expert} alt="Expert"></img>      
        <Typography
          variant="h3"
          color="textPrimary"
          align="left"
          style={{marginBottom: "12px"}}
        >
        Expert Assessment
        </Typography>
        <Typography
          variant="body1"
          align="left"
          className={classes.subtext}
        >
          Consider our Expert Assessment for a more in-depth review of your contract by one of our media experts
        </Typography>
        {/* <Box mt={4}></Box> */}
      </div>
      <Box alignItems="center"> 
      <NextOptionsButton
        onClick={() => {navigate('/app/expert-assessment')}}
        color="secondary"
        type="submit"
        variant="contained"
        size="large"
        align="center"
      >
        Start Now
      </NextOptionsButton></Box>
      <Box mt={4}></Box>
      </Card>
    </Grid>
  </Grid>);
  }
  else if(grade==="BEST-IN-CLASS")
  {
    return (<Grid container spacing={6} justifyContent="center">
    <Grid item md={4} xs={12} align="center">
    <Card className={classes.card}>
      <div className={classes.subContainer}>
        <img className={classes.image} src={Telephone} alt="Call"></img>      
        <Typography
          variant="h3"
          color="textPrimary"
          align="left"
          style={{marginBottom: "12px"}}
        >
        Schedule Free Call
        </Typography>
        <Typography
        variant="body1"
        align="left"
        className={classes.subtext}
        >
          Talk to one of our media experts if you have questions or queries about your results
        </Typography>
      </div>
      {/* <Box mt={4}></Box> */}
      <Box alignItems="center"> 
        <NextOptionsButton
          target="_blank"           
          variant="contained"
          size="large"
          align="center"         
        >
          <a href="https://meetings-eu1.hubspot.com/philippe-dominois" className={classes.link}>
            Schedule
          </a>
        </NextOptionsButton>
      </Box>      
      </Card>
    </Grid>
  </Grid>)
  }
  else
  {
    return ( 
      <Grid container spacing={2} justifyContent="center">
      <Grid item md={3} xs={12} lg={3} xl={3} align="center" >
      <Card className={classes.card}>
        <div className={classes.subContainer}>
          <img className={classes.image} src={Telephone} alt="Call"></img>      
          <Typography
            variant="h3"
            color="textPrimary"
            align="left"
            style={{marginBottom: "12px"}}
          >
          Schedule Free Call
          </Typography>
          <Typography
          variant="body1"
          align="left"
          className={classes.subtext}
          >
            Talk to one of our media experts if you have questions or queries about your results
          </Typography>
        </div>
      {/* <Box mt={4}></Box> */}
      <Box alignItems="center"> 
        <a href="https://meetings-eu1.hubspot.com/philippe-dominois">
          <NextOptionsButton>
            Schedule         
          </NextOptionsButton>
        </a>
      </Box>
      {/* <Box mt={4}></Box> */}
      </Card>
      </Grid>
      <Grid item md={3} xs={12} lg={3} xl={3} align="center">
      <Card className={classes.card}>    
        <div className={classes.subContainer}>    
          <img className={classes.image} src={Expert} alt="Expert"></img>      
          <Typography
            variant="h3"
            color="textPrimary"
            align="left"
            style={{marginBottom: "12px"}}
          >
          Expert Assessment
          </Typography>
          <Typography
            variant="body1"
            align="left"
            className={classes.subtext}
          >
            Consider our Expert Assessment for a more in-depth review of your contract by one of our media experts
          </Typography>
        </div>      
      <Box alignItems="center"> 
      <NextOptionsButton
        onClick={() => {navigate('/app/expert-assessment')}}
      >
        Start Now
      </NextOptionsButton></Box>
      {/* <Box mt={4}></Box> */}
        </Card>
      </Grid>
      <Grid item md={3} xs={12} lg={3} xl={3} align="center">
      <Card className={classes.card}>
        <div className={classes.subContainer}>
          <img src={Academy} className={classes.image}></img>
          <Typography
            variant="h3"
            align="left"
            style={{marginBottom: "12px"}}
          >
            Abintus Academy
          </Typography>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            align="left"
          >
          Learn more about media agency contract best practices with our course on the Abintus Academy. 
          </Typography>     
        </div>   
        <Box alignItems="center"> 
          <a href="https://www.abintus.academy/courses/media-agency-contracts">
            <NextOptionsButton>
              Visit Abintus Academy
            </NextOptionsButton>
          </a>
        </Box>
        {/* <Box mt={4}></Box> */}
        </Card>
      </Grid>
      <Grid item md={3} xs={12} lg={3} xl={3} align="center">
      <Card className={classes.card}>
        <div className={classes.subContainer}>
          <img src={Template} alt="Template" className={classes.image}></img>
          <Typography 
          variant="h3"
          align='left'
          style={{marginBottom: "12px"}}
          >
            Contract Templates
          </Typography>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            align="left"
          >
          Purchase our best-in-class media agency contract template and all related schedules.  
          </Typography>
          {window.innerWidth > 1197 &&<Typography style={{fontSize:"18px"}}>&nbsp;</Typography>}  
        </div>      
        <Box alignItems="center"> 
        <a className={classes.link} href="https://www.abintus.consulting/contact">
          <NextOptionsButton>
            Inquire
          </NextOptionsButton>
        </a>            
        </Box>
        {/* <Box mt={4}></Box> */}
        </Card>
      </Grid>
    </Grid>);
  }
  };

  const setId = (value) => {
    setSelfAssessmentId(value);
  }
  var questions = [];
  for(var i = 2; i < 22; i++)
  {
     questions.push(
      activeStep === i && (
        <ContractInformation
          {...{question:activeStep}}
          onBack={handleBack}
          onNext={handleNext}
          key={i}
        />
      )
     );
  }

  return (
    <Page
      className={classes.root}
      title="Self Assesssment"
    >
      <Container maxWidth="lg" className={classes.container}>
        <Box mb={3}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              variant="body1"
              color="inherit"
              to="/app/getting-started"
              component={RouterLink}
            >
              Home {'>'}
            </Link>
            {/* <Typography
              variant="body1"
              color="textPrimary"
            >
              Self Assessment
            </Typography> */}
          </Breadcrumbs>    
          <Typography variant="h1" style={{color: "#000000"}}>Self Assessment</Typography>      
        </Box>
        {!completed ? (
          <Paper className={classes.card}>
            <Grid container sx={{
              backgroundColor: "#FFFFFF",
            }}>
              <Grid
                item
                xs={12}
                md={3}                
              >
                <Hidden only={'xs'}>              
                  <Stepper
                    activeStep={activeStep}
                    connector={<CustomStepConnector />}
                    orientation="vertical"
                    component={Box}                  
                    style={{
                      backgroundColor: "#FFFFFF",                      
                    }}
                  >
                    {steps.map((step) => step.show === true ?
                      (  
                        <Step  key={step.label}>
                          <StepLabel StepIconComponent={CustomStepIcon}>
                            {step.label}
                          </StepLabel>
                        </Step>                   
                      )
                      :
                      ( 
                        <Step alternativeLabel={true} key={step.label}></Step>
                      )
                    )}
                  </Stepper>
                </Hidden>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
              >
                <Box>
                {activeStep === 0 && (
                    <Introduction  onNext={handleNext} />
                  )}
                  {activeStep === 1 && (
                    <YourAgency  onNext={handleNext} onBack={handleBack}/>
                  )}
                   {questions}
                  {activeStep === 22 && ( //22
                    <AboutYou
                      onBack={handleBack}
                      onComplete={handleComplete}
                      setId={setId}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        ) : (
         
          <Card style={{boxShadow: "none", backgroundColor: "white"}}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={3}
            >
              
            </Grid>
          </Grid>
            <CardContent style={{boxShadow: "none"}}>
              <Box
                maxWidth={1200}
                mx="auto"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  style={{
                    backgroundColor: "#FFFFFF"
                  }}
                >
                  <Grid container spacing={6}>
                    <Hidden only={['md', 'sm', 'xs']}>
                      <Grid item md={3} xs={12}>
                      <Stepper
                        activeStep={23}
                        connector={<CustomStepConnector />}
                        orientation="vertical"
                        component={Box}
                        style={{
                          backgroundColor: "#FFFFFF"
                        }}
                      >
                        {
                          steps.map((step) => step.show === true ?(            
                            <Step  key={step.label}>
                              <StepLabel StepIconComponent={CustomStepIcon}>
                                {step.label}
                              </StepLabel>
                            </Step>                 
                          )
                          :
                          ( 
                            <Step alternativeLabel={true} key={step.label}></Step>
                          )                
                        )}
                      </Stepper>
                    </Grid>
                  </Hidden>
                <Grid item md={9} xs={12} style={{backgroundColor: "white"}}>
                  <Box mt={4}>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    align="justify"
                  >
                    Based on your self-assessment of your media agency contract, please find below your scorecard and findings below. 
                  </Typography>
                  </Box>
                  <Box mt={2} >
                  <Typography variant="body1" color="textPrimary" style={{fontSize: '16px'}}>
                    Please note:
                  </Typography>
                  </Box>
                  <Box mt={1}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{fontSize: '16px'}}
                    align="justify"
                  >
                    • A contract with a total score above 80% is considered to be a Best-in-Class Contract.
                  </Typography>
                  <Box mt={1} />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{fontSize: '16px'}}
                    align="justify"
                  >
                    • A contract with a total score between 60% to 80% is considered to be a Strong Contract with room for improvement.There should be matters to be raised and discussed, but not seen to be alarming.
                  </Typography>
                  <Box mt={1} />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{fontSize: '16px'}}
                    align="justify"
                  >
                    • A contract with a total score between 40% to 60%  is considered to be a Average Contract with significant changes required.
                  </Typography>
                  <Box mt={1} />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{fontSize: '16px'}}
                    align="justify"
                  >
                    • A contract with a total score below 40% is considered to be a Poor Contract with serious issues of concern from our point of view.This category of contracts highlights issues requiring immediate attention from management.
                     </Typography>
                  </Box>
                  </Grid>
                  <Grid item md={4} xs={12} align="center">
                  <Box mt={2}>
                  <GaugeChart id="userGrade" 
                    nrOfLevels={20} 
                    percent={score}                    
                    textColor="#14213D"
                    colors = {["#FF6347","#CCCC00","#006400" ]}
                    needleColor = "#f8d02d"                  
                  />
                <Typography
                    variant="body1"
                    color="textPrimary"
                    align="center"
                    style={{fontWeight:"bold"}}
                  >
                    Your Score: {Math.round(score * 100,0)}%
                  </Typography>
                </Box>
                  </Grid>
                  <Grid item md={4} xs={12} align="center">
                  <Box mt={2}>
                <GaugeChart id="benchmark"
                  nrOfLevels={20} 
                  percent={benchmark}
                  textColor="#14213D"
                  colors = {["#FF6347","#CCCC00","#006400" ]}
                  needleColor = "#f8d02d"
                />
                 <Typography
                    variant="h5"
                    color="textPrimary"
                    align="center"
                    style={{fontWeight:"bold"}}
                  >
                    Global Benchmark: {Math.round(benchmark * 100,0)}%
                  </Typography>
                </Box>
                  </Grid>
                  <Grid item md={4} xs={12} align="center">
                  <Box mt={2}>
                <GaugeChart id="abintusAverage" 
                  nrOfLevels={20} 
                  percent={abintusAverage}
                  textColor="#14213D"
                  colors = {["#FF6347","#CCCC00","#006400" ]}
                  needleColor = "#f8d02d"
                />
                 <Typography
                    variant="h5"
                    color="textPrimary"
                    align="center"
                    style={{fontWeight:"bold"}}
                  >
                    Abintus Average: {Math.round(abintusAverage * 100, 0)}%
                  </Typography>
                </Box>
                  </Grid>
                  </Grid>
                </Box>
                <Box mt={4}>
                  <Typography
                    variant="h3"
                    color="textPrimary"
                    align="center"
                    style={{fontWeight:"bold"}}
                  >
                    Your Rating: <Typography style={{display: 'inline-block',color:gradeColour,fontSize: '24px',fontWeight:"bold"}}>{grade}</Typography>
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="justify"
                  >
                    {gradeText}
                  </Typography>
                </Box>
                <Box mt={6}>
                <Typography
                    variant="h3"
                    color="textPrimary"
                    align="left"
                    style={{paddingBottom:"10px",fontWeight:"bold"}}
                  >
                    The Good
                  </Typography>
                  <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon style={{color:"#14213D"}} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {goodList.length > 0 && <Typography component={'span'} className={classes.heading}>Based on your Self-Assessment, it is good to see that you have <Typography component={'span'} style={{fontWeight:"bold",fontSize:"20px"}}>{goodList.length}</Typography> essential best practices in your contract, which are:</Typography> }
                      {goodList.length === 0 && <Typography component={'span'} className={classes.heading}>Based on your Self-Assessment, there is unfortunately no best practices included in your media agency contract at present. </Typography> }
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <dl>
                        {goodList.map((item,i)=> <dd key={i}> 
                        <Box mt={1}></Box>
                        <Typography variant="h5"
                        style={{fontSize: '16px',paddingLeft:"10px"}}
                        color="textSecondary"
                        align="left">
                          <Typography style={{fontSize: '24px',display: 'inline-block',fontWeight:"bolder"}}>•</Typography> {item}
                  </Typography></dd>)}
                      </dl>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Box>
                <Box mt={4}>
                <Typography
                    variant="h3"
                    color="textPrimary"
                    align="left"
                    style={{paddingBottom:"10px",fontWeight:"bold"}}
                  >
                    The Bad
                  </Typography>
                  <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon style={{color:"#14213D"}}/>}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      {badList.length > 0 && <Typography component={'span'}>Our analysis would like to highlight the following <Typography component={'span'} style={{fontWeight:"bold",fontSize:"20px"}}>{badList.length}</Typography> sections/clauses which require your attention and should be addressed while renegotiating your contract or while conducting a media agency pitch:</Typography>}
                      {badList.length === 0 && <Typography component={'span'}>Our analysis has found no sections/clauses/conditions which are missing or require improvements.</Typography>}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <dl>
                        {badList.map((item,i)=> <dd key={i}>  
                        <Box mt={1}></Box>
                        <Typography variant="h5"
                        style={{fontSize: '16px',paddingLeft:"10px"}}
                        color="textSecondary"
                        align="left"><Typography style={{fontSize: '24px',display: 'inline-block',fontWeight:"bolder"}}>•</Typography> {item}
                  </Typography></dd>)}
                      </dl>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Box>
                <Box mt={4}>
                <Typography
                    variant="h3"
                    color="textPrimary"
                    align="left"
                    style={{paddingBottom:"10px",fontWeight:"bold"}}
                  >
                    The Ugly
                  </Typography>
                  <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon style={{color:"#14213D"}}/>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {uglyList.length > 0 && <Typography component={'span'}>The following  <Typography component={'span'} style={{fontWeight:"bold",fontSize:"20px"}}>{uglyList.length}</Typography> elements are missing in your contract and are essential to make sure your contract in line with industry norms. These elements require your consideration and should also be addressed as a matter of priority while renegotiating your contract or while conducting a media agency pitch:</Typography>}
                      {uglyList.length === 0 && <Typography component={'span'}>We found no missing elements in your contract which are essential to make it in line with industry norms.</Typography>}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails >
                      <Box justifyContent="center"> <dl>
                        {uglyList.map((item,i)=> <dd key={i} style={{textAlign: "center"}}> <Box mt={1}></Box>
                        <Typography variant="h5"
                        style={{fontSize: '16px',paddingLeft:"10px"}}
                        color="textSecondary"
                        align="left"><Typography style={{fontSize: '24px',display: 'inline-block',fontWeight:"bolder"}}>•</Typography> {item}
                      </Typography></dd>)}
                      </dl>
                      </Box>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Box>
                <Box mt={6}>
                  <Grid container spacing={4} justifyContent="center">
                    <Grid item> 
                      <NextOptionsButton
                        onClick={resetResults}
                        color="secondary"
                        type="submit"
                        variant="contained"
                        size="large"
                        align="center"
                      >
                        Retry Assessment
                    </NextOptionsButton>
                    </Grid>
                    <Grid item> 
                    {
                      docLink == null ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body1" style={{ marginRight: "10px" }}>Assessment Loading</Typography>
                          <Box><CircularProgress color="secondary" /></Box>
                        </div>
                      ) : (
                        <a href={docLink} target="_blank">
                          <NextOptionsButton disabled={downloadingDoc}>
                            Download PDF
                          </NextOptionsButton>
                        </a>
                      )
                    }
                 </Grid>
                 {/* <Box display={displayLoading} style={{paddingTop:"10px"}}> <CircularProgress color="secondary" /></Box> */}
                  </Grid>
                </Box>
                <Box mt={6}>
                <Typography
                      variant="h3"
                      color="textPrimary"
                      align="center"
                      style={{fontWeight:"bold"}}
                    >
                      Next Steps
                    </Typography>
                </Box>
                <Box mt={4}>
                <NextStep grade={grade}></NextStep> 
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}
      </Container>
    </Page>
  );
}

const NextOptionsButton = styled.button`
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1rem; 
  border: none;
  padding: 10px;  
  transition: 0.2s;
  font-family: Poppins;
  width: 200px;  

  &:hover {
    background-color: #3D3D3D;
    transition: 0.2s;
    cursor: pointer;
    color: #F2C230;   
  
    >* {
      color: #F2C230;
    }
  }
`

export default ProjectCreateView;

