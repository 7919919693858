import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import MainLayout from './layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoginView from './views/auth/LoginView';
import RegisterView from './views/auth/RegisterView';
import GettingStartedView from './views/reports/GettingStarted';
import ResetPasswordView from './views/auth/ResetPasswordView';
import TermsAndConditions from './views/reports/TermsAndConditions';
import DashboardLayout from './layouts/DashboardLayout';
import CustomerDetailsView from './views/reports/Settings';
import YourDashboardView from './views/reports/YourDashboard';
import ProjectCreateView from './views/reports/SelfAssessment'
import ExpertAssessment from './views/reports/ExpertAssessment';
import AccountView from './views/reports/ContactUs';
import ContractDashboard from './views/admin/ContractDashboard';
import AddContractView from './views/admin/AddContract';
import EditContractView from './views/admin/EditContract';
import SelfAssessmentDashboardView from './views/admin/SelfAssessmentDashboard';
import ExpertDashboardView from './views/admin/ExpertDashboard';
import AuthGuard from './components/AuthGuard';
import AuthGuardAdmin from './components/AuthGuardAdmin';
import AuthUserId from './components/AuthUserId';


const AppRoutes = () => {
    return (
        <Routes>       
            <Route path="/" element={
                <MainLayout>
                    <HomeView />
                </MainLayout>     
            } 
            />
            <Route path="/home" element={<Navigate to="/" replace />} />
            <Route path="/login" element={<LoginView />} />
            <Route path="/register" element={<RegisterView />} />
            <Route path="/reset-password" element={<ResetPasswordView />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/app/getting-started" element={
                <DashboardLayout>
                    <AuthGuard>
                        <GettingStartedView />
                    </AuthGuard>
                </DashboardLayout>
            } />
            <Route path="/app/your-dashboard" element={
                <DashboardLayout>
                    <AuthGuard>
                        <YourDashboardView />
                    </AuthGuard>
                </DashboardLayout>
            } 
            />
            <Route path="/app/self-assessment" element={
                <DashboardLayout>
                    <AuthGuard>
                        <ProjectCreateView />
                    </AuthGuard>
                </DashboardLayout>
            } 
            />
            <Route path="/app/expert-assessment" element={
                <DashboardLayout>
                    <AuthGuard>
                        <ExpertAssessment />
                    </AuthGuard>
                </DashboardLayout>
            } 
            />
            <Route path="/app/settings" element={
                <DashboardLayout>
                    <AuthGuard>
                        <CustomerDetailsView />
                    </AuthGuard>
                </DashboardLayout>
            } 
            />
            <Route path="/app/contact-us" element={
                <DashboardLayout>
                    <AuthGuard>
                        <AccountView />
                    </AuthGuard>
                </DashboardLayout>
            } 
            />
            <Route path="/app/admin/contract-dashboard" element={
                <DashboardLayout>
                    <AuthGuardAdmin>
                        <ContractDashboard />
                    </AuthGuardAdmin>
                </DashboardLayout>
            } 
            />
            <Route path="/app/admin/AddContract" element={
                <DashboardLayout>
                    <AuthGuardAdmin>
                        <AddContractView />
                    </AuthGuardAdmin>
                </DashboardLayout>
            } 
            />
            <Route path="/app/admin/EditContract" element={
                <DashboardLayout>
                    <AuthGuardAdmin>
                        <EditContractView />
                    </AuthGuardAdmin>
                </DashboardLayout>
            } 
            />
            <Route path="/app/admin/self-assessment-dashboard" element={
                <DashboardLayout>
                    <AuthGuardAdmin>
                        <SelfAssessmentDashboardView />
                    </AuthGuardAdmin>
                </DashboardLayout>
            } 
            />
            <Route path="/app/admin/expert-dashboard" element={
                <DashboardLayout>
                    <AuthGuardAdmin>
                        <ExpertDashboardView />
                    </AuthGuardAdmin>
                </DashboardLayout>
            }
            />
        </Routes>
    )
}

export default AppRoutes;