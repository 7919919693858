import React,{useEffect, useState,useRef} from 'react';
import { Card,CardContent,Container,Grid,makeStyles,TextField,Select,MenuItem,InputLabel,FormControl,
FormHelperText,Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,InputAdornment,Typography,withStyles,Box
} from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import { Autocomplete } from '@material-ui/lab';
import { useHistory } from 'react-router';
import {DropzoneArea} from 'material-ui-dropzone'
var jwt = require('jsonwebtoken');
const countryList = require('country-list');
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
      paddingTop: "24px",
      paddingBottom: "24px"
    }
  }
}));

const CustomDropBox = withStyles((theme) => ({
  root:{
    border:"1px solid #14213D",
    "& .MuiDropzonePreviewList-image":
    {
      boxShadow:"0 0 0",
      color:"#f8d02d",
      height:"50px",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}))(DropzoneArea);

function AverageRating(score)
{
  var rating = "Best-in-Class";
  if(score < 39)
  {
    rating = "Poor";
  }
  else if(score < 59)
  {
    rating = "Average";
  }
  else if(score < 80)
  {
    rating = "Strong";
  }
  return rating;
}

function AddContractView({openView,mainData, evaluationData,contract}) 
{
  const history = useHistory();
  const classes = useStyles();
  let btnRef = useRef();
  const [filesSelected,setFileSelected] = React.useState([]);
  const [contractId, SetContractId] = React.useState(0);
  const [isSubmitting,SetIsSubmitting] = React.useState(false);
  const [preparedByAbintus, setPreparedByAbintus] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState('Various');
  const [annualMediaBudget, setAnnuaMediaBudget] = React.useState('');
  const [remunerationModel, setRemunerationModel] = React.useState('');
  const [lenghtOfContract, setLenghtOfContract] = React.useState('');
  const [autoRenew, setAutoRenew] = React.useState('');
  const [paymentTerms, setPaymentTerms] = React.useState('');
  const [signedByBothParties, setSignedByBothParties] = React.useState('');
  const [signedByBothPartiesImpr, setSignedByBothPartiesImpr] = React.useState('');
  const [definitions, setDefinitions] = React.useState('');
  const [definitionsImpr, setDefinitionsImpr] = React.useState('');
  const [servicesDescribed, setServicesDescribed] = React.useState('');
  const [servicesDescribedImpr, setServicesDescribedImpr] = React.useState('');
  const [reportingClause, setReportingClause] = React.useState('');
  const [reportingClauseImpr, setReportingClauseImpr] = React.useState('');
  const [agentOnly, setAgentOnly] = React.useState('');
  const [agentOnlyImpr, setAgentOnlyImpr] = React.useState('');
  const [authorityClause, setAuthorityClause] = React.useState('');
  const [authorityClauseImpr, setAuthorityClauseImpr] = React.useState('');
  const [exclusivityReciprocal, setExclusivityReciprocal] = React.useState('');
  const [exclusivityReciprocalImpr, setExclusivityReciprocalImpr] = React.useState('');
  const [keyIndividuals, setKeyIndividuals] = React.useState('');
  const [keyIndividualsImpr, setKeyIndividualsImpr] = React.useState('');
  const [transparentRemuneration, setTransparentRemuneration] = React.useState('');
  const [transparentRemunerationImpr, setTransparentRemunerationImpr] = React.useState('');
  const [prfIncluded, setPrfIncluded] = React.useState('');
  const [prfIncludedImpr, setPrfIncludedImpr] = React.useState('');
  const [prfWithMalus, setPrfWithMalus] = React.useState('');
  const [prfWithMalusImpr, setPrfWithMalusImpr] = React.useState('');
  const [prfMethodology, setPrfMethodology] = React.useState('');
  const [prfMethodologyImpr, setPrfMethodologyImpr] = React.useState('');
  const [agencyCommitments, setAgencyCommitments] = React.useState('');
  const [agencyCommitmentsImpr, setAgencyCommitmentsImpr] = React.useState('');
  const [auditRights, setAuditRights] = React.useState('');
  const [auditRightsImpr, setAuditRightsImpr] = React.useState('');
  const [rebatesClauses, setRebatesClauses] = React.useState('');
  const [rebatesClausesImpr, setRebatesClausesImpr] = React.useState('');
  const [epdClause, setEpdClause] = React.useState('');
  const [epdClauseImpr, setEpdClauseImpr] = React.useState('');
  const [unbilledMediaClause, setUnbilledMediaClause] = React.useState('');
  const [unbilledMediaClauseImpr, setUnbilledMediaClauseImpr] = React.useState('');
  const [competitivePaymentTerms, setCompetitivePaymentTerms] = React.useState('');
  const [competitivePaymentTermsImpr, setCompetitivePaymentTermsImpr] = React.useState('');
  const [standardTerminationClause, setStandardTerminationClause] = React.useState('');
  const [standardTerminationClauseImpr, setStandardTerminationClauseImpr] = React.useState('');
  const [standardSubcontractingClause, setStandardSubcontractingClause] = React.useState('');
  const [standardSubcontractingClauseImpr, setStandardSubcontractingClauseImpr] = React.useState('');
  const [dateReviewFlag, setDateReviewFlag] = React.useState(false);
  const [preparedByAbintusFlag, setPreparedByAbintusFlag] = React.useState(false);
  const [annualMediaBudgetFlag, setAnnualMediaBudgetFlag] = React.useState(false);
  const [remunerationModelFlag, setRemunerationModelFlag] = React.useState(false);
  const [lenghtOfContractFlag, setLenghtOfContractFlag] = React.useState(false);
  const [autoRenewFlag, setAutoRenewFlag] = React.useState(false);
  const [paymentTermsFlag, setPaymentTermsFlag] = React.useState(false);
  const [startDateFlag, setStartDateFlag] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("Success");
  const [dialogText, setDialogText] = React.useState(["Arena Media","Blue 449","Carat","Essence","Havas Media","Hearts and Science","Horizon Media","Madison Media","Mediacom","Mindshare","OMD","PHD","PlatformGSK","Spark Foundry","Starcom","UM","Vizeum","Wavemaker","Dentsu X","m/SIX"]);
  const [agencyList, setAgencyList] = React.useState(["Universal McCann","AKA","Conversion","Havas"]);
  const [advertiserList, setAdvertiserList] = React.useState(["Pernord Ricard Italia","Stage Entertainment Germany","Kao Germany"]);
  const [agencyGroupList, setAgencyGroupList] = React.useState(["Independent","GroupM","Havas Media Group"]);
  const [servicesList, setServicesList] = React.useState(['All Media','Social Media & Creative','Full Media Management','Media & Creative']);
  const [avbCalculations, setAvbCalculationsList] = React.useState(['Commitment','Fair Share','Full transparency','None','Not Applicable','Not defined']);
  const [currencyList, setCurrencyList] = React.useState(["EUR (€)","GBP (£)","BRL (R$)","MXN ($)","ZAR ($)"]);
  var columnNumber = 0;
  const [selectClient, setSelectClient] = React.useState('None');
  const [expertIds,setExpertIds] = React.useState([]);
  const [contractLinks,setContractLinks] = React.useState([]);
  const [contractNames,setContractNames] = React.useState([]);
  const [removeDocs, setRemoveDocs] = React.useState([]);
  const selectFile = (files) =>
  {
      if(files.length !== 0)
      {
        setFileSelected(files);
      }
  }
  var getListValues = (columnName,func) =>
  {
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ column:columnName, token: bearer }, process.env.REACT_APP_ReadToken);
    fetch('https://abintus-app-api-linux.azurewebsites.net/contracts/list'
    ,{
      method: 'GET',
      headers: {
          'token': token
      }}
      )
    .then(response => response.json()).then(response => {
      func(response);
    });
  }

  useEffect(() => { 
    if(mainData["Definitions"] !== '')
    {
      if(mainData["Definitions"] !== undefined)
      {
        setSignedByBothParties(mainData["SignedByBothParties"]);
        setDefinitions(mainData["Definitions"]);
        setServicesDescribed(mainData["ServicesDescribed"]);
        setReportingClause(mainData["ReportingClause"]);
        setAgentOnly(mainData["AgentOnly"]);
        setAuthorityClause(mainData["AuthorityClause"]);
        setExclusivityReciprocal(mainData["ExclusivityReciprocal"]);
        setKeyIndividuals(mainData["KeyIndividuals"]);
        setTransparentRemuneration(mainData["TransparentRemuneration"]);
        setPrfIncluded(mainData["PRFIncluded"]);
        setPrfWithMalus(mainData["PRFWithMalus"]);
        setPrfMethodology(mainData["PRFMethodology"]);
        setAgencyCommitments(mainData["AgencyCommitments"]);
        setAuditRights(mainData["AuditRights"]);
        setRebatesClauses(mainData["RebatesClauses"]);
        setEpdClause(mainData["EPDClause"]);
        setUnbilledMediaClause(mainData["UnbilledMediaClause"]);
        setCompetitivePaymentTerms(mainData["CompetitivePaymentTerms"]);
        setStandardTerminationClause(mainData["StandardTerminationClause"]);
        setStandardSubcontractingClause(mainData["StandardSubcontractingClause"]);
        var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
        var token = jwt.sign({ Id:mainData["Id"], token: bearer }, process.env.REACT_APP_ReadToken);
        fetch('https://abintus-app-api-linux.azurewebsites.net/contracts/url'
        ,{
          method: 'GET',
          headers: {
              'token': token
          }}
          )
        .then(response => response.json()).then(response =>{
         response.forEach(element => {
           if(response.length > 0)
           {
            var links = [];
            var link = element[1];
            var nameArray = element[3].split(",");
            if(nameArray[0] !== "")
            {
              setContractNames(nameArray);
              nameArray.forEach(element => {  
              links.push(link.replace(/[^/]+(?=\?)/g,element)); // /[^\/]+(?=\?)/g
              });
              setContractLinks(links);
            }
            else
            {
              setContractNames([""]);
            }
           }
         });
        });
      }
    }
  },[mainData]);

  useEffect(() => { 
    var agencyArray = ["Arena Media","Blue 449","Carat","Essence","Havas Media","Hearts and Science","Horizon Media","Madison Media","Mediacom","Mindshare","OMD","PHD","PlatformGSK","Spark Foundry","Starcom","UM","Vizeum","Wavemaker","Dentsu X","m/SIX"];
    getListValues("Advertiser",(result) => {setAdvertiserList(result);});
    getListValues("Agency",(result) => {agencyArray.forEach(element => {
      result.push(element);
    }); const uniqueNames = Array.from(new Set(result));setAgencyList(uniqueNames);});
    getListValues("AgencyGroup",(result) => {setAgencyGroupList(result);});
    getListValues("Services",(result) => {setServicesList(result);});
    getListValues("AVBCalculation",(result) => {setAvbCalculationsList(result);});
    var val = [];
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);
    fetch('https://abintus-app-api-linux.azurewebsites.net/contracts/expert-assessment'
    ,{
      method: 'GET',
      headers: {
          'token': token
      }}
      )
    .then(response => response.json())
    .then(response => {
      response.forEach((element => {
      val.push([element[0],"Client ID: "+ element[1] + " Expert Assess. ID: " + element[0]]);
      }))

      setExpertIds(val);
    });
  },[]);
const inputValidation = async()=>
{
  var noError = true;
  if(document.getElementById('DateOfReview').value === "")
  {
    setDateReviewFlag(true);
    noError = false;
  }

  if(document.getElementById('StartDate').value === "")
  {
    setStartDateFlag(true);
    noError = false;
  }
  return noError;
}

const handleClose = () => {
  SetIsSubmitting(false);
  if(dialogTitle === "Success")
  {
    window.location.reload(false);
    setOpen(false);
  }
  else
  {
    setOpen(false);
  }
};

const contractEvaluation = () => {
  history.push('/app/admin/evaluation-table',{id: contractId});
};

const removeDoc = (e) => {
  var x = document.getElementById(`Document${e}`);
  x.style.display = "none";
  var remove = removeDocs; 
  remove.push(contractNames[e]);
  setRemoveDocs(remove);
}

const trueOrFalse = (val) =>
{
    var result = null;
     if(val !== "Not Applicable")
     {
       if (val === "Yes")
       {
         result = 1;
       }
       else
       {
         result = 0;
       }
     }
   return result;
}

const editContract = (event) =>  
{
  event.preventDefault();
  var result = [];
  SetIsSubmitting(true);
  if(inputValidation())
  {
    var ratingYesValues = 0;
    var totalQuestions = 20;
    var calculationAnswers = [trueOrFalse(signedByBothParties),trueOrFalse(definitions),trueOrFalse(servicesDescribed),trueOrFalse(reportingClause),trueOrFalse(agentOnly),trueOrFalse(authorityClause),trueOrFalse(exclusivityReciprocal),trueOrFalse(keyIndividuals),trueOrFalse(transparentRemuneration),trueOrFalse(prfIncluded),trueOrFalse(prfWithMalus),trueOrFalse(prfMethodology),trueOrFalse(agencyCommitments),trueOrFalse(auditRights),trueOrFalse(rebatesClauses),trueOrFalse(epdClause),trueOrFalse(unbilledMediaClause),trueOrFalse(competitivePaymentTerms),trueOrFalse(standardTerminationClause),trueOrFalse(standardSubcontractingClause)];
    for (let index = 0; index < calculationAnswers.length; index++) {
      const element = calculationAnswers[index];
      if(element === 1)
      {
        ++ratingYesValues;
      }

      if(index === 0 && element === null)
      {
        totalQuestions = totalQuestions - 1;
      }
      else if(index === 9 && element == 0)
      {
        totalQuestions = totalQuestions - 2;
      }
      else if(index === 15 && element === null)
      {
        totalQuestions = totalQuestions - 1;
      }
    }
    var ratingValue = Math.round((ratingYesValues/totalQuestions* 100),0);
    var rating = AverageRating(parseInt(ratingValue));
    var annualNetSpend = document.getElementById('AnnualNetSpend').value === ""? null: document.getElementById('AnnualNetSpend').value;
    var maxFees = document.getElementById('MaxFees').value === ""? null: document.getElementById('MaxFees').value;
    result.push(document.getElementById('Advertiser').value,document.getElementById('Agency').value,document.getElementById('AgencyGroup').value,document.getElementById('Services').value,document.getElementById('DateOfReview').value);
    result.push(preparedByAbintus.length === 0 ?trueOrFalse(mainData["PreparedByAbintus"]):trueOrFalse(preparedByAbintus),selectedCountry,annualMediaBudget.length === 0?mainData["AnnualMediaBudget"]:annualMediaBudget,ratingValue,rating,annualNetSpend,maxFees,remunerationModel.length === 0?mainData["RemunerationModel"]:remunerationModel);
    result.push(document.getElementById('EstimatedAvbs').value,document.getElementById('AvbCalculation').value,document.getElementById('StartDate').value);
    result.push(lenghtOfContract.length === 0?mainData["LengthOfContract"]:lenghtOfContract,autoRenew.length === 0 ?trueOrFalse(mainData["AutoRenew"]):trueOrFalse(autoRenew),paymentTerms.length === 0? mainData["PaymentTerms"]:paymentTerms,
    trueOrFalse(signedByBothParties),trueOrFalse(definitions),trueOrFalse(servicesDescribed),
    trueOrFalse(reportingClause),trueOrFalse(agentOnly),trueOrFalse(authorityClause),
    trueOrFalse(exclusivityReciprocal),trueOrFalse(keyIndividuals),trueOrFalse(transparentRemuneration),
    trueOrFalse(prfIncluded),trueOrFalse(prfWithMalus),trueOrFalse(prfMethodology),trueOrFalse(agencyCommitments),
    trueOrFalse(auditRights),trueOrFalse(rebatesClauses),trueOrFalse(epdClause),trueOrFalse(unbilledMediaClause),
    trueOrFalse(competitivePaymentTerms),trueOrFalse(standardTerminationClause),trueOrFalse(standardSubcontractingClause));
    var expertIdvalue = selectClient.length === 0 ?mainData["ExpertAssessmentId"]:selectClient;
    if(expertIdvalue === "None")
    {
      expertIdvalue = null;
    }
    result.push(expertIdvalue);
    var accessToken = localStorage.getItem('accessToken');
    var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);
    result.push(validToken.id);
    result.push(document.getElementById('Currency').value);
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
          var token = jwt.sign({ Id:mainData["Id"], contract: result, token: bearer }, process.env.REACT_APP_ReadToken);
          fetch('https://abintus-app-api-linux.azurewebsites.net/contract/edit'
          ,{
            method: 'POST',
            headers: {
                'token': token
            }}
            )
          .then(response => response.text()).then(response => {            
            if(response === false)
            {
              setDialogTitle("Error");
              setDialogText("There has been an issue editing contract. Please try again or contract IT support");
              setOpen(true);
            }
            else
            {
              var evaluationValues = [];
              setDialogTitle("Success");
              setDialogText("Contract has been edited with Id: " + mainData["Id"]);
              SetContractId(mainData["Id"]);
              evaluationValues.push(signedByBothPartiesImpr.length === 0 ?trueOrFalse(evaluationData["SignedByBothPartiesImpr"]):trueOrFalse(signedByBothPartiesImpr),document.getElementById('SignedByBothPartiesComment').value.toString().replace(/'/g,"''"),definitionsImpr.length === 0 ?trueOrFalse(evaluationData["DefinitionsImpr"]):trueOrFalse(definitionsImpr),document.getElementById('DefinitionsComment').value.toString().replace(/'/g,"''"),servicesDescribedImpr.length === 0 ?trueOrFalse(evaluationData["ServicesDescribedImpr"]):trueOrFalse(servicesDescribedImpr),document.getElementById('ServicesDescribedComment').value.toString().replace(/'/g,"''"),
              reportingClauseImpr.length === 0 ?trueOrFalse(evaluationData["ReportingClauseImpr"]):trueOrFalse(reportingClauseImpr),document.getElementById('ReportingClauseComment').value.toString().replace(/'/g,"''"),agentOnlyImpr.length === 0 ?trueOrFalse(evaluationData["AgentOnlyImpr"]):trueOrFalse(agentOnlyImpr),document.getElementById('AgentOnlyComment').value.toString().replace(/'/g,"''"),authorityClauseImpr.length === 0 ?trueOrFalse(evaluationData["AuthorityClauseImpr"]):trueOrFalse(authorityClauseImpr),document.getElementById('AuthorityClauseComment').value.toString().replace(/'/g,"''"),
              exclusivityReciprocalImpr.length === 0 ?trueOrFalse(evaluationData["ExclusivityReciprocalImpr"]):trueOrFalse(exclusivityReciprocalImpr),document.getElementById('ExclusivityReciprocalComment').value.toString().replace(/'/g,"''"),keyIndividualsImpr.length === 0 ?trueOrFalse(evaluationData["KeyIndividualsImpr"]):trueOrFalse(keyIndividualsImpr),document.getElementById('KeyIndividualsComment').value.toString().replace(/'/g,"''"),transparentRemunerationImpr.length === 0 ?trueOrFalse(evaluationData["TransparentRemunerationImpr"]):trueOrFalse(transparentRemunerationImpr),document.getElementById('TransparentRemunerationComment').value.toString().replace(/'/g,"''"),prfIncludedImpr.length === 0 ?trueOrFalse(evaluationData["PRFIncludedImpr"]):trueOrFalse(prfIncludedImpr),document.getElementById('PRFIncludedComment').value.toString().replace(/'/g,"''"));
              evaluationValues.push(prfWithMalusImpr.length === 0 ?trueOrFalse(evaluationData["PRFWithMalusImpr"]):trueOrFalse(prfWithMalusImpr),document.getElementById('PRFWithMalusComment').value.toString().replace(/'/g,"''"),prfMethodologyImpr.length === 0 ?trueOrFalse(evaluationData["PRFMethodologyImpr"]):trueOrFalse(prfMethodologyImpr),document.getElementById('PRFMethodologyComment').value.toString().replace(/'/g,"''"),agencyCommitmentsImpr.length === 0 ?trueOrFalse(evaluationData["AgencyCommitmentsImpr"]):trueOrFalse(agencyCommitmentsImpr),document.getElementById('AgencyCommitmentsComment').value.toString().replace(/'/g,"''"),
              auditRightsImpr.length === 0 ?trueOrFalse(evaluationData["AuditRightsImpr"]):trueOrFalse(auditRightsImpr),document.getElementById('AuditRightsComment').value.toString().replace(/'/g,"''"),rebatesClausesImpr.length === 0 ?trueOrFalse(evaluationData["RebatesClausesImpr"]):trueOrFalse(rebatesClausesImpr),document.getElementById('RebatesClausesComment').value.toString().replace(/'/g,"''"),epdClauseImpr.length === 0 ?trueOrFalse(evaluationData["EPDClauseImpr"]):trueOrFalse(epdClauseImpr),document.getElementById('EPDClauseComment').value.toString().replace(/'/g,"''"),
              unbilledMediaClauseImpr.length === 0 ?trueOrFalse(evaluationData["UnbilledMediaClauseImpr"]):trueOrFalse(unbilledMediaClauseImpr),document.getElementById('UnbilledMediaClauseComment').value.toString().replace(/'/g,"''"),competitivePaymentTermsImpr.length === 0 ?trueOrFalse(evaluationData["CompetitivePaymentTermsImpr"]):trueOrFalse(competitivePaymentTermsImpr),document.getElementById('CompetitivePaymentTermsComment').value.toString().replace(/'/g,"''"),
              standardTerminationClauseImpr.length === 0 ?trueOrFalse(evaluationData["StandardTerminationClauseImpr"]):trueOrFalse(standardTerminationClauseImpr),document.getElementById('StandardTerminationClauseComment').value.toString().replace(/'/g,"''"),standardSubcontractingClauseImpr.length === 0 ?trueOrFalse(evaluationData["StandardSubcontractingClauseImpr"]):trueOrFalse(standardSubcontractingClauseImpr),document.getElementById('StandardSubcontractingClauseComment').value.toString().replace(/'/g,"''"));
              token = jwt.sign({ Id: mainData["Id"],contractEvaluation: evaluationValues,token: bearer }, process.env.REACT_APP_ReadToken);
              fetch('https://abintus-app-api-linux.azurewebsites.net/contract/edit/evaluation'
              ,{
                method: 'POST',
                headers: {
                    'token': token
                }}
                ).then(res => res.text()).then(res => { console.log(res);});
               
                setOpen(true);

              var allContractNames = contractNames;
              if(filesSelected.length > 0)
              {
                var formData = new FormData();
                  var name = [];
                  var type = [];
                  var i=1;
                if(contractNames.length > 0)
                {
                  if(contractNames[0] === "")
                  {
                    i = 1;
                    allContractNames=[];
                  }
                  else
                  {
                    var l = contractNames[contractNames.length - 1].split("DocNr").pop();
                    i = parseInt(l) + 1;
                  }

                  filesSelected.forEach(element => {
                    type.push(element.type);
                    formData.append('file', element);
                    name.push(`ContractID-${mainData["Id"]}-DocNr${i}`);
                    allContractNames.push(`ContractID-${mainData["Id"]}-DocNr${i}`);
                    ++i;
                  })
                  token = jwt.sign({DocumentName:name, AllDocumentNames:allContractNames, Type:type, token: bearer, Id: mainData["Id"]}, process.env.REACT_APP_ReadToken);
                  fetch('https://abintus-app-api-linux.azurewebsites.net/contract/edit-contract/upload'
                    ,{
                      method: 'POST',
                      headers: {
                        'token': token
                    },
                      body:formData
                    }
                      )
                      .then(response => response.text()).then(response => {
                        if(response === "false")
                        {
                          setDialogTitle("Error");
                          setDialogText("Your Contract has been editted but couldn't upload your file");
                          setOpen(true);
                        }
                        else if(removeDocs.length > 0)
                        {
                          console.log("delete");
                          removeDocs.forEach(element => {
                              var index = allContractNames.indexOf(element);
                              allContractNames.splice(index, 1);
                           });
                           console.log(allContractNames);
                           token = jwt.sign({DocumentName:allContractNames ,token: bearer, Id: mainData["Id"]}, process.env.REACT_APP_ReadToken);
                           fetch('https://abintus-app-api-linux.azurewebsites.net/contract/edit-contract/remove'
                             ,{
                               method: 'POST',
                               headers: {
                                 'token': token
                             },
                               body:formData
                             }
                               )
                               .then(response => response.text()).then(response => {
                                 if(response === "false")
                                 {
                                   setDialogTitle("Error");
                                   setDialogText("Your Contract has been editted but couldn't upload your file");
                                   setOpen(true);
                                 }
                               });
                        }
                      });
                }
                else
                {
                  filesSelected.forEach(element => {
                    type.push(element.type);
                    formData.append('file', element);
                    name.push(`ContractID-${mainData["Id"]}-DocNr${i}`);
                    ++i;
                  })
                  token = jwt.sign({DocumentName:name, Type:type, token: bearer, Id: mainData["Id"]}, process.env.REACT_APP_ReadToken);
                  fetch('https://abintus-app-api-linux.azurewebsites.net/contract/add-contract/upload'
                    ,{
                      method: 'POST',
                      headers: {
                        'token': token
                    },
                      body:formData
                    }
                      )
                      .then(response => response.text()).then(response => {
                        if(response === "false")
                        {
                          setDialogTitle("Error");
                          setDialogText("Your Contract has been editted but couldn't upload your file");
                          setOpen(true);
                        }
                      });
                }
              }
              else if(removeDocs.length > 0)
              {
                removeDocs.forEach(element => {
                    var index = allContractNames.indexOf(element);
                    allContractNames.splice(index, 1);
                 });
                 token = jwt.sign({DocumentName:allContractNames ,token: bearer, Id: mainData["Id"]}, process.env.REACT_APP_ReadToken);
                 fetch('https://abintus-app-api-linux.azurewebsites.net/contract/edit-contract/remove'
                   ,{
                     method: 'POST',
                     headers: {
                       'token': token
                   },
                     body:formData
                   }
                     )
                     .then(response => response.text()).then(response => {
                       if(response === "false")
                       {
                         setDialogTitle("Error");
                         setDialogText("Your Contract has been editted but couldn't upload your file");
                         setOpen(true);
                       }
                     });
              }

              setOpen(true);
            }
          });
  }
  else
  {
    setDialogTitle("Validation Error");
    setDialogText("Please modify fields marked with an error");
    setOpen(true);
  }
}

  if(openView && mainData["PreparedByAbintus"] !== undefined && evaluationData["SignedByBothPartiesImpr"] !== undefined)
  {
    return (
      <Page>
        <form id="contractForm">
        <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={0} justify="center">
            <Box mt={2}>
              <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{fontWeight:"bold"}}
                  >
                    Contract ID:{mainData['Id']}
              </Typography>
            </Box>
          </Grid>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={4} xs={12}>
                  <Autocomplete
                      id="Advertiser"
                      value={advertiserList.find(x => x === mainData["Advertiser"]) || ""}
                      freeSolo
                      options={advertiserList.sort().map((option) => option)}
                      renderInput={(params) => (
                        < TextField {...params} label="Advertiser" margin="normal" variant="outlined"
                      />
                      )}
                    />
                  </Grid>
                <Grid item md={4} xs={12}>
                  <Autocomplete
                      id="Agency"
                      freeSolo
                      value={agencyList.find(x => x === mainData["Agency"]) || ""}
                      options={agencyList.sort().map((option) => option)}
                      renderInput={(params) => (
                        <TextField {...params} label="Agency" margin="normal" variant="outlined" />
                      )}
                    />
                  </Grid>
                <Grid item md={4} xs={12}>
                  <Autocomplete
                      id="AgencyGroup"
                      freeSolo
                      value={agencyGroupList.find(x => x === mainData["AgencyGroup"]) || ""}
                      options={agencyGroupList.sort().map((option) => option)}
                      renderInput={(params) => (
                        <TextField {...params} label="Agency Group" margin="normal" variant="outlined" />
                      )}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Autocomplete
                          id="Services"
                          freeSolo
                          value={servicesList.find(x => x === mainData["Services"]) || ""}
                          options={servicesList.sort().map((option) => option)}
                          renderInput={(params) => (
                            <TextField {...params} label="Services" margin="normal" variant="outlined" />
                          )}
                        />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                        style={{width:"100%",marginTop: "16px"}}
                        error = {dateReviewFlag}
                        helperText={dateReviewFlag === true?"Date Of Review can't be blank":""}
                        variant="outlined"
                          id="DateOfReview"
                          label="Date Of Review"
                          type="date"
                          onChange={(event)=> { setDateReviewFlag(false)}}
                          defaultValue={String(mainData["DateOfReview"]).substring(0,10)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl style={{width:"100%", marginTop: "16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="PreparedByAbintusLabel">Prepared by Abintus</InputLabel>
                            <Select
                              error = {preparedByAbintusFlag}
                              id="PreparedByAbintus"
                              labelId="PreparedByAbintusLabel"
                              label="Prepared by Abintus"
                              defaultValue={mainData["PreparedByAbintus"]}
                              onChange={(event) => {
                                setPreparedByAbintus(event.target.value);
                                setPreparedByAbintusFlag(false);
                              }}
                            >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                            <FormHelperText  error = {preparedByAbintusFlag} >{preparedByAbintusFlag === true?"Prepared by Abintus must be set":""}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
              <FormControl style={{width:"100%", marginTop: "16px"}} 
          variant="outlined" className={classes.formControl}>
                        <InputLabel id="CountryLabel">Country</InputLabel>
                          <Select
                            labelId="CountryLabel"
                            label="Country"
                            id="Country"
                            name ="country"
                            value={mainData["Country"]}
                            onChange={(event) => {
                              setSelectedCountry(event.target.value);
                            }}
                          >
                            <MenuItem value="Global">Global</MenuItem>
                            <MenuItem value="Various">Various</MenuItem>
                            {countryList.getNames().sort().map((value,index) =>
                            {
                            return <MenuItem key={index} value={value}>{value}</MenuItem>
                            })}
                          </Select>
            </FormControl>
              </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl style={{width:"100%", marginTop: "16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="AnnualMediaBudgetLabel">Annual Media Budget</InputLabel>
                            <Select
                              error={annualMediaBudgetFlag}
                              labelId="AnnualMediaBudgetLabel"
                              label="Annual Media Budget"
                              id="AnnualMediaBudget"
                              value={mainData["AnnualMediaBudget"]!==undefined?mainData["AnnualMediaBudget"]:""}
                              onChange={(event) => {
                                setAnnuaMediaBudget(event.target.value);
                                setAnnualMediaBudgetFlag(false);
                              }}
                            >
                              <MenuItem value="Less than €1M">Less than €1M</MenuItem>
                              <MenuItem value="€1M - €5M">€1M - €5M</MenuItem>
                              <MenuItem value="€5M - €10M">€5M - €10M</MenuItem>
                              <MenuItem value="€10M - €25M">€10M - €25M</MenuItem>
                              <MenuItem value="€25M - €50M">€25M - €50M</MenuItem>
                              <MenuItem value="€50M - €100M">€50M - €100M</MenuItem>
                            </Select>
                            <FormHelperText  error = {annualMediaBudgetFlag} >{annualMediaBudgetFlag === true?"Annual Media Budget must be set":""}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                  style={{width:"100%",marginTop: "16px"}}
                  variant="outlined"
                  id="AnnualNetSpend"
                  label="Annual Net Spend"
                  type="tel"
                  InputProps={{
                    // inputComponent: NumberFormatCustom,
                    startAdornment: (
                      <InputAdornment position="start">
                        €
                      </InputAdornment>
                    ),
                  }}
                  defaultValue={mainData["AnnualNetSpend"]!==undefined?mainData["AnnualNetSpend"]:""}
                              />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                  style={{width:"100%",marginTop: "16px"}}
                  variant="outlined"
                  id="MaxFees"
                  label="Max Fees"
                  type="tel"
                  InputProps={{
                    // inputComponent: NumberFormatCustom,
                    startAdornment: (
                      <InputAdornment position="start">
                        €
                      </InputAdornment>
                    ),
                  }}
                  defaultValue={mainData["MaxFees"]!==undefined?mainData["MaxFees"]:""}
                              />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                            <InputLabel id="RemunerationModelLabel">Remuneration Model</InputLabel>
                            <Select
                              error={remunerationModelFlag}
                              labelId="RemunerationModelLabel"
                              label="Remuneration Model"
                              id="RemunerationModel"
                              defaultValue={mainData["RemunerationModel"]!==undefined?mainData["RemunerationModel"]:""}
                              onChange={(event) => {
                                setRemunerationModel(event.target.value);
                                setRemunerationModelFlag(false);
                              }}
                            >
                              <MenuItem value="Commission">Commission</MenuItem>
                              <MenuItem value="Commission + PRF">Commission + PRF</MenuItem>
                              <MenuItem value="Commission + Fixed Fees">Commission + Fixed Fees</MenuItem>
                              <MenuItem value="Commission + Fixed Fee + PRF">Commission + Fixed Fee + PRF</MenuItem>
                              <MenuItem value="Daily rate">Daily rate</MenuItem>
                              <MenuItem value="Fixed Fees">Fixed Fees</MenuItem>
                              <MenuItem value="Fixed Fees + PRF">Fixed Fees + PRF</MenuItem>
                            </Select>
                            <FormHelperText  error = {remunerationModelFlag} >{remunerationModelFlag === true?"Remuneration Model must be set":""}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                            style={{width:"100%",marginTop: "16px"}}
                            variant="outlined"
                            id="EstimatedAvbs"
                            label="Estimated AVBs"
                            type="text"
                            InputProps={{
                              // inputComponent: NumberFormatCustom,
                              startAdornment: (
                                <InputAdornment position="start">
                                  €
                                </InputAdornment>
                              ),
                            }}
                            defaultValue={mainData["EstimatedAVBs"]!==undefined?mainData["EstimatedAVBs"]:""}
                            InputLabelProps={{
                              shrink: true,
                            }}
                        />
                </Grid>
                <Grid item md={4} xs={12}>
                <Autocomplete
                    id="AvbCalculation"
                    freeSolo
                    value={avbCalculations.find(x => x === mainData["AVBCalculation"]) || ""}
                    options={avbCalculations.sort().map((option) => option)}
                    renderInput={(params) => (
                      < TextField {...params} label="AVB Calculation" margin="normal" variant="outlined"
                    />
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                          style={{width:"100%", marginTop:"16px", fill:"#f8d02d",color:"primary",buttonColor:"#f8d02d"}}
                          error = {startDateFlag}
                          helperText={startDateFlag === true?"Start Date can't be blank":""}
                          variant="outlined"
                          id="StartDate"
                          label="Start Date"
                          type="date"
                          defaultValue={String(mainData["StartDate"]).substring(0,10)}
                          onChange={(event)=>{setStartDateFlag(false);}}
                          InputLabelProps={{
                            fill:"#f8d02d",
                            color:"primary",
                            shrink: true,
                          }}
                        />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="LengthOfContractLabel">Length Of Contract</InputLabel>
                          <Select
                              labelId="LengthOfContractLabel"
                              label = "Length Of Contract"
                              id="LengthOfContract"
                              error={lenghtOfContractFlag}
                              defaultValue={mainData["LengthOfContract"]!==undefined?mainData["LengthOfContract"]:""}
                              onChange={(event) => {
                                setLenghtOfContract(event.target.value);
                                setLenghtOfContractFlag(false);
                              }}
                            >
                              <MenuItem value="1 Year">1 Year</MenuItem>
                              <MenuItem value="2 Years">2 Years</MenuItem>
                              <MenuItem value="3 Years">3 Years</MenuItem>
                              <MenuItem value="4 Years">4 Years</MenuItem>
                              <MenuItem value="5 Years">5 Years</MenuItem>
                              <MenuItem value="Indefinite">Indefinite</MenuItem>
                            </Select>
                            <FormHelperText  error = {lenghtOfContractFlag} >{lenghtOfContractFlag === true?"Length Of Contract must be set":""}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="AutoRenewLabel">Auto Renew</InputLabel>
                            <Select 
                              error={autoRenewFlag}
                              labelId="AutoRenewLabel"
                              label = "Auto Renew"
                              id="AutoRenew"
                              defaultValue={mainData["AutoRenew"]!==undefined?mainData["AutoRenew"]:""}
                              onChange={(event) => {
                                setAutoRenew(event.target.value);
                                setAutoRenewFlag(false);
                              }}
                            >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                            <FormHelperText  error = {autoRenewFlag} >{autoRenewFlag === true?"Auto Renew must be set":""}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                      <InputLabel id="PaymentTermsLabel">Payment Terms</InputLabel>
                        <Select
                          error={paymentTermsFlag}
                          label="Payment Terms"
                          labelId="PaymentTermsLabel"
                          id="PaymentTerms"
                          defaultValue={mainData["PaymentTerms"]!==undefined?mainData["PaymentTerms"]:""}
                          onChange={(event) => {
                            setPaymentTerms(event.target.value);
                            setPaymentTermsFlag(false);
                          }}
                        >
                          <MenuItem value="< 30 Days">&lt; 30 Days</MenuItem>
                          <MenuItem value="30 Days">30 Days</MenuItem>
                          <MenuItem value="60 Days">60 Days</MenuItem>
                          <MenuItem value="90 Days">90 Days</MenuItem>
                          <MenuItem value="120 Days">120 Days</MenuItem>
                        </Select>
                        <FormHelperText  error = {paymentTermsFlag} >{paymentTermsFlag === true?"Payment Terms must be set":""}</FormHelperText>
                        </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                <Autocomplete
                    id="Currency"
                    value={currencyList.find(x => x === mainData["Currency"]) || ""}
                    freeSolo
                    options={currencyList.sort().map((option) => option)}
                    renderInput={(params) => (
                      <TextField {...params} label="Currency" margin="normal" variant="outlined"
                    />
                    )}
                  />
              </Grid>
                <Grid item md={4} xs={12} >
                <FormControl style={{width:"100%", marginTop: "16px"}} 
                 variant="outlined" className={classes.formControl}>
                        <InputLabel id="UserLabel">Select Client</InputLabel>
                          <Select
                            labelId="UserLabel"
                            label="Select Client"
                            id="Expert"
                            name ="expert"
                            defaultValue={mainData["ExpertAssessmentId"]!=="Not Applicable"?mainData["ExpertAssessmentId"]:"None"}
                            onChange={(event) => {
                              setSelectClient(event.target.value);
                            }}
                          >
                            <MenuItem value="None">None</MenuItem>
                            {expertIds.map((value,index) =>
                            {
                            return <MenuItem key={index} value={value[0]}>{value[1]}</MenuItem>
                            })}
                          </Select>
            </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <div style={{paddingBottom: "24px"}}></div>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
              <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="SignedbyBothPartiesLabel">Signed By Both Parties</InputLabel>
                          <Select
                            label= "Signed By Both Parties"
                            labelId="SignedbyBothPartiesLabel"
                            id="SignedByBothParties"
                            defaultValue={mainData["SignedByBothParties"]}
                            onChange={(event) => {
                              setSignedByBothParties(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="SignedbyBothPartiesImprLabel">Improvements Required?</InputLabel>
                          <Select
                            label= "Improvements Required?"
                            labelId="SignedbyBothPartiesImprLabel"
                            id="SignedByBothPartiesImpr"
                            defaultValue={evaluationData["SignedByBothPartiesImpr"]}
                            onChange={(event) => {
                              setSignedByBothPartiesImpr(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <TextField
                          style={{width:"100%",marginTop: "16px"}}
                          variant="outlined"
                            id="SignedByBothPartiesComment"
                            label="Comment"
                            type="text"
                            defaultValue={evaluationData["SignedByBothPartiesComment"]}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="DefinitionsLabel">Definitions</InputLabel>
                          <Select
                            label="Definitions"
                            id="Definitions"
                            labelId="DefinitionsLabel"
                            defaultValue={mainData["Definitions"]}
                            onChange={(event) => {
                              setDefinitions(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="DefinitionsImprLabel">Improvements Required?</InputLabel>
                          <Select
                            label="Improvements Required?"
                            id="DefinitionsImpr"
                            labelId="DefinitionsImprLabel"
                            defaultValue={evaluationData["DefinitionsImpr"]}
                            onChange={(event) => {
                              setDefinitionsImpr(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <TextField
                            style={{width:"100%",marginTop: "16px"}}
                            variant="outlined"
                              id="DefinitionsComment"
                              label="Comment"
                              type="text"
                              defaultValue={evaluationData["DefinitionsComment"]!==undefined?evaluationData["DefinitionsComment"]:""}
                              InputLabelProps={{
                                shrink: true,
                              }}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="ServicesDescribedLabel">Services Described</InputLabel>
                        <Select
                          label="Services Described"
                          id="ServicesDescribed"
                          labelId="ServicesDescribedLabel"
                          defaultValue={mainData["ServicesDescribed"]!==undefined?mainData["ServicesDescribed"]:""}
                          onChange={(event) => {
                            setServicesDescribed(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="ServicesDescribedImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="ServicesDescribedImpr"
                          labelId="ServicesDescribedImprLabel"
                          defaultValue={evaluationData["ServicesDescribedImpr"]!==undefined?evaluationData["ServicesDescribedImpr"]:""}
                          onChange={(event) => {
                            setServicesDescribedImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                            style={{width:"100%",marginTop: "16px"}}
                            variant="outlined"
                              id="ServicesDescribedComment"
                              defaultValue={evaluationData["ServicesDescribedComment"]!==undefined?evaluationData["ServicesDescribedComment"]:""}
                              label="Comment"
                              type="text"
                              InputLabelProps={{
                                shrink: true,
                              }}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="ReportingClauseLabel">Reporting Clause</InputLabel>
                          <Select
                            id="ReportingClause"
                            label="Reporting Clause"
                            labelId="ReportingClauseLabel"
                            defaultValue={mainData["ReportingClause"]!==undefined?mainData["ReportingClause"]:""}
                            onChange={(event) => {
                              setReportingClause(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="ReportingClauseImprLabel">Improvements Required?</InputLabel>
                          <Select
                            id="ReportingClauseImpr"
                            label="Needs Improvements?"
                            labelId="ReportingClauseImprLabel"
                            defaultValue={evaluationData["ReportingClauseImpr"]!==undefined?evaluationData["ReportingClauseImpr"]:""}
                            onChange={(event) => {
                              setReportingClauseImpr(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <TextField
                              style={{width:"100%",marginTop: "16px"}}
                              variant="outlined"
                                id="ReportingClauseComment"
                                label="Comment"
                                type="text"
                                defaultValue={evaluationData["ReportingClauseComment"]!==undefined?evaluationData["ReportingClauseComment"]:""}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                      />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="AgentOnlyLabel">Agent Only</InputLabel>
                        <Select
                          label="Agent Only"
                          id="AgentOnly"
                          labelId="AgentOnlyLabel"
                          defaultValue={mainData["AgentOnly"]!==undefined?mainData["AgentOnly"]:""}
                          onChange={(event) => {
                            setAgentOnly(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="AgentOnlyImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="AgentOnlyImpr"
                          labelId="AgentOnlyImprLabel"
                          defaultValue={evaluationData["AgentOnlyImpr"]!==undefined?evaluationData["AgentOnlyImpr"]:""}
                          onChange={(event) => {
                            setAgentOnlyImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="AgentOnlyComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue={evaluationData["AgentOnlyComment"]!==undefined?evaluationData["AgentOnlyComment"]:""}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="AuthorityClauseLabel">Authority Clause</InputLabel>
                          <Select
                            label="Authority Clause"
                            id="AuthorityClause"
                            labelId="AuthorityClauseLabel"
                            defaultValue={mainData["AuthorityClause"]!==undefined?mainData["AuthorityClause"]:""}
                            onChange={(event) => {
                              setAuthorityClause(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="AuthorityClauseImprLabel">Improvements Required?</InputLabel>
                          <Select
                            label="Improvements Required?"
                            id="AuthorityClauseImpr"
                            labelId="AuthorityClauseImprLabel"
                            defaultValue={evaluationData["AuthorityClauseImpr"]!==undefined?evaluationData["AuthorityClauseImpr"]:""}
                            onChange={(event) => {
                              setAuthorityClauseImpr(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="AuthorityClauseComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue={evaluationData["AuthorityClauseComment"]!==undefined?evaluationData["AuthorityClauseComment"]:""}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="ExclusivityReciprocalLabel">Exclusivity Reciprocal</InputLabel>
                        <Select
                          label="Exclusivity Reciprocal"
                          id="ExclusivityReciprocal"
                          labelId="ExclusivityReciprocalLabel"
                          defaultValue={mainData["ExclusivityReciprocal"]!==undefined?mainData["ExclusivityReciprocal"]:""}
                          onChange={(event) => {
                            setExclusivityReciprocal(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="ExclusivityReciprocalImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="ExclusivityReciprocalImpr"
                          labelId="ExclusivityReciprocalLabel"
                          defaultValue={evaluationData["ExclusivityReciprocalImpr"]!==undefined?evaluationData["ExclusivityReciprocalImpr"]:""}
                          onChange={(event) => {
                            setExclusivityReciprocalImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="ExclusivityReciprocalComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue={evaluationData["ExclusivityReciprocalComment"]!==undefined?evaluationData["ExclusivityReciprocalComment"]:""}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="KeyIndividualsLabel">Key Individuals</InputLabel>
                        <Select
                          label="Key Individuals"
                          id="KeyIndividuals"
                          labelId="KeyIndividualsLabel"
                          defaultValue={mainData["KeyIndividuals"]!==undefined?mainData["KeyIndividuals"]:""}
                          onChange={(event) => {
                            setKeyIndividuals(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="KeyIndividualsImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="KeyIndividualsImpr"
                          labelId="KeyIndividualsImprLabel"
                          defaultValue={evaluationData["KeyIndividualsImpr"]!==undefined?evaluationData["KeyIndividualsImpr"]:""}
                          onChange={(event) => {
                            setKeyIndividualsImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="KeyIndividualsComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue={evaluationData["KeyIndividualsComment"]!==undefined?evaluationData["KeyIndividualsComment"]:""}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="TransparentRemunerationLabel">Transparent Remuneration</InputLabel>
                          <Select
                            label="Transparent Remuneration"
                            id="TransparentRemuneration"
                            labelId="TransparentRemunerationLabel"
                            defaultValue={mainData["TransparentRemuneration"]!==undefined?mainData["TransparentRemuneration"]:""}
                            onChange={(event) => {
                              setTransparentRemuneration(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="TransparentRemunerationImprLabel">Improvements Required?</InputLabel>
                          <Select
                            label="Improvements Required?"
                            id="TransparentRemunerationImpr"
                            labelId="TransparentRemunerationImprLabel"
                            defaultValue={evaluationData["TransparentRemunerationImpr"]!==undefined?evaluationData["TransparentRemunerationImpr"]:""}
                            onChange={(event) => {
                              setTransparentRemunerationImpr(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="TransparentRemunerationComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue={evaluationData["TransparentRemunerationComment"]!==undefined?evaluationData["TransparentRemunerationComment"]:""}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="PRFIncludedLabel">PRF Included</InputLabel>
                          <Select
                            label="PRF Included"
                            id="PRFIncluded"
                            labelId="PRFIncludedLabel"
                            defaultValue={mainData["PRFIncluded"]!==undefined?mainData["PRFIncluded"]:""}
                            onChange={(event) => {
                              setPrfIncluded(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="PRFIncludedImprLabel">Improvements Required?</InputLabel>
                          <Select
                            label="Improvements Required?"
                            id="PRFIncludedImpr"
                            labelId="PRFIncludedImprLabel"
                            defaultValue={evaluationData["PRFIncludedImpr"]!==undefined?evaluationData["PRFIncludedImpr"]:""}
                            onChange={(event) => {
                              setPrfIncludedImpr(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="PRFIncludedComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue={evaluationData["PRFIncludedComment"]!==undefined?evaluationData["PRFIncludedComment"]:""}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="PRFWithMalusLabel">PRF With Malus</InputLabel>
                          <Select
                            label="PRF With Malus"
                            id="PRFWithMalus"
                            labelId="PRFWithMalusLabel"
                            defaultValue={mainData["PRFWithMalus"]!==undefined?mainData["PRFWithMalus"]:""}
                            onChange={(event) => {
                              setPrfWithMalus(event.target.value);
                            }}
                          >
                         {/* <MenuItem value="Yes">Yes</MenuItem>
                         <MenuItem value="No">No</MenuItem>
                         <MenuItem value="Not Applicable">Not Applicable</MenuItem> */}
                         {prfIncluded ===  "Yes" && <MenuItem value="Yes">Yes</MenuItem>}
                        {prfIncluded ===  "Yes" && <MenuItem value="No">No</MenuItem>}
                        {prfIncluded ===  "No" && <MenuItem value="Not Applicable">Not Applicable</MenuItem>}
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="PRFWithMalusImprLabel">Improvements Required?</InputLabel>
                          <Select
                            label="Improvements Required?"
                            id="PRFWithMalusImpr"
                            labelId="PRFWithMalusImprLabel"
                            defaultValue={evaluationData["PRFWithMalusImpr"]!==undefined?evaluationData["PRFWithMalusImpr"]:""}
                            onChange={(event) => {
                              setPrfWithMalusImpr(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="PRFWithMalusComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue={evaluationData["PRFWithMalusComment"]!==undefined?evaluationData["PRFWithMalusComment"]:""}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="PRFMethodologyLabel">PRF Methodology</InputLabel>
                          <Select 
                            label="PRF Methodology"
                            id="PRFMethodology"
                            labelId="PRFMethodologyLabel"
                            defaultValue={mainData["PRFMethodology"]!==undefined?mainData["PRFMethodology"]:""}
                            onChange={(event) => {
                              setPrfMethodology(event.target.value);
                            }}
                          >
                          {prfIncluded ===  "Yes" && <MenuItem value="Yes">Yes</MenuItem>}
                        {prfIncluded ===  "Yes" && <MenuItem value="No">No</MenuItem>}
                        {prfIncluded ===  "No" && <MenuItem value="Not Applicable">Not Applicable</MenuItem>}
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="PRFMethodologyImprLabel">Improvements Required?</InputLabel>
                          <Select 
                            label="Improvements Required?"
                            id="PRFMethodologyImpr"
                            labelId="PRFMethodologyImprLabel"
                            defaultValue={evaluationData["PRFMethodologyImpr"]!==undefined?evaluationData["PRFMethodologyImpr"]:""}
                            onChange={(event) => {
                              setPrfMethodologyImpr(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                style={{width:"100%",marginTop: "16px"}}
                                variant="outlined"
                                  id="PRFMethodologyComment"
                                  label="Comment"
                                  type="text"
                                  defaultValue={evaluationData["PRFMethodologyComment"]!==undefined?evaluationData["PRFMethodologyComment"]:""}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                        />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="AgencyCommitmentsLabel">Agency Commitments</InputLabel>
                          <Select
                            label="Agency Commitments"
                            id="AgencyCommitments"
                            labelId="AgencyCommitmentsLabel"
                            defaultValue={mainData["AgencyCommitments"]!==undefined?mainData["AgencyCommitments"]:""}
                            onChange={(event) => {
                              setAgencyCommitments(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="AgencyCommitmentsImprLabel">Improvements Required?</InputLabel>
                          <Select
                            label="Improvements Required?"
                            id="AgencyCommitmentsImpr"
                            labelId="AgencyCommitmentsImprLabel"
                            defaultValue={evaluationData["AgencyCommitmentsImpr"]!==undefined?evaluationData["AgencyCommitmentsImpr"]:""}
                            onChange={(event) => {
                              setAgencyCommitmentsImpr(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <TextField
                                  style={{width:"100%",marginTop: "16px"}}
                                  variant="outlined"
                                    id="AgencyCommitmentsComment"
                                    label="Comment"
                                    type="text"
                                    defaultValue={evaluationData["AgencyCommitmentsComment"]!==undefined?evaluationData["AgencyCommitmentsComment"]:""}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                          />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="AuditRightsLabel">Audit Rights</InputLabel>
                          <Select
                            label="Audit Rights"
                            id="AuditRights"
                            labelId="AuditRightsLabel"
                            defaultValue={mainData["AuditRights"]!==undefined?mainData["AuditRights"]:""}
                            onChange={(event) => {
                              setAuditRights(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="AuditRightsImprLabel">Improvements Required?</InputLabel>
                          <Select
                            label="Improvements Required?"
                            id="AuditRightsImpr"
                            labelId="AuditRightsImprLabel"
                            defaultValue={evaluationData["AuditRightsImpr"]!==undefined?evaluationData["AuditRightsImpr"]:""}
                            onChange={(event) => {
                              setAuditRightsImpr(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                  style={{width:"100%",marginTop: "16px"}}
                                  variant="outlined"
                                    id="AuditRightsComment"
                                    label="Comment"
                                    type="text"
                                    defaultValue={evaluationData["AuditRightsComment"]!==undefined?evaluationData["AuditRightsComment"]:""}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                          />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="RebatesClausesLabel">Rebates Clauses</InputLabel>
                        <Select
                          label="Rebates Clauses"
                          id="RebatesClauses"
                          labelId="RebatesClausesLabel"
                          defaultValue={mainData["RebatesClauses"]!==undefined?mainData["RebatesClauses"]:""}
                          onChange={(event) => {
                            setRebatesClauses(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="RebatesClausesImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="RebatesClausesImpr"
                          labelId="RebatesClausesImprLabel"
                          defaultValue={evaluationData["RebatesClausesImpr"]!==undefined?evaluationData["RebatesClausesImpr"]:""}
                          onChange={(event) => {
                            setRebatesClausesImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                  style={{width:"100%",marginTop: "16px"}}
                                  variant="outlined"
                                    id="RebatesClausesComment"
                                    label="Comment"
                                    type="text"
                                    defaultValue={evaluationData["RebatesClausesComment"]!==undefined?evaluationData["RebatesClausesComment"]:""}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                          />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="EPDClauseLabel">EPD Clauses</InputLabel>
                          <Select
                            label="EPD Clauses"
                            id="EPDClause"
                            labelId="EPDClauseLabel"
                            defaultValue={mainData["EPDClause"]!==undefined?mainData["EPDClause"]:""}
                            onChange={(event) => {
                              setEpdClause(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="EPDClauseImprLabel">Improvements Required?</InputLabel>
                          <Select
                            label="Improvements Required?"
                            id="EPDClauseImpr"
                            labelId="EPDClauseImprLabel"
                            defaultValue={evaluationData["EPDClauseImpr"]!==undefined?evaluationData["EPDClauseImpr"]:""}
                            onChange={(event) => {
                              setEpdClauseImpr(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                  style={{width:"100%",marginTop: "16px"}}
                                  variant="outlined"
                                    id="EPDClauseComment"
                                    label="Comment"
                                    type="text"
                                    defaultValue={evaluationData["EPDClauseComment"]!==undefined?evaluationData["EPDClauseComment"]:""}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                          />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="UnbilledMediaClauseLabel">Unbilled Media Clause</InputLabel>
                        <Select
                          label="Unbilled Media Clause"
                          id="UnbilledMediaClause"
                          labelId="UnbilledMediaClauseLabel"
                          defaultValue={mainData["UnbilledMediaClause"]!==undefined?mainData["UnbilledMediaClause"]:""}
                          onChange={(event) => {
                            setUnbilledMediaClause(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="UnbilledMediaClauseImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="UnbilledMediaClauseImpr"
                          labelId="UnbilledMediaClauseImprLabel"
                          defaultValue={evaluationData["UnbilledMediaClauseImpr"]!==undefined?evaluationData["UnbilledMediaClauseImpr"]:""}
                          onChange={(event) => {
                            setUnbilledMediaClauseImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                  style={{width:"100%",marginTop: "16px"}}
                                  variant="outlined"
                                    id="UnbilledMediaClauseComment"
                                    label="Comment"
                                    type="text"
                                    defaultValue={evaluationData["UnbilledMediaClauseComment"]!==undefined?evaluationData["UnbilledMediaClauseComment"]:""}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                          />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="CompetitivePaymentTermsLabel">Competitive Payment Terms</InputLabel>
                        <Select
                          label="Competitive Payment Terms"
                          id="CompetitivePaymentTerms"
                          labelId="CompetitivePaymentTermsLabel"
                          defaultValue={mainData["CompetitivePaymentTerms"]!==undefined?mainData["CompetitivePaymentTerms"]:""}
                          onChange={(event) => {
                            setCompetitivePaymentTerms(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="CompetitivePaymentTermsImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="CompetitivePaymentTermsImpr"
                          labelId="CompetitivePaymentTermsImprLabel"
                          defaultValue={evaluationData["CompetitivePaymentTermsImpr"]!==undefined?evaluationData["CompetitivePaymentTermsImpr"]:""}
                          onChange={(event) => {
                            setCompetitivePaymentTermsImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                  style={{width:"100%",marginTop: "16px"}}
                                  variant="outlined"
                                    id="CompetitivePaymentTermsComment"
                                    label="Comment"
                                    type="text"
                                    defaultValue={evaluationData["CompetitivePaymentTermsComment"]!==undefined?evaluationData["CompetitivePaymentTermsComment"]:""}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                          />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="StandardTerminationClauseLabel">Standard Termination Clause</InputLabel>
                          <Select
                            label="Standard Termination Clause"
                            id="StandardTerminationClause"
                            labelId="StandardTerminationClauseLabel"
                            defaultValue={mainData["StandardTerminationClause"]!==undefined?mainData["StandardTerminationClause"]:""}
                            onChange={(event) => {
                              setStandardTerminationClause(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                          <InputLabel id="StandardTerminationClauseImprLabel">Improvements Required?</InputLabel>
                          <Select
                            label="Improvements Required?"
                            id="StandardTerminationClauseImpr"
                            labelId="StandardTerminationClauseImprLabel"
                            defaultValue={evaluationData["StandardTerminationClauseImpr"]!==undefined?evaluationData["StandardTerminationClauseImpr"]:""}
                            onChange={(event) => {
                              setStandardTerminationClauseImpr(event.target.value);
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                          </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                  style={{width:"100%",marginTop: "16px"}}
                                  variant="outlined"
                                    id="StandardTerminationClauseComment"
                                    label="Comment"
                                    type="text"
                                    defaultValue={evaluationData["StandardTerminationClauseComment"]!==undefined?evaluationData["StandardTerminationClauseComment"]:""}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                          />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                style={{ marginTop:"30px"}}
                variant="subtitle1"
                color="textPrimary"
                align="center"
                >
                {++columnNumber}
                </Typography>
                </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="StandardSubcontractingClauseLabel">Standard Subcontracting Clause</InputLabel>
                        <Select
                          label="Standard Subcontracting Clause"
                          id="StandardSubcontractingClause"
                          labelId="StandardSubcontractingClauseLabel"
                          defaultValue={mainData["StandardSubcontractingClause"]!==undefined?mainData["StandardSubcontractingClause"]:""}
                          onChange={(event) => {
                            setStandardSubcontractingClause(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <FormControl style={{width:"100%", marginTop:"16px"}} variant="outlined" className={classes.formControl}>
                        <InputLabel id="StandardSubcontractingClauseImprLabel">Improvements Required?</InputLabel>
                        <Select
                          label="Improvements Required?"
                          id="StandardSubcontractingClauseImpr"
                          labelId="StandardSubcontractingClauseImprLabel"
                          defaultValue={evaluationData["StandardSubcontractingClauseImpr"]!==undefined?evaluationData["StandardSubcontractingClauseImpr"]:""}
                          onChange={(event) => {
                            setStandardSubcontractingClauseImpr(event.target.value);
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                        </FormControl>
                  </Grid>
                  <Grid item md={5} xs={12}>
                  <TextField
                                  style={{width:"100%",marginTop: "16px"}}
                                  variant="outlined"
                                    id="StandardSubcontractingClauseComment"
                                    label="Comment"
                                    type="text"
                                    defaultValue={evaluationData["StandardSubcontractingClauseComment"]!==undefined?evaluationData["StandardSubcontractingClauseComment"]:""}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                          />
                  </Grid>
                  <Grid item md={8} xs={8}>
                  <Box mt={4} ml={10}>
                  <Typography
                  variant="h2"
                  color="textPrimary"
                  align="left"
                  >
                 Edit Contracts Documents
                </Typography>
                  </Box>
                  <Box mt={4} ml={10}>
                  {
                    contractLinks.map((link,x) => (
                      <Box mt={2} key={x} id={`Document${x}`}> <a href={link} name={contractNames[x]} target="_blank" rel="noopener noreferrer" ><Button variant="contained" color="primary" style={{display:"inline-block"}}>View Doc. {x + 1}</Button></a>
                    {/* <input  style={{display: "none"}} type="file" id={`File${x}`} name={`File${x}`}/>
                    <label htmlFor={`File${x}`}>
                    </label> */}
                    <Button ref={btnRef} onClick={removeDoc.bind(this,x)} variant="contained" component="span" color="secondary" style={{display:"inline-block",left: "4%"}}>Remove Doc. {x + 1}</Button>
                      </Box>
                    ))
                  }
                  </Box>
                    </Grid>
                    <Grid item md={4} xs={4}>
                    <Box mt={4}>
                    <Typography
                  variant="h2"
                  color="textPrimary"
                  align="left"
                  >
                  Add Contract Documents
                </Typography>
                    <CustomDropBox
                        acceptedFiles={['application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/docx','application/pdf','application/msword','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint','application/vnd.openxmlformats-officedocument.presentationml.slideshow','application/vnd.openxmlformats-officedocument.presentationml.presentation']}
                        filesLimit={10}
                        maxFileSize={60000000}
                        showFileNames={true}
                        dropzoneText={'Drag and drop files here or click here'}
                        onChange={(files) => {selectFile(files)}}
                  />
                    </Box>
                    </Grid>
                     <Grid item md={12} xs={12} align="center">
                    <Button  disabled={isSubmitting} variant="contained" color="secondary" style={{left: "4%"}} onClick={editContract}>Save &amp; Edit Contract</Button>
                    <a href={`/app/admin/evaluation-table/${mainData["Id"]}`} style={{ textDecoration: "none"}}><Button variant="contained" color="secondary" style={{left: "6%",}} >Evaluation Table</Button></a>
                  </Grid>
              </Grid>
            </CardContent>
          </Card>
          
        </Container>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="DialogTitle">
          <DialogTitle id="DialogTitle">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
           {dialogTitle === "Success" && 
            <Button variant="contained" color="secondary" onClick={contractEvaluation}>Evaluation Table</Button>
           } 
            <Button variant="contained" color="secondary" onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        </form>
      </Page>
    );
  }
  else{
    return ( <div></div>)
  }
 
}

const classes = useStyles;
export default function EditContractView() {
  const [contractLink, SetContractLink] = React.useState("");
  const [buttonDisable, setButtonDisable] = React.useState(false);
  const [showContractView, SetShowContractView] = useState(false);
  const [mainContractData, SetMainContractData] = useState([]);
  const [evaluationContractData, SetEvaluationContractData] = useState([]);
  const [id, setId] = React.useState(71398);
  const [idFlag, setIdFlag] = React.useState(false);

  const findContract = () =>
  {
    setButtonDisable(true);
    if(id === "")
    {
      setIdFlag(true);
      setButtonDisable(false);
      return;
    }
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ Id: id, token: bearer }, process.env.REACT_APP_ReadToken);
    fetch('https://abintus-app-api-linux.azurewebsites.net/contract/information'
    ,{
      method: 'GET',
      headers: {
          'token': token
      }}
      )
    .then(response => response.json()).then(response => { 
      console.log(response);
      if(response.length === 0)
      {
        setIdFlag(true);
        setButtonDisable(false);
        return;
      }
      else
      {
        SetShowContractView(true);
        SetMainContractData(response[0]);
        fetch('https://abintus-app-api-linux.azurewebsites.net/contract/evaluation/information'
        ,{
          method: 'GET',
          headers: {
              'token': token
          }}
          )
        .then(evaluation => evaluation.json()).then(evaluation => { 
          SetEvaluationContractData(evaluation[0]);
          var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
        var token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);
        var contractUrls = [];
        fetch('https://abintus-app-api-linux.azurewebsites.net/contracts/url/list'
        ,{
          method: 'GET',
          headers: {
              'token': token
          }}
          )
        .then(res => res.json())
        .then(res => {
          contractUrls = res;
          for(var i = 0; i < contractUrls.length; i++)
          {
            if(contractUrls[i][0] === response[0]["Id"])
            {
              SetContractLink(contractUrls[i][1]);
              break;
            }
          }
        });
      });
      }
  });
  }


  return (
    <Page
    className={classes.root}
    title="Edit Contract"
  >
    <Container maxWidth={false} className={classes.container} style={{ paddingLeft: 64,paddingRight: 64,
    paddingTop: "24px",
    paddingBottom: "24px"}}>
    <Header /> 
    {showContractView === false &&
    <Grid container spacing={3}
    direction="column"
    alignItems="center"
    justify="center"
    >
    <Card>
      <CardContent style={{padding : "24px"}}>
      <Grid item  align="center" >
                <TextField
                          style={{width:"100%",marginTop:"20px"}}
                          error = {idFlag}
                          helperText={idFlag === true?"Incorrect Contract Id":""}
                          variant="outlined"
                          align="left"
                          id="ContractId"
                          label="Contract Id"
                          type="tel"
                          defaultValue={id}
                          onChange ={(event)=> {if(idFlag === true){setIdFlag(false)}; setId(event.target.value);}}
                          InputLabelProps={{
                            shrink: true,
                          }}
                      />
                      <Button disabled={buttonDisable} style={{marginTop:"20px"}} variant="contained" color="secondary" 
                      onClick={findContract}
                      >Find Contract</Button>
                      
                      
      </Grid>
      </CardContent>
    </Card>
                        </Grid> }
  <div style={{paddingBottom: "34px"}}></div>
                        {showContractView && <AddContractView openView={showContractView} mainData={mainContractData} evaluationData={evaluationContractData} contract={contractLink}></AddContractView> }
    </Container>
  </Page>
  );
}
