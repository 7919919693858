import React, {
  useState,
  useLayoutEffect
} from 'react';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import PersonalDetails from './PersonalDetails';
import WorkStatus from './WorkStatus';
import Password from './Password';
import Logs from './Logs';
import Billing from './Billing';
var jwt = require('jsonwebtoken');

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
      paddingTop: "24px",
      paddingBottom: "24px"
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: 10,
      paddingLeft: 10,
    },
  },
}));

function CustomerDetailsView() {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [industry, setIndustry] = React.useState("");
  const [mediaAgency, setMediaAgency] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [email, setEmail] = React.useState("");
  const tabs = [
    { value: 'details', label: 'Personal Details' },
    { value: 'workStatus', label: 'Work Status' },
    { value: 'password', label: 'Password' },
    // { value: 'billing', label: 'Billing' },
    // { value: 'logs', label: 'Recent Activity' }
  ];
  
  var tabToken = localStorage.getItem('currentTab')
  const [currentTab, setCurrentTab] = useState(tabToken ? tabToken : 'details');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    localStorage.setItem('currentTab', value);
  };

  useLayoutEffect(() => { 
    var id = 0;
    jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {
      id = parseInt(decoded.id);
      setEmail(decoded.username);
    });
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ UserId:id, token: bearer }, process.env.REACT_APP_ReadToken);
    fetch('https://abintus-app-api-linux.azurewebsites.net/user/info'
    ,{
      method: 'GET',
      headers: {
          'token': token
      }}
      )
    .then(response => response.json()).then(response => {      
      setFirstName(response[0][0]);
      setLastName(response[0][1]);
      setUserId(id);
      if(response[0][4] !== null)
      {
        setLocation(response[0][4]);
      }

      if(response[0][5] !== null)
      {
        setMobileNumber(response[0][5]);
      }

      if(response[0][2] !== null)
      {
        setCompanyName(response[0][2]);
      }

      if(response[0][3] !== null)
      {
        setJobTitle(response[0][3]);
      }

      if(response[0][6] !== null)
      {
        setIndustry(response[0][6]);
      }

      if(response[0][7] !== null)
      {
        setMediaAgency(response[0][7]);
      }

    })
    .catch(err => {
      console.log(err)
    })
    ;
  },[]);

  return (
    <Page
      className={classes.root}
      title="Settings"
    >
      <Container maxWidth={false} className={classes.container} 
      // style={{ paddingLeft: 64,paddingRight: 64,
      // paddingTop: "24px",
      // paddingBottom: "24px"}}
      >
        <Header />
        <Box mt={3} style={{ display: "flex", justifyContent: "center" }}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === 'details' && <PersonalDetails firstName={firstName} lastName={lastName} location={location} userId={userId} mobileNumber={mobileNumber} email={email}/>}
          {currentTab === 'workStatus' && <WorkStatus companyName={companyName} jobTitle={jobTitle} industry={industry} mediaAgency={mediaAgency} userId={userId} />}
          {currentTab === 'password' && <Password userId={userId} email={email} />}          
        </Box>
      </Container>
    </Page>
  );
}

export default CustomerDetailsView;
