import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  makeStyles,
  CardContent,
  Card,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Hidden,
  useMediaQuery,
} from "@material-ui/core";
import styled from "styled-components";
import style from "./Options.css";
import DownArrow from "./Media/downArrow.svg";
import dateFormat from "dateformat";
var jwt = require("jsonwebtoken");

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
    borderRadius: 0,
    width: "100%",
    padding: "20px 20px",
    overflowY: "hidden",      
    [theme.breakpoints.down("md")]: {      
      padding: 0,
    },
  },
  results: {
    backgroundColor: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    transition: "0.2s",
    borderRadius: "20px",
    transition: "0.2s",
    overflowY: "scroll",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "80vh",
    },
  },
  resultsMobile: {
    // height: "68vh",
    width: "100%",
    margin: "0 auto",
    overflowY: "scroll",
  },
  mobileContract: {
    width: "100%",
    height: "210px",
    display: "flex",
  },
  mobileLeft: {
    width: "25%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: "0 1 auto",
    justifyContent: "space-around",
  },
  mobileRight: {
    width: "75%",
    padding: "30px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  contractDisplay: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bottomDiv: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "23px",
    backgroundColor: "#F1F1F1",
    borderRadius: "0 0 20px 20px",

    "&:hover": {
      cursor: "pointer",
    },
  },
  downArrow: {
    height: "20px",
    width: "20px",
    transition: "0.3s",
  },
}));

function Options({ setActiveStep }) {
  const [expanded, setExpanded] = useState(false);
  const [userId, setUserId] = useState();
  const [contracts, setContracts] = useState([]);
  const [defaultVal, setDefaultVal] = useState("Select");
  const [loading, setLoading] = useState(true);
  const mobile = useMediaQuery("(max-width: 900px)");
  const classes = useStyles();

  useEffect(() => {
    jwt.verify(
      localStorage.getItem("accessToken"),
      process.env.REACT_APP_PrivateKey,
      function (err, decoded) {
        setUserId(decoded.id);
      },
    );
    var bearer = "Bearer " + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);
    fetch(
      "https://abintus-app-api-linux.azurewebsites.net/contracts/expert-assessment",
      {
        method: "GET",
        headers: {
          token: token,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        res.forEach((contract) => {
          if (contract[1] == userId) {
            setContracts((prev) => [...prev, contract]);
          }
          setLoading(false);
        });
      });
    var accessToken = localStorage.getItem("accessToken");
    var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);
  }, [userId]);

  const handleNext = () => {
    setActiveStep(0);
  };

  function setCharAt(str, index, chr) {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
  }

  async function analysisOpen(document, id) {
    var accessToken = localStorage.getItem("accessToken");
    var validToken = jwt.verify(accessToken, process.env.REACT_APP_PrivateKey);
    var bearer = "Bearer " + process.env.REACT_APP_SecretKey;
    var clientName = validToken.firstName + " " + validToken.lastName;
    var token = jwt.sign(
      {
        token: bearer,
        ClientEmail: validToken.username,
        ClientName: clientName,
        DocLink: document,
        DocType: "Expert Analysis",
        Id: id,
      },
      process.env.REACT_APP_ReadToken,
    );
    await fetch(
      "https://abintus-app-api-linux.azurewebsites.net/non-db/user-notif",
      {
        method: "POST",
        headers: {
          token: token,
        },
      },
    );
  }

  return (
    <Card className={classes.card}>
      <div className="start-div" onClick={handleNext}>
        <Hidden only={["xs", "sm"]}>
          <div className="sliding-div">
            <Typography variant="h3">Start Now</Typography>
          </div>
        </Hidden>
        <div className="non-sliding-div">
          <Typography variant="h3">Upload New Contract</Typography>
        </div>
      </div>
      <Typography
        variant="h3"
        style={{ fontSize: "20px", marginBottom: "10px" }}
      >
        My Contracts
      </Typography>
      {mobile ? (
        <Box className={classes.resultsMobile}>
          {contracts.length ? (
            <>
              {contracts.map((contract, index) => {                
                return (
                  <div
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                      borderRadius: "20px",
                      marginBottom: "30px",
                    }}
                    key={index}
                  >
                    <StyledHeader style={{ width: "100%", height: "30px" }} />
                    <div className={classes.mobileContract}>
                      <div className={classes.mobileLeft}>
                        <StatusCircle
                          style={{
                            backgroundColor:
                              contract[9] !== null ? "#07AF1B" : "#FAE166",
                          }}
                        />
                        <div>
                          <Typography variant="h4">ID:</Typography>
                          <Typography variant="h1">{contract[0]}</Typography>
                        </div>
                      </div>
                      <div className={classes.mobileRight}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Typography
                              variant="h4"
                              style={{ fontSize: "15px" }}
                            >
                              Agency:
                            </Typography>
                            <Typography variant="h1">{contract[10]}</Typography>
                          </div>
                          <div>
                            <Typography
                              variant="h4"
                              style={{ fontSize: "15px" }}
                            >
                              Contract:
                            </Typography>
                            <a href={contract[3]} target="_blank">
                              <StyledButton style={{ padding: "7px 40px" }}>
                                View
                              </StyledButton>
                            </a>
                          </div>
                        </div>
                        <div>
                          <Typography variant="h4" style={{ fontSize: "15px" }}>
                            Analysis:
                          </Typography>
                          {
                            contract[9] ? (
                              <a href={contract[9]} target="_blank">
                                <StyledButton
                                  onClick={() =>
                                    analysisOpen(contract[9], contract[0])
                                  }
                                  style={{ padding: "12px 40px", width: "100%" }}
                                >
                                  View
                                </StyledButton>
                              </a>
                            ) : (
                              <Typography variant="body1">Pending</Typography>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <Typography variant="h3">
              You have not uploaded any contracts to be reviewed
            </Typography>
          )}
        </Box>
      ) : (
        <Box className={classes.results} style={{ height: "400px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                position: "sticky",
                top: 0,
                borderRadius: "20px 20px 0 0",
                width: "100%",
                marginBottom: "15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#F5F5F5",
              }}
            >
              <StyledHeader>
                <div style={{ width: "20%", textAlign: "center" }}>
                  <Typography variant="h3" style={{ fontSize: "15px" }}>
                    ID
                  </Typography>
                </div>
                <div style={{ width: "20%", textAlign: "center" }}>
                  <Typography variant="h3" style={{ fontSize: "15px" }}>
                    Agency
                  </Typography>
                </div>
                <div style={{ width: "20%", textAlign: "center" }}>
                  <Typography variant="h3" style={{ fontSize: "15px" }}>
                    Contract(s)
                  </Typography>
                </div>
                <div style={{ width: "20%", textAlign: "center" }}>
                  <Typography variant="h3" style={{ fontSize: "15px" }}>
                    Status
                  </Typography>
                </div>
                <div style={{ width: "20%", textAlign: "center" }}>
                  <Typography variant="h3" style={{ fontSize: "15px" }}>
                    Date Uploaded
                  </Typography>
                </div>
              </StyledHeader>
            </div>
            {loading ? (
              <>
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    }}
                >
                    <Contract className="loading-div"/>
                    <Contract className="loading-div"/>
                    <Contract className="loading-div"/>
                </div>
              </>
            ) : contracts.length ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {contracts.map((contract, index) => {
                  var date = dateFormat(Date.parse(contract[8]), "dd/mm/yyyy");
                  var noOfDocs = contract[7];
                  var contractLink = contract[3];
                  var analysisLink = contract[9];
                  var multipleContracts = [];

                  if (noOfDocs > 1) {
                    for (var i = 1; i <= noOfDocs; i++) {
                      var link = contractLink;
                      var location = link.indexOf("DocNr") + 5;
                      link = setCharAt(link, location, i.toString());
                      multipleContracts.push(
                        <a
                          href={link}
                          key={i}
                          target="_blank"
                          style={{ textDecoration: "none", color: "#000000" }}
                        >
                          <MenuItem>File {i}</MenuItem>
                        </a>,
                      );
                    }
                  }

                  return (
                    <Contract
                      key={index}
                      style={{ borderLeft: contract[9] && "solid 3px #07AF1B" }}
                    >
                      <div style={{ width: "20%", textAlign: "center" }}>
                        <Typography variant="body1">{contract[0]}</Typography>
                      </div>
                      <div style={{ width: "20%", textAlign: "center" }}>
                        <Typography variant="body1">{contract[10]}</Typography>
                      </div>
                      {noOfDocs > 1 ? (
                        <div style={{ width: "20%", textAlign: "center" }}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            style={{ width: "125px" }}
                          >
                            <Select
                              value={defaultVal}
                              // onChange={handleChange}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              {multipleContracts.map((item) => {
                                return <MenuItem key={item}>{item}</MenuItem>;
                              })}
                            </Select>
                            <FormHelperText>Select Contract</FormHelperText>
                          </FormControl>
                        </div>
                      ) : (
                        <div style={{ width: "20%", textAlign: "center" }}>
                          <a href={contractLink} target="_blank">
                            <StyledButton>View</StyledButton>
                          </a>
                        </div>
                      )}
                      <div style={{ width: "20%", textAlign: "center" }}>
                        {contract[9] != null ? (
                          <a
                            href={analysisLink}
                            target="_blank"
                            onClick={() =>
                              analysisOpen(analysisLink, contract[0])
                            }
                          >
                            <StyledButton>View Analysis</StyledButton>
                          </a>
                        ) : (
                          <Typography variant="body1">
                            Analysis Pending
                          </Typography>
                        )}
                      </div>
                      <div style={{ width: "20%", textAlign: "center" }}>
                        <Typography variant="body1">{date}</Typography>
                      </div>
                    </Contract>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "15px" }}>
                  You have not completed any Expert Assessments
                </Typography>
              </div>
            )}
          </div>
          {/* <div className={classes.bottomDiv} onClick={handleClick}>
                <img src={DownArrow} className={classes.downArrow} style={{ rotate: expanded && "180deg"}}/>
            </div> */}
        </Box>
      )}
    </Card>
  );
}

const Contract = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 75px;
  margin-bottom: 20px;
  padding: 0 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  height: 60px;
  padding: 0 30px;
  background-color: #f5f5f5;
  border-radius: 20px 20px 0 0;
`;

const StyledButton = styled.button`
  padding: 10px 28px;
  background-color: #f5f5f5;
  font-family: Poppins;
  border: none;
  transition: 0.2s;

  &:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }
`;

const StatusCircle = styled.div`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: inline-block;
`;

export default Options;
