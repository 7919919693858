import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import {
  Container,
  Typography,
  Grid,
  makeStyles,
  Link
} from '@material-ui/core';
import Logo from 'src/components/LogoLight'
import LinkedIn from './Media/LinkedIn.png'
import Youtube from './Media/Youtube.png'
import Facebook from './Media/Facebook.png'
import Insta from './Media/Insta.png'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#282829",
    paddingTop: 45,
    paddingBottom: 40,
    paddingRight: 45,
    paddingLeft: 45,
    // height: "50vh",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    color: "#FFFFFF",
    marginBottom: "50px",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    },  
  },
  column: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",   
  },
  header: {
    marginBottom: "20px"
  },
  link: {
    marginBottom: "14px",
  },
  social: {
    height: "32px",
    marginRight: "5px",

    "&:hover": {
      transform: "scale(1.1)"
    }
  },
  info: {
    display: "flex",
    color: "#c8c8c8",
    justifyContent: "flex-end",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    },  
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  // link:{
  //   '& :hover':
  //   {
  //     transform: 'scale(1.1)'
  //   }
  // }
}));

function Footer({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg" className={classes.container}>
        <Grid md={3}>
          <Logo/>
        </Grid>
        <Grid md={3} className={classes.column}>
          <Typography variant="h5" className={classes.header}>OTHER SERVICES</Typography>
          <Link className={classes.link} color="inherit" variant="body1" href="https://www.abintus.consulting/media-auditing">Holistic Media Auditing</Link>
          <Link className={classes.link} color="inherit" variant="body1" href="https://www.abintus.consulting/media-agency-pitch-management">Media Agency Pitch Management</Link>
          <Link className={classes.link} color="inherit" variant="body1" href="https://www.abintus.consulting/media-management-training">Media Management Training</Link>
          <Link className={classes.link} color="inherit" variant="body1" href="https://www.abintus.consulting/media-performance-tracking">Media Performance Tracking</Link>
        </Grid>
        <Grid md={3} className={classes.column}>
          <Typography variant="h5" className={classes.header}>LINKS</Typography>
          <Link className={classes.link} color="inherit" variant="body1" href="https://www.abintus.consulting/about-us">About</Link>
          <Link className={classes.link} color="inherit" variant="body1" href="https://www.abintus.consulting/careers">Careers</Link>
          <Link className={classes.link} color="inherit" variant="body1" href="https://www.abintus.consulting/blog">Resources</Link>
          <Link className={classes.link} color="inherit" variant="body1" href="https://www.abintus.consulting/consultation">Book a Consultation</Link>
          <Link className={classes.link} color="inherit" variant="body1" href="https://www.abintus.consulting/contact">Contact Us</Link>
        </Grid>
        <Grid md={3} className={classes.column}>
          <Typography variant="h5" className={classes.header}>STAY IN THE LOOP</Typography>
          <div>
            <Link href="https://www.linkedin.com/company/abintus-consulting"><img className={classes.social} src={LinkedIn} /></Link>
            <Link href="https://www.youtube.com/channel/UCCnJkaO4OSgw5dW3DvPiilA"><img className={classes.social} src={Youtube} /></Link>
            <Link href="https://www.facebook.com/abintus.consulting/"><img className={classes.social} src={Facebook} /></Link>
            <Link href="https://www.instagram.com/abintus_consulting/"><img className={classes.social} src={Insta} /></Link>
          </div>
        </Grid>
      </Container>
      <div className={classes.info}>
        <Typography variant="h6"><Link style={{color: "#c8c8c8", marginRight: "10px"}} onClick={()=> history.push("/terms")}>Terms &amp; Conditions</Link></Typography>
        <Typography variant="h6">|</Typography>
        <Typography variant="h6"><Link style={{color: "#c8c8c8", marginLeft: "10px", marginRight: "20px"}} onClick={()=> history.push("/privacy")}>Privacy Policy</Link></Typography>
        <Typography variant="h6">© 2022 Abintus Consulting®</Typography>
      </div>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
