/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styled from 'styled-components'
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Typography,
  makeStyles,
  CardContent
} from '@material-ui/core';
import Expert from './Media/Expert.jpg';


const useStyles = makeStyles((theme) => ({
  root: {}, 
}));

function Introduction({
  className,
  onBack,
  onNext,
  onComplete,
  setActiveStep,
  mobile,
  ...rest
}) {
  const classes = useStyles();

  const handleBack = () => {
    setActiveStep(null);
  }
 
  return (
    <Formik
      initialValues={{
      }}
      validationSchema={Yup.object().shape({
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          // Do API call to store step data in server session
          // It is important to have it on server to be able to reuse it if user
          // decides to continue later.
          setStatus({ success: true });
          setSubmitting(false);
          if (onComplete) {
            onComplete();
          }
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}         
          className={clsx(classes.root, className)}
          {...rest}
        >
          <CardContent>
            <Box mt={2} justifyContent="center" align="left">
            <img src={Expert} alt="ExpertAssessment" style={{height: '100%',
               width: '80%'}}></img>
            </Box>
            
          <Box mt={2}>
            <Box
              display="flex"
              alignItems="center"
            >
               <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
          >
          Using our expert assessment service, you will receive a comprehensive assessment of your media agency contract done by one of our media experts. 
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
               <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
          >
           Our expert assessment report will provide you with a detailed scorecard of over 20 points of analysis, as well as benchmarks for each of them. 
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
              align="justify"
            >
            <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
          >
         In addition, you will receive detailed findings and comments based on our review, highlighting issues with significant or financial implications that require consideration. 
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
            <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
          >
         You will also receive our recommendations for improvement and our best practices to reach a best-in-class media agency contract.  
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
            <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
          >
         To proceed, you will need to e-sign an NDA for confidentiality purposes. We guarantee that your contractual information will remain 100% confidential and will be held securely at all times.  
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
            <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
          >
         Once the NDA has been e-signed directly on our platform for simplicity and ease of use, you will then be able to upload your media agency contract for us to assess.  
          </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
            >
            <Typography
            variant="body1"
            color="textPrimary"
            align="justify"
          >
         48 hours after your contract upload, you will receive an email notification that your report is available in the ‘Your Results’ tab for you to view, download and print. You will also be able to schedule your call with one of our experts at a date and time of your choosing using our automated call scheduling platform.    
          </Typography>
            </Box>
          </Box>
          <Box
            mt={2}            
            style={{width: "100%", display: "flex", justifyContent: "space-between", marginTop: "40px"}}
          >            
            <StyledButton
            backgroundColor="#000000"
            color="#FFFFFF"
            hoverColor="#FAE082"
            onClick={handleBack}
            style={{ width: mobile && "40vw" }}
            >
             Back
            </StyledButton>
            <StyledButton
              className={classes.startButton}              
              disabled={isSubmitting}
              // type="submit"                            
              backgroundColor="#F2C230"
              color="#000000"
              hoverColor="#3D3D3D"
              style={{ width: mobile && "40vw" }}
            >
              Start
            </StyledButton>
          </Box>
          </CardContent>
        </form>
      )}
    </Formik>

  );
}

const StyledButton = styled.button`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  font-size: 1rem;
  width: 30%;
  border: none;
  padding: 10px;  
  transition: 0.2s;
  font-family: Poppins;

  &:hover {
    opacity: 0.8;
    transition: 0.2s;
    cursor: pointer;
  }
`

Introduction.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};

export default Introduction;
