/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  Typography,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import {
  BarChart2 as Bar2ChartIcon,
  Star as StarIcon,
  HelpCircle as HelpIcon,
  Mail as MailIcon,
  Settings as SettingsIcon,
  LogOut as LogOutIcon,
  Home as HomeIcon
} from 'react-feather';
import Account from '../Account'
import NavItem from './NavItem';
import Logo from 'src/components/LogoLight'
//
import { logout } from 'src/actions/accountActions';
import { useHistory } from 'react-router';
import { useDispatch} from 'react-redux';
import { useSnackbar } from 'notistack';
var jwt = require('jsonwebtoken');

const navConfig = [
  {
    // subheader: 'MEDIA AGENCY CONTRACT',
    items: [
      {
        icon: Logo,
        href: "/",
        title: ""
      },
      {
        title: 'Your Dashboard',
        icon: HomeIcon,
        href: '/app/your-dashboard'
      },
      {      
        title: 'Getting Started',
        icon: HelpIcon,
        href: '/app/getting-started'
      },
      {      
        title: 'Self Assessment',
        icon: Bar2ChartIcon,
        href: '/app/self-assessment'
      },
      {      
        title: 'Expert Assessment',
        icon: StarIcon,
        href: '/app/expert-assessment'
      },
      {      
        title: 'My Account',
        icon: SettingsIcon,
        href: '/app/settings'
      },
      {      
        title: 'Contact Us',
        icon: MailIcon,
        href: '/app/contact-us'
      },      
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List  disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;  
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}               
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {    
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 0,    
    // height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  scrollBar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",  
    alignItems: "center",
    paddingBottom: "10px",
  },
  logOut: {
    width: "90%",       
    textAlign: "center"
  }, 
  logOutLink: {
    color: "#FFFFFF",
    margin: 0,

    "&:hover": {
      cursor: "pointer"
    }
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();   
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const handleLogout = async () => {
    var id = 0;
    jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {
      id = parseInt(decoded.id);
    });
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ UserId:id, EventId:4, token: bearer }, process.env.REACT_APP_ReadToken);
    fetch('https://abintus-app-api-linux.azurewebsites.net/user/event'
    ,{
      method: 'POST',
      headers: {
          'token': token
      }}
      );
    try {
      // handleClose();
      await dispatch(logout());
      navigate('/')
      window.location.reload();
    } catch (error) {
      
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      style={{backgroundColor:"#025373"}}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }} 
      className={classes.scrollBar}
      >        
        <Box p={2} style={{marginRight: "30px"}}>
          {navConfig.map((config, index) => (           
            <List
              key={index}          
              subheader={(
                <ListSubheader                
                  disableGutters
                  disableSticky
                  style={{color:"#FFFFFF"}}
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>        
        <Box className={classes.logOut}>
          <Divider style={{marginBottom: "10px"}}/>
          <Link variant="body1" className={classes.logOutLink} onClick={handleLogout}>Log Out</Link>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
