import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
var jwt = require('jsonwebtoken');

function AuthUserId({ children }) {
var navigate = useNavigate();

  var dataLayer = window.dataLayer = window.dataLayer || []; 
  jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {
      if(err)
      {
        navigate("/")
      }
      else
      {
      dataLayer.push({ "user_id": decoded.id });
      }
      });

      return children;
}

AuthUserId.propTypes = {
  children: PropTypes.any
};

export default AuthUserId;
