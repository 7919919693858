import React, {
} from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import {
 Grid,
 CircularProgress,
 makeStyles
} from '@material-ui/core';
var jwt = require('jsonwebtoken');
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
      paddingTop: "24px",
      paddingBottom: "24px"
    }
  }
}));

var eventColumns = [{
  name: "Time",
  options: {
    filter: false,
  }
  },
  {
    name: "Event",
    options: {
      filter: true,
    }
    }
  ];

var eventData = [];
var eventOptions = {
  filterType: "dropdown",
  responsive: "standard",
  rowsPerPageOptions: [5,10,50],
  rowsPerPage: 5,
    onTableInit: (action, tableState) => {
      var l = [];
      tableState.displayData.forEach(element => {
        l.push(element.data);
      });
      },
  selectableRows: "none"
};
    
var eventDataArray = [];  

class EventTable extends React.Component {
  _isMounted = false;
    constructor(props){
      super(props);
      this.state = {
        id: props.id,
        eventdata: [],
        Loading: true,
      };
    }
    render() {
      return (
        <React.Fragment>
        <Grid
        item
        md={6}
        lg={6}
        xl={6}
      >
        {/* <MuiThemeProvider 
        theme={theme} 
      >  */}
        <MUIDataTable
         className={useStyles.table}
          title={<h1 variant="title">
          {this.state.Loading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4, color:"#f8d02d"}} />}
          </h1>
          }
          data={eventData}
          columns={eventColumns}
          options={eventOptions}
      > </MUIDataTable>
         {/* </MuiThemeProvider> */}
      </Grid>
      </React.Fragment>);
      }

      componentWillUnmount() {
        this._isMounted = false;
      }
  
      componentDidMount(){
          this._isMounted = true;
          if (this._isMounted && eventData.length < 1) 
          {
            this.getEvents();
          }
          else
          {
            this.setState({Loading: false});
          }
        }

        getEvents = async _=> {
        var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
        var token = jwt.sign({ token: bearer }, process.env.REACT_APP_ReadToken);
        var output = await fetch('https://abintus-app-api-linux.azurewebsites.net/user/user-events'
        ,{
          method: 'GET',
          headers: {
              'token': token
          }}
        );
        var eventsLookUp = await output.json();
        fetch(`https://abintus-app-api-linux.azurewebsites.net/user/events/${this.state.id}`
        ,{
          method: 'GET',
          headers: {
              'token': token
          }}
          )
        .then(response => response.json())
        .then(response => {
          response.forEach((element => {
            var date = new Date();
            date.setDate(date.getDate() + 1);
            var datetime = Date.parse(element[3]);
            var diff = (date - datetime)/(1000*60*60*24);
            var timeDiff = "";
            if(diff > 2)
            {
                timeDiff = Math.round(diff,0) + " days ago";
            }
            else if(Math.round(diff * 24,0)  > 2)
            {
                timeDiff = Math.round(diff * 24,0) + " hours ago";
            }
            else if(Math.round(diff * 60 * 24,0)  > 1)
            {
                timeDiff = Math.round(diff * 60 * 24,0) + " minutes ago";
            }
            else
            {
               timeDiff = "Less than 1 minute ago";
            }

            eventDataArray.push([timeDiff,eventsLookUp[element[2] - 1][1]]);
          }));

          eventData = eventDataArray.reverse();
          this.setState({Loading: false});
        });
      } 
    }

function Logs({ className,userId, ...rest }) {
  return (
    <Grid container spacing={3}
    alignItems="center"
    justify="center"
    >
      <EventTable id={userId}></EventTable>
 
    </Grid>
  );
}

Logs.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.any
};

export default Logs;
