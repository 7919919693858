import "../fonts.css";

export default {
  p: {
    fontFamily: "Poppins"
  },
  h1: {
    fontSize: 35,
    // fontWeight: 900,
    fontFamily:"PoppinsExtraBold",
  },
  h2: {
    fontSize: 29,
    fontFamily:"Poppins",
  },
  h3: {
    fontSize: 24,
    fontFamily:"PoppinsExtraBold",
  },
  h4: {
    fontSize: 20,
    fontFamily:"Poppins",
  },
  h5: {
    fontSize: 19,
    fontFamily:"PoppinsExtraBold",
  },
  h6: {
    fontSize: 12,
    fontFamily:"PoppinsSemiBold",
  },
  body1:{
    fontSize: 17,
    fontFamily:"Poppins",
  },
  body2:{
    fontFamily:"Poppins",
  },
  overline: {
    fontFamily:"Poppins",
  }
};
