import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import LogoDark from 'src/components/Logo';
import RegisterForm from './RegisterForm';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: "#F2F2F2",
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 50
  },
  card: {
    boxShadow: "none",
    borderRadius: "30px",
    padding: "0 40px 10px 40px"
  }
}));

function RegisterView() {
  const classes = useStyles();

  const handleSubmitSuccess = () => {    
  };

  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Container maxWidth="sm">
        <Box
          mb={1}
          display="flex"
          alignItems="center"
        >
          <RouterLink to="/">
            <LogoDark />
          </RouterLink>        
        </Box>
        <Card className={classes.card}>
          <CardContent>            
            <Box mt={2}>
              <RegisterForm onSubmitSuccess={handleSubmitSuccess} />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <Link
              component={RouterLink}
              to="/login"
              variant="body2"
              color="textSecondary"
            >
              Have an account?
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default RegisterView;
