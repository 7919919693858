import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  Avatar,
  Button,
  Box,
  Container,
  Card,
  CardContent,
  Divider,
  Link,
  Typography,
  makeStyles,
  Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import ConfirmAccountForm from './ConfirmAccountForm';
import ConfirmCodeForm from './ConfirmCodeForm';
import ResetPasswordForm from './ResetPasswordForm';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: "#F2F2F2",
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80
  },
  backButton: {
    marginLeft: theme.spacing(2)
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    boxShadow: 'none',
    borderRadius: '30px',
    marginBottom: "10px",
    padding: "0 20px 20px 20px",
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(7, 4, 3, 4)
  },
  icon: {
    backgroundColor: "#f8d02d",
    color: "#14213D",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64
  },
  otherOptions: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 10px",
  }
}));

function ResetPasswordView() {
  const navigate = useNavigate();
  const classes = useStyles();
  const history = useHistory();
  const [subText,setSubText] = useState("Enter your email address and we'll send you a code to reset your password");
  const [showConfirmCode, setShowConfirmCode] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const [open, setOpen] = useState(false);
  const confirmAccount = () => {
    setSubText("Please enter the code that we've sent you");
    setShowConfirmCode(true);
  };

  const confirmCode = () => {
    setSubText("Please enter your new password");
    setShowResetForm(true);
  };

  const SubmitPassword = () => {
    setOpen(true);
  };

  const handleClose = () => {
    navigate('/login');
  };

  return (
    <Page
      className={classes.root}
      title="ResetPassword"
    >
      <Container maxWidth="sm">
        <Box
          mb={8}
          display="flex"
          alignItems="center"
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>          
        </Box>
        <Card className={classes.card}>
          <CardContent className={classes.content}>          
            <Typography
              variant="h2"
              color="textPrimary"
            >
              Reset your password
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
            </Typography>
            <Box my={1}>
            </Box>
            <Typography
              variant="h5"
              color="textSecondary"
            >
             {subText}
            </Typography>
            <Box mt={2}>
              {showConfirmCode === false && <ConfirmAccountForm onSubmitSuccess={confirmAccount} /> }
              {showConfirmCode === true && showResetForm === false && <ConfirmCodeForm onSubmitSuccess={confirmCode} />}
              {showResetForm === true && <ResetPasswordForm onSubmitSuccess={SubmitPassword} />}
            </Box>            
          </CardContent>          
        </Card>        
        <div className={classes.otherOptions}>        
          <Link
            component={RouterLink}
            to="/login"
            variant="body2"
            color="textSecondary"
          >
            Login
          </Link>            
          <Link
            component={RouterLink}
            to="/register"
            variant="body2"
            color="textSecondary"
          >
            Register
          </Link>
        </div>
      </Container>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="DialogTitle">
        <DialogTitle id="DialogTitle">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your account has been succesfully updated.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default ResetPasswordView;
