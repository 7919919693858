import React, {
  useRef,
  useState,useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch} from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import { logout } from 'src/actions/accountActions';
var jwt = require('jsonwebtoken');

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    backgroundColor:"#f8d02d",
    color:"#14213D",
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();  
  const [isOpen, setOpen] = useState(false);
  const [firstName,setFirstName] = useState("");
  const [lastName,setLastName] = useState("");
  useEffect(() => { 
    const abortController = new AbortController();
    const signal = abortController.signal;
    jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {
      setFirstName(decoded.firstName);
      setLastName(decoded.lastName);
    });
    
    return function cleanup()
    {
      abortController.abort();
    }
  },[]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    var id = 0;
    jwt.verify(localStorage.getItem('accessToken'), process.env.REACT_APP_PrivateKey,function(err, decoded) {
      id = parseInt(decoded.id);
    });
    var bearer = 'Bearer ' + process.env.REACT_APP_SecretKey;
    var token = jwt.sign({ UserId:id, EventId:4, token: bearer }, process.env.REACT_APP_ReadToken);
    fetch('https://abintus-app-api-linux.azurewebsites.net/user/event'
    ,{
      method: 'POST',
      headers: {
          'token': token
      }}
      );
    try {
      handleClose();
      await dispatch(logout());
      history.push('/');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
        />
        <Hidden smDown>
          <Typography
            variant="h6"
            color="inherit"
            style={{fontWeight:"Bold",color:"#FFFFFF"}}
          >
             {/* {firstName.charAt(0).toUpperCase() + firstName.slice(1) + " " + lastName.substring(0,1).charAt(0).toUpperCase()} */}
            {`${firstName} ${lastName}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          component={RouterLink}
          to="/app/settings"
        >
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;