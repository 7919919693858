import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  }
}));

const textData=[["Signed by both Parties?","Is your Contract signed by both parties?"],["Terms & Definitions?","Does your contract include 'Defined Terms and Definitions'?"],["Deliverables Included?","Are the agreed 'Services' and 'Deliverables' clearly described?"],["Reporting Clauses?","Does your contract cover 'Detailed and Transparent Reporting'?"],
["Acting as Agent?","Is your Agency acting as an Agent?"],["Authority Clauses?","Does your Contract have standard 'Authority' clauses?"],["Exclusivity?","Is the exclusive / non-exclusive basis reciprocal?"],["Key Individuals Included?","Does your contract cover key individuals working on your account?"],
["Transparent Remuneration?","Is the Agency remuneration calculated in a transparent manner?"],["PRF Included?","Does your agency remuneration model include a PRF?"],["Bonus and Malus?","Does your PRF include both a bonus and malus mechanism?"],["Methodology?","Does your contract includes methodology for calculating the agency bonus/malus?"],
["Agency Commitments?","Does your contract include any agency commitments for all key media channels?"],["Audit Rights?","Does your contract include any audit rights?"],["Treatment of AVBs?","Does your Contract cover the treatment of Agency Volume Benefits (AVBs)?"],["Treatment of EPDs?","Does your contract cover the treatment of Early Payment Discounts (EPDs)?"],
["Unbilled Media Clauses?","Does your contract cover the treatment of Unbilled Media?"],["Payment Terms OK?","Are your agreed payment terms competitive?"],["Standard Termination?","Do you have standard termination clauses?"],["Sub-Contracting Clauses?","Does the contract has standard sub-contracting clauses?"]]

function KPIChart({ className, ...rest }) {
  const classes = useStyles();
  const data = {
    value: parseInt(rest.percentage),
    key: parseInt(rest.keyvalue)
  };
    return (
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box flexGrow={1} align="center">
          <Typography
            component="h3"
            gutterBottom
            variant="overline"
            color="textSecondary"
          >
           {textData[data.key][0]}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            
            style={{width: "125px"}}
          >
             <AnimatedProgressProvider valueStart={0} valueEnd={data.value} duration={3} easingFunction={easeQuadInOut}>
                      {value => { const roundedValue = Math.round(value);return (<CircularProgressbar  value={value} text={`${roundedValue}%`} styles={buildStyles({ pathTransition: "none",textColor: "#14213D",pathColor: "#14213D",trailColor:"white" })}/>);}}
                    </AnimatedProgressProvider>
          </Box>
          <div></div>
          <Typography
            component="h3"
            gutterBottom
            variant="overline"
            color="textSecondary"
          > {textData[data.key][1]}</Typography>
        </Box>
      </Card>
    );
}

KPIChart.propTypes = {
  className: PropTypes.string
};

export default KPIChart;
