import React from 'react';
import styled, { keyframes } from 'styled-components'
import { makeStyles } from '@material-ui/core';
import {    
    Container,
    Grid,   
    Typography         
  } from '@material-ui/core';
import Phillips from './Media/phillips.png';
import Cartier from './Media/cartier.png';
import Absolut from './Media/absolut.png';
import Montblanc from './Media/montblanc.png';
import Richemont from './Media/richemont.png';
import Kao from './Media/kao.png';
import Stage from './Media/stage.png';
import Betway from './Media/betway.svg';
import PizzaHut from './Media/pizzahut.svg';
import Telepizza from './Media/telepizza.svg';
import FoodDelivery from './Media/food-delivery.svg';
import JohnFrieda from './Media/john-frieda.svg';
import GUS from './Media/gus.png';
import { after, before } from 'lodash';

var companies = [
    Telepizza, Phillips, Cartier, FoodDelivery, Betway, Absolut, JohnFrieda, Montblanc, Richemont, PizzaHut, Kao, Stage, GUS, Telepizza, Phillips, Cartier, FoodDelivery, Betway, Absolut, JohnFrieda, Montblanc, Richemont, PizzaHut, Kao, Stage, GUS
];

const useStyles = makeStyles((theme) => ({
    root: {
        height: "160px",
        width: "100vw",
        marginTop: 10,
        marginBottom: 10,  
        [theme.breakpoints.down('md')]: {
            marginBottom: 0,
        }
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center"        
    },
    companyLogo: {
        height: "160px",
        width: "200px",  
        margin: "0 30px 0 30px",

        [theme.breakpoints.down('md')]: {
            height: "120px",
            width: "125px"
        }
    }
}));

function Carousel(props){
    const classes = useStyles();

    return (
        <div className={classes.root}>            
            <Slider>
                <SlideTrack>                   
                    {companies.map((company, i) => {
                        return (
                            <img 
                            src={company} 
                            key={i} 
                            className={classes.companyLogo}
                            style={{
                                margin: company == Stage && "0 0 0 0",                                 
                            }}
                            />
                        )
                    })}
                </SlideTrack>            
            </Slider>
        </div>
    )
}

const scroll = keyframes`
    0% { transform: translateX(0) };
    100% { transform: translateX(calc(-250px * 12))};
`
const Slider = styled.div`
    // background: white;
    height: 150px;
    margin: auto;
    overflow:hidden;
    position: relative;
    width: 100vw;
    
    &::before,
    &::after {
        // @include white-gradient;
        content: "";
        height: 160px;
        position: absolute;
        width: 20%;
        z-index: 2;
        background: linear-gradient(to right,  rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }
    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }
    &::before {
        left: 0;
        top: 0;
    }
`
const SlideTrack = styled.div`
    animation: ${scroll} 35s linear infinite;
    display: flex;
    width: calc(250px * 23);
`


export default Carousel;
